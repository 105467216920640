import type { Language } from '../domain/user.ts'
import { assertNonEmpty, objectKeys } from '../util.ts'
import type { DateString } from './date.ts'
import type {
  DiscussionCommentId,
  DiscussionNoteId,
  DiscussionId,
  EvaluationId,
  ManagementFeedbackId,
  PulseRoundId,
  RequestId,
  TeamId,
} from './ids.ts'
import { messagingPlatformTypes, type MessagingPlatformType } from './messagingPlatform.ts'
import type { SlackMessageContent } from './slack.ts'
import type { User } from './user.ts'
import type { NonEmptyArray } from './common.ts'

export type SendNotificationResult = 'error' | 'optout' | 'demo' | 'email' | MessagingPlatformType
export type EvaluationNotificationType =
  | 'evaluation-request'
  | 'evaluation-reminder'
  | 'evaluation-reminder-final-hour'
  | 'evaluation-extended'
  | 'evaluation-extended-last-chance'

export type EmailDestination = {
  type: 'email'
  email: string
}

export type UserDestination = {
  type: 'user'
  user: User
}

export type OnboardingEmailPayload =
  | {
      template: 'onboarding-intro'
      destination: UserDestination
      preference: 'recommendations'
      lang: 'en'
      params: null
    }
  | {
      template: 'onboarding-feedback'
      destination: UserDestination
      preference: 'recommendations'
      lang: 'en'
      params: null
    }
  | {
      template: 'onboarding-pulse'
      destination: UserDestination
      preference: 'recommendations'
      lang: 'en'
      params: null
    }
  | {
      template: 'onboarding-praise'
      destination: UserDestination
      lang: 'en'
      preference: 'recommendations'
      params: null
    }

export type AlwaysEmailNotificationPayload =
  | {
      template: 'login'
      destination: EmailDestination
      preference: 'none'
      lang: 'en'
      params: {
        loginUrl: string
      }
    }
  | {
      template: 'login-missing-email'
      destination: EmailDestination
      preference: 'none'
      lang: 'en'
      params: null
    }
  | {
      template: 'invited-by-perspective'
      destination: EmailDestination
      preference: 'none'
      lang: 'en'
      params: {
        loginUrl: string
      }
    }
  | {
      template: 'invitation-and-feedback'
      destination: EmailDestination
      preference: 'none'
      lang: Language
      params: {
        loginUrl: string
        feedbackRequesterFirstName: string
        feedbackRequesterFullName: string
        personalMessage: string | null
      }
    }
  | {
      template: 'evaluation-request-with-invite'
      destination: EmailDestination
      preference: 'none'
      lang: Language
      params: {
        evaluationId: EvaluationId
        evalueeEmail: string
        evalueeFullName: string
        firstName: string
        actionUrl: string
        expires: string
        workspaceName: string
        templateMessage: string | null
      }
    }
  | {
      template: 'pulse-start'
      destination: EmailDestination
      preference: 'pulseReminders'
      lang: Language
      params: { surveyName: string; teamName: string; actionUrl: string; closingTime: string }
    }
  | {
      template: 'pulse-round-started'
      destination: EmailDestination
      preference: 'pulseReminders'
      lang: Language
      params: { surveyName: string; teamName: string; actionUrl: string; closingTime: string }
    }
  | OnboardingEmailPayload
  | {
      template: 'network-survey-started'
      destination: EmailDestination
      preference: 'pulseReminders'
      lang: Language
      params: {
        firstName: string
        teamName: string
        answerBy: string
        actionUrl: string
      }
    }
  | {
      template:
        | 'workspace-free-trial-activated'
        | 'workspace-free-trial-reminder'
        | 'workspace-free-trial-ended'
      destination: EmailDestination
      preference: 'none'
      lang: 'en'
      params: {
        workspaceName: string
        teamId: TeamId
        firstName: string
        trialEnds: string
        trialDaysLeft: number
        actionUrl: string
      }
    }
  | {
      template: 'slack-token-invalidated'
      destination: EmailDestination
      preference: 'none'
      lang: Language
      params: { slackWorkspaceName: string; actionUrl: string }
    }
  | {
      template: 'notification'
      destination: UserDestination
      preference: 'none'
      lang: 'en'
      params: SlackMessageContent & { emailFallbackUrlDescription: string }
    }

export type PulseReminderPayload = {
  template: 'pulse-reminder' | 'pulse-final-hour'
  destination: UserDestination
  preference: 'pulseReminders'
  lang: Language
  params: {
    surveyName: string
    actionUrl: string
    closingTime: string
    teamId: TeamId
    teamName: string
    pulseRoundId: PulseRoundId
  }
}

export type PulseCommentReactionNotificationPayload = {
  template: 'pulse-comment-reaction'
  destination: UserDestination
  preference: 'pulseReminders'
  lang: Language
  params: {
    actionUrl: string
    reactionCount: number
    reactionsInMultipleComments: boolean
  }
}

export type FeedbackReminderPayload = {
  template: 'feedback-reminder'
  destination: UserDestination
  preference: 'feedback'
  lang: Language
  requestId: RequestId
  retryCount?: number
  params: {
    feedbackRequesterFirstName: string
    feedbackRequesterFullName: string
    personalMessage: string
    actionUrl: string
    requestId: RequestId
  }
}

export type ManagementFeedbackReminderPayload = {
  template: 'workspace-feedback-recipient-reminder'
  destination: UserDestination
  preference: 'none'
  lang: Language
  params: {
    managementFeedbackId: ManagementFeedbackId
    managementFeedbackChannelName: string
    workspaceName: string
    actionUrl: string
  }
}

export type EvaluationReminderPayload = {
  template: EvaluationNotificationType
  destination: UserDestination
  preference: 'feedback'
  lang: Language
  params: {
    evaluationIds: EvaluationId[] // Used to update the lastNotifiedAt and lastNotifiedMedium
    actionUrl: string
    firstName: string
    pendingEvaluationsHeading: string
    expires: string // Refers to the earliest expiration date of the pending evaluations
    timeLeftHours: number // Time left until the evaluation we're reminding about expires
  }
}

export type PulseCommentReplyNotificationPayload = {
  template: 'pulse-comment-reply'
  destination: UserDestination
  preference: 'none'
  lang: Language
  params: {
    replierName: string | null
    actionUrl: string
    replyContent: string
  }
}

type NetworkQuestionnaireReminderPayload = {
  template: 'network-survey-reminder'
  destination: UserDestination
  preference: 'none'
  lang: Language
  params: {
    firstName: string
    teamName: string
    answerBy: string
    actionUrl: string
    mentionCount: number
  }
}

type MinimalUser = { email: string }

export type WorkspaceUpdatesNotificationPayload = {
  template: 'workspace-updates'
  destination: UserDestination
  preference: 'none'
  lang: Language
  params: {
    workspaceName: string
    addedUsers: MinimalUser[]
    deactivatedUsers: MinimalUser[]
    linkedUsers: MinimalUser[]
  }
}

export type WorkspaceActivationSummaryNotificationPayload = {
  template: 'workspace-activation-summary'
  destination: UserDestination
  preference: 'none'
  lang: Language
  params: {
    workspaceName: string
    activatedRequests: number
    activatedPraise: number
    activatedListConnections: number
  }
}

type DiscussionNotificationEntity =
  | { kind: 'discussion'; id: DiscussionId }
  | { kind: 'note'; id: DiscussionNoteId }
  | { kind: 'comment'; id: DiscussionCommentId }

export type DiscussionNotificationDiff = DiscussionNotificationEntity & {
  action: 'created' | 'edited'
  title: string
  excerpt?: string
  timestamp: DateString
}

export type DiscussionNotificationPayload = {
  template: 'discussion-updated'
  destination: UserDestination
  preference: 'feedback'
  lang: Language
  params: {
    discussionId: DiscussionId
    discussionTitle: string
    diffs: NonEmptyArray<DiscussionNotificationDiff>
  }
}

export type MultiChannelNotificationPayload =
  | {
      template: 'added-to-workspace'
      destination: UserDestination
      preference: 'none'
      lang: Language
      params: {
        workspaceName: string
        actionUrl: string
      }
    }
  | {
      template: 'feedback-request'
      destination: UserDestination
      preference: 'feedback'
      lang: Language
      params: {
        actionUrl: string
        requestId: RequestId
        feedbackRequesterFirstName: string
        feedbackRequesterFullName: string
        personalMessage: string | null
      }
    }
  | {
      template: 'you-have-feedback'
      destination: UserDestination
      preference: 'feedback'
      lang: Language
      params: {
        feedbackProviderFirstName: string
        feedbackProviderFullName: string
        actionUrl: string
      }
    }
  | {
      template: 'you-have-anonymous-feedback'
      destination: UserDestination
      preference: 'feedback'
      lang: Language
      params: {
        actionUrl: string
      }
    }
  | {
      template: 'workspace-feedback-received'
      destination: UserDestination
      preference: 'none'
      lang: Language
      params: {
        workspaceName: string
        managementFeedbackChannelName: string
        actionUrl: string
        feedbackProviderName: string | null
      }
    }
  | {
      template: 'workspace-feedback-comment-received'
      destination: UserDestination
      preference: 'none'
      lang: Language
      params: {
        workspaceName: string
        actionUrl: string
        isFeedbackProvider: boolean
      }
    }
  | ManagementFeedbackReminderPayload
  | {
      template: 'you-have-praise'
      destination: UserDestination
      preference: 'feedback'
      lang: Language
      params: {
        feedbackProviderFirstName: string
        feedbackProviderFullName: string
        actionUrl: string
      }
    }
  | {
      template: 'request-comment-recieved'
      destination: UserDestination
      preference: 'feedback'
      lang: Language
      params: {
        commentContent: string
        authorFirstName: string
        authorFullName: string
        actionUrl: string
      }
    }
  | {
      template: 'pulse-results'
      destination: UserDestination
      preference: 'pulseResults'
      lang: Language
      params: {
        surveyName: string
        answerCount: number
        commentCount?: number
        actionUrl: string
        teamId: TeamId
        teamName: string
        pulseRoundId: PulseRoundId
      }
    }
  | {
      template: 'invitation-and-team'
      destination: UserDestination
      preference: 'none'
      lang: Language
      params: {
        loginUrl: string
        inviterFirstName: string
        inviterFullName: string
        teamName: string
      }
    }
  | {
      template: 'feedback-shared'
      destination: UserDestination
      preference: 'feedback'
      lang: Language
      params: { feedbackSharerFullName: string; actionUrl: string }
    }
  | {
      template: 'self-reflection-shared'
      destination: UserDestination
      preference: 'feedback'
      lang: Language
      params: { feedbackSharerFullName: string; actionUrl: string }
    }
  | {
      template: 'evaluations-published'
      destination: UserDestination
      preference: 'feedback'
      lang: Language
      params: { actionUrl: string }
    }
  | {
      template: 'evaluations-reviewable'
      destination: UserDestination
      preference: 'feedback'
      lang: Language
      params: { evaluees: { userName: string; actionUrl: string }[]; fallbackActionUrl: string }
    }
  | FeedbackReminderPayload
  | PulseReminderPayload
  | EvaluationReminderPayload
  | PulseCommentReactionNotificationPayload
  | PulseCommentReplyNotificationPayload
  | WorkspaceUpdatesNotificationPayload
  | NetworkQuestionnaireReminderPayload
  | WorkspaceActivationSummaryNotificationPayload
  | DiscussionNotificationPayload

export type TransactionalNotificationPreferenceId = MultiChannelNotificationPayload['preference']

export type NonTransactionalNotificationPreferenceId =
  | 'recommendations'
  | 'announcements'
  | 'research'
  | 'newsletter'

export type NotificationPreferenceId =
  | TransactionalNotificationPreferenceId
  | NonTransactionalNotificationPreferenceId

export type EditableNotificationPreferenceId = Exclude<NotificationPreferenceId, 'none'>

const notificationPreferenceIdMap: Record<EditableNotificationPreferenceId, null> = {
  feedback: null,
  pulseReminders: null,
  pulseResults: null,
  recommendations: null,
  announcements: null,
  research: null,
  newsletter: null,
} as const

export const notificationPreferenceIds = assertNonEmpty(objectKeys(notificationPreferenceIdMap))

export const TransactionalNotificationPreferenceKeyMap: {
  [key in TransactionalNotificationPreferenceId]: true
} = {
  feedback: true,
  pulseReminders: true,
  pulseResults: true,
  none: true,
} as const

export const NonTransactionalNotificationPreferenceKeyMap: {
  [key in NonTransactionalNotificationPreferenceId]: true
} = {
  recommendations: true,
  announcements: true,
  research: true,
  newsletter: true,
} as const

export const notificationMediums = ['email', ...messagingPlatformTypes] as const

export type NotificationMedium = (typeof notificationMediums)[number]

export type NonEmailNotificationMedium = Exclude<NotificationMedium, 'email'>

export type UserNotificationPreference = {
  enabled: boolean
  medium: NotificationMedium
}

export type UserNotificationPreferences = {
  [k in NotificationPreferenceId]: UserNotificationPreference
}

export type EveryNotificationPayload =
  | AlwaysEmailNotificationPayload
  | (MultiChannelNotificationPayload & { forcedNotificationMedium?: NotificationMedium })
