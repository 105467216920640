import { useState } from 'react'
import { Alert, Button, Input } from 'antd'
import { LockIcon } from '../icons'
import { translations } from '@teamspective/common'

const lang = 'en'

const CodeLoginForm = ({
  onSubmit,
}: {
  onSubmit: (params: { code: string }) => Promise<void>
}): JSX.Element => {
  const [code, setCode] = useState<string>('')
  const [errorState, setErrorState] = useState<boolean>(false)

  const submit = async (params: { code: string }) => {
    setErrorState(false)
    if (code.length === 0) {
      setErrorState(true)
    }
    try {
      await onSubmit(params)
    } catch {
      setErrorState(true)
    }
  }

  return (
    <div style={{ maxWidth: 420 }}>
      <Input
        id="input-code-login"
        onChange={(e) => setCode(e.target.value)}
        onPressEnter={() => {
          void submit({ code })
        }}
        autoFocus={true}
        prefix={<LockIcon style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
        placeholder="Code"
        type="text"
        style={{ marginBottom: 12 }}
      />
      <Button
        id="action-login"
        disabled={code.length === 0}
        onClick={() => submit({ code })}
        type="primary"
        block
        htmlType="submit"
        style={{ marginBottom: 24 }}
      >
        {translations[lang].login}
      </Button>
      {errorState ? (
        <Alert
          message="Sorry, something went wrong :("
          type="error"
          closable
          afterClose={() => setErrorState(false)}
          style={{ marginBottom: 24 }}
        />
      ) : (
        <div />
      )}
    </div>
  )
}

export default CodeLoginForm
