import { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import type { Language, RequestId } from '@teamspective/common'
import { links } from '@teamspective/common'

import { api } from '../../api'
import { translations } from '@teamspective/common'
import Loader from '../../components/Loader'
import { useNotifyOnResponse } from '../../apiActionNotification'
import CopyableText from '../../components/CopyableText'

const CopyLink = ({ link, lang }: { link: string; lang: Language }): JSX.Element => {
  return (
    <div style={{ display: 'flex' }}>
      <CopyableText
        content={link}
        lang={lang}
        copyText={translations[lang].copyLink}
        copiedText={translations[lang].linkCopied}
      />
    </div>
  )
}
export const ShareFeedbackModal = ({
  requestId,
  lang,
}: {
  requestId: RequestId
  lang: Language
}): JSX.Element => {
  const notifyOnResponse = useNotifyOnResponse()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState<null | string>(null)

  useEffect(() => {
    if (visible) {
      setLoading(true)
      void api.createViewFeedbackAuthToken({ requestId }).then((res) => {
        notifyOnResponse({ lang })(res)
        if (res.status === 'ok') {
          setToken(res.data.token)
        }
        setLoading(false)
      })
    }
  }, [requestId, visible, lang, notifyOnResponse])

  return (
    <div>
      <Modal
        closable={false}
        destroyOnClose={true}
        maskClosable={true}
        confirmLoading={loading}
        onCancel={() => {
          setLoading(false)
          setVisible(false)
        }}
        title={translations[lang].shareFeedback}
        open={visible}
        cancelText={translations[lang].cancel}
        onOk={() => {
          setLoading(false)
          setVisible(false)
        }}
      >
        <p>{translations[lang].feedbackVisibleWithLink}</p>
        {token ? (
          <CopyLink
            lang={lang}
            link={`https://${window.location.hostname}${links.sharedFeedback(token)}`}
          />
        ) : (
          <Loader />
        )}
      </Modal>
      <Button icon={<LinkOutlined />} onClick={() => setVisible(true)}>
        {translations[lang].share}
      </Button>
    </div>
  )
}
