import { useMemo, useRef, useState } from 'react'
import { Alert, Button, Divider } from 'antd'
import { useLocation } from 'react-router'
import { links } from '@teamspective/common'
import { Link } from 'react-router-dom'
import EmailForm from '../../components/user/EmailForm'
import authStore from '../../stores/authStore'
import GoogleSignIn from '../../components/integrations/GoogleSignIn'
import { translations } from '@teamspective/common'
import SignInWithSlack from '../../components/integrations/SignInWithSlack'
import { t } from './translations'
import BaseHeader from '../../components/BaseHeader'
import { animated, useSpring } from 'react-spring'
import { getIllustrationUrl } from '../../illustration'
import {
  Checkmark,
  DesktopBackgroundBlock1,
  DesktopBackgroundBlock2,
  DesktopImageWrapper,
  Heading,
  MainColumn,
  MobileBackgroundBlock1,
  MobileBackgroundBlock2,
  MobileImageWrapper,
} from './loginSignupSharedComponents'
import { useResizeObserver } from 'usehooks-ts'
import { Trans } from '@lingui/react/macro'

const lang = 'en'

type PageState =
  | { state: 'init'; email?: string }
  | { state: 'sent-link'; email: string; intent: 'login' | 'signUp' }

type LoginPageType = 'default' | 'code'

const LocationInfo = ({ type }: { type: LoginPageType }) => {
  const location = useLocation()

  switch (location.search) {
    case '?state=invalid-token':
      return (
        <Alert
          style={{ marginBottom: 32 }}
          message={type === 'default' ? t.en.invalidToken : translations[lang].invalidLoginCode}
          type="error"
        />
      )
    case '?state=expired-token':
      return <Alert style={{ marginBottom: 32 }} message={t.en.expiredToken} type="info" />
    default:
      return null
  }
}

const LoginPage = () => {
  const location = useLocation()
  const slackTeamId = useMemo(
    () => new URLSearchParams(location.search).get('slackTeamId'),
    [location.search]
  )
  const [pageState, setPageState] = useState<PageState>({
    state: 'init',
  })

  const { initiateMagicLogin } = authStore.useContainer()

  const imageAnimation = useSpring({
    from: { x: 200 },
    to: { x: 0 },
  })

  const loginRef = useRef(null)
  const { width } = useResizeObserver({ ref: loginRef })

  return (
    <div id="page-login">
      <BaseHeader />
      <MobileImageWrapper>
        <MobileBackgroundBlock1 $inverted />
        <MobileBackgroundBlock2 $inverted />
        <animated.img
          src={getIllustrationUrl('log-in-mobile')}
          style={{
            height: 240,
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            top: -20,
            left: 0,
            right: 0,
            textAlign: 'center',
            ...imageAnimation,
          }}
        />
      </MobileImageWrapper>

      <div style={{ display: 'flex' }}>
        <div
          style={{
            flex: '1 0 53%',
          }}
        >
          <MainColumn $side="left">
            {pageState.state === 'sent-link' ? (
              <>
                <Heading>
                  <Trans>Log in to Teamspective with your company account!</Trans>
                </Heading>
                <div style={{ textAlign: 'center' }}>
                  <Checkmark />
                  <h2>
                    <Trans>Check your email</Trans>
                  </h2>
                  <b style={{ color: 'rgba(0, 0, 0, 0.88)' }}>{pageState.email}</b>
                  <Divider />
                  <p>
                    <Trans>
                      We sent you an email at {pageState.email}. It has a link that will log you in.
                    </Trans>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div ref={loginRef} />
                <LocationInfo type="default" />
                <Heading>
                  <Trans>Log in to Teamspective with your company account!</Trans>
                </Heading>
                <SignInWithSlack
                  lang={lang}
                  type="login"
                  redirectPath={location.pathname}
                  slackTeamId={slackTeamId ?? undefined}
                  showFreeDomainError={false}
                />
                {width && width > 0 && (
                  <GoogleSignIn
                    lang={lang}
                    type="login"
                    width={width}
                    showFreeDomainError={false}
                  />
                )}
                <EmailForm
                  showRememberMe={true}
                  onSubmit={async (params) => {
                    const res = await initiateMagicLogin(params)
                    if (res.status !== 'redirect') {
                      setPageState({
                        state: 'sent-link',
                        intent: 'login',
                        email: params.email,
                      })
                    }
                  }}
                  cta="login"
                />
                <div>
                  <h4 style={{ marginBottom: 8 }}>
                    <Trans>Don&apos;t have an account yet?</Trans>
                  </h4>
                  <Link to={links.register} id="link-register">
                    <Button type="link">
                      <Trans>Sign up</Trans>
                    </Button>
                  </Link>
                </div>
              </>
            )}
          </MainColumn>
        </div>
        <DesktopImageWrapper>
          <DesktopBackgroundBlock2 $inverted>
            <DesktopBackgroundBlock1 $inverted>
              <animated.img
                src={getIllustrationUrl('log-in')}
                style={{ position: 'absolute', left: -75, bottom: -34, ...imageAnimation }}
              />
            </DesktopBackgroundBlock1>
          </DesktopBackgroundBlock2>
        </DesktopImageWrapper>
      </div>
    </div>
  )
}

export default LoginPage
