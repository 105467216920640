import type { TzDatabaseName } from '@teamspective/common'
import { DEFAULT_TIME_ZONE } from '@teamspective/common'

const getBrowserTimeZone = (): TzDatabaseName => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone as TzDatabaseName | 'UTC'
  // reverse special handling. see: https://tc39.es/ecma402/#sec-canonicalizetimezonename
  return tz === 'UTC' ? 'Etc/UTC' : tz
}

const tz = getBrowserTimeZone() || DEFAULT_TIME_ZONE
export default tz
