import type React from 'react'
import { Avatar, Badge, Tooltip } from 'antd'
import { UserOutlined as UserIcon, MailOutlined as MailIcon } from '@ant-design/icons'

import type { CompleteUser, LimitedUser, ToBeInvitedUser, User } from '@teamspective/common'
import {
  translations,
  uniqueBy,
  userIsUnlimited,
  userName,
  userNameWithEmail,
} from '@teamspective/common'

import { lightGrey, textColor, yellow } from '@teamspective/common'
import authStore from '../../stores/authStore'
import { userColor } from '../../colors'

const limitedUserColor = 'lightgrey'

const defaultRenderName = (user: User | 'anonymous') => {
  if (user === 'anonymous') {
    return ''
  } else {
    return userName(user)
  }
}

const userInitials = (user: CompleteUser | LimitedUser) =>
  `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`

export const UserAvatarBadge = ({ user }: { user: CompleteUser | LimitedUser }) => (
  <Badge
    color={userColor(user.email ?? '')}
    count={userInitials(user)}
    style={{ fontWeight: 700 }}
  />
)

export const UserAvatarGroup: React.FC<{
  users: (User | 'anonymous')[]
  style?: React.CSSProperties
  size?: 'small' | 'default'
  max?: number
  renderName?: (user: User | 'anonymous') => string
  tooltip?: boolean
  showNames?: boolean
}> = ({ users, style, size: sizeProp, renderName: renderNameProp, tooltip, showNames }) => {
  const renderName = renderNameProp ?? defaultRenderName
  const size = sizeProp ?? 'default'

  const avatarElement = (
    <div style={{ cursor: 'default' }}>
      <Avatar.Group max={{ count: 3 }} size={size} style={style}>
        {uniqueBy(users, (u) => (u === 'anonymous' ? u : u.userId)).map((user) => (
          <UserAvatar
            key={user === 'anonymous' ? user : user.userId}
            user={user}
            withToolTip={false}
            size={size}
          />
        ))}
      </Avatar.Group>
    </div>
  )

  const element = showNames ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: 180,
      }}
    >
      {avatarElement}
      <div
        className="hide-on-medium-and-narrow-screen"
        style={{
          marginLeft: 8,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {users.map((user) => renderName(user)).join(', ')}
      </div>
    </div>
  ) : (
    avatarElement
  )

  return tooltip ? (
    <Tooltip
      placement="bottom"
      overlay={
        <div>
          {users.map((user, i) => (
            <div key={user === 'anonymous' ? `anon-${i}` : user.userId}>{renderName(user)}</div>
          ))}
        </div>
      }
    >
      {element}
    </Tooltip>
  ) : (
    element
  )
}

const UserAvatar = ({
  user,
  extra,
  size: sizeProp,
  style: styleProp,
  withToolTip = true,
}: {
  user: User | ToBeInvitedUser | { status: 'icon'; icon: JSX.Element } | 'anonymous'
  style?: React.CSSProperties
  extra?: string
  size?: 'small' | 'default'
  withToolTip?: boolean
}): JSX.Element => {
  const { lang } = authStore.useContainer()
  const size = sizeProp ?? 'default'
  const gap = { small: 4, default: 6, large: 6 }[size]
  const style = { fontWeight: 700, ...styleProp }

  const avatar = (() => {
    if (user === 'anonymous') {
      return <Avatar gap={gap} style={style} size={size ?? 'default'} icon={<UserIcon />} />
    }
    switch (user.status) {
      case 'icon':
        return <Avatar gap={gap} style={style} size={size} icon={user.icon} />
      case 'to-be-invited':
        return (
          <Avatar
            gap={gap}
            style={{ flexShrink: 0, backgroundColor: yellow, color: textColor, ...style }}
            size={size}
            icon={<MailIcon />}
          />
        )
      case 'incomplete':
        return (
          <Avatar
            gap={gap}
            style={{ flexShrink: 0, backgroundColor: lightGrey, ...style }}
            size={size}
            icon={<UserIcon />}
          />
        )
      case 'limited':
      case 'complete': {
        const color = userIsUnlimited(user) ? userColor(user.email) : limitedUserColor
        const initials = userInitials(user)
        return (
          <Avatar
            gap={gap}
            style={{ flexShrink: 0, backgroundColor: color, ...style }}
            size={size}
            icon={<span>{initials}</span>}
          />
        )
      }
    }
  })()

  return withToolTip ? (
    <Tooltip
      title={`${user === 'anonymous' ? translations[lang].anonymous : user.status === 'icon' ? '' : userNameWithEmail(user)}${extra ? '\n' + extra : ''}`}
    >
      {avatar}
    </Tooltip>
  ) : (
    avatar
  )
}

export default UserAvatar
