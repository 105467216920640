import type React from 'react'
import type { FeedbackCategory, Language } from '@teamspective/common'
import { translations } from '@teamspective/common'
import { api } from '../../api'

const FeedbackInstructions: React.FC<{ lang: Language; category: FeedbackCategory }> = ({
  lang,
  category,
}) => (
  <div style={{ marginTop: 16, marginBottom: 16 }}>
    <p>{translations[lang][`${category}_explanation`]}</p>
    <ul style={{ paddingInlineStart: 16 }}>
      {translations[lang][`${category}_explanationItems`].map((str, i) => (
        <li key={i}>{str}</li>
      ))}
    </ul>
    <p>
      {translations[lang].explanationMoreTipsLink}{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://teamspective.com/blog/the-recipe-for-perfect-feedback/"
        onClick={() => api.logEvent({ event: 'clickedFeedbackInstructionsReadHere' })}
      >
        {translations[lang].readHere}
      </a>
    </p>
  </div>
)

export default FeedbackInstructions
