import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import { CheckCircleFilled } from '@ant-design/icons'
import type { CSSProperties } from 'react'

export const Heading = styled.h1`
  font-weight: normal;
`

export const MainColumn = styled.div<{ $side: 'left' | 'right' }>`
  box-sizing: border-box;
  width: 358px;
  display: flex;
  gap: 24px;
  align-items: stretch;
  flex-direction: column;
  margin: ${(p) => (p.$side === 'left' ? '100px 139px 0 auto' : '100px auto 0 139px')};
  text-align: center;
  @media screen and (max-width: 1000px) {
    height: initial;
    width: 100%;
    max-width: 352px;
    padding: 24px;
    margin: 32px auto;
  }
`

export const DesktopImageWrapper = styled.div`
  flex: 1 0 47%;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

export const MobileImageWrapper = styled.div`
  position: relative;
  display: none;
  @media screen and (max-width: 1000px) {
    display: flex;
  }
`

const backgroundColors = {
  desktop: [
    'linear-gradient(128deg, #ffd8bf 36.16%, #ffc49e 68.27%)',
    'linear-gradient(180deg, #aef0e7 0%, #b5f5ec 100%)',
  ],
  mobile: [
    'linear-gradient(128deg, #ffd8bf 36.16%, #ffc49e 68.27%)',
    'linear-gradient(180deg, #aef0e7 0%, #b5f5ec 100%)',
  ],
} as const

export const MobileBackgroundBlock1 = styled.div<{ $inverted?: boolean }>`
  height: 200px;
  width: 50%;
  background: ${(p) => backgroundColors.mobile[p.$inverted ? 1 : 0]};
`

export const MobileBackgroundBlock2 = styled.div<{ $inverted?: boolean }>`
  height: 220px;
  width: 50%;
  background: ${(p) => backgroundColors.mobile[p.$inverted ? 0 : 1]};
`

export const DesktopBackgroundBlock1 = styled.div<{ $inverted?: boolean }>`
  height: 500px;
  width: 100%;
  position: relative;
  background: ${(p) => backgroundColors.desktop[p.$inverted ? 1 : 0]};
`
export const DesktopBackgroundBlock2 = styled.div<{ $inverted?: boolean }>`
  height: calc(100vh - 64px);
  background: ${(p) => backgroundColors.desktop[p.$inverted ? 0 : 1]};
`

export const Checkmark = ({ style }: { style?: CSSProperties }) => {
  const checkMarkAnimation = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
  })
  return (
    <animated.div style={checkMarkAnimation}>
      <CheckCircleFilled
        style={{
          fontSize: 72,
          marginTop: 0,
          marginBottom: 40,
          color: '#5CDBD3',
          ...style,
        }}
      />
    </animated.div>
  )
}
