export type IllustrationName =
  | '404'
  | 'bell'
  | 'bell-no-activity'
  | 'plant'
  | 'receipt'
  | 'care'
  | 'sent'
  | 'completed'
  | 'sport'
  | 'device'
  | 'finance'
  | 'technologies'
  | 'downloading'
  | 'education'
  | 'lifting-cat'
  | 'waiting'
  | 'party'
  | 'party-together'
  | 'walk'
  | 'phonecall'
  | 'workflow'
  | 'welcome'
  | 'log-in'
  | 'log-in-mobile'
  | 'sign-up'
  | 'sign-up-mobile'
  | 'pulse'
  | 'feedback'
  | 'ona'
  | 'broken'
  | 'desk'
  | 'raising-hand'
  | 'rest'
  | 'leaves'
  | 'leaves2'
  | 'fan'

export const getIllustrationUrl = (name: IllustrationName) => `/illustrations/${name}.svg`
