import { useState, useEffect } from 'react'
import { CheckCircleOutlined as CheckCircleIcon } from '@ant-design/icons'
import { Input, Modal } from 'antd'
import type {
  AnyRequest,
  Feedback,
  FeedbackCategory,
  Language,
  BaseTopic,
} from '@teamspective/common'

import useStateWithDebouncedSideEffect from '../../components/hooks/useStateWithDebouncedSideEffect'
import Loader from '../../components/Loader'
import ExpanderInfo from '../../components/ExpanderInfo'
import { FormattedDescription } from '../../components/feedback/TopicTitle'

import { cyan } from '@teamspective/common'
import { translations } from '@teamspective/common'
import FeedbackInstructions from '../../components/feedback/FeedbackInstructions'
import type { FeedbackApi } from './DemoFeedbackApi'

const EditFeedback = ({
  topic,
  category,
  request,
  lang,
  onClose,
  api,
}: {
  topic: BaseTopic
  category: FeedbackCategory
  request: AnyRequest
  lang: Language
  onClose: () => unknown
  api: FeedbackApi
}): JSX.Element => {
  const [isSaved, setIsSaved] = useState<boolean | undefined>(undefined)
  const [feedback, setFeedback, debounceAction] = useStateWithDebouncedSideEffect<null | Feedback>(
    null,
    1200,
    async (draftFeedback) => {
      if (draftFeedback) {
        await api.updateDraftFeedback({ feedback: draftFeedback })
        setIsSaved(true)
      }
    }
  )

  useEffect(() => {
    const existingDraft = request.feedback.find(
      (f) => f.topicId === topic.topicId && f.category === category
    )
    if (existingDraft) {
      setFeedback(existingDraft)
      return
    }

    void api
      .createDraftFeedback({
        requestId: request.requestId,
        topicId: topic.topicId,
        category,
      })
      .then((res) => {
        if (res.status === 'ok') {
          setFeedback(res.data)
          void debounceAction('flush')
        }
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      closable={false}
      destroyOnClose={true}
      open={true}
      maskClosable={true}
      onOk={async () => {
        await debounceAction('flush')
        onClose()
      }}
      onCancel={() => {
        onClose()
      }}
    >
      {feedback ? (
        <div>
          <div style={{ marginBottom: 8 }}>
            <ExpanderInfo
              title={<h3 style={{ marginBottom: 0 }}>{topic.label}</h3>}
              content={
                topic.description ? <FormattedDescription description={topic.description} /> : null
              }
              lang={lang}
            />
          </div>

          <div style={{ marginBottom: 16 }}>
            <ExpanderInfo
              title={<h3 style={{ marginBottom: 0 }}>{translations[lang][`${category}_cue`]}</h3>}
              content={<FeedbackInstructions lang={lang} category={category} />}
              lang={lang}
            />
          </div>
          <div style={{ display: 'flex', marginBottom: 16 }}>
            <div style={{ flex: 1 }}>
              <Input.TextArea
                value={feedback.content || ''}
                onChange={(e) => {
                  setIsSaved(false)
                  setFeedback({ ...feedback, content: e.target.value })
                }}
                autoFocus={true}
                placeholder={translations[lang][`${category}_example`]}
                rows={4}
                autoSize={{ minRows: 4, maxRows: 12 }}
              />
            </div>
          </div>
          {isSaved || isSaved === undefined ? (
            <span>
              {translations[lang].saved}
              <CheckCircleIcon style={{ color: cyan, marginLeft: 8 }} />
            </span>
          ) : (
            <span>{translations[lang].saving}</span>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </Modal>
  )
}

export default EditFeedback
