import Paragraph from 'antd/es/typography/Paragraph'
import type { Language } from '@teamspective/common'
import { khakiDarker, translations } from '@teamspective/common'

const CopyableText = ({
  content,
  copyText,
  lang,
  copiedText,
  style,
}: {
  content: string
  lang: Language
  copyText?: string
  copiedText?: string
  style?: React.CSSProperties
}) => (
  <Paragraph
    style={{
      backgroundColor: khakiDarker,
      fontWeight: 600,
      padding: 8,
      borderRadius: 6,
      display: 'inline',
      ...style,
    }}
    copyable={{
      tooltips: [copyText ?? translations[lang].copy, copiedText ?? translations[lang].copied],
    }}
  >
    {content}
  </Paragraph>
)

export default CopyableText
