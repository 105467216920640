export const FI_VAT_PERCENTAGE = 25.5

export const countryTaxIds = [
  {
    countryCode: 'AU',
    countryName: 'Australia',
    taxIdType: 'au_abn',
    taxIdDescription: 'Australian Business Number (AU ABN)',
    taxIdExample: '12345678912',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    taxIdType: 'au_arn',
    taxIdDescription: 'Australian Taxation Office Reference Number',
    taxIdExample: '123456789123',
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'ATU12345678',
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'BE0123456789',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    taxIdType: 'br_cnpj',
    taxIdDescription: 'Brazilian CNPJ number',
    taxIdExample: '01.234.456/5432-10',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    taxIdType: 'br_cpf',
    taxIdDescription: 'Brazilian CPF number',
    taxIdExample: '123.456.789-87',
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    taxIdType: 'bg_uic',
    taxIdDescription: 'Bulgaria Unified Identification Code',
    taxIdExample: '123456789',
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'BG0123456789',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    taxIdType: 'ca_bn',
    taxIdDescription: 'Canadian BN',
    taxIdExample: '123456789',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    taxIdType: 'ca_gst_hst',
    taxIdDescription: 'Canadian GST/HST number',
    taxIdExample: '123456789RT0002',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    taxIdType: 'ca_pst_bc',
    taxIdDescription: 'Canadian PST number (British Columbia)',
    taxIdExample: 'PST-1234-5678',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    taxIdType: 'ca_pst_mb',
    taxIdDescription: 'Canadian PST number (Manitoba)',
    taxIdExample: '123456-7',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    taxIdType: 'ca_pst_sk',
    taxIdDescription: 'Canadian PST number (Saskatchewan)',
    taxIdExample: '1234567',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    taxIdType: 'ca_qst',
    taxIdDescription: 'Canadian QST number (Québec)',
    taxIdExample: '1234567890TQ1234',
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    taxIdType: 'cl_tin',
    taxIdDescription: 'Chilean TIN',
    taxIdExample: '12.345.678-K',
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'HR12345678912',
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'CY12345678Z',
  },
  {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'CZ1234567890',
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'DK12345678',
  },
  // {
  //   countryCode: 'EG',
  //   countryName: 'Egypt',
  //   taxIdType: 'eg_tin',
  //   taxIdDescription: 'Egyptian Tax Identification Number',
  //   taxIdExample: '123456789',
  // },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'EE123456789',
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'FI12345678',
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'FRAB123456789',
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    taxIdType: 'ge_vat',
    taxIdDescription: 'Georgian VAT',
    taxIdExample: '123456789',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'DE123456789',
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'EL123456789',
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    taxIdType: 'hk_br',
    taxIdDescription: 'Hong Kong BR number',
    taxIdExample: '12345678',
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'HU12345678912',
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    taxIdType: 'hu_tin',
    taxIdDescription: 'Hungary tax number (adószám)',
    taxIdExample: '12345678-1-23',
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    taxIdType: 'is_vat',
    taxIdDescription: 'Icelandic VAT',
    taxIdExample: '123456',
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    taxIdType: 'in_gst',
    taxIdDescription: 'Indian GST number',
    taxIdExample: '12ABCDE3456FGZH',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    taxIdType: 'id_npwp',
    taxIdDescription: 'Indonesian NPWP number',
    taxIdExample: '12.345.678.9-012.345',
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'IE1234567AB',
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    taxIdType: 'il_vat',
    taxIdDescription: 'Israel VAT',
    taxIdExample: '12345',
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'IT12345678912',
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    taxIdType: 'jp_cn',
    taxIdDescription: 'Japanese Corporate Number (*Hōjin Bangō*)',
    taxIdExample: '1234567891234',
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    taxIdType: 'jp_rn',
    taxIdDescription:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    taxIdExample: '12345',
  },
  // {
  //   countryCode: 'JP',
  //   countryName: 'Japan',
  //   taxIdType: 'jp_trn',
  //   taxIdDescription: 'Japanese Tax Registration Number (*Tōroku Bangō*)',
  //   taxIdExample: 'T1234567891234',
  // },
  // {
  //   countryCode: 'KE',
  //   countryName: 'Kenya',
  //   taxIdType: 'ke_pin',
  //   taxIdDescription: 'Kenya Revenue Authority Personal Identification Number',
  //   taxIdExample: 'P000111111A',
  // },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'LV12345678912',
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    taxIdType: 'li_uid',
    taxIdDescription: 'Liechtensteinian UID number',
    taxIdExample: 'CHE123456789',
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'LT123456789123',
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'LU12345678',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    taxIdType: 'my_frp',
    taxIdDescription: 'Malaysian FRP number',
    taxIdExample: '12345678',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    taxIdType: 'my_itn',
    taxIdDescription: 'Malaysian ITN',
    taxIdExample: 'C 1234567890',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    taxIdType: 'my_sst',
    taxIdDescription: 'Malaysian SST number',
    taxIdExample: 'A12-3456-78912345',
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'MT12345678',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    taxIdType: 'mx_rfc',
    taxIdDescription: 'Mexican RFC number',
    taxIdExample: 'ABC010203AB9',
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'NL123456789B12',
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    taxIdType: 'nz_gst',
    taxIdDescription: 'New Zealand GST number',
    taxIdExample: '123456789',
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    taxIdType: 'no_vat',
    taxIdDescription: 'Norwegian VAT number',
    taxIdExample: '123456789MVA',
  },
  // {
  //   countryCode: 'PH',
  //   countryName: 'Philippines',
  //   taxIdType: 'ph_tin',
  //   taxIdDescription: 'Philippines Tax Identification Number',
  //   taxIdExample: '123456789012',
  // },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'PL1234567890',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'PT123456789',
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'RO1234567891',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    taxIdType: 'ru_inn',
    taxIdDescription: 'Russian INN',
    taxIdExample: '1234567891',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    taxIdType: 'ru_kpp',
    taxIdDescription: 'Russian KPP',
    taxIdExample: '123456789',
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    taxIdType: 'sa_vat',
    taxIdDescription: 'Saudi Arabia VAT',
    taxIdExample: '123456789012345',
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    taxIdType: 'sg_gst',
    taxIdDescription: 'Singaporean GST',
    taxIdExample: 'M12345678X',
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    taxIdType: 'sg_uen',
    taxIdDescription: 'Singaporean UEN',
    taxIdExample: '123456789F',
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'SK1234567891',
  },
  {
    countryCode: 'SL',
    countryName: 'Slovenia',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'SI12345678',
  },
  {
    countryCode: 'SL',
    countryName: 'Slovenia',
    taxIdType: 'si_tin',
    taxIdDescription: 'Slovenia tax number (davčna številka)',
    taxIdExample: '12345678',
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    taxIdType: 'za_vat',
    taxIdDescription: 'South African VAT number',
    taxIdExample: '4123456789',
  },
  {
    countryCode: 'KR',
    countryName: 'South Korea',
    taxIdType: 'kr_brn',
    taxIdDescription: 'Korean BRN',
    taxIdExample: '123-45-67890',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    taxIdType: 'es_cif',
    taxIdDescription: 'Spanish NIF number (previously Spanish CIF number)',
    taxIdExample: 'A12345678',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'ESA1234567Z',
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    taxIdType: 'eu_vat',
    taxIdDescription: 'European VAT number',
    taxIdExample: 'SE123456789123',
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    taxIdType: 'ch_vat',
    taxIdDescription: 'Switzerland VAT number',
    taxIdExample: 'CHE-123.456.789 MWST',
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan',
    taxIdType: 'tw_vat',
    taxIdDescription: 'Taiwanese VAT',
    taxIdExample: '12345678',
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    taxIdType: 'th_vat',
    taxIdDescription: 'Thai VAT',
    taxIdExample: '1234567891234',
  },
  // {
  //   countryCode: 'TR',
  //   countryName: 'Turkey',
  //   taxIdType: 'tr_tin',
  //   taxIdDescription: 'Turkish Tax Identification Number',
  //   taxIdExample: '123456789',
  // },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    taxIdType: 'ua_vat',
    taxIdDescription: 'Ukrainian VAT',
    taxIdExample: '123456789',
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates1',
    taxIdType: 'ae_trn',
    taxIdDescription: 'United Arab Emirates TRN',
    taxIdExample: '123456789012345',
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    taxIdType: 'eu_vat',
    taxIdDescription: 'Northern Ireland VAT number',
    taxIdExample: 'XI123456789',
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    taxIdType: 'gb_vat',
    taxIdDescription: 'United Kingdom VAT number',
    taxIdExample: 'GB123456789',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    taxIdType: 'us_ein',
    taxIdDescription: 'United States EIN',
    taxIdExample: '12-3456789',
  },
] as const

export type TaxIdType = (typeof countryTaxIds)[number]['taxIdType']

export const taxIdTypes = countryTaxIds.map(({ taxIdType }) => taxIdType)

export type TaxId = {
  type: TaxIdType
  value: string
}
