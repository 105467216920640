import type { PropsWithChildren } from 'react'
import type React from 'react'
import { Comment } from '@ant-design/compatible'
import { Divider, Tooltip, Checkbox, Card } from 'antd'
import type { Language, BaseTopic, ProvidedRequest, SharedRequest } from '@teamspective/common'
import { userName, isNotNull } from '@teamspective/common'

import UserAvatar from '../../components/user/UserAvatar'
import { translations } from '@teamspective/common'
import { TopicTag } from '../../components/feedback/TopicTag'
import { ForwardIcon } from '../../customIcons'
import type { FeedbackSelectActions } from '../../components/feedback/FeedbackTimelineItem'
import SelfRelfectAvatar from '../../components/feedback/SelfReflectAvatar'
import AnonymousAvatar from '../../components/feedback/AnonymousAvatar'
import { isSelfReflection } from '../../components/feedback/util'
import { FeedbackVisibilityInfo } from '../../components/feedback/FeedbackVisibilityInfo'

const PlainDiv: React.FC<PropsWithChildren> = ({ children }) => <div>{children}</div>
const PlainCard: React.FC<PropsWithChildren> = ({ children }) => (
  <Card styles={{ body: { paddingTop: 8, paddingBottom: 8 } }} style={{ marginBottom: 8 }}>
    {children}
  </Card>
)

export const FeedbackList = ({
  request,
  requestTopics,
  type,
  lang,
  selectionState,
  feedbackSelectActions,
  onCards,
}: {
  request: ProvidedRequest | SharedRequest
  requestTopics: BaseTopic[]
  type: 'reinforcing' | 'redirecting' | 'all'
  lang: Language
  selectionState: number[]
  feedbackSelectActions: FeedbackSelectActions | null
  onCards: boolean
}) => {
  const Wrapper = onCards ? PlainCard : PlainDiv

  const filteredFeedback =
    type === 'all' ? request.feedback : request.feedback.filter((f) => f.category === type)

  return (
    <div>
      {filteredFeedback.map((feedback) => {
        const description = `${translations[lang][`${feedback.category}Feedback`]}: ${
          translations[lang][`${feedback.category}Synonyms`]
        }`
        const topic = requestTopics.find(({ topicId }) => topicId === feedback.topicId)
        return (
          <Wrapper key={feedback.feedbackId}>
            <Comment
              avatar={
                feedbackSelectActions !== null && feedbackSelectActions.sharing ? (
                  <Checkbox
                    checked={selectionState.includes(feedback.feedbackId)}
                    onChange={() => feedbackSelectActions.toggle(feedback.feedbackId)}
                  />
                ) : isSelfReflection(request) ? (
                  <SelfRelfectAvatar lang={lang} />
                ) : request.provider === 'anonymous' ? (
                  <AnonymousAvatar lang={lang} />
                ) : (
                  <UserAvatar user={request.provider} />
                )
              }
              style={{ marginRight: 32, whiteSpace: 'pre-wrap' }}
              author={
                <span>
                  <span>
                    {request.provider === 'anonymous'
                      ? translations[lang].anonymousFeedback
                      : userName(request.provider)}
                  </span>{' '}
                  <Divider type="vertical" />
                  {topic ? (
                    <>
                      <TopicTag id={topic.topicId} label={topic.label} />
                      <Divider type="vertical" />
                    </>
                  ) : null}
                  <span>{description}</span>
                </span>
              }
              actions={[
                request.type !== 'shared' &&
                (request.feedbackIsAbout === 'me' || request.feedbackIsAbout === 'self') ? (
                  <FeedbackVisibilityInfo
                    key="visibility"
                    recipient={request.requester}
                    provider={request.provider}
                    lang={lang}
                    visibility={request.visibility}
                    feedbackShares={request.feedbackShares}
                    compact
                  />
                ) : null,
                feedbackSelectActions !== null && feedbackSelectActions.sharing === false ? (
                  <Tooltip key="shared" title={translations[lang].shareWith}>
                    <span
                      role="button"
                      onClick={() => feedbackSelectActions.set([feedback.feedbackId])}
                    >
                      <ForwardIcon />
                    </span>
                  </Tooltip>
                ) : null,
              ].filter(isNotNull)}
              content={feedback.content}
            />
          </Wrapper>
        )
      })}
    </div>
  )
}
