import type { ApiResponse, Language } from '@teamspective/common'
import { App } from 'antd'
import { translations } from '@teamspective/common'
import { useCallback } from 'react'

export const useGenericErrorNotification = () => {
  const { notification } = App.useApp()
  return useCallback(
    (lang: Language) =>
      notification.error({
        message: translations[lang].genericErrorMessageTitle,
        description: translations[lang].genericErrorMessageDescription,
      }),
    [notification]
  )
}

export const useNotifyOnResponse = () => {
  const { message } = App.useApp()
  const genericErrorNotification = useGenericErrorNotification()
  return useCallback(
    <T>({
      successMessage,
      failureMessage,
      lang,
    }: {
      successMessage?: string
      failureMessage?: string
      lang: Language
    }) =>
      (response: ApiResponse<T>) => {
        if (response.status === 'ok') {
          if (successMessage) {
            void message.success(successMessage)
          }
        } else {
          if (failureMessage) {
            void message.error(failureMessage)
          } else {
            genericErrorNotification(lang)
          }
        }
        return response
      },
    [message, genericErrorNotification]
  )
}
