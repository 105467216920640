import type { Messages } from '@lingui/core'
import { i18n } from '@lingui/core'

/**
 * Importing is handled dynamically by `@lingui/vite-plugin`
 *
 * @see https://lingui.dev/ref/vite-plugin#usage
 */
export async function loadCatalog(locale: string) {
  // this is a bit hacky since vite only supports analyzing dynamic imports for relative imports
  // and we can't import from @teamspective/locale
  const { messages } = (await import(`../../locale/${locale}/messages.po`)) as {
    messages: Messages
  }
  i18n.loadAndActivate({ locale, messages })
}
