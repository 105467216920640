import { captureEvent } from '@sentry/core'
import { customChoiceColor, lightGrey } from './color.ts'
import type {
  EvaluationAnswerValue,
  PulseAnswerValue,
  PulseRoundResponseRateKey,
  QuestionScale,
  Score,
} from '../../../index.ts'

// Method: https://vis4.net/palettes/#/5|d|5385bc,83b8d7,b7ddeb,f7f9d9|f7f9d9,fdd081,f99858,df4931|0|0
// Assoc array because negative numbers cannot be used object keys
const posNegNeutralColors: Array<[number, string]> = [
  [2, '#dd523c'],
  [1, '#fbb46d'],
  [0, '#f7f9d9'],
  [-1, '#9dcbe1'],
  [-2, '#5385bc'],
]

const connectionStrengthColor = [
  '#fafcf8',
  '#edf5e4',
  '#e0edd0',
  '#d3e6bd',
  '#c6dfa9',
  '#b9d795',
  '#acd081',
  '#9fc96e',
  '#92c15a',
  '#85ba46',
] as const

export const connectionScoreToColor = (score: Score) => {
  if (score === 'N/A') {
    return lightGrey
  }
  return connectionStrengthColor[score >= 100 ? 9 : Math.floor(score / 10)]
}

export const activeInactiveGradient = [
  '#85ba46',
  '#9dc568',
  '#b4cf89',
  '#cadaaa',
  '#e0e5cb',
  '#ebe4e1',
] as const

export const pulseRoundResponseRateKeyToColor: { [key in PulseRoundResponseRateKey]: string } = {
  count: activeInactiveGradient[0],
  countLag1: activeInactiveGradient[1],
  countLag2: activeInactiveGradient[2],
  countLag3: activeInactiveGradient[3],
  countLagMore: activeInactiveGradient[4],
  countNever: activeInactiveGradient[5],
}

const npsScaleColors: Record<number, string> = {
  0: '#dd523c',
  1: '#e96c48',
  2: '#f38756',
  3: '#faa365',
  4: '#fec177',
  5: '#fee08b',
  6: '#eee488',
  7: '#dae180',
  8: '#c1da72',
  9: '#a5cc5f',
  10: '#85ba46',
}

// http://vis4.net/palettes/#/10|s|dd523c,fc8d59,fee08b,d9ef8b,85ba46|ffffe0,ff005e,93003a|0|1
export const RdGr10 = [
  '#dd523c',
  '#ea6f49',
  '#f58d59',
  '#fcad6b',
  '#ffce80',
  '#f6e38a',
  '#e1e383',
  '#c7dc76',
  '#a8ce61',
  '#85ba46',
]

export const MaGr10 = [
  '#FF3AFF',
  '#FD6AF7',
  '#FA8BEF',
  '#F5A8E7',
  '#EFC2DE',
  '#E6DBD5',
  '#A8CFB6',
  '#7BB48E',
  '#539D6E',
  '#22874F',
]

// TODO: we now use an updated color palette for 7- and 4-point scales (used in evaluations)
// but old colors for 5-point scale (used in engagement)
export const linearScaleColorArray = ['#dd523c', '#f7955d', '#fee08b', '#cede7a', '#85ba46']
export const linear7PointColorArray = [
  '#DA8271',
  '#F4B78F',
  '#F8D8A2',
  '#CBC9C2',
  '#B2E4BE',
  '#A3D4AF',
  '#8FBC99',
]
export const linear4PointColorArray = ['#DA8271', '#F4B78F', '#A3D4AF', '#8FBC99']

const linearScaleColors: Record<number, string> = {
  1: linearScaleColorArray[0],
  2: linearScaleColorArray[1],
  3: linearScaleColorArray[2],
  4: linearScaleColorArray[3],
  5: linearScaleColorArray[4],
}

export const getQuestionChoiceColor = (
  p:
    | {
        value: PulseAnswerValue | EvaluationAnswerValue
        scale: Exclude<QuestionScale, 'custom'>
      }
    | { scale: 'custom'; value: PulseAnswerValue | EvaluationAnswerValue; choiceCount: number }
): string => {
  if (p.value === 'not-applicable' || p.value === 'prefers-not-to' || p.value === 'skip-question') {
    return lightGrey
  }
  switch (p.scale) {
    case 'balance5': {
      const colorPair = posNegNeutralColors.find(([key]) => key === p.value)
      if (!colorPair) {
        captureEvent({ message: 'Corrupt QuestionChoice', extra: p })
        return '#000'
      }
      return colorPair[1]
    }

    case 'nps': {
      const color = npsScaleColors[p.value]
      if (!color) {
        captureEvent({ message: 'Corrupt QuestionChoice', extra: p })
        return '#000'
      }
      return color
    }

    case 'linear5': {
      const color = linearScaleColors[p.value]
      if (!color) {
        captureEvent({ message: 'Corrupt QuestionChoice', extra: p })
        return '#000'
      }
      return color
    }

    case 'linear7': {
      const color = linear7PointColorArray[p.value - 1]
      if (!color) {
        captureEvent({ message: 'Corrupt QuestionChoice', extra: p })
        return '#000'
      }
      return color
    }

    case 'linear4': {
      const color = linear4PointColorArray[p.value - 1]
      if (!color) {
        captureEvent({ message: 'Corrupt 4-point QuestionChoice', extra: p })
        return '#000'
      }
      return color
    }
    case 'binary':
      // Note: Higher values are always more favorable. The favourable choice (value === 1) might be "Yes" or "No" depending on the question.
      return p.value === 1 ? linearScaleColorArray[4] : linearScaleColorArray[0]
    case 'written':
      return '#fff'

    case 'custom':
      return customChoiceColor(p.value, p.choiceCount)
  }
}

export const getScoreColor = (
  score: Score | 'redacted',
  scale: 'score' | 'nps',
  variant?: 'MaGr' | 'RdGr'
): string => {
  if (score === 'N/A' || score === 'redacted') {
    return lightGrey
  }
  const colorMap = variant === 'MaGr' ? MaGr10 : RdGr10

  switch (scale) {
    case 'score': {
      const idx = Math.max(0, Math.ceil(score / 10) - 1)
      return colorMap[idx]
    }
    case 'nps': {
      const minusFiftyToPlusFifty = Math.min(50, Math.max(-50, score))
      const zeroToHundredScore = minusFiftyToPlusFifty + 50
      const idx = Math.max(0, Math.ceil(zeroToHundredScore / 10) - 1)
      return colorMap[idx]
    }
  }
}

export const getScoreColorEmoji = (score: Score | 'redacted') => {
  if (score === 'N/A' || score === 'redacted') {
    return '⚪'
  }
  const ranges = [
    [0, 30, '🔴'], // :red_circle:
    [31, 50, '🟠'], // :orange_circle:
    [51, 70, '🟡'], // :yellow_circle:
    [71, 100, '🟢'], // :green_circle:
  ] as const
  for (const [min, max, emoji] of ranges) {
    if (score >= min && score <= max) {
      return emoji
    }
  }
  throw new Error('Score under 0 or over 100')
}
