import type React from 'react'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import type { ProvidedRequest, NewRequest, BaseTopic } from '@teamspective/common'
import { userLang, links, userName } from '@teamspective/common'
import Logo from '../../components/Logo'
import { translations } from '@teamspective/common'

import Loader from '../../components/Loader'
import { api } from '../../api'
import { RequestView } from '../ViewFeedback/RequestView'
import authStore from '../../stores/authStore'
import Redirect from '../../components/Redirect'
import { InfoIcon } from '../../components/icons'

const SharedFeedbackPage: React.FunctionComponent = () => {
  const { token } = useParams<{ token: string }>()

  const [request, setRequest] = useState<
    ProvidedRequest | NewRequest | 'loading' | 'none' | 'token-missing'
  >('loading')
  const [topics, setTopics] = useState<BaseTopic[] | 'loading'>('loading')
  const { user } = authStore.useContainer()
  const lang = userLang(user)

  useEffect(() => {
    if (!token) {
      setRequest('token-missing')
      return
    }
    void api.viewFeedbackWithAuthToken({ token }).then((res) => {
      if (res.status !== 'ok') {
        setRequest('none')
        return
      }

      setRequest(res.data.request)
      setTopics(res.data.topics)
    })
  }, [token])

  if (request === 'loading' || topics === 'loading') {
    return <Loader />
  }

  if (request === 'none' || request === 'token-missing') {
    return <Redirect to={links.frontPage} />
  }

  if (request.type === 'requested') {
    return <div>{JSON.stringify(request)}</div>
  }

  return (
    <div>
      <div style={{ width: '100%', backgroundColor: '#ffffff', marginBottom: 32 }}>
        {user.status === 'logged-out' ? (
          <div
            style={{
              margin: '0 auto',
              width: 220,
            }}
          >
            <Link to={links.frontPage}>
              <Logo size="regular" />
            </Link>
          </div>
        ) : null}
      </div>
      <div
        style={{
          margin: '0 auto',
          maxWidth: 640,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <b>
          <InfoIcon style={{ marginRight: 8 }} />
          {translations[lang].feedbackSharedBy(userName(request.requester))}
        </b>
        <RequestView request={request} requestTopics={topics} type="shared" />
      </div>
    </div>
  )
}

export default SharedFeedbackPage
