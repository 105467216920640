/* eslint-disable i18next/no-literal-string */
import { notification } from 'antd'
import { userLang } from '@teamspective/common'
import { khaki } from '@teamspective/common'
import ContactUs from './components/ContactUs'
import { api } from './api'
import authStore from './stores/authStore'

const displayVersion = () =>
  api.version().then((v) =>
    notification.info({
      message: 'Environment',
      description: (
        <ul>
          <li>Stage: {v.data.stage}</li>
          <li>Hash: {v.data.hash}</li>
          <li>Released: {v.data.released}</li>
          <li>App Name: {v.data.appName}</li>
        </ul>
      ),
    })
  )

const Footer = (): JSX.Element => {
  const { user } = authStore.useContainer()
  return (
    <footer
      role="contentinfo"
      style={{
        backgroundColor: khaki,
        width: '100%',
        height: 100,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100% - 1px)',
        }}
      >
        <div
          aria-hidden="true"
          onClick={displayVersion}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: 16,
          }}
        >
          {`© Teamspective ${new Date().getFullYear()}`}
        </div>
        <ContactUs lang={userLang(user)} />
      </div>
    </footer>
  )
}

export default Footer
