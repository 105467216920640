import type React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Alert } from 'antd'
import Logo from '../../components/Logo'
import { translations } from '@teamspective/common'
import { t } from './translations'

const LimitWidthOnWideScreens = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 475px) {
    width: 425px;
    margin: 0 auto;
  }
  @media (max-width: 475px) {
    margin: 0 16px;
  }
`
type LoginPageType = 'default' | 'code'

const LocationInfo = ({ type }: { type: LoginPageType }) => {
  const location = useLocation()

  switch (location.search) {
    case '?state=invalid-token':
      return (
        <Alert
          style={{ marginBottom: 32 }}
          message={type === 'default' ? t.en.invalidToken : translations.en.invalidLoginCode}
          type="warning"
        />
      )
    case '?state=expired-token':
      return <Alert style={{ marginBottom: 32 }} message={t.en.expiredToken} type="info" />
    default:
      return null
  }
}

const LoginPageContainer: React.FC<{
  children: JSX.Element | JSX.Element[]
  id: 'page-login' | 'page-register'
  type: LoginPageType
}> = ({ children, type, id }) => {
  return (
    <div id={id}>
      <LocationInfo type={type} />
      <LimitWidthOnWideScreens>
        <Logo style={{ margin: 32 }} size="regular" />
        {children}
      </LimitWidthOnWideScreens>
    </div>
  )
}
export default LoginPageContainer
