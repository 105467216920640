import type React from 'react'
import type { TagProps } from 'antd'
import { Tag } from 'antd'
import { getTagColors } from '../colors'

export const ColoredTag: React.FC<TagProps & { baseColor: string }> = (props) => {
  const { baseColor, ...tagProps } = props
  const { textColor, opaqueColor } = getTagColors(baseColor)
  return (
    <Tag
      {...tagProps}
      color={opaqueColor}
      style={{
        borderColor: baseColor,
        color: textColor,
        cursor: 'default',
        ...props.style,
      }}
    >
      {props.children}
    </Tag>
  )
}
