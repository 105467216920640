import type React from 'react'
import type { Size, Tone } from './getLogoPath'
import { getLogoPath } from './getLogoPath'

const Logo = ({
  className,
  size,
  style,
  tone,
}: {
  className?: string
  style?: React.CSSProperties
  size: Size
  tone?: Tone
}): JSX.Element => {
  return (
    <div className={className}>
      <img
        alt="Teamspective logo"
        style={{ height: 64, display: 'block', ...style }}
        src={getLogoPath({ size, tone })}
      />
    </div>
  )
}

export default Logo
