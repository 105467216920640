import useSWRImmutable from 'swr/immutable'
import type { ApiResponse } from '@teamspective/common'
import React from 'react'
import { useCallback, useMemo } from 'react'
import Loader from '../Loader'
import type { MutatorOptions } from 'swr'

export type OptimisticMutator<TData> = (
  optimisticData: TData | ((currentData: TData | undefined) => TData)
) => Promise<TData | undefined>

type Response<T> = {
  refreshOptimistic: OptimisticMutator<T>
  refresh: () => Promise<void>
} & (
  | { type: 'ok'; data: T }
  | { type: 'loading'; previousData: T | null }
  | { type: 'error'; reason?: string }
)

type ExtractDataType<R> = R extends Response<infer T> ? T : never
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- needs to be any for generics to work
type AnyResponse = Response<any>

export function DataLoader<const T extends readonly AnyResponse[]>({
  responses,
  children,
}: {
  responses: [...T]
  children: (...data: { [K in keyof T]: ExtractDataType<T[K]> }) => React.ReactNode
}): React.ReactNode {
  if (responses.length === 0) {
    return null
  }

  if (responses.every((response) => response.type === 'ok')) {
    const dataValues = responses.map(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- type mangling, checked above
      (response) => (response as Extract<AnyResponse, { type: 'ok' }>).data
    ) as { [K in keyof T]: ExtractDataType<T[K]> }

    return children(...dataValues)
  }

  // TODO: Maybe add a customizable error component?
  return React.createElement(Loader)
}

const API_CALL_REFRESH_INTERVAL_MS = 60 * 60 * 1000

const useApiCall = <K extends string | null, T extends object | null>(
  key: K,
  fn: (() => Promise<ApiResponse<T>>) | null
): null extends K ? Response<T> | null : Response<T> => {
  if (key && !fn) {
    throw Error('key is not null but fn is')
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, error, mutate, isLoading } = useSWRImmutable(key, fn, {
    refreshInterval: API_CALL_REFRESH_INTERVAL_MS,
    keepPreviousData: true,
  })
  const refresh = useCallback(async () => {
    await mutate()
  }, [mutate])

  const refreshOptimistic = useCallback<OptimisticMutator<T>>(
    async (optimisticDataOrFn) => {
      const currentData = data && 'data' in data ? data.data : undefined

      const newDataPromise: Promise<ApiResponse<T> | undefined> = Promise.resolve({
        status: 'ok',
        data: (typeof optimisticDataOrFn === 'function'
          ? await optimisticDataOrFn(currentData)
          : optimisticDataOrFn) as T,
      })

      const newOpts: MutatorOptions<ApiResponse<T>> = {
        optimisticData:
          typeof optimisticDataOrFn === 'function'
            ? (currentResponse?: ApiResponse<T>) => ({
                status: 'ok',
                data: (optimisticDataOrFn as (currentData: T | undefined) => T)(
                  currentResponse?.status === 'ok' ? currentResponse.data : undefined
                ),
              })
            : {
                status: 'ok',
                data: optimisticDataOrFn,
              },
      }

      const result = await mutate(newDataPromise, newOpts)
      if (result?.status !== 'ok') {
        return undefined
      }

      return result.data
    },
    [mutate, data]
  )

  return useMemo((): null extends K ? Response<T> | null : Response<T> => {
    if (key === null) {
      // @ts-expect-error This type is not inferred correctly from the key type and conditional return type
      return null
    }

    if (error) {
      return { type: 'error', refresh, refreshOptimistic }
    } else if (isLoading || !data) {
      return {
        type: 'loading',
        previousData: data && data.status === 'ok' ? data.data : null,
        refresh,
        refreshOptimistic,
      }
    } else {
      return data.status === 'ok'
        ? { type: 'ok', data: data.data, refresh, refreshOptimistic }
        : { type: 'error', reason: data.status, refresh, refreshOptimistic }
    }
  }, [key, error, data, refresh, refreshOptimistic, isLoading])
}

export default useApiCall
