import type React from 'react'
import { Suspense, lazy } from 'react'
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import { links } from '@teamspective/common'
import { ConfigProvider, App as AntDApp } from 'antd'
import en from 'antd/es/locale/en_US'
import fi from 'antd/es/locale/fi_FI'

import * as Sentry from '@sentry/react'

import LoginPage from './pages/Login/LoginPage'

import SharedFeedbackPage from './pages/SharedFeedback'

import Loader from './components/Loader'

import topicStore from './stores/topicStore'
import CodeLoginPage from './pages/Login/CodeLoginPage'
import Redirect from './components/Redirect'
import ProvideAnonymousFeedbackPage from './pages/ProvideFeedback/ProvideAnonymousFeedbackPage'
import authStore from './stores/authStore'
import workspaceSelectorStore from './stores/workspaceSelectorStore'
import theme from './theme'
import ErrorPage from './components/ErrorPage'
import SignupPage from './pages/Login/SignupPage'
import { isReloadParamSet, reloadWithParam } from './reload'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'

const AppRouter = lazy(() => import('./AppRouter'))

const AuthSwitcher = () => {
  const { user, refresh } = authStore.useContainer()
  switch (user.status) {
    case 'loading':
      return (
        <div style={{ height: '320px' }}>
          <Loader />
        </div>
      )
    case 'logged-out':
      return (
        <topicStore.Provider>
          <Routes>
            <Route element={<LoginPage />} path="*" />
            <Route element={<SignupPage />} path={links.register} />
            <Route element={<CodeLoginPage />} path={links.codeLogin()} />

            <Route element={<SharedFeedbackPage />} path={links.sharedFeedback()} />
            <Route element={<ProvideAnonymousFeedbackPage />} path={links.feedbackLink()} />

            <Route element={<Redirect to={links.login} />} path="/" />
            <Route element={<Redirect to={links.login} />} path={links.frontPage} />
          </Routes>
        </topicStore.Provider>
      )
    case 'complete':
    case 'incomplete':
    case 'limited':
    default: {
      return (
        <workspaceSelectorStore.Provider>
          <Suspense>
            <AppRouter user={user} refreshUser={refresh} />
          </Suspense>
        </workspaceSelectorStore.Provider>
      )
    }
  }
}

const langToLocale = {
  fi,
  en,
  sv: en,
  no: en,
  dk: en,
  es: en,
  de: en,
  it: en,
  fr: en,
  pl: en,
  cn: en,
  uk: en,
} as const
const AntDConfigProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { user } = authStore.useContainer()
  const lang = user.status === 'complete' ? user.language : 'en'
  return (
    <ConfigProvider theme={theme} locale={langToLocale[lang]}>
      <AntDApp>{children}</AntDApp>
    </ConfigProvider>
  )
}

const reloadOnImportError = (error: unknown): boolean => {
  if (!(error instanceof Error)) {
    return false
  }

  return (
    // Avoid infinite reload loop when there is an actual error
    !isReloadParamSet() &&
    (error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed'))
  )
}

const App = (): React.ReactElement => {
  return (
    <Sentry.ErrorBoundary
      onError={(error) => {
        if (reloadOnImportError(error)) {
          reloadWithParam()
        } else {
          Sentry.captureException(error)
        }
      }}
      fallback={(e) => {
        if (reloadOnImportError(e.error)) {
          return <Loader />
        }
        return <ErrorPage error={e.error} resetError={() => e.resetError()} />
      }}
    >
      <authStore.Provider>
        <I18nProvider i18n={i18n}>
          <AntDConfigProvider>
            <BrowserRouter>
              <AuthSwitcher />
            </BrowserRouter>
          </AntDConfigProvider>
        </I18nProvider>
      </authStore.Provider>
    </Sentry.ErrorBoundary>
  )
}

export default App
