import type { ProvidedRequest, BaseTopic } from '@teamspective/common'
import { userName, listItemsInNaturalLanguage, userLang } from '@teamspective/common'
import Timestamp from '../../components/date/Timestamp'
import RequestComment, { AnonymousRequestComment } from '../../components/feedback/RequestComment'
import authStore from '../../stores/authStore'
import { translations } from '@teamspective/common'
import { ShareFeedbackModal } from './ShareFeedbackModal'
import { FeedbackList } from './FeedbackList'

export const RequestView = ({
  request,
  requestTopics,
  type,
}: {
  request: ProvidedRequest
  requestTopics: BaseTopic[]
  type: 'normal' | 'shared' | 'preview'
}): JSX.Element => {
  const { currentUserId, user } = authStore.useContainer()
  const lang = userLang(user)

  const allRecipients = [request.requester].concat(request.additionalRecipients)

  const texts = translations[lang]

  const title =
    request.feedbackIsAbout === 'self'
      ? texts.selfReflection
      : type === 'shared'
        ? request.provider === 'anonymous'
          ? texts.anonymousFeedbackTo(userName(request.requester))
          : texts.xSentFeedbackToY(userName(request.provider), userName(request.requester))
        : request.feedbackIsAbout === 'me'
          ? request.provider === 'anonymous'
            ? texts.feedbackForMeAnonymous
            : texts.feedbackForMeFrom(userName(request.provider))
          : texts.sentFeedbackTo(allRecipients.map(userName))

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {request.personalMessage ? (
        <RequestComment
          lang={lang}
          request={request}
          comment={{
            content: request.personalMessage,
            createdAt: request.requestCreated,
            madeBy: request.feedbackIsAbout === 'me' ? 'me' : 'other',
            authorRole: 'requester',
            userId: request.requester.userId,
          }}
          extra={null}
        />
      ) : null}

      {request.providerMessage ? (
        request.provider !== 'anonymous' ? (
          <RequestComment
            lang={lang}
            request={request}
            comment={{
              madeBy: request.feedbackIsAbout === 'me' ? 'other' : 'me',
              content: request.providerMessage,
              authorRole: 'provider',
              createdAt: request.providedAt,
              userId: request.provider.userId,
            }}
          />
        ) : (
          <AnonymousRequestComment
            lang={lang}
            request={request}
            comment={{
              madeBy: request.feedbackIsAbout === 'me' ? 'other' : 'me',
              content: request.providerMessage,
              authorRole: 'provider',
              createdAt: request.providedAt,
            }}
          />
        )
      ) : null}

      {type === 'preview' ? null : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          <div>
            <h2 style={{ marginBottom: 0 }}>{title}</h2>
            {type !== 'shared' && request.requester.userId === currentUserId ? (
              <ShareFeedbackModal requestId={request.requestId} lang={lang} />
            ) : null}
          </div>
          <Timestamp date={request.providedAt} lang={lang} />
          {allRecipients.length > 1 && (
            <i>
              {texts.sentTo} {listItemsInNaturalLanguage[lang](allRecipients.map(userName))}
            </i>
          )}
        </div>
      )}

      <FeedbackList
        type="reinforcing"
        request={request}
        requestTopics={requestTopics}
        lang={lang}
        selectionState={[]}
        feedbackSelectActions={null}
        onCards
      />

      <FeedbackList
        type="redirecting"
        request={request}
        requestTopics={requestTopics}
        lang={lang}
        selectionState={[]}
        feedbackSelectActions={null}
        onCards
      />
    </div>
  )
}
