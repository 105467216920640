import type { DateFormatStyle, DateString, Language } from '@teamspective/common'
import {
  fullLanguage,
  formatDate as libFormatDate,
  customFormatDate as libCustomFormatDate,
  toDate,
} from '@teamspective/common'
import { getYear, isSameDay, isSameMonth, isSameYear } from 'date-fns'

import tz from './tz'

export const formatDate = (p: {
  date: Date | DateString
  lang: Language
  style: DateFormatStyle
}): string => libFormatDate({ ...p, tz })

export const customFormatDate = (p: {
  date: Date | DateString
  lang: Language
  formatTemplate: string
}): string => libCustomFormatDate({ ...p, tz })

const DayFormat = {
  fi: 'd.',
  en: ' d ',
}
const formatDayOfMonth = ({ date, lang }: { date: Date | DateString; lang: Language }) =>
  customFormatDate({
    date: toDate(date),
    formatTemplate: DayFormat[fullLanguage(lang)],
    lang,
  })

const MonthFormat = {
  fi: 'M.',
  en: ' MMM',
}

const MonthYearFormat = {
  fi: 'M.yyyy',
  en: ' MMM yyyy',
}

const formatMonth = ({ date, lang }: { date: Date | DateString; lang: Language }) =>
  customFormatDate({
    date: toDate(date),
    formatTemplate: MonthFormat[fullLanguage(lang)],
    lang,
  })

const formatMonthYear = ({ date, lang }: { date: Date | DateString; lang: Language }) =>
  customFormatDate({
    date: toDate(date),
    formatTemplate: MonthYearFormat[fullLanguage(lang)],
    lang,
  })

const DayMonthFormat = {
  fi: 'd.M.',
  en: ' d MMM ',
}

const formatDayMonth = ({ date, lang }: { date: Date | DateString; lang: Language }) =>
  customFormatDate({
    date: toDate(date),
    formatTemplate: DayMonthFormat[fullLanguage(lang)],
    lang,
  })

export const formatRange = ({
  begin,
  end,
  lang,
  hideYear,
}: {
  begin: Date | DateString
  end: Date | DateString
  lang: Language
  hideYear?: boolean
}): string => {
  const beginDate = toDate(begin)
  const endDate = toDate(end)

  if (isSameDay(beginDate, endDate)) {
    return formatDate({ date: beginDate, lang, style: 'date' })
  }
  if (isSameMonth(beginDate, endDate)) {
    const startDay = formatDayOfMonth({ date: beginDate, lang })
    const endDay = formatDayOfMonth({ date: endDate, lang })
    const monthYear = hideYear
      ? formatMonth({ date: endDate, lang })
      : formatMonthYear({ date: endDate, lang })
    return `${startDay}–${endDay}${monthYear}`.trim()
  }
  if (isSameYear(beginDate, endDate)) {
    const startDayMonth = formatDayMonth({ date: beginDate, lang })
    const endDayMonth = formatDayMonth({ date: endDate, lang })
    const year = hideYear ? '' : getYear(endDate)

    return `${startDayMonth}–${endDayMonth}${year}`.trim()
  }

  const startFullDate = formatDate({ date: beginDate, lang, style: 'date' })
  const endFullDate = formatDate({ date: end, lang, style: 'date' })
  return `${startFullDate} – ${endFullDate}`.trim()
}
