import type { Language } from '../domain/user.ts'
import type { NonEmptyArray } from './common.ts'
import type { DateString, Range } from './date.ts'
import type { QuestionId, PulseRoundId, QuestionScaleStyleId, TeamId, PulseId } from './ids.ts'
import type { SimplePulseComment } from './pulseComment.ts'
import type { Score } from './stats.ts'

export const pulseQuestionsInSortOrder = [
  'wellbeing',
  'wellbeing_statement',
  'workload',
  'clarity',
  'clarity_statement',
  'psychological_safety',
  'psychological_safety_statement',
  'dependability',
  'dependability_statement',
  'learning',
  'autonomy',
  'autonomy_statement',
  'support',
  'communication_in_team',
  'communication_in_team_statement',
  'fairness',
  'fairness_statement',
  'satisfaction_with_feedback',
  'satisfaction_with_feedback_statement',
  'diversity',
  'motivation',
  'competence',
  'competence_statement',
  'enps',
  'interest_in_work',
  'impact',
  'team_effectiveness',
  'voicing_concerns',
  'brainstorming',
  'company_ambition',
  'coordination',
  'understanding_mission',
  'meaningful_mission',
  'mistake_tolerance',
  'confidence_in_future',
  'understanding_strategy',
  'values',
  'rewarding',
  'agility',
  'processes',
  'performance_issues',
  'tools_and_resources',
  'dealing_with_problems',
  'career_opportunities',
  'career_opportunities_statement',
  'pace_of_learning',
  'personal_growth',
  'effectiveness',
  'accomplishment',
  'manager_attention',
  'appreciation',
  'appreciation_statement',
  'recognition',
  'redirection',
  'feedback_to_leaders',
  'giving_feedback',
  'goal_setting',
  'stress',
  'prioritization',
  'sense_of_control',
  'recovery',
  'safety',
  'equipment',
  'relationships',
  'role_expectations',
  'support_for_wellbeing',
  'sick_leave',
  'strengths_utilization',
  'remote_policies',
  'inclusion',
  'inclusion_statement',
  'acceptance',
  'accepting_differences',
  'equal_opportunity',
  'non_discrimination',
  'belonging',
  'barrier_to_help',
  'rejecting_differences',
  'risk_tolerance',
  'social_undermining',
  'career_progression',
  'reasonable_workload',
  'recognition_frequency',
] as const

export type PulseQuestionTag = (typeof pulseQuestionsInSortOrder)[number]

export const questionOptionSets = [
  'wellbeing',
  'agreeing',
  'reverse_agreeing',
  'frequency',
  'reverse_frequency',
  'clarity',
  'learning',
  'satisfaction',
  'satisfaction_with_feedback',
  'workload',
  'motivation',
  'nps',
  'evaluation_agreeing',
  'evaluation_binary_positive',
  'evaluation_binary_negative',
  'quality',
  'evaluation_written',
] as const

export type QuestionOptionSet = (typeof questionOptionSets)[number]

export const pulseQuestionOptions: Record<QuestionTag, QuestionOptionSet> = {
  voicing_concerns: 'agreeing',
  mistake_tolerance: 'reverse_agreeing',
  goal_setting: 'agreeing',
  role_expectations: 'agreeing',
  strengths_utilization: 'agreeing',
  barrier_to_help: 'reverse_agreeing',
  rejecting_differences: 'reverse_agreeing',
  risk_tolerance: 'agreeing',
  social_undermining: 'agreeing',
  recognition_frequency: 'agreeing',
  learning: 'learning',
  pace_of_learning: 'agreeing',
  personal_growth: 'agreeing',
  career_opportunities: 'satisfaction',
  career_opportunities_statement: 'agreeing',
  company_ambition: 'agreeing',
  coordination: 'agreeing',
  understanding_mission: 'agreeing',
  meaningful_mission: 'agreeing',
  confidence_in_future: 'agreeing',
  understanding_strategy: 'agreeing',
  values: 'agreeing',
  rewarding: 'agreeing',
  agility: 'agreeing',
  processes: 'agreeing',
  performance_issues: 'agreeing',
  tools_and_resources: 'agreeing',
  dealing_with_problems: 'agreeing',
  clarity: 'clarity',
  clarity_statement: 'agreeing',
  team_effectiveness: 'agreeing',
  dependability: 'frequency',
  dependability_statement: 'agreeing',
  communication_in_team: 'frequency',
  communication_in_team_statement: 'agreeing',
  support: 'frequency',
  brainstorming: 'frequency',
  psychological_safety: 'reverse_frequency',
  psychological_safety_statement: 'reverse_agreeing',
  motivation: 'motivation',
  autonomy: 'frequency',
  autonomy_statement: 'agreeing',
  competence: 'frequency',
  competence_statement: 'agreeing',
  interest_in_work: 'agreeing',
  impact: 'agreeing',
  effectiveness: 'agreeing',
  accomplishment: 'agreeing',
  satisfaction_with_feedback: 'satisfaction_with_feedback',
  satisfaction_with_feedback_statement: 'agreeing',
  relationships: 'agreeing',
  manager_attention: 'agreeing',
  enps: 'nps',
  appreciation: 'frequency',
  appreciation_statement: 'agreeing',
  recognition: 'frequency',
  redirection: 'agreeing',
  feedback_to_leaders: 'agreeing',
  giving_feedback: 'agreeing',
  wellbeing: 'wellbeing',
  wellbeing_statement: 'agreeing',
  stress: 'agreeing',
  prioritization: 'frequency',
  sense_of_control: 'agreeing',
  recovery: 'agreeing',
  safety: 'agreeing',
  equipment: 'agreeing',
  fairness: 'frequency',
  fairness_statement: 'agreeing',
  workload: 'workload',
  support_for_wellbeing: 'agreeing',
  sick_leave: 'agreeing',
  remote_policies: 'agreeing',
  inclusion: 'reverse_frequency',
  inclusion_statement: 'reverse_agreeing',
  acceptance: 'agreeing',
  accepting_differences: 'agreeing',
  diversity: 'agreeing',
  equal_opportunity: 'agreeing',
  non_discrimination: 'agreeing',
  belonging: 'agreeing',
  reasonable_workload: 'agreeing',
  career_progression: 'agreeing',

  evaluation_employee_collaboration_a: 'evaluation_agreeing',
  evaluation_employee_collaboration_b: 'evaluation_agreeing',
  evaluation_employee_performance_a: 'evaluation_agreeing',
  evaluation_employee_performance_b: 'evaluation_agreeing',
  evaluation_employee_performance_c: 'evaluation_agreeing',
  evaluation_employee_performance_d: 'evaluation_agreeing',
  evaluation_employee_promotion_a: 'evaluation_binary_positive',
  evaluation_employee_promotion_b: 'evaluation_binary_positive',
  evaluation_employee_risk_a: 'evaluation_binary_negative',

  evaluation_manager_collaboration_a: 'evaluation_agreeing',
  evaluation_manager_performance_a: 'evaluation_agreeing',
  evaluation_manager_performance_b: 'evaluation_agreeing',
  evaluation_manager_performance_c: 'evaluation_agreeing',

  evaluation_peer_collaboration_a: 'evaluation_agreeing',
  evaluation_peer_collaboration_b: 'evaluation_agreeing',
  evaluation_peer_performance_a: 'evaluation_agreeing',
  evaluation_peer_performance_b: 'evaluation_agreeing',
  evaluation_peer_performance_c: 'evaluation_agreeing',

  evaluation_self_collaboration_a: 'evaluation_agreeing',
  evaluation_self_collaboration_b: 'evaluation_agreeing',
  evaluation_self_performance_a: 'evaluation_agreeing',
  evaluation_self_performance_b: 'evaluation_agreeing',

  evaluation_strengths_a: 'evaluation_written',
  evaluation_development_a: 'evaluation_written',
  evaluation_development_b: 'evaluation_written',
  evaluation_open: 'evaluation_written',
}

export const questionScales = [
  'nps',
  'linear5',
  'balance5',
  'linear4',
  'linear7',
  'binary',
  'written',
  'custom',
] as const

export type QuestionScale = (typeof questionScales)[number]

export type PulseQuestionScale = 'nps' | 'linear5' | 'balance5' | 'custom'

export type EvaluationQuestionScale =
  | 'linear7'
  | 'linear5'
  | 'linear4'
  | 'binary'
  | 'written'
  | 'custom'

export const questionScaleAnswerValues = {
  nps: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
  linear5: [5, 4, 3, 2, 1],
  balance5: [2, 1, 0, -1, -2],
  linear7: [7, 6, 5, 4, 3, 2, 1],
  linear4: [4, 3, 2, 1],
  binary: [1, 0],
} as const

export type EmployeeEvaluationQuestionTag =
  | 'evaluation_employee_collaboration_a'
  | 'evaluation_employee_collaboration_b'
  | 'evaluation_employee_performance_a'
  | 'evaluation_employee_performance_b'
  | 'evaluation_employee_performance_c'
  | 'evaluation_employee_performance_d'
  | 'evaluation_employee_promotion_a'
  | 'evaluation_employee_promotion_b'
  | 'evaluation_employee_risk_a'

export type ManagerEvaluationQuestionTag =
  | 'evaluation_manager_collaboration_a'
  | 'evaluation_manager_performance_a'
  | 'evaluation_manager_performance_b'
  | 'evaluation_manager_performance_c'

export type PeerEvaluationQuestionTag =
  | 'evaluation_peer_collaboration_a'
  | 'evaluation_peer_collaboration_b'
  | 'evaluation_peer_performance_a'
  | 'evaluation_peer_performance_b'
  | 'evaluation_peer_performance_c'

export type SelfEvaluationQuestionTag =
  | 'evaluation_self_collaboration_a'
  | 'evaluation_self_collaboration_b'
  | 'evaluation_self_performance_a'
  | 'evaluation_self_performance_b'

export type CommonEvaluationQuestionTag =
  | 'evaluation_strengths_a'
  | 'evaluation_development_a'
  | 'evaluation_development_b'
  | 'evaluation_open'

export type EvaluationQuestionTag =
  | EmployeeEvaluationQuestionTag
  | ManagerEvaluationQuestionTag
  | PeerEvaluationQuestionTag
  | SelfEvaluationQuestionTag
  | CommonEvaluationQuestionTag

export type QuestionTag = PulseQuestionTag | EvaluationQuestionTag

export type MetaEvaluationAnswerReason = 'skip-question'

export type MetaPulseAnswerReason = 'not-applicable' | 'prefers-not-to'

export type MetaAnswerReason = MetaPulseAnswerReason | MetaEvaluationAnswerReason

export type PulseAnswerReason = 'answered' | 'unanswered' | MetaPulseAnswerReason

export type QuestionType = 'pulse' | 'evaluation'

export type Question<Tag = QuestionTag> = {
  questionId: QuestionId
  tag: Tag | null
  scale: QuestionScale
  content: QuestionContent
  workspaceId: TeamId | null
  deleted: boolean
  questionType: QuestionType
}

export type PulseAnswerValue = number | MetaPulseAnswerReason

export type EvaluationAnswerValue = number | MetaEvaluationAnswerReason

export type Answer = {
  questionId: QuestionId
  pulseRoundId: PulseRoundId
  pulseAnswerValue: PulseAnswerValue
  pulseAnswerComment: SimplePulseComment | null
}

export type UnansweredAnswerState = {
  type: 'unanswered'
  questionId: QuestionId
  draftValue?: string | number
}

export type AnsweredAnswerState = {
  type: 'answered'
  answer: Answer & { answeredAt: DateString }
  questionId: QuestionId
  draftValue?: string | number
}

export type AnswerState = UnansweredAnswerState | AnsweredAnswerState

export type SurveyDescriptor = {
  pulseRoundId: PulseRoundId
  pulseId: PulseId | null
} & Range

export type PulseAnswerCount = {
  value: PulseAnswerValue
  count: number
}

export type PulseAnswerValueCount = {
  value: number
  count: number
}

export type QuestionResult = {
  score: Score | 'redacted'
  questionId: QuestionId
  tag: PulseQuestionTag | null
  counts: PulseAnswerValueCount[] | 'redacted'
  n: number | 'redacted'
}

export type SurveyQuestionResult = SurveyDescriptor & QuestionResult

export type TranslationString = { [lang in Language]?: string } & { en: string }

export const translated = (t: TranslationString, lang: Language): string => t[lang] ?? t.en

export type QuestionChoice = { value: number; label: TranslationString }

export type QuestionContent = {
  title: TranslationString
  name: TranslationString
  choices: Array<QuestionChoice>
}

export type QuestionScaleStyle = {
  questionScaleStyleId: QuestionScaleStyleId
  questionScale: QuestionScale
  emojis: string[]
}

export type TargetedQuestion = { questionId: QuestionId; teamIds: NonEmptyArray<TeamId> }

const predefinedChoiceTemplatesPulse = [
  'frequencyOfPositivePhenomenon',
  'frequencyOfNegativePhenomenon',
  'satisfaction',
  'agreeing',
  'quality',
] as const

const predefinedChoiceTemplatesEvaluation = [
  'agreeing7point',
  'expectations',
  'standards',
  'rolefit',
  'skillLevel1',
  'skillLevel2',
  'skillLevel3',
  'skillLevel4',
  'scale1to5',
  'positiveFrequency4',
  'yesNoPositive',
  'yesNoNegative',
  'written',
] as const

export const predefinedChoicesByQuestionnaireType = {
  pulse: predefinedChoiceTemplatesPulse,
  evaluation: predefinedChoiceTemplatesEvaluation,
}

export type PredefinedQuestionChoiceTemplate =
  | (typeof predefinedChoiceTemplatesPulse)[number]
  | (typeof predefinedChoiceTemplatesEvaluation)[number]
