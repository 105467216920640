import type React from 'react'
import { Avatar, Tooltip } from 'antd'
import type { Language } from '@teamspective/common'
import { SelfReflectIcon } from '../icons'
import { lightBrown, selfReflectionYellow } from '@teamspective/common'

import { translations } from '@teamspective/common'

const SelfRelfectAvatar: React.FC<{ lang: Language }> = ({ lang }) => {
  return (
    <Tooltip title={translations[lang].selfReflection}>
      <Avatar
        style={{ backgroundColor: selfReflectionYellow, color: lightBrown }}
        icon={<SelfReflectIcon />}
      />
    </Tooltip>
  )
}

export default SelfRelfectAvatar
