import { Tooltip } from 'antd'
import type { Language, DateString } from '@teamspective/common'
import { formatUntilNow, toDate } from '@teamspective/common'
import { differenceInDays } from 'date-fns'
import { formatDate } from '../../format'
import { lightBlack } from '@teamspective/common'

const Timestamp = ({
  date,
  lang,
  color,
  fontSize,
  isRelative = true,
}: {
  date: DateString | Date
  lang: Language
  color?: string
  fontSize?: number
  isRelative?: boolean
}): JSX.Element => (
  <Tooltip
    title={formatDate({
      date,
      style: 'shortDateTime',
      lang,
    })}
  >
    <span
      style={{
        color: color ?? lightBlack,
        whiteSpace: 'nowrap',
        fontSize: fontSize ?? 'inherit',
      }}
    >
      {isRelative && differenceInDays(new Date(), toDate(date)) < 7
        ? formatUntilNow(date, lang)
        : formatDate({
            date,
            style: 'date',
            lang,
          })}
    </span>
  </Tooltip>
)

export default Timestamp
