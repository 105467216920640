import type { Language } from '@teamspective/common'
import { contactEmail } from '@teamspective/common'
import { Button } from 'antd'

import { MailOutlined as MailIcon } from '@ant-design/icons'

import { blue } from '@teamspective/common'
import { translations } from '@teamspective/common'

const ContactUs = ({ lang }: { lang: Language }): JSX.Element => {
  return (
    <a href={`mailto:${contactEmail}`}>
      <Button type="link">
        <MailIcon style={{ color: blue }} />
        {translations[lang].contactUs}
      </Button>
    </a>
  )
}

export default ContactUs
