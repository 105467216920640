import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// From https://reactrouter.com/docs/en/v6/upgrading/reach#update-locationprovider-to-browserrouter
export default function Redirect({ to }: { to: string }) {
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    navigate({ ...location, pathname: to })
  })
  return null
}
