export const t = {
  en: {
    registrationText: 'Not a member yet?',
    registrationCta: 'Contact us for an invitation',
    magicLinkSentTitle: 'Check your email',
    magicLinkSent: (email: string) =>
      `We sent you an email at ${email}. It has a link that will log you in.`,
    register: 'Register',
    emailLoginTitle: "Email login – we'll send you a link",
    emailLogin: 'Sign in with email',
    expiredToken:
      'It seems that the link you used has expired. No worries! You can always login with your email address below.',
    invalidToken:
      'We did not recognize the link you used. No worries! You can always login with your email address below.',
  },
}
