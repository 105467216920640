const RELOAD_PARAM_NAME = 'reload'

export const reloadWithParam = () => {
  const url = new URL(window.location.href)
  url.searchParams.set(RELOAD_PARAM_NAME, '1')
  window.location.href = url.toString()
}

export const isReloadParamSet = () =>
  new URLSearchParams(window.location.search).has(RELOAD_PARAM_NAME)

export const removeReloadParamIfExists = () => {
  if (isReloadParamSet()) {
    const url = new URL(window.location.href)
    url.searchParams.delete(RELOAD_PARAM_NAME)
    window.history.replaceState(window.history.state, '', url.toString())
  }
}
