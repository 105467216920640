import type { Language } from './domain/user.ts'
import type { NonEmptyArray, Result } from './types/common.ts'
import type { BrandedId, BrandedString } from './types/ids.ts'
import type { Score, QuartileLimits } from './types/stats.ts'

export const oneOrNone = <T>(res: T[]): T | null => {
  if (res.length === 0) {
    return null
  } else {
    return res[0]
  }
}

export const exactlyOne = <T>(res: T[]): T => {
  if (res.length !== 1) {
    throw new Error('There should be only exactly one item in list')
  }
  return res[0]
}

export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const domainRegex =
  /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const leftPadNumber = (n: number): string => (n < 10 ? `0${n}` : `${n}`)

export const compareScores = (a: Score, b: Score): number => {
  if (a === 'N/A' || b === 'N/A') {
    if (b !== 'N/A') {
      return 1
    }
    if (a !== 'N/A') {
      return -1
    }
    return 0
  }

  return a - b
}

export const percentage = (
  a: number,
  b: number,
  { round, decimals }: { round: 'up' | 'down' | 'nearest'; decimals: number } = {
    round: 'nearest',
    decimals: 0,
  }
): Score => {
  if (b === 0) {
    return 'N/A'
  }
  const decimalMultiplier = Math.pow(10, decimals)
  const resultWithDecimals = (a * 100 * decimalMultiplier) / b
  const truncatedResult =
    round === 'up'
      ? Math.ceil(resultWithDecimals)
      : round === 'down'
        ? Math.floor(resultWithDecimals)
        : Math.round(resultWithDecimals)

  const scaledTruncatedResult = truncatedResult / decimalMultiplier
  return Math.min(scaledTruncatedResult, 100)
}

export const nonNullable = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined

export const assertNonNullable = <T>(value: T | null | undefined): T => {
  if (value === null) {
    throw Error('value is null')
  }
  if (value === undefined) {
    throw Error('value is undefined')
  }
  return value
}

export const isNonEmpty = <T>(value: T[]): value is NonEmptyArray<T> => {
  return value.length > 0
}

export const assertNonEmpty = <T>(value: T[]): NonEmptyArray<T> => {
  if (!isNonEmpty(value)) {
    throw Error('value is empty')
  }
  return value
}

export const defaultIfEmpty = <T>(value: T[], defaultValue: T): NonEmptyArray<T> =>
  value.length === 0 ? [defaultValue] : (value as NonEmptyArray<T>)

export const unnest2DArray = <T>(s: T[][]): T[] => s.reduce((acc, curr) => acc.concat(curr), [])

export const isNotNull = <T>(x: T | null): x is T => x !== null
export const isNotUndefined = <T>(x: T | undefined): x is T => x !== undefined
export const isNotLoading = <T>(x: T | 'loading'): x is T => x !== 'loading'

export const toLines = (...strings: (string | null)[]): string =>
  strings.filter(isNotNull).join('\n')

export const toParagraphs = (...strings: (string | null)[]): string =>
  strings.filter(isNotNull).join('\n\n')

export const memoize = <T, K extends string | number>(fn: (fn: K) => T): ((fn: K) => T) => {
  const memo: { [key: string | number]: T } = {}
  return (key: K) => {
    if (!(key in memo)) {
      memo[key] = fn(key)
    }
    return memo[key]
  }
}

export const firstOf = <T>(arr: readonly T[]): T | null => {
  if (arr.length === 0) {
    return null
  }
  return arr[0]
}

export const lastOf = <T>(arr: readonly T[]): T | null => {
  if (arr.length === 0) {
    return null
  }
  return arr[arr.length - 1]
}

/**
 * Gets object values. If object contains values explicitly set to `undefined`
 * those are filtered out.
 *
 * @param o - Record
 * @returns - Array of non-undefined values
 */
export const objectValues = <V>(o: { [key in string | number]?: V }): V[] =>
  Object.values(o).filter(isNotUndefined)

export const countBy = <V, K extends string>(
  arr: V[],
  fn: (v: V) => K | null
): Partial<Record<K, number>> =>
  arr.reduce(
    (prev, curr) => {
      const key = fn(curr)
      if (key === null) {
        return prev
      }
      return {
        ...prev,
        [key]: (prev[key] ?? 0) + 1,
      }
    },
    {} as Partial<Record<K, number>>
  )

export const mapObject = <T extends Record<string, unknown>, O>(
  obj: T,
  fn: (v: T[keyof T], k: SerializedKeyType<T>) => O
): Record<SerializedKeyType<T>, O> => {
  const result = {} as Record<SerializedKeyType<T>, O>
  for (const key of objectKeys(obj)) {
    result[key] = fn(obj[key], key)
  }
  return result
}

export const mapObjectPartial = <T extends Record<string, unknown>, O>(
  obj: T,
  // The values passed in are any non-undefined values.
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  fn: (v: T[keyof T] & ({} | null), k: SerializedKeyType<T>) => O | undefined
): Partial<Record<SerializedKeyType<T>, O>> => {
  const result = {} as Partial<Record<SerializedKeyType<T>, O>>
  for (const key of objectKeys(obj)) {
    const val = obj[key]
    const res = val === undefined ? undefined : fn(val, key)
    if (res === undefined) {
      continue
    }
    result[key] = res
  }
  return result
}

export const filterObject = <T extends Record<string, unknown>>(
  obj: T,
  fn: (v: T[keyof T], k: SerializedKeyType<T>) => boolean
): Partial<Record<SerializedKeyType<T>, T[keyof T]>> => {
  const result = {} as Partial<Record<SerializedKeyType<T>, T[keyof T]>>
  for (const key of objectKeys(obj)) {
    if (fn(obj[key], key)) {
      result[key] = obj[key]
    }
  }
  return result
}

export function deepOmit<T>(obj: T, keysToOmit: string[]): Partial<T> {
  if (Array.isArray(obj)) {
    return obj.map((item) => deepOmit(item, keysToOmit)) as unknown as Partial<T>
  } else if (typeof obj === 'object' && obj !== null) {
    return mapObject(
      filterObject(obj as Record<string, unknown>, (_, key) => !keysToOmit.includes(key)),
      (value) => deepOmit(value, keysToOmit)
    ) as T
  }
  return obj
}

type Stringly<K> = K extends number ? string : K

// Polyfill for client-side support adapted from https://github.com/feross/fromentries
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fromEntries = <K extends string | number, T = any>(
  iterable: readonly (readonly [K, T])[] | Iterable<readonly [K, T]>
): Record<Stringly<K>, T> => {
  return [...iterable].reduce(
    (obj, [key, val]) => {
      obj[key] = val
      return obj
    },
    {} as Record<K, T>
  ) as Record<Stringly<K>, T>
}

/**
 * Build object from readonly array of key-value pairs.
 *
 * This can narrow some `const` types better than `fromEntries`.
 */
// If you know the typescript black magic why this has to be downcasted for `DemoPulseResultsAll` to work, please fix :D
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fromEntriesStatic = fromEntries as <K extends string | number, T = any>(
  iterable: readonly (readonly [K, T])[]
) => Record<Stringly<K>, T>

export type SerializedBrandedId<T extends string> = BrandedString<`str_${T}`>

export const deserializeBrandedId = <T extends string>(
  value: SerializedBrandedId<T>
): BrandedId<T> => {
  const parsed = parseInt(value)
  if (isNaN(parsed)) {
    throw new Error(`Invalid branded string, got ${value}`)
  }
  return parsed as BrandedId<T>
}

export const extractSerializedId = <Id extends BrandedId<string>, Prefix extends string>(
  prefix: Prefix,
  value: `${Prefix}${Id}`
) => {
  if (!value.startsWith(prefix)) {
    throw new Error(`Couldn't extract id, prefix ${prefix} not found in ${value}`)
  }
  const parsed = parseInt(value.slice(prefix.length))
  if (isNaN(parsed)) {
    throw new Error(`Couldn't extract id, ${value} is not a number`)
  }
  return parsed as Id
}

type SerializedKeyTypeInner<T> = T extends { __brand: infer Brand }
  ? Brand extends string
    ? SerializedBrandedId<Brand>
    : // Brand cannot be non-string
      never
  : // Passed value was not branded. Maybe a union of record key names
    T extends string
    ? // All keys are strings, fine :)
      T
    : // There were numbers/symbols/objects in the union
      T extends number
      ? // Give hint that record key names contained numbers
        string & { __brand: 'numeric' }
      : // Just pass whatever type originally was (union number/symbol. If you end up here, you shouldn't be happy)
        T extends symbol
        ? never // These are not returned by Object.entries
        : T & { __brand: 'hazard' }

/**
 * Maps types from T to string union/branded types as returned by Object.keys.
 * Javascript mangles all keys of objects to strings (as returned by .toString())
 * so (number & { __brand: 'UserId' }) becomes a string with the numeric value.
 *
 * However some types say that they have numbers as keys (which works when setting/retrieving values)
 * but `Object.keys` and `Object.entries` return a string form of them.
 * This utility carries the narrower type to those strings.
 */
type SerializedKeyType<T> = string extends keyof T
  ? keyof T extends string
    ? keyof T
    : SerializedKeyTypeInner<keyof T>
  : SerializedKeyTypeInner<keyof T>

export const objectEntries = <T extends Record<string, unknown>>(obj: T) =>
  Object.entries(obj) as [SerializedKeyType<T>, T[keyof T]][]

export const objectKeys = <T extends Record<string, unknown>>(o: T) =>
  Object.keys(o) as SerializedKeyType<T>[]

export const sum = (ns: number[]): number => ns.reduce((a, c) => a + c, 0)

export const mean = (values: number[]): number => {
  if (values.length === 0) {
    return NaN
  }
  return sum(values) / values.length
}

export const isNumber = (v: unknown) => typeof v === 'number'

export const average = (nums: number[]): number => sum(nums) / nums.length

export const averageScore = (scores: Score[]): Score => {
  const numericScores = scores.filter(isNumber)
  if (numericScores.length === 0) {
    return 'N/A'
  }
  return Math.round(average(numericScores))
}

export const mapScore = (score: Score, fn: (a: number) => number): Score =>
  score === 'N/A' ? score : fn(score)

export const pick = <T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  keys: readonly K[]
): Pick<T, K> => {
  return Object.assign(
    {},
    ...keys.map((key: K) => {
      return { [key]: obj[key] }
    })
  ) as Pick<T, K>
}

// https://github.com/microsoft/TypeScript/wiki/FAQ#add-a-key-constraint-to-omit
type OmitConstrained<T, K> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [P in keyof T as Exclude<P, K & keyof any>]: T[P]
}

export const omit = <T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  keys: readonly K[]
): OmitConstrained<T, K> => {
  const copy = { ...obj }
  for (const key of keys) {
    delete copy[key]
  }
  return copy
}

export const range = (start: number, end: number): number[] => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i)
}

export const sort = <T>(arr: readonly T[], compareFn?: (a: T, b: T) => number): T[] => {
  return arr.slice().sort(compareFn)
}

export function partition<T, K extends T>(arr: T[], predicate: (t: T) => t is K): [K[], T[]]
export function partition<T>(arr: T[], predicate: (t: T) => boolean): [T[], T[]]
export function partition<T, K extends T>(
  arr: T[],
  predicate: ((t: T) => t is K) | ((t: T) => boolean)
): [T[], T[]] {
  const trueArr = []
  const falseArr = []
  for (const item of arr) {
    if (predicate(item)) {
      trueArr.push(item)
    } else {
      falseArr.push(item)
    }
  }
  return [trueArr, falseArr]
}

export const reverse = <T>(arr: readonly T[]): T[] => {
  return arr.slice().reverse()
}

export const unique = <T extends number | string | boolean>(arr: readonly T[]): T[] =>
  uniqueBy(arr, (a) => a)

export const zeroNa = (x: Score): number => (x === 'N/A' ? 0 : x)

/**
 * Returns a new array with unique items based on the result of the `fn` function.
 * When multiple items have the same result of the `fn` function, the first item is kept.
 *
 * @param arr - The array to filter.
 * @param fn - The function to use to determine uniqueness.
 * @returns A new array with unique items.
 */
export const uniqueBy = <T, U extends number | string | boolean>(
  arr: readonly T[],
  fn: (v: T) => U
): T[] => {
  const withIds = arr.map((a) => [a, fn(a)] as const)
  const result: T[] = []
  const ids: U[] = []
  for (const [value, id] of withIds) {
    if (!ids.includes(id)) {
      result.push(value)
      ids.push(id)
    }
  }
  return result
}

export const nonNullableGroupBy = <T, K extends string | number>(
  arr: readonly T[],
  fn: (a: T) => K
): Record<K, T[]> => groupBy(arr, fn) as Record<K, T[]>

/**
 * Note: groupBy is guaranteed to be stable: items will be grouped together in
 * the same order as they appear in the input array.
 */
export const groupBy = <T, K extends string | number>(
  arr: readonly T[],
  fn: (a: T) => K
): Partial<Record<K, T[]>> => {
  return arr.reduce(
    (obj, item) => {
      const key = fn(item)

      const groupItems = obj[key]
      if (groupItems) {
        groupItems.push(item)
      } else {
        obj[key] = [item]
      }

      return obj
    },
    {} as Partial<Record<K, T[]>>
  )
}

export const sumArray = (arr: number[]): number => arr.reduce((acc, v) => acc + v, 0)

export const intersectionBy = <T, K extends string | number>(
  arr1: readonly T[],
  arr2: readonly T[],
  fn: (v: T) => K
): T[] => {
  return arr1.filter((a) => arr2.map(fn).includes(fn(a)))
}

export const flatten = <T>(arr: T[][]): T[] => arr.reduce((acc, curr) => acc.concat(curr), [])

export const chunk = <T>(arr: T[], size: number): T[][] => {
  if (size < 1) {
    throw Error('Invalid argument size to function chunk')
  }
  const chunks = []
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size))
  }
  return chunks
}

/**
 * Take in a async generator, and return a new async generator that returns the same values
 * and a promise that resolves to the return value of the original generator.
 *
 * This allows using the values in a `for await` loop, but also using the return value of the generator.
 */
export const generatorAndReturn = <T, TReturn>(
  generator: AsyncGenerator<T, TReturn, void>
): {
  generator: AsyncGenerator<T, void, unknown>
  returnValue: Promise<TReturn>
} => {
  let resolveReturn: (value: TReturn) => void

  // Create a promise that will resolve with the return value
  const returnValue: Promise<TReturn> = new Promise((resolve) => {
    resolveReturn = resolve
  })

  // Create a new generator that yields the same values
  async function* wrappedGenerator(): AsyncGenerator<T, void, unknown> {
    while (true) {
      const result = await generator.next()
      if (result.done) {
        resolveReturn(result.value)
        break
      } else {
        yield result.value
      }
    }
  }
  return { generator: wrappedGenerator(), returnValue }
}

export async function* batchGenerator<T, TReturn>(
  generator: AsyncGenerator<T, TReturn, void>,
  batchSize: number
): AsyncGenerator<T[], TReturn, void> {
  let batch: T[] = []
  while (true) {
    const result = await generator.next()
    if (result.done) {
      if (batch.length > 0) {
        yield batch
      }
      return result.value
    }
    batch.push(result.value)
    if (batch.length === batchSize) {
      yield batch
      batch = []
    }
  }
}

export const chunkBy = <T>(arr: T[], fn: (a: T) => number | string): T[][] =>
  objectValues(groupBy(arr, fn))
    .map((arr) => arr ?? [])
    .filter(isNonEmpty)

export const split = <T>(arr: T[], partCount: number): T[][] => {
  if (partCount < 2) {
    return [arr]
  }

  if (arr.length % partCount === 0) {
    return chunk(arr, arr.length / partCount)
  }

  // Balanced split
  // e.g. 11 elements to 3 parts => 4 elements, 4 elements, 3 elements
  const parts = []
  let i = 0,
    remainingElements = arr.length,
    remainingParts = partCount
  while (remainingParts > 0) {
    const partSize = Math.ceil(remainingElements / remainingParts)
    parts.push(arr.slice(i, (i += partSize)))
    remainingElements -= partSize
    remainingParts--
  }
  return parts
}

export const dropUntil = <T>(arr: readonly T[], predicate: (a: T) => boolean) => {
  const matchingIndex = arr.findIndex(predicate)
  return matchingIndex === -1 ? [] : arr.slice(matchingIndex)
}

export const parseIntSafe = (p: string): number => {
  const n = parseInt(p, 10)
  if (isNaN(n)) {
    throw Error(`Failed to parse int from ${p}`)
  } else {
    return n
  }
}

export const parseIntOrNull = (p: string | null): number | null => {
  if (p === null) {
    return null
  }
  const n = parseInt(p, 10)
  if (isNaN(n)) {
    return null
  } else {
    return n
  }
}

const digitsOnlyRegex = /^\d+$/
export const isParseableAsId = (s: string) => digitsOnlyRegex.test(s)

const listItemsInEnglish = (arr: readonly string[], fn = (s: string) => s) =>
  arr.length > 1
    ? `${arr.slice(0, -1).map(fn).join(', ')} and ${arr.slice(-1).map(fn)[0]}`
    : arr.join(', ')

export const listItemsInNaturalLanguage: {
  [lang in Language]: (arr: readonly string[], transform?: (s: string) => string) => string
} = {
  en: listItemsInEnglish,
  es: listItemsInEnglish,
  sv: listItemsInEnglish,
  no: listItemsInEnglish,
  dk: listItemsInEnglish,
  de: listItemsInEnglish,
  it: listItemsInEnglish,
  fr: listItemsInEnglish,
  pl: listItemsInEnglish,
  cn: listItemsInEnglish,
  uk: listItemsInEnglish,
  fi: (arr, fn = (s) => s) =>
    arr.length > 1
      ? `${arr.slice(0, -1).map(fn).join(', ')} ja ${arr.slice(-1).map(fn)[0]}`
      : arr.join(', '),
}

export const withEllipsis = (str: string, limit: number): string => {
  if (str.length > limit) {
    return str.slice(0, limit - 1) + '…'
  }
  return str
}

export const containSameItems = <T extends string | number>(a: T[], b: T[]): boolean =>
  a.length === b.length && a.every((item) => b.includes(item))

// Workaround for https://github.com/Microsoft/TypeScript/issues/7294#issuecomment-465794460
type ArrayItem<T> = T extends Array<infer S> ? S : never
export const flattenArrayType = <T>(array: T): Array<ArrayItem<T>> => {
  return array as unknown as Array<ArrayItem<T>>
}

export const matchSingleCaptureGroup = (str: string, regex: RegExp): string | null => {
  const matches = regex.exec(str)
  if (!matches) {
    return null
  }
  return matches[1]
}

export const matchUser = (
  user: { email: string | null; firstName?: string; lastName?: string; displayName?: string },
  searchString: string
): boolean =>
  searchString
    .trim()
    .toLocaleLowerCase()
    .split(' ')
    .every(
      (searchFragment) =>
        ('firstName' in user &&
          user.firstName &&
          user.firstName.toLocaleLowerCase().includes(searchFragment)) ||
        ('lastName' in user &&
          user.lastName &&
          user.lastName.toLocaleLowerCase().includes(searchFragment)) ||
        ('displayName' in user &&
          user.displayName &&
          user.displayName.toLocaleLowerCase().includes(searchFragment)) ||
        user.email?.toLocaleLowerCase().includes(searchFragment)
    )

export const searchUsers = <
  T extends { email: string | null; firstName?: string; lastName?: string; displayName?: string },
>(
  users: T[],
  searchString: string
): T[] => users.filter((user) => matchUser(user, searchString))

export function assertNever(value: never): never {
  throw new Error(`assertNever called with value: ${JSON.stringify(value)}`)
}

export function assertUnreachable(message?: string): never {
  throw new Error(`assertUnreachable called${message ? `: ${message}` : ''}`)
}

export const immutableSplice = <T>(
  input: T[],
  start: number,
  deleteCount: number = input.length - start,
  ...items: T[]
) => input.slice(0, start).concat(...items, input.slice(start + deleteCount))

export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1)

export const mapTree = <T extends { children?: T[] }, U>(tree: T[], fn: (t: T) => U): U[] =>
  tree.map((node) => ({ ...fn(node), children: node.children ? mapTree(node.children, fn) : [] }))

export const prepend = <T>(arr: T[], elem: T) => [elem].concat(arr)

export const randomLetter = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  return alphabet[Math.floor(Math.random() * alphabet.length)]
}

export const randomFromArray = <T>(arr: T[]) => arr[Math.floor(Math.random() * arr.length)]

export const round = (num: number, precision: number) => Number(num.toFixed(precision))

export const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(max, value))

export const identity = <T>(v: T) => v

/**
 * Less strict linear interpolation of a value from an input range to an output range.
 *
 * @param {[number, number]} inputRange - Input range [min, max]
 * @param {[number, number]} outputRange - Output range [min, max]
 * @param {number} value - Value to map from input range to output range.
 * @throws {Error} If either range is invalid (min > max)
 * @returns {number} Interpolated value in output range.
 */
export const mapRangeClamped = (
  [inputMin, inputMax]: [number, number],
  [outputMin, outputMax]: [number, number],
  value: number
) => {
  if (inputMin === inputMax) {
    return outputMin
  }
  return mapRange([inputMin, inputMax], [outputMin, outputMax], clamp(value, inputMin, inputMax))
}

/**
 * Linear interpolation of a value from an input range to an output range.
 *
 * @param {[number, number]} inputRange - Input range [min, max]
 * @param {[number, number]} outputRange - Output range [min, max]
 * @param {number} value - Value to map from input range to output range.
 * @throws {Error} If either range is invalid (min > max), input range is single point, or value is outside input range.
 * @returns {number} Interpolated value in output range.
 */
export const mapRange = function (
  [inputMin, inputMax]: [number, number],
  [outputMin, outputMax]: [number, number],
  value: number
) {
  if (inputMin === inputMax) {
    throw new Error(`mapRange: Input range is a single point: ${inputMin}`)
  } else if (inputMin > inputMax) {
    throw new Error(`mapRange: Invalid input range: ${inputMin} > ${inputMax}`)
  } else if (outputMin > outputMax) {
    throw new Error(`mapRange: Invalid output range: ${outputMin} > ${outputMax}`)
  } else if (value < inputMin) {
    throw new Error(`mapRange: Value is less than inputMin: ${value} < ${inputMin}`)
  } else if (value > inputMax) {
    throw new Error(`mapRange: Value is greater than inputMax: ${value} > ${inputMax}`)
  }
  return outputMin + ((value - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin)
}

export const encodeHashParams = (params: Partial<Record<string, string | number>>) => {
  const entries = Object.entries(filterObject(params, nonNullable))
  if (!entries.length) {
    return ''
  }
  return ':' + entries.map(([key, value]) => `${key}=${value}`).join(',')
}

export const absoluteLink = (url: string) =>
  url.startsWith('http://') || url.startsWith('https://') || url.startsWith('//')
    ? url
    : `https://${url}`

export const quartileNames = ['top', 'topMid', 'bottomMid', 'bottom'] as const
export type QuartileName = (typeof quartileNames)[number]

export const findQuartile = (score: Score, limits: QuartileLimits): QuartileName | null => {
  if (score === 'N/A' || isNaN(score)) {
    return null
  }
  // We place scores above/below the range limits in the top/bottom quartiles
  if (score < limits[0]) {
    return 'bottom'
  } else if (score < limits[1]) {
    return 'bottom'
  } else if (score < limits[2]) {
    return 'bottomMid'
  } else if (score < limits[3]) {
    return 'topMid'
  } else {
    return 'top'
  }
}

export const quartileRange = (range: QuartileName, limits: QuartileLimits): [number, number] => {
  switch (range) {
    case 'bottom':
      return [limits[0], limits[1]]
    case 'bottomMid':
      return [limits[1], limits[2]]
    case 'topMid':
      return [limits[2], limits[3]]
    case 'top':
      return [limits[3], limits[4]]
  }
}

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(() => resolve(undefined), ms))

export function arrayIs<In, Out extends In>(arr: In[], isFn: (x: In) => x is Out): arr is Out[] {
  return arr.every(isFn)
}

export function assertIs<In, Out extends In>(
  value: In,
  isFn: (x: In) => x is Out
): asserts value is Out {
  if (!isFn(value)) {
    throw Error('value did not have correct type')
  }
}

export function assertArray<In, Out extends In>(
  arr: In[],
  assertFn: (x: In) => asserts x is Out
): asserts arr is Out[] {
  arr.forEach(assertFn)
}

export function assertArrayIs<In, Out extends In>(
  arr: In[],
  isFn: (x: In) => x is Out
): asserts arr is Out[] {
  assertArray(arr, (x): void => assertIs(x, isFn))
}

export const shuffleInplace = <T>(arr: T[]): T[] => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = arr[i]
    arr[i] = arr[j]
    arr[j] = temp
  }
  return arr
}

export const sampleArray = <T>(arr: T[], n: number): T[] => {
  // Fisher-Yates shuffle starting at the beginning and iterating n times
  const shuffled = arr.slice()
  for (let i = 0; i < n; i++) {
    const j = i + Math.floor(Math.random() * (shuffled.length - i))
    const temp = shuffled[i]
    shuffled[i] = shuffled[j]
    shuffled[j] = temp
  }
  return shuffled.slice(0, n)
}

export const isIn = <T extends readonly string[]>(arr: T, value: string): value is T[number] =>
  arr.includes(value as T[number])

export const zip = <T, U>(a: T[], b: U[]): Array<[T, U]> =>
  a.length < b.length ? zip(b, a).map(([x, y]) => [y, x]) : a.map((x, i) => [x, b[i]])

export const mapToResult =
  <T, K>(fun: (p: K) => T): ((p: K) => Result<T>) =>
  (p: K) => {
    try {
      const result = fun(p)
      return { data: result, status: 'ok' }
    } catch (e) {
      return { error: e, status: 'error' }
    }
  }

export const batchApplyToField = async <T, K extends keyof T, A>(
  objs: T[],
  key: K,
  fn: (v: T[K][]) => Promise<A[]>
) => {
  const values = objs.map((obj) => obj[key])
  const res = await fn(values)
  return objs.map((obj, i) => ({ ...obj, [key]: res[i] }))
}

export const getRandomInRange = (min: number, max: number): number => {
  if (max < min) {
    throw new Error(`Invalid range ${min} - ${max}`)
  }
  const range = max - min
  return min + Math.round(Math.random() * range)
}

export const startsWith = <Prefix extends string>(
  prefix: Prefix,
  value: string
): value is `${Prefix}${string}` => value.startsWith(prefix)
