import type React from 'react'
import { Popover, Button, Drawer } from 'antd'
import { darkBlue } from '@teamspective/common'
import { InfoOutlinedIcon } from './icons'
import { useState } from 'react'

const label = ({
  title,
  buttonLabel,
  compacted,
}: {
  title?: string
  buttonLabel?: string | null
  compacted?: boolean
}): string | null => {
  if (compacted) {
    return null
  }
  if (buttonLabel) {
    return buttonLabel
  }
  if (buttonLabel === null) {
    return null
  }
  if (title) {
    return title
  }
  return null
}

const Info = ({
  content,
  title,
  icon,
  buttonLabel,
  compacted,
  placement,
  style,
  mode = 'popover',
}: {
  content: React.ReactNode
  title?: string
  icon?: React.ReactNode | null
  buttonLabel?: string | null
  compacted?: boolean
  placement?: 'bottom'
  style?: React.CSSProperties
  mode?: 'popover' | 'drawer'
}): JSX.Element => {
  const [open, setOpen] = useState(false)
  const button = (
    <Button
      type="link"
      style={{ cursor: 'pointer', color: darkBlue, paddingLeft: 8, paddingRight: 8, ...style }}
      onClick={() => setOpen(true)}
    >
      {icon ?? <InfoOutlinedIcon />}
      {label({ buttonLabel, title, compacted })}
    </Button>
  )
  if (mode === 'drawer') {
    return (
      <>
        {button}
        <Drawer open={open} onClose={() => setOpen(false)} placement="right" title={title}>
          {content}
        </Drawer>
      </>
    )
  }
  return (
    <Popover
      trigger="click"
      overlayStyle={{ maxWidth: 560 }}
      arrow={{ pointAtCenter: true }}
      content={content}
      title={title}
      placement={placement}
    >
      {button}
    </Popover>
  )
}

export default Info
