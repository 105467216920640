import { formatDistanceToNow } from 'date-fns'
import { format, toZonedTime } from 'date-fns-tz'

import { enUS } from 'date-fns/locale'
import { fi } from 'date-fns/locale'
import type { TzDatabaseName } from '../timezones.ts'
import type { DateString } from '../types/date.ts'
import { toDate } from './converters.ts'
import type { Language } from '../domain/user.ts'

export const DateFnsLocale = {
  en: enUS,
  fi,
  sv: enUS,
  no: enUS,
  dk: enUS,
  es: enUS,
  it: enUS,
  de: enUS,
  fr: enUS,
  pl: enUS,
  cn: enUS,
  uk: enUS,
} as const

export type DateFormatStyle = 'date' | 'dateTimeTz' | 'shortDateTime' | 'dateTimeInput' | 'time'

const enFormat = {
  date: 'MMM d, yyyy',
  dateTimeTz: 'EEEE, MMMM d, yyyy HH:mm (zzz)',
  shortDateTime: 'EEE, MMM d, yyyy HH:mm (zzz)',
  dateTimeInput: 'MMM d, yyyy HH:mm',
  time: 'HH:mm',
}

// See: https://date-fns.org/v2.16.1/docs/format
const FormatLocales = {
  en: enFormat,
  fi: {
    date: 'd.M.yyyy',
    dateTimeTz: "EEEE d.M.yyyy 'klo' HH.mm (zzz)",
    shortDateTime: "d.M.yyyy 'klo' HH:mm (zzz)",
    dateTimeInput: "d.M.yyyy 'klo' HH:mm",
    time: 'HH:mm',
  },
  sv: enFormat,
  no: enFormat,
  dk: enFormat,
  es: enFormat,
  it: enFormat,
  de: enFormat,
  fr: enFormat,
  pl: enFormat,
  cn: enFormat,
  uk: enFormat,
} as const

export const formatUntilNow = (d: Date | DateString, lang: Language): string => {
  return formatDistanceToNow(toDate(d), { locale: DateFnsLocale[lang], addSuffix: true })
}

export const dateFormatString = ({
  lang,
  style,
}: {
  lang: Language
  style: DateFormatStyle
}): string => FormatLocales[lang][style]

export const formatDate = ({
  date,
  lang,
  style,
  tz,
}: {
  date: Date | DateString
  lang: Language
  style: DateFormatStyle
  tz: TzDatabaseName
}): string =>
  format(toZonedTime(toDate(date), tz), FormatLocales[lang][style], {
    locale: DateFnsLocale[lang],
    timeZone: tz,
  })

export const customFormatDate = ({
  date,
  lang,
  formatTemplate,
  tz,
}: {
  date: Date | DateString
  lang: Language
  formatTemplate: string
  tz: TzDatabaseName
}): string =>
  format(toDate(date), formatTemplate, {
    locale: DateFnsLocale[lang],
    timeZone: tz,
  })
