import { translations } from '../translations.ts'
import type { DateString } from './date.ts'
import type { TeamId, UserId } from './ids.ts'
import type { DemoTeamId } from './team.ts'

export const teamRoles = ['admin', 'observer', 'analyst', 'member'] as const
export type TeamRole = (typeof teamRoles)[number]

export const userIsPrivileged = (userTeam: UserTeam): boolean =>
  ['admin', 'observer', 'analyst'].includes(userTeam.role)

export const groupScopedPermissions = [
  'accessPulseResults',
  'accessPulseComments',
  'accessPrivilegedPulseResults',
  'manageUsers',
  'accessFeedbackStats',
  'identifyPulseCommentsInGroup',
] as const
export type GroupScopedPermission = (typeof groupScopedPermissions)[number]

export type GroupScopedPermissions = Record<GroupScopedPermission, boolean>

export const workspacePermissions = [
  'manageFeedback',
  'manageOwnFeedback',
  'controlFeedback',
  'controlPulse',
  'controlSettings',
  'accessPulseCommentSummary',
  'workspaceAccess',
  /**
   * Individual network results grants access to "which people person X mentioned in their network survey and in which categories?".
   * Does not grant access to individual events/activities of passive data.
   */
  'accessIndividualNetworkResults',
  /**
   * Aggregated network results gives access to network data aggregated by source.
   * Gives access to aggregate passive data (but no individual events/activities) and
   * survey based aggregate data (influence/demand/aggregate collaboration network but no individual mentions).
   *
   * {@link auth.ts}
   */
  'accessAggregatedNetworkResults',
  'evaluatorNameAdminAccess',
] as const
export type WorkspacePermission = (typeof workspacePermissions)[number]

export const permissionExplanations: Record<GroupScopedPermission | WorkspacePermission, string> = {
  accessPulseResults: 'May access pulse results (heatmap and graphs)',
  accessPulseComments: 'May access pulse comments',
  accessPrivilegedPulseResults:
    'May access all pulse results and all comments for team and subteams.',
  accessAggregatedNetworkResults: 'May access aggregated network graph.',
  accessIndividualNetworkResults: 'May access detailed individual network results.',
  manageUsers:
    'May change the roles of users. May create, delete and move teams. May invite users. Note: group admins can create new groups, but ordinary members cannot',
  accessFeedbackStats: 'May access feedback stats',
  identifyPulseCommentsInGroup: 'May identify to what groups a pulse comment is visible to',

  manageFeedback: translations.en.manageFeedbackDescription('in workspace'),
  manageOwnFeedback: translations.en.manageFeedbackDescription('for self'),
  controlFeedback:
    'May add feedack managers, evaluation questions and templates, access feedback activation.',

  controlPulse: 'May start and stop pulse, change schedule, add custom questions',

  controlSettings:
    'May change workspace settings, archive workspace, setup workspace integrations, manage billing',
  accessPulseCommentSummary: 'Can access pulse comment summary view',
  workspaceAccess: 'Basic access to workspace',
  evaluatorNameAdminAccess:
    'May access evaluator names for all users if workspace evaluation visibility setting is set to "admin"',
}

type UserScopedPermission = 'manageUserFeedback'

export type WorkspacePermissions = Record<WorkspacePermission, boolean>

export type UserPermissions = Record<UserScopedPermission, number[]>

export type UserTeam<T extends TeamId | DemoTeamId = TeamId> = {
  userId: UserId
  teamId: T
  workspaceId: T
  type: 'workspace' | 'group'
  role: TeamRole | 'none'
  pulseParticipant: boolean
  memberSince: DateString
  source: 'api' | 'integration'
}

export type DemoUserTeam = UserTeam<DemoTeamId>

export const emptyPermissions: GroupScopedPermissions = {
  accessFeedbackStats: false,
  accessPulseResults: false,
  accessPulseComments: false,
  accessPrivilegedPulseResults: false,
  identifyPulseCommentsInGroup: false,
  manageUsers: false,
}

export const workspacePermissionsByRole: Record<
  TeamRole | 'none',
  Record<WorkspacePermission, boolean>
> = {
  none: {
    workspaceAccess: false,
    manageOwnFeedback: false,
    controlFeedback: false,
    controlPulse: false,
    controlSettings: false,
    manageFeedback: false,
    accessPulseCommentSummary: false,
    accessAggregatedNetworkResults: false,
    accessIndividualNetworkResults: false,
    evaluatorNameAdminAccess: false,
  },
  member: {
    workspaceAccess: true,
    manageOwnFeedback: false,
    controlFeedback: false,
    controlPulse: false,
    controlSettings: false,
    manageFeedback: false,
    accessPulseCommentSummary: false,
    accessAggregatedNetworkResults: false,
    accessIndividualNetworkResults: false,
    evaluatorNameAdminAccess: false,
  },
  analyst: {
    workspaceAccess: true,
    manageOwnFeedback: false,
    controlFeedback: false,
    controlPulse: false,
    controlSettings: false,
    manageFeedback: false,
    accessPulseCommentSummary: false,
    accessAggregatedNetworkResults: true,
    accessIndividualNetworkResults: false,
    evaluatorNameAdminAccess: false,
  },
  observer: {
    workspaceAccess: true,
    manageOwnFeedback: false,
    controlFeedback: false,
    controlPulse: false,
    controlSettings: false,
    manageFeedback: true,
    accessPulseCommentSummary: false,
    accessAggregatedNetworkResults: true,
    accessIndividualNetworkResults: false,
    evaluatorNameAdminAccess: true,
  },
  admin: {
    workspaceAccess: true,
    manageOwnFeedback: true,
    controlFeedback: true,
    controlPulse: true,
    controlSettings: true,
    manageFeedback: true,
    accessPulseCommentSummary: true,
    accessAggregatedNetworkResults: true,
    accessIndividualNetworkResults: true,
    evaluatorNameAdminAccess: true,
  },
}

export const groupInheritablePermissionsByRole: Record<
  TeamRole | 'none',
  Partial<Record<GroupScopedPermission, true>>
> = {
  admin: {
    accessFeedbackStats: true,
    accessPulseResults: true,
    accessPulseComments: true,
    accessPrivilegedPulseResults: true,
    identifyPulseCommentsInGroup: true,
    manageUsers: true,
  },
  observer: {
    accessFeedbackStats: true,
    accessPulseResults: true,
    accessPulseComments: true,
    accessPrivilegedPulseResults: true,
    identifyPulseCommentsInGroup: true,
  },
  analyst: {
    accessPulseResults: true,
    accessFeedbackStats: true,
    accessPulseComments: true,
    accessPrivilegedPulseResults: true,
    identifyPulseCommentsInGroup: true,
  },
  member: {},
  none: {},
}

export const groupNonInheritablePermissionsByRole: Record<
  TeamRole | 'none',
  Partial<Record<GroupScopedPermission, true>>
> = {
  admin: {},
  observer: {},
  analyst: {},
  member: {
    accessPulseResults: true,
    accessPulseComments: true,
    identifyPulseCommentsInGroup: true,
  },
  none: {},
}
