import type { Language } from '@teamspective/common'
import { BASE_URL, GOOGLE_CLIENT_ID, translations } from '@teamspective/common'
import { useEffect, useRef } from 'react'
import authStore from '../../stores/authStore'
import { colorError } from '@teamspective/common'
import { WarningIcon } from '../icons'

const GoogleSignIn = ({
  lang,
  type,
  width,
  showFreeDomainError,
}: {
  lang: Language
  type: 'login' | 'signUp'
  width?: number
  showFreeDomainError: boolean
}): JSX.Element | null => {
  const texts = translations[lang]

  const { version } = authStore.useContainer()
  const scriptLoadedRef = useRef(false)
  useEffect(() => {
    if (version === 'loading' || scriptLoadedRef.current === true) {
      return
    }
    scriptLoadedRef.current = true
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    document.body.appendChild(script)
  }, [version])

  if (version === 'loading') {
    return null
  }
  return (
    <div>
      <div
        id="g_id_onload"
        data-client_id={GOOGLE_CLIENT_ID}
        data-context="signin"
        data-ux_mode="redirect"
        data-login_uri={`${
          version.stage === 'dev' ? 'http://localhost:8081' : BASE_URL[version.stage]
        }/google-api/login`}
        data-nonce=""
        data-auto_prompt="false"
        // excludes consumer accounts (user@gmail.com) during account selection on sign up
        {...(type === 'signUp' ? { 'data-hd': '*' } : {})}
      ></div>

      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-locale="en_US"
        data-text={type === 'login' ? 'signin_with' : 'signup_with'}
        data-size="large"
        data-logo_alignment="center"
        data-width={width ?? 400}
      ></div>
      {showFreeDomainError && (
        <div style={{ textAlign: 'center', color: colorError, marginTop: 8 }}>
          <WarningIcon /> {texts.pleaseUseYourCompanyGoogleAccountToLogin}
        </div>
      )}
    </div>
  )
}

export default GoogleSignIn
