import { useEffect, useState, useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'
import useSWR from 'swr'

import type {
  Ok,
  NotFound,
  Version,
  PersonalDetails,
  RequestLoginTokenPayload,
  Redirect,
  Language,
  UserId,
  CurrentUser,
} from '@teamspective/common'
import { links, userLang } from '@teamspective/common'

import { setUser as setSentryUser } from '@sentry/react'
import { api } from '../api'
import { identifyHeapUser, initHeap } from '../analytics/heap'
import { useNotifyOnResponse } from '../apiActionNotification'
import { loadCatalog } from '../i18n'

type UserState = CurrentUser | { status: 'loading' } | { status: 'logged-out' }
const useAuthStore = () => {
  const notifyOnResponse = useNotifyOnResponse()
  const [version, setVersion] = useState<Version | 'loading'>('loading')

  useSWR('getVersion', () => api.version(), {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshWhenOffline: false,
    refreshWhenHidden: false,

    // First checking if the above conditions will catch most situations. Trying to avoid too many reloads.
    // refreshInterval: 10 * 60 * 1000,

    onSuccess: async ({ data }) => {
      if (version === 'loading') {
        initHeap(data.stage)
        setVersion(data)
      } else if (data.hash !== version.hash) {
        await api.logEvent({ event: 'forceReloadBrowserToUpgradeClient' })
        window.location.reload()
      }
    },
  })

  const [user, setUser] = useState<UserState>({
    status: 'loading',
  })

  const refresh = useCallback(async () => {
    const initiallyLoggedOut = user.status === 'loading' || user.status === 'logged-out'

    const result = await api.getUser()

    // In production, result.data seems to be sometimes undefined even if status
    // is 'ok', despite typing https://teamspective.sentry.io/issues/5384049426/
    const refreshedUser =
      result.status === 'ok' && result.data ? result.data : ({ status: 'logged-out' } as const)

    setUser(refreshedUser)
    if (initiallyLoggedOut && refreshedUser.status !== 'logged-out') {
      identifyHeapUser(refreshedUser.userId)
      setSentryUser({ id: refreshedUser.userId.toString() })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    void refresh()
  }, [refresh])

  // NOTE: Router is not available here, so we need to use window.location
  const queryParamLang = (new URLSearchParams(window.location.search).get('lang') ?? undefined) as
    | Language
    | undefined

  const lang = userLang(user, queryParamLang)

  const setLanguage = useCallback(
    async (lang: Language) => {
      if (user.status === 'complete' || user.status === 'limited') {
        await api.updateUser({
          language: lang,
          firstName: user.firstName,
          lastName: user.lastName,
          demoVisible: user.demoVisible,
        })
        await refresh()
      } else {
        window.location.search = new URLSearchParams({ lang }).toString()
      }
    },
    [user, refresh]
  )

  useEffect(() => {
    void loadCatalog(lang)
  }, [lang])

  return {
    user,
    lang,
    setLanguage,
    refresh,
    version,
    logout: useCallback(
      async (navigateToFrontPage = true) => {
        localStorage.removeItem('teamId')
        await api.logout()
        setUser({ status: 'logged-out' })
        if (navigateToFrontPage) {
          void window.location.replace(links.frontPage)
        }
      },
      [setUser]
    ),
    initiateMagicLogin: async (
      payload: RequestLoginTokenPayload
    ): Promise<Ok | Redirect | NotFound> => {
      if (user.status === 'loading') {
        return { status: 'ok', data: null }
      }
      // Remove possible previous user teamId
      localStorage.removeItem('teamId')
      const res = await api.requestLoginToken(payload)
      if (res.status === 'redirect') {
        window.location.assign(res.url)
      }
      return res
    },
    currentUserId: useMemo(
      () =>
        user.status === 'complete' || user.status === 'incomplete' || user.status === 'limited'
          ? user.userId
          : null,
      [user]
    ),
    updateUser: async (updatedUser: { userId: UserId } & PersonalDetails) => {
      await api
        .updateUser(updatedUser)
        .then(notifyOnResponse({ lang: userLang(user) }))
        .then(refresh)
    },
  }
}

export default createContainer(useAuthStore)
