import { evaluationRemindersDaysBefore } from './types/evaluation.ts'
import { FREE_WORKSPACE_SIZE } from './constants.ts'
import type { Language } from './domain/user.ts'
import { getQuotedString } from './localization.ts'
import { listItemsInNaturalLanguage } from './util.ts'
import { FI_VAT_PERCENTAGE } from './domain/tax.ts'

const languageTranslationsEn = {
  fi: 'Finnish',
  en: 'English',
  sv: 'Swedish',
  no: 'Norwegian',
  dk: 'Danish',
  es: 'Spanish',
  it: 'Italian',
  de: 'German',
  fr: 'French',
  pl: 'Polish',
  cn: 'Chinese',
  uk: 'Ukrainian',
}

export const languageTranslations: Record<Language, Record<Language, string>> = {
  en: languageTranslationsEn,
  sv: languageTranslationsEn,
  no: languageTranslationsEn,
  dk: languageTranslationsEn,
  es: languageTranslationsEn,
  it: languageTranslationsEn,
  de: languageTranslationsEn,
  fr: languageTranslationsEn,
  pl: languageTranslationsEn,
  cn: languageTranslationsEn,
  uk: languageTranslationsEn,
  fi: {
    fi: 'suomi',
    en: 'englanti',
    sv: 'ruotsi',
    no: 'norja',
    dk: 'tanska',
    es: 'espanja',
    it: 'italia',
    de: 'saksa',
    fr: 'ranska',
    pl: 'puola',
    cn: 'kiina',
    uk: 'ukraina',
  },
}

export const productTexts = {
  pulse: {
    title: {
      en: 'Pulse',
      fi: 'Pulssi',
    },

    subheading: {
      en: 'Continuously track wellbeing & engagement and take timely action. Enable data-driven leadership with AI-powered guidance.',
      fi: 'Seuraa hyvinvointia & engagementia oikea-aikaisesti, ja johda yritystäsi datan ja AI-pohjaisten suositusten avulla.',
    },
    infoBullets: {
      en: [
        'AI-powered suggestions and guidance for action, directly in Slack & Teams',
        'Anonymous surveys, open comments & discussions',
        'Science-backed data models, validated questions library, and benchmark results',
        'Custom questions',
        'Fast and easy survey answering within Slack & MS Teams (or web browser)',
      ],
      fi: [
        'AI-pohjainen ohjaus ja suositukset, suoraan Slackissa ja MS Teamsissa',
        'Anonyymit kyselyt, avoimet kommentit ja keskustelu',
        'Tieteeseen pohjautuvat datamallit, validoitu kysymyskirjasto ja benchmark-tulokset',
        'Custom-kysymykset',
        'Nopea ja vaivaton kyselyihin vastaaminen Slackissa ja MS Teamsissa (tai selaimessa)',
      ],
    },
  },
  feedback: {
    title: {
      en: '360 Feedback',
      fi: '360 Palaute',
    },

    subheading: {
      en: 'Help your employees coach and appreciate each other. Manage employee performance fairly and effectively.',
      fi: 'Auta ihmisiä kehittämään ja arvostamaan toisiaan. Johda suoriutumista sujuvasti ja suoraselkäisesti.',
    },
    infoBullets: {
      en: [
        'Save time and trouble by moving your employee feedback processes to Slack or MS Teams',
        'Customizable 360 feedback templates',
        '6x feedback activity with smart activation',
        'Develop feedback skills at scale with in-app guidance',
        'Collect unbiased data for complete, easy, and reliable performance reviews',
      ],
      fi: [
        'Säästä aikaa ja vaivaa siirtämällä palauteprosessit Slackiin ja MS Teamsiin',
        'Mukautettavat 360 palautepohjat',
        '6x aktiivisuus palautteessa älykkäiden muistutusten avulla',
        'Kehitä palautetaitoja skaalautuvasti sovelluksen ohjeistuksen avulla',
        'Kerää luotettavaa dataa suorituksen johtamiseen',
      ],
    },
  },
  network: {
    title: {
      en: 'Organizational Network Analysis',
      fi: 'Organisaation verkostoanalyysi',
    },

    subheading: {
      en: 'Understand how your company and leadership really work. Resolve bottlenecks and reconnect silos.',
      fi: 'Ymmärrä miten yrityksesi ja sen johtajuus todella toimivat. Ratkaise pullonkaulat ja yhdistä siilot.',
    },
    infoBullets: {
      en: [
        'Discover the true teams and real networks within your organization',
        'Identify silos, critical connections and bottlenecks',
        'Overlay engagement data to see unique pain points & focus areas for each part of the company',
        'Plan organizational growth and restructuring in a more informed way',
      ],
      fi: [
        'Näe todelliset tiimit ja yhteistyön verkostot yrityksessäsi',
        'Tunnista siilot, kriittiset yhteydet ja pullonkaulat',
        'Tunnista todelliset haasteet organisaation eri osissa yhdistämällä verkostot pulssikyselyn engagement-tuloksiin',
        'Suunnittele organisaation kasvua ja muutoksia paremman datan pohjalta',
      ],
    },
  },
}

type CohortTranslationMap = Record<string, { key: string; values?: Record<string, string> }>

const percentileLabels = {
  en: {
    0: 'Bottom 20%',
    1: 'Lower-middle 20%',
    2: 'Middle 20%',
    3: 'Upper-middle 20%',
    4: 'Top 20%',
  },
  fi: {
    0: 'Alimmat 20%',
    1: 'Alempi-keski 20%',
    2: 'Keskimmäinen 20%',
    3: 'Ylempi-keski 20%',
    4: 'Korkein 20%',
  },
}

const cohortsEn = {
  role: {
    key: 'Role',
    values: {
      member: 'Group member',
      admin: 'Group admin',
    },
  },
  department: {
    key: 'Department',
  },
  unit: {
    key: 'Unit',
  },
  costCenter: {
    key: 'Cost center',
  },
  company: {
    key: 'Company',
  },
  site: {
    key: 'Location',
  },
  gender: {
    key: 'Gender',
    values: {
      Male: 'Male',
      Female: 'Female',
      Other: 'Other',
    },
  },
  title: {
    key: 'Title',
  },
  isManager: {
    key: 'Manager',
    values: {
      Yes: 'Yes',
      No: 'No',
    },
  },
  tenure: {
    key: 'Tenure',
    values: {
      '0-3-months': '0-3 months',
      '3-6-months': '3-6 months',
      '6-months-1-year': '6 months - 1 year',
      '1-2-years': '1-2 years',
      '2-5-years': '2-5 years',
      'over-5-years': 'Over 5 years',
    },
  },
  seniority: {
    key: 'Seniority',
  },
  fte: {
    key: 'FTE',
  },
  reportsTo: {
    key: 'Reports to',
  },
  influence_percentile: {
    key: 'Influence (network)',
    values: percentileLabels.en,
  },
  demand_percentile: {
    key: 'Demand (network)',
    values: percentileLabels.en,
  },
  leadership_percentile: {
    key: 'Leadership (network)',
    values: percentileLabels.en,
  },
  culture_percentile: {
    key: 'Culture (network)',
    values: percentileLabels.en,
  },
  execution_percentile: {
    key: 'Execution (network)',
    values: percentileLabels.en,
  },
}

const cohortsFi: typeof cohortsEn = {
  role: {
    key: 'Rooli',
    values: {
      member: 'Ryhmän jäsen',
      admin: 'Ryhmän admin-käyttäjä',
    },
  },
  department: {
    key: 'Osasto',
  },
  unit: {
    key: 'Yksikkö',
  },
  costCenter: {
    key: 'Kustannuspaikka',
  },
  company: {
    key: 'Yritys',
  },
  site: {
    key: 'Sijainti',
  },
  gender: {
    key: 'Sukupuoli',
    values: {
      Male: 'Mies',
      Female: 'Nainen',
      Other: 'Muu',
    },
  },
  title: {
    key: 'Nimike',
  },
  isManager: {
    key: 'Esihenkilö',
    values: {
      Yes: 'Kyllä',
      No: 'Ei',
    },
  },
  tenure: {
    key: 'Työsuhteen kesto',
    values: {
      '0-3-months': '0-3 kuukautta',
      '3-6-months': '3-6 kuukautta',
      '6-months-1-year': '6 kuukautta - 1 vuosi',
      '1-2-years': '1-2 vuotta',
      '2-5-years': '2-5 vuotta',
      'over-5-years': 'Yli 5 vuotta',
    },
  },
  seniority: {
    key: 'Senioriteetti',
  },
  fte: {
    key: 'FTE',
  },
  reportsTo: {
    key: 'Esihenkilö',
  },
  influence_percentile: {
    key: 'Vaikutusvalta (verkosto)',
    values: percentileLabels.fi,
  },
  demand_percentile: {
    key: 'Kuormittuneisuus (verkosto)',
    values: percentileLabels.fi,
  },
  leadership_percentile: {
    key: 'Johtajuus (verkosto)',
    values: percentileLabels.fi,
  },
  execution_percentile: {
    key: 'Suoriutuminen (verkosto)',
    values: percentileLabels.fi,
  },
  culture_percentile: {
    key: 'Kulttuuri (verkosto)',
    values: percentileLabels.fi,
  },
}

export const cohortTranslations: Record<Language, CohortTranslationMap> = {
  en: cohortsEn,
  fi: cohortsFi,
  sv: cohortsEn,
  no: cohortsEn,
  dk: cohortsEn,
  es: cohortsEn,
  it: cohortsEn,
  de: cohortsEn,
  fr: cohortsEn,
  pl: cohortsEn,
  cn: cohortsEn,
  uk: cohortsEn,
}

const roundPrice = (n: number) => Number(n.toFixed(2))

const tableLocaleEn = {
  filterTitle: 'Filter menu',
  filterConfirm: 'OK',
  filterReset: 'Reset',
  filterEmptyText: 'No filters',
  selectAll: 'Select current page',
  selectInvert: 'Invert current page',
  selectionAll: 'Select all data',
  sortTitle: 'Sort',
  expand: 'Expand row',
  collapse: 'Collapse row',
  triggerDesc: 'Click sort by descend',
  triggerAsc: 'Click sort by ascend',
  cancelSort: 'Click to cancel sort',
}

export const tableLocales = {
  en: tableLocaleEn,
  es: tableLocaleEn,
  sv: tableLocaleEn,
  no: tableLocaleEn,
  dk: tableLocaleEn,
  it: tableLocaleEn,
  fr: tableLocaleEn,
  pl: tableLocaleEn,
  de: tableLocaleEn,
  cn: tableLocaleEn,
  uk: tableLocaleEn,
  fi: {
    filterTitle: 'Suodatinvalikko',
    filterConfirm: 'OK',
    filterReset: 'Resetoi',
    filterEmptyText: 'Ei suodattimia',
    selectAll: 'Valitse nykyinen sivu',
    selectInvert: 'Nykyinen sivu käänteisenä',
    selectionAll: 'Valitse kaikki',
    sortTitle: 'Järjestä',
    expand: 'Laajenna rivi',
    collapse: 'Rajoita rivi',
    triggerDesc: 'Järjestä laskevaan järjestykseen',
    triggerAsc: 'Järjestä nousevaan järjestykseen',
    cancelSort: 'Älä järjestä',
  },
}

const en = {
  yes: 'Yes',
  no: 'No',
  ok: 'OK',
  back: 'Back',
  next: 'Next',
  cancel: 'Cancel',
  settings: 'Settings',
  mySettings: 'My settings',
  submit: 'Submit',
  running: 'Running',
  schedule: 'Schedule',
  scheduled: 'Scheduled',
  automated: 'Automated',
  singleRound: 'Single round',
  singleSurvey: 'Single survey',
  singleSurveys: 'Single surveys',
  surveyName: 'Survey name',
  surveyNameTip:
    'You can give the survey a name. It makes it easier to find the survey in the results and in the survey template menu.',
  editingSingleRound: 'Editing scheduled single round',
  saveSingleRound: 'Save scheduled single round',
  deleteSingleRound: 'Are you sure you want to delete this scheduled survey?',
  pulseSingleRoundScheduled: 'Survey scheduled',
  surveyPublished: 'Survey published',
  pulseSingleRoundDeleted: 'Scheduled single round canceled',
  pulseSingleRoundTemplatePlaceholder: 'Past single rounds will appear here',
  pulseSingleRoundTemplateTip: 'Use a previous survey as a starting point for this survey.',
  newSurvey: 'New survey',
  editSurvey: 'Edit survey',
  surveyActivity: 'Survey activity',
  actions: 'Actions',
  kpi_wellbeing: 'Wellbeing',
  kpi_engagement: 'Engagement',
  enps: 'eNPS',
  enpsExpanded: 'Employee Net Promoter Score',
  enpsExplanation:
    'eNPS is the percentage of “promoting” minus the percentage of “detracting” employees as measured by the question “On a scale of 0-10, how likely are you to recommend your organization as a good place to work?” Promoters are those answering with a 9 or 10, detractors answer with a 6 or below. Other responses are only counted in the total sum of responses.',
  answerCount: 'Answer count',
  whatIsEnps: 'What is eNPS?',
  comments: 'Comments',
  commentsInGroup: (groupName: string) => `Comments in ${groupName}`,
  commentsWithTopic: (topic: string) => `Comments with topic "${topic}"`,
  linkCopied: 'Link copied to clipboard',
  feedbackVisibleWithLink: 'People with the link can see this feedback.',
  share: 'Share',
  shareFeedback: 'Share feedback',
  feedbackSharedBy: (name: string): string => `Feedback shared with you by ${name}`,
  copyLink: 'Copy link',
  once: 'Once',
  weekly: 'Weekly',
  biweekly: 'Every other week',
  monthly: 'Monthly',
  bimonthly: 'Every other month',
  quarterly: 'Quarterly',
  quarterlyApproximate: 'Quarterly',
  onceExtra: 'Asked in next survey',
  monthlyExtra: '4 weeks',
  bimonthlyExtra: '8 weeks',
  quarterlyExtra: '13 weeks',
  quarterlyApproximateExtra: '12 weeks',
  insufficientDataForMetric: 'Insufficient data to calculate this metric',
  workspacePulseParticipation: 'Participating in engagement',
  notParticipatingInPulse: 'Not Participating in engagement',
  groupPulseParticipation: 'Include pulse answers in group results',
  createGroup: 'Create group',
  createWorkspace: 'Create workspace',
  createYourWorkspace: 'Create your workspace',
  name: 'Name',
  pulse: 'Pulse',
  engagementProductName: 'Engagement',
  search: 'Search',
  reset: 'Reset',
  leave: 'Leave',
  confirmLeave: 'Are you sure you want to leave?',
  reinforcingSynonyms: 'Recognition, praise, appreciation',
  redirectingSynonyms: 'Requests, guidance, coaching',
  more: 'More',
  showMore: 'Show more',
  workspaces: 'Workspaces',
  profile: 'Profile',
  integrations: 'Integrations',
  save: 'Save',
  saved: 'Saved',
  logout: 'Logout',
  logoutConfirm: 'Are you sure you want to log out?',
  areYouSure: 'Are you sure?',
  join: 'Join',
  joinGroup: 'Join group',
  joinWorkspace: 'Join workspace',
  joinableWorkspaces: 'Joinable workspaces',
  slackIntegration: 'Slack integration',
  slackWorkspaceAlreadyLinked:
    'Could not link Slack workspace that is linked to another Teamspective workspace.',
  msTeamsIntegration: 'Microsoft Teams integration',
  createPulseSurvey: 'Schedule pulse surveys',
  addSlackToWorkspaceDescription: 'Add Teamspective to your Slack workspace as Slack admin.',
  addMsTeamsToWorkspaceDescription: 'Add Teamspective to your MS Teams workspace as admin.',
  frontPage: 'Home',
  feedback: 'Feedback',
  contactUs: 'Contact us',
  edit: 'Edit',
  expand: 'Expand',
  hide: 'Hide',
  backToFrontPage: 'Back to front page',
  exitDemo: 'Exit Demo',
  pulseCta: 'Start designing your automated pulse survey',
  pulseSingleRoundCta: 'Run single pulse round',
  pulseDescription:
    'Meaningful conversations are started by listening. Create psychological safety, transparency, and trust with pulse surveys.',
  requestFeedbackDescription:
    'A culture of feedback is only created when feedback is actively sought out.',
  slackInstalled: 'Connected to Slack workspace',
  slackTokenInvalidShort: "There is an issue with your workspace's integration to Slack.",
  slackTokenInvalid:
    'There is an issue with the workspace\'s integration to Slack. Please click "Add to Slack" to reconnect your Slack workspace.',
  tokenScopesMissing:
    'Teamspective requires additional permissions from Slack. Please click "Add to Slack" to reconnect your Slack workspace. Permissions are needed for the following features:',
  msTeamsInstalled: 'Connected to MS Teams team',
  sendRequestFailed:
    'Something went wrong and your requests were not sent. Please note that free users can only send 5 feedback requests in a week.',
  addFrom_slack: 'Add from Slack',
  addFrom_msteams: 'Add from MS Teams',
  addUsersFrom_slack: 'Add users from Slack',
  'addUsersFrom_ms-teams': 'Add users from MS Teams',
  inviteByEmail: 'Invite by email',
  selectAll: 'Select all',
  alreadyAdded: 'This user has already been added',
  create: 'Create',
  invite: 'Invite',
  egAcmeInc: `eg. ${getQuotedString('Acme Inc.', 'en')}`,
  workspaceCreationSuccess: 'Workspace successfully created',
  groupCreationSuccess: 'Group successfully created',
  typeSomething: 'Type something',

  all: 'All',
  answered: 'Answered',
  theme: 'Theme',
  themes: 'Themes',
  selectTheme: 'Select theme',
  newTheme: 'New theme',
  themeAmount: 'themes',
  noThemes: 'No themes',
  allThemes: 'All themes',
  comment: 'Comment',
  writeSomething: 'Write something...',
  commentSuccess: 'Thank you! Your anonymous comment has been saved.',
  resultsPublished: 'Results published',
  timezone: 'Timezone',
  timeToReply: 'Time to reply',
  answerTimeBegins: 'Answer time begins',
  answerTimeEnds: 'Answer time ends',
  confirm: 'Confirm',
  confirmSelection: 'Confirm selection',
  pulseSurveyMin24h: 'Pulse survey duration needs to exceed 24 hours.',
  pulseQuestions: 'Pulse questions',
  question: 'Question',
  questionName: 'Question name',
  questions: 'Questions',
  nQuestions: (n: number) => (n === 1 ? '1 question' : `${n} questions`),
  never: 'Never',
  unsavedChanges: 'Unsaved changes',
  noActivePulseSurvey: 'No pulse surveys scheduled',
  onMondays: 'Mondays',
  onTuesdays: 'Tuesdays',
  onWednesdays: 'Wednesdays',
  onThursdays: 'Thursdays',
  onFridays: 'Fridays',
  resumePulse: 'Resume pulse schedule',
  stopPulse: 'Stop pulse',
  stopAndPublishConfirm: 'Are you sure you want to close this survey and publish the results?',
  stopPulseConfirm:
    'Results for the current pulse survey will be published immediately and no further pulse surveys will be scheduled.',
  stopPulseConfirmSingleRound: 'Results for the current survey will be published immediately.',
  cancelPulse: 'Unschedule pulse',
  cancelSingleRoundConfirm: 'This scheduled survey will be removed.',
  cancelPulseConfirm: 'Pending pulse surveys will be unscheduled.',
  surveySettings: 'Survey settings',
  preview: 'Preview',
  changesSaved: 'Changes saved',
  cancelChanges: 'Cancel changes',
  affectsNext: 'Changes take effect at the beginning of the next pulse survey.',
  pulseThemes: 'Pulse themes',
  open: 'Open',
  noOneHere: 'No one here',
  noResults: 'No results',
  pulseSurveyFrequency: 'Pulse survey frequency',
  noComments: 'No Comments',
  noNewComments: 'No new comments',
  newComment: 'New comment',
  previousComments: 'Previous comments',
  showPreviousComments: 'Show previous comments',
  requestCommentHeader: 'Add a comment to this discussion',
  send: 'Send',
  commentDeleteConfirmation: 'Are you sure you want to delete this comment?',
  seeComment: 'See comment',
  seeNote: 'See note',
  delete: 'Delete',
  replyDeletedSuccessfully: 'Reply deleted successfully',
  respondAnonymously: 'Respond anonymously',
  score: 'Score',
  scores: 'Scores',
  schedulePulse: 'Schedule pulse surveys',
  scheduleSingleRound: 'Schedule single round',
  chooseQuestions: 'Select some questions first',
  waitingForfirstResults: 'Waiting for first results',
  firstResultsOn: (formattedDate: string): string =>
    `The first results will be published ${formattedDate}`,
  inviteUsers: 'Invite users to workspace',
  confirmRemove: 'Are you sure you want to remove this user?',
  memberSince: (since: string): string => `Member since ${since}`,
  members: 'Members',
  memberCount: 'members',
  addUser: 'Add person',
  addUsers: 'Add people',
  addUsersIndefinite: 'Add people',
  groupRemoveUser: 'Remove from group',
  workspaceRemoveUser: 'Remove from workspace',
  workspaceRemovalSuccess: 'User successfully removed from workspace',
  leaveWorkspace: 'Leave workspace',
  leaveWorkspaceSuccess: 'You have left the workspace',
  export: 'Export',
  exportingCompatiblePpt: 'The exported powerpoint file is compatible with most slide software.',
  existing: 'existing',
  notifyUsersOfInvite: 'Send notification of invitation to added users',
  allChannelMembersOnTeam: 'all added already',
  defaultLanguage: 'Default Language',
  whatIsAGroup: 'What is a group?',
  readMore: 'Read more',
  atLeastThreeUsersReqired:
    'At least three users per workspace are required to ensure anonymity of the results',
  groupsCanBeEditedLater: 'You can edit groups and add hierarchy later',
  bringSelectedChannelsAsGroups: 'Use selected channels as Teamspective user groups',
  whoCanSeeThis: 'Who can see my answer?',
  whoSeesThis:
    'Group members will see anonymized results after the survey is over. No one will be able to know what you specifically answered.',
  newPulseResults: 'New pulse results',
  pulseIsScheduledToBegin: 'This pulse is scheduled to begin',
  previewOfPulseSurveySchedule: 'Preview of pulse survey schedule',
  choice: 'Choice',
  choices: 'Choices',
  week: 'Week',
  weekAmount: 'Weeks',
  detractors: 'Detractors',
  passives: 'Passives',
  promoters: 'Promoters',
  answerDistribution: 'Answer distribution',
  scoreDistribution: 'Score distribution',
  distribution: 'Distribution',
  seeResults: 'See results',
  seeEarlierResults: 'See earlier results',
  manageSubscription: 'Manage subscription',
  manageSubscriptionPortalInfo:
    'Our subscription management portal is hosted by Stripe. You will be logged in in a new tab.',
  startInSettings: 'Modify pulse settings and start',
  betterWithOthers: 'Teamspective works better with others',
  freeForWorkspacesBelow: `Free for workspaces of under ${FREE_WORKSPACE_SIZE} members.`,
  workspaceName: 'Workspace name',
  slackOptOutOk:
    'Okay! You can find the Slack integration at any time under your workspace settings',
  demoPulseResults: 'See pulse results',
  groupName: 'Group name',
  egGroupName: 'e.g. Marketing',
  new: 'New',
  whyAddSlack: 'Try our Slack integration',
  whyAddSlackDescription:
    'Add users directly from Slack, and enable them to answer the pulse and be notified without leaving Slack.',
  latest: 'Latest',
  weeks: 'Weeks',
  month: 'Month',
  months: 'Months',
  quarters: 'Quarters',
  lastQuarter: 'Last 3 months',
  lastHalf: 'Last 6 months',
  chooseProvidersAndTopics: 'Choose feedback providers and topics',
  pleaseAddTopics: 'Please select topics for each feedback provider.',
  pleaseAddMessages: 'We recommend adding a message to each feedback request.',
  lookingGood: 'Looking good!',
  tooManyTopics: 'We recommend limiting yourself to four topics per request.',
  workspaceChangeNameConfirmation: 'Workspace name will change for all workspace members.',
  groupChangeNameConfirmation: 'The group name will change for all group members',
  deleteGroup: 'Delete group',
  groupNameToConfirm: 'Type group name to confirm permanent deletion of group',
  irreversibleAction: 'This action is irreversible!',
  groupDeletedSuccess: 'Group deleted successfully',
  powerpointExport: 'Export to powerpoint',
  latestResult: 'Latest result',
  latestResults: 'Latest results',
  combinedResults: 'Combined results',
  trend: 'Trend',
  previous: 'Previous scores',
  benchmarkValues: 'Benchmark values',
  benchmarkDescription:
    'Benchmark values are global averages of Teamspective customers, comprising of companies in software, consulting, and other high-skilled industries. Benchmarks and company scores provide a good reference point for any team to evaluate their pulse scores.',
  language: 'Language',
  workspaceRole_admin: 'Workspace administrator',
  workspaceRole_observer: 'Workspace observer',
  workspaceRole_analyst: 'Workspace analyst',
  workspaceRole_member: 'Workspace member',
  workspaceRoleDescription_admin: 'Full access and control',
  workspaceRoleDescription_observer: 'Full access',
  workspaceRoleDescription_analyst: 'Full access to survey results',
  workspaceRoleDescription_member: 'Limited access',
  groupAdministrator: 'Group administrator',
  groupAdministratorToggle: 'Make this user group administrator',
  groupBy: 'Group by',
  filterBy: 'Filter by',
  tryRequesting: 'Try collecting personal feedback',
  youHaveNewFeedback: 'You have new feedback',
  feedbackWithUnreadComments: 'Feedback with unread comments',
  collectingFeedbackFrom: 'Collecting feedback from others',
  sendPraise: 'Send praise',
  reinforcingOpenFeedback: 'Reinforcing open feedback',
  praiseSent: 'Praise sent!',
  feedbackSent: 'Feedback sent!',
  singleFocusAreaDescription:
    'Teamspective has identified this theme as a potential focus area for this group.',
  playbookAvailable: 'There is a pulse playbook available.',
  playbook: 'Playbook',
  notFoundPageTitle: "We couldn't find the page",
  reinforcingFeedback: 'Reinforcing feedback',
  redirectingFeedback: 'Redirecting feedback',
  writeFeedback: 'Write feedback',
  feedbackInstructionsNone: 'Choose a relevant topic and write reinforcing or redirecting feedback',
  feedbackInstructionsOne:
    'You have written feedback on your first topic, great! Keep going if you have more to say.',
  feedbackInstructionsTwo: 'Two already, great! Got any more?',
  feedbackInstructionsThree: 'Thank you for the feedback!',
  feedbackInstructionsTooMany:
    "Four is plenty! Don't try to fill every box, only the ones where you have something valuable to share",
  reviewUpdatedPrivacyPolicy: (formattedDate: string): string =>
    `We have updated our privacy policy on ${formattedDate}.`,
  reviewPrivacyPolicy: 'Review privacy policy',
  addCustomTopic: 'Add a custom topic',
  topicName: 'Topic name',
  topicDescription: 'Topic description',
  description: 'Description',
  generalTopics: 'General topics',
  customerTopics: 'Customer topics',
  customTopics: 'Custom topics',
  workspaceTopics: 'Workspace topics',
  addTopic: 'Add topic',
  addWorkspaceTopic: 'Add workspace topic',
  createSuccess: (label: string): string => `Topic ${label} created and added successfully`,
  choose: (amount: number, total: number): string => `Choose topics... (${amount}/${total})`,
  addDescriptionOptional: 'Add description (optional)',
  sendToPublicSlackChannel: (channelName: string): string =>
    `Also send praise to public Slack channel #${channelName}`,
  sendToPublicMsTeamsChannel: (channelName: string): string =>
    `Also send praise to public MS Teams channel ${channelName}`,
  sendToPublicSlackAndMsTeamsChannel: (slackChannel: string, msTeamsChannel: string): string =>
    `Also send praise to public channels #${slackChannel} (Slack) and ${msTeamsChannel} (MS Teams)`,
  promotersMinusDetractors: 'promoters − detractors',
  allResponders: 'all responders',
  ending: 'Ending',
  ended: 'Ended',
  surveyEnding: (ending: string): string => `Survey ending ${ending}`,
  nextPulseSurveyStarting: (starting: string): string => `Next pulse survey starting ${starting}`,
  noPulseRoundScheduled: 'No pulse round scheduled',
  break: 'Break',
  userCreatedCustomTopic: (name: string): string => `Custom topic by ${name}`,
  noSlackPraiseChannelChosen: 'You have not chosen a channel to send public praise on Slack.',
  noMsTeamsPraiseChannelChosen: 'You have not chosen a channel to send public praise on Ms Teams.',
  chooseChannel: 'Choose channel',
  changeChannel: 'Change channel',
  praiseWillBeSentToSlackChannel: 'Praise marked as public will also be sent to Slack channel',
  praiseWillBeSentToTeamsChannel: 'Praise marked as public will also be sent to MS Teams channel',
  login: 'Log in',
  joinWorkspaceConfirm: 'Are you sure you would like to join this workspace?',
  joinWorkspaceSuccess: 'You have joined this workspace',
  workspaceIsOpen: 'This workspace is open to you',
  noOpenWorkspaces: 'No available workspaces found',
  feedbackRequestSent: 'Feedback requests sent!',
  evaluationRequests: 'Feedback requests',
  amountNotified: (amount: number): string =>
    `We have notified the ${amount} ${
      amount === 1 ? 'recipient' : 'recipients'
    } you named. Sit tight!`,
  lastChoiceWasWhatWhen: (what: string, when: string): string =>
    `You last answered ${what} on ${when}`,
  unknownCoupon: 'Coupon not recognized',
  purchase: 'Purchase',
  cardDetails: 'Card details',
  cardDeclined: 'The card details as you entered them were declined. Please try again.',
  cardConfirmationFailed: 'Your card could not be confirmed. Please try again.',
  createAccount: 'Create account',
  createAccountFirst: 'Create account first',
  publishFeedback: 'Send feedback',
  sendFeedback: 'Send feedback',
  selfReflectSuccess: 'Nice work! Self-reflection saved.',
  feedbackSuccess: 'Nice work! Your feedback has been sent.',
  feedbackFor: (name: string): string => `Feedback for ${name}`,
  begin: 'Begin',
  youCanAskForFeedbackToo:
    'Did you know that you can also get feedback from your co-workers in Teamspective?',
  followTheseGuidelines: 'Please follow these guidelines',
  guideLineRows: `Great feedback is honest, well-intended and focused on concrete examples
On this page you can give reinforcing and redirecting feedback about the chosen topics
Reinforcing feedback means giving encouragement, showing appreciation or simply saying thanks
Redirecting feedback is about giving suggestions, making request or sharing advice
Give both types of feedback about concrete behavior rather than person’s qualities
There are no numeric evaluations here, so you will need to use words. And we’ll help you with that.`,
  seePreview: 'See preview',
  seePreviewAndSave: 'Preview and save',
  seePreviewAndSend: 'Preview and send',
  markAsNonPrivate: 'Mark as non-private',
  markAsNonPrivateConfirmation: 'Are you sure you want to mark this feedback as non-private?',
  private: 'Private',
  limited: 'Limited',
  public: 'Public',
  visibleTo: 'Visible to',
  visibleToProviderAndRecipient: 'Visible to provider and recipient',
  feedbackVisibility_private: "Other people won't see it unless it's shared",
  feedbackVisibility_limited: 'People with permission can see this feedback',
  feedbackVisibility_public: 'Public to workspace members',
  whoCanSeeFeedbackVisibility_private: "Other people won't see it unless it's shared",
  whoCanSeeFeedbackVisibility_limited: 'People with permission to view your feedback',
  feedbackVisibilityHint: 'Feedback will be always visible to you and recipient.',
  sharedWith: 'Shared with',

  suspiciouslyShort: 'You wrote very little – are you sure you want to send this feedback?',
  thanksInAdvance: 'Thanks in advance!',
  accessTitle: 'Who can join?',
  invitees: 'Member invitees',
  inviteesDescription: 'Members can always invite anyone to join.',
  allowDomains: 'With certain email addresses',
  allowDomain: (domain: string): string => `Allow users with a ${domain} email address to join`,
  adminSelfRemoveConfirm:
    'Are you sure you want to remove your administrator access? You will not longer be able to manage this group.',
  addDomain: 'Add domain',
  updated: 'Settings updated',
  feedbackToManagementTitle: (wsName: string) => `Feedback for ${wsName} management`,
  feedbackFromMeToManagement: (wsName: string) => `My feedback to ${wsName} management`,
  emptyFeedbackListMy:
    'Here you will see your sent and received feedback. Currently there is none.',
  emptyFeedbackListOthers: (name: string) =>
    `Here you will see the feedback received by ${name}. Currently there is none.`,
  emptyAboutWorkspace:
    'Here you will see feedback provided to the workspace. Currently there is none.',
  emptyToWorkspace:
    "Here you will see the feedback you've provided to your workspace. Currently there is none.",
  loadMore: 'Load more',
  exampleFirstName: 'Jane',
  exampleLastName: 'Doe',
  firstName: 'First name',
  firstNameRequired: 'Your first name is required',
  lastName: 'Last name',
  lastNameRequired: 'Your last name is required',
  writeNameOrEmailConnections: 'Type name or email address from your connections',
  writeNameOrEmailAnyDomain: 'Type name or email address from any domain',
  needValidEmailToInvite: 'Type an email address to invite a user',
  exactMatch: 'Exact match',
  exactMatchAlreadySelected: 'This user has already been added',
  willBeInvited: 'This user will be invited',
  fiCommunicateIn: 'in Finnish',
  enCommunicateIn: 'in English',
  esCommunicateIn: 'in Spanish',
  svCommunicateIn: 'in Swedish',
  noCommunicateIn: 'in Norwegian',
  dkCommunicateIn: 'in Danish',
  itCommunicateIn: 'in Italian',
  deCommunicateIn: 'in German',
  frCommunicateIn: 'in French',
  plCommunicateIn: 'in Polish',
  cnCommunicateIn: 'in Chinese',
  ukCommunicateIn: 'in Ukrainian',
  confirmDeleteProvider: 'Are you sure you want to remove this person?',
  thisIsYou: "That's you! Take a moment to introspect.",
  noChannel: 'None',
  chooseSlackChannel: 'Select Slack channel',
  chooseMsTeamsChannel: 'Select MS Teams channel',
  loading: 'Loading',
  endingAt: (ending: string): string => `Ending ${ending}`,
  personalMessage: 'Personal message',
  addPersonalMessage: 'Add personal message',
  feedbackForMeFrom: (userName: string): string => `Feedback from ${userName} to you`,
  feedbackForMeAnonymous: 'Anonymous feedback to you',
  sentFeedbackTo: (userNames: string[]): string =>
    `Your feedback for ${listItemsInNaturalLanguage.en(userNames)}`,
  anonymousFeedbackTo: (name: string) => `Anonymous feedback to ${name}`,
  xSentFeedbackToY: (x: string, y: string): string => `${x} sent feedback to ${y}`,
  topic: 'Topic',
  saving: 'Saving...',
  youWillBeAdminOfGroup: "You will become the group's administrator",
  redacted: 'Few answers – redacted for privacy',
  level: 'Level',
  start: 'Start',
  amountEvaluations: (n: number) => `${n} feedback${n !== 1 ? 's' : ''}`,
  evaluationTitle: (evaluee: string) => `Feedback for ${evaluee}`,
  evaluationRequestIntro: ({
    evalueeName,
    evalueeEmail,
  }: {
    evalueeName: string
    evalueeEmail?: string
  }) =>
    `Your feedback on working with ${evalueeName}${
      evalueeEmail ? ` (${evalueeEmail})` : ''
    } is requested.`,
  selfEvaluationRequestIntro: 'You have been requested to complete a self-reflection.',
  evaluationVisibilityInfo: {
    all: 'Your answers will be visible to the feedback recipient and people with supervising roles together with your name.',
    finegrained:
      'Your answers will be anonymous to the feedback recipient. People with a supervising role can see your answers with your name.',
    admins:
      'Your answers will be anonymous to the feedback recipient. Organization administrators can see your answers with your name.',
    never: 'Your answers will be anonymous.',
  },
  evaluationSubmitSuccess: 'Feedback successfully submitted.',
  privacy: 'Privacy',
  evaluationThankYou: (evalueeName: string) =>
    `Thank you for providing feedback to ${evalueeName} in Teamspective!`,
  selfEvaluationThankYou: 'Thank you for your self-reflection!',
  respondent: 'Respondent',
  respondentsCount: 'Respondents',
  orFewer: 'or fewer',
  participationInEachPulseSurvey: 'Pulse activity by survey',
  resultPublishedAfterRoundEnds: 'Feedback results will be visible here.',
  startImmediately: 'Start immediately',
  writeEvaluation: 'Write feedback',
  evaluee: 'Collecting feedback for',
  requestingFeedbackFrom: 'Requesting feedback from',
  evaluator: 'Feedback provider',
  results: 'Results',
  ofAllEvaluations: 'of all feedback',
  ofAllUsers: 'of all users',
  allowRedirectingOpenFeedback: 'Allow sending unrequested redirecting feedback',
  allowRequestingEvaluationForMe: 'Allow anyone to request 360 feedback for themselves',
  allowViewingOwnNumericEvaluationsByTheme:
    'Allow employees to see their own numeric theme averages for published feedback',
  allowViewingOwnNumericEvaluationsByQuestion:
    'Allow the employees see the result with question-level accuracy',
  errorAddUsers: `Adding users failed. Please note that there is a ${FREE_WORKSPACE_SIZE} person limit for free workspaces.`,
  errorSendRequests: 'Unfortunately something went wrong and the requests were not sent.',
  anonymousChat: 'Anonymous chat',
  anonymousChatEnabled: 'Anonymous chat is enabled',
  channelsActive: (n: number) =>
    n > 0 ? `${n} channel${n > 1 ? 's' : ''} active` : 'No channels active',
  sendFeedbackToManagement: 'Send feedback to management',
  sendFeedbackAnonymously: 'Send feedback anonymously',
  anonymousChatCanBeSeen: (wsName: string, recipientNames: string[]) =>
    `Anonymous chat can be seen by the following people at ${wsName}: ${listItemsInNaturalLanguage.en(
      recipientNames
    )}`,
  anonymousFeedback: 'Anonymous feedback',
  feedbackFrom: 'Feedback from',
  sendCommentAnonymously: 'Send comment anonymously',
  yourCommentWillBeAnonymous: 'Your comment will be anonymous',
  yourNameWillBeVisible: 'Your name will be visible',
  sentTo: 'Sent to',
  seeFeedback: 'See feedback',
  draft: 'Draft',
  continueDraft: 'Continue draft',
  myFeedback: 'My feedback',
  commentAmount: (n: number) => `${n} comment${n !== 1 ? 's' : ''}`,
  sharedFeedback: 'Shared feedback',
  filterByQuestion: 'Filter by question',
  feedbackToMeOrByMe: 'Sent and received feedback',
  feedbackToMe: 'Received feedback',
  feedbackByMe: 'Sent feedback',
  selectFeedbackToShare: 'Select feedback to share',
  nSelected: (n: number) => `${n} selected`,
  nQuestionsSelected: (n: number) => `${n} question${n !== 1 ? 's' : ''} selected`,
  nQuestionsTargeted: (n: number) => `${n} question${n !== 1 ? 's' : ''} targeted`,
  feedbackToShare: 'Feedback to share',
  feedbackShareSuccess: 'Your feedback has been shared and the recipients have been notified.',
  shareWith: 'Share with',
  demand: 'Demand',
  influence: 'Influence',
  support: 'Support',
  knowledgeBase: 'Knowledge base',
  howToReadThisChart: 'How to read this chart',
  howToReadOnaScatterplots: `This chart illustrates how individual employees position on two measures: Influence and Demand. Interesting observations can be found by looking for outliers:
  • People at the extreme ends of the measures
  • High on one axis and low on the other
  • In an unexpected position compared to their position`,
  filterByGroups: 'Filter by groups',
  showNames: 'Show names',
  network: 'Collaboration',
  networkSurveyNotPublishedOrUserHasNotResponded:
    'Network survey needs to be published and you need to have replied to see your own results. Sorry!',
  average: 'Average',
  anonymousChatRecipients: 'Anonymous chat recipients',
  anonymousChatRecipientsUpdatedMessage: 'Anonymous chat recipients have been updated',
  update: 'Update',
  everyone: 'Everyone',
  lastYear: 'Last year',
  lastMonth: 'Last month',
  'in_all-time': 'since beginning',
  'in_last-year': 'in last year',
  'in_last-six-months': 'in last 6 months',
  'in_last-quarter': 'in last 3 months',
  'in_last-month': 'in last month',
  undo: 'Undo',
  responses: 'responses',
  noResponses: 'unresponded',
  responseRate: 'response rate',
  nResponses: (n: number) => `${n} response${n !== 1 ? 's' : ''}`,
  questionAmount: (n: number | string) => (n === 1 ? '1 question' : `${n} questions`),
  seeResponses: 'See responses',
  resetFilters: 'Reset filters',
  evaluationsForUser: (name: string) => `Evaluations for ${name}`,
  evalueeNotEvaluatedWarning: (n: number) =>
    `There are ${n} people without an evaluator. Do you want to proceed?`,
  networkSurveyRoundResponses: 'Network survey responses',
  pending: 'Pending',
  complete: 'Complete',
  status: 'Status',
  date: 'Date',
  pleaseUseYourCompanySlackAccountToLogin: 'Please use your company Slack account to log in',
  pleaseUseYourCompanyGoogleAccountToLogin: 'Please use your company Google account to log in',
  show: 'Show',
  filters: 'Filters',
  clearFilters: 'Clear filters',
  clear: 'Clear',
  clearAll: 'Clear all',
  clearAllSelections: 'Clear all selections',
  feedbackStats: 'Feedback stats',
  allResults: 'All results',
  viewAllResults: 'View all results',
  personal: 'Personal',
  workspace: 'Workspace',
  subscription: 'Subscription',
  startNextPulseSurveyNow: 'Start next pulse survey now',
  nextPulseSurveyWillBeScheduledOn: 'The next pulse survey will be scheduled to begin on',
  nextPulseSurveyIsScheduledOn: 'Next pulse survey scheduled to begin on',
  pulseSurveyWillBeginAndEnd: 'The pulse survey will begin immediately and end',
  manageUsers: 'Manage users',
  addToGroup: 'Add to group',
  accountLinked: (service: 'Slack' | 'MS Teams') =>
    `The user has connected their ${service} account to Teamspective`,
  userLimitedAccess: 'This user has access only to survey answering',
  groupMembers: (n: number) => `${n} members`,
  workspaceInformation: 'Workspace information',
  noWorkspaceGroups: 'No groups in workspace',
  whyWorkspaceGroups: 'By dividing users into groups you can view pulse results by group.',
  genericErrorMessage: 'Unfortunately there was an error. Our team has been notified.',
  genericErrorMessageTitle: 'Unfortunately there was an error',
  genericErrorMessageDescription: 'Our team has been notified. Please try again soon.',
  selectUser: 'Select user',
  selectUsers: 'Select users',
  selectChannels_slack: 'Select channels',
  'selectChannels_ms-teams': 'Select teams',
  demoWriteFeedback: 'Write feedback',
  reply: 'Reply',
  groupCount: (n: number) => (n === 1 ? '1 group' : `${n} groups`),
  pulseComments: 'Pulse comments',
  allComments: 'All comments',
  noPulseComments: 'No pulse comments',
  relatedTopic: 'Related topic',
  incompleteSentimentAnalysisDescription:
    'Analyzing the topics of the comments. This may take a few minutes.',
  incompleteEvaluationSummaryDescription: 'Analyzing feedback. This may take a few minutes.',
  topicsFoundInComments: 'Topics found in comments',
  noTopicsFound: 'No common topics were found for your selection',
  commentSummaryInfo:
    'This summary is powered by AI. Harmful content is filtered out from the summary. We recommend reading all comments to get a full understanding of the responses.',
  selectedTopic: 'Selected topic',
  sentiment: 'Sentiment',
  idea: 'Idea',
  ideas: 'Ideas',
  noReplies: 'No replies',
  visibleToAdmins: 'Visible to admins',
  visibleToWorkspace: 'Visible to workspace',
  ownComments: 'Own comments',
  workspaceCommentVisibilityAdminsOnly: 'The comment is visible only to workspace admins',
  workspaceCommentVisibility: 'The comment is visible to all workspace members',
  teamsCommentVisibilityAdminsOnly: 'Visible to workspace admins and group admins of these groups.',
  teamsCommentVisibility: 'Visible to members of these groups, and to workspace admins.',
  allowedPulseCommentsVisibility: 'Survey comments can be made visible to',
  'allowedPulseCommentsVisibility_workspace-members': 'All users in workspace',
  'allowedPulseCommentsVisibility_teams-members': "Admins or members of user's groups",
  'allowedPulseCommentsVisibility_admins-only': "Admins of user's groups only",
  'allowedPulseCommentsVisibility_workspace-admins-only': 'Workspace admins only',
  emailAddress: 'Email address',
  emailUpdateInstruction:
    'To update your email address in Teamspective, please contact our support at',
  sharedFeedbackVerb: 'Shared feedback',
  gavePraise: 'Gave praise',
  firstResults: 'First results',
  answers: 'answers',
  pulseInsightsTitle: 'Insights & Suggestions',
  pulseInsightsPositiveTitle: 'Keep up the good work',
  pulseInsightsNegativeTitle: 'Take action on',
  insightsNoIssues: (groupName: string) =>
    `We didn’t find any major issues for ${groupName}. Please note that each group has different suggestion based on their results.`,
  fromLastRound: 'change from last survey',
  inLastTwoRounds: 'in last 2 surveys',
  versusBenchmark: 'vs benchmark',
  comparedToWorkspace: 'compared to workspace',
  reinforcing_cue: 'Reinforcing feedback',
  benchmark: 'Benchmark',
  reinforcing_example: 'It was great when you...\nI admire/appreciate how you...',
  reinforcing_explanation:
    'Give reinforcing feedback to show appreciation, recognise great work, say thanks, and to celebrate wins. Here’s how:',
  reinforcing_explanationItems: [
    'Share your observation: What was done, what exactly made the difference? Be as specific as you can when sharing examples.',
    'Communicate the impact: What happened as a result? Did you feel relief, joy, pride, satisfaction, or some other positive emotion? Was some need (team’s or yours) met because of this behavior?',
    'Close with e.g. “You’re setting a great example” or “I’m going to try and learn that” or “Thank you for putting in all the effort” or just “Happy to work with you”',
  ],
  redirecting_example: 'We should figure out together how to ...\nCould you try...',
  redirecting_explanation:
    'Give redirecting feedback to share your ideas and suggestions going forward. Here’s how:',
  redirecting_explanationItems: [
    'Share your observation neutrally: Describe in meaningful detail what happened. Describe the situation and behavior, not the person.',
    'Communicate the impact: Tell what happened (or didn’t happen) because of that behavior? If relevant, tell how you felt as a result.',
    'Make an actionable request: Tell what you think would make things better next time in a similar situation. Remember that making a request or suggestion is different from demanding.',
  ],
  redirecting_cue: 'Redirecting feedback',
  explanationMoreTipsLink: 'For more tips on effective feedback,',
  readHere: 'read here',
  topics: 'Topics',
  chooseTopic: 'Choose topic',
  addAnotherTopic: 'Add another topic',
  favouriteTopicsTabDescription:
    'Select topics to be highlighted when people write feedback to you.',
  redactionLimitText: 'Show survey results in groups with a minimum of',
  wantsFeedbackOnTopic: (name: string) => `${name} wants to receive feedback on the topic.`,
  selfReflection: 'Self-reflection',
  preferredTopics: 'Preferred topics',
  choosePreferredTopics: 'Choose preferred topics',
  lastDays: (d: number) => `Last ${d} days`,
  requesters: 'requesters',
  requester: 'requester',
  provider: 'provider',
  providers: 'providers',
  recipient: 'recipient',
  recipients: 'recipients',
  replyNoun: 'reply',
  replies: 'replies',
  request: 'request',
  requests: 'requests',
  responsiveness: 'Responded to requests',
  askedForFeedback: 'Asked for feedback',
  providerRate: 'Gave feedback or praise',
  gotFeedback: 'Got feedback',
  sentPraise: 'Sent praise',
  sentFeedback: 'Sent feedback',
  people: 'People',
  peopleAmount: (n: number) => (n === 1 ? '1 person' : `${n} people`),
  role: 'Role',
  allTopics: 'All topics',
  praise: 'Praise',
  collapseAll: 'Collapse all',
  expandAll: 'Expand all',
  feedbackTimeline: 'Feedback timeline',
  praiseFrom: 'Praise from',
  viewAndComment: 'View and comment',
  yourFeedbackNeeded: 'Your feedback is needed',
  allPeople: 'All people',
  you: 'you',
  from: 'From',
  unseenComments: 'New comment',
  type: 'Type',
  shareableFeedback: 'Shareable feedback',
  customQuestion: 'Custom question',
  customQuestions: 'Custom questions',
  oldQuestions: 'Old questions',
  oldQuestionsTip:
    'We do not recommend these old questions. In the new data model, they are no longer used to calculate the results. Delete old questions if there is no particular need for them.',
  oldQuestionsTipContinuation:
    'Old questions are deleted when you remove a selection from the list and press OK. Deleting questions cannot be undone. The existing answers remain in the results view of the Pulse survey.',
  choicesNeedToBeUnique: 'Choices need to be unique',
  createNewQuestion: 'Create new question',
  createNewTemplate: 'Create new template',
  editQuestion: 'Edit question',
  howToDesignASurveyQuestion: 'How to design a survey question',
  addChoice: 'Add choice',
  linear7: '7-point scale',
  linear5: '5-point scale',
  linear4: '4-point scale',
  binary: 'Yes/No',
  nps: 'Net Promoter Score',
  balance5: '5-point balance scale',
  frequencyOfPositivePhenomenon_1: 'Almost never',
  frequencyOfPositivePhenomenon_2: 'Rarely',
  frequencyOfPositivePhenomenon_3: 'Sometimes',
  frequencyOfPositivePhenomenon_4: 'Often',
  frequencyOfPositivePhenomenon_5: 'Almost always',
  frequencyOfNegativePhenomenon_1: 'Almost always',
  frequencyOfNegativePhenomenon_2: 'Often ',
  frequencyOfNegativePhenomenon_3: 'Sometimes',
  frequencyOfNegativePhenomenon_4: 'Rarely',
  frequencyOfNegativePhenomenon_5: 'Almost never',
  satisfaction_1: 'Not at all satisfied',
  satisfaction_2: 'Slightly satisfied',
  satisfaction_3: 'Moderately satisfied',
  satisfaction_4: 'Very satisfied',
  satisfaction_5: 'Completely satisfied',
  agreeing_1: 'Strongly disagree',
  agreeing_2: 'Disagree',
  agreeing_3: 'Neutral',
  agreeing_4: 'Agree',
  agreeing_5: 'Strongly agree',
  agreeing7point_1: 'Strongly disagree',
  agreeing7point_2: 'Disagree',
  agreeing7point_3: 'Somewhat disagree',
  agreeing7point_4: 'Partly agree and partly disagree',
  agreeing7point_5: 'Somewhat agree',
  agreeing7point_6: 'Agree',
  agreeing7point_7: 'Strongly agree',
  expectations_1: 'Unacceptable',
  expectations_2: 'Needs improvement',
  expectations_3: 'Meets expectations',
  expectations_4: 'Exceeds expectations',
  expectations_5: 'Significantly exceeds expectations',
  standards_1: 'Not meeting standards',
  standards_2: 'Inconsistently meets standards',
  standards_3: 'Meets standards',
  standards_4: 'Meets high standards',
  standards_5: 'Regularly exceeds high standards',
  rolefit_1: 'Needs Improvement',
  rolefit_2: 'Developing',
  rolefit_3: 'Fully Successful',
  rolefit_4: 'Often Exceeds',
  rolefit_5: 'Role Model',
  skillLevel1_1: 'Beginner',
  skillLevel1_2: 'Learning',
  skillLevel1_3: 'Normal',
  skillLevel1_4: 'Advanced',
  skillLevel1_5: 'Expert',
  skillLevel2_1: 'Low',
  skillLevel2_2: 'Basic',
  skillLevel2_3: 'Normal',
  skillLevel2_4: 'High',
  skillLevel2_5: 'Exceptional',
  skillLevel3_1: 'Insufficient',
  skillLevel3_2: 'Below average',
  skillLevel3_3: 'Good',
  skillLevel3_4: 'Above average',
  skillLevel3_5: 'Exceptional',
  skillLevel4_1: 'Entry level',
  skillLevel4_2: 'Basic knowledge',
  skillLevel4_3: 'Performs independently',
  skillLevel4_4: 'Expert',
  skillLevel4_5: 'Elevates others',
  scale1to5_1: '1',
  scale1to5_2: '2',
  scale1to5_3: '3',
  scale1to5_4: '4',
  scale1to5_5: '5',
  quality_1: 'Very poor',
  quality_2: 'Poor',
  quality_3: 'Fair',
  quality_4: 'Good',
  quality_5: 'Excellent',
  yesNo_1: 'Yes',
  yesNo_0: 'No',
  deleteQuestion: 'Delete question',
  frequencyOfPositivePhenomenon: 'Frequency (of a positive phenomenon)',
  frequencyOfNegativePhenomenon: 'Frequency (of a negative phenomenon)',
  satisfaction: 'Satisfaction',
  agreeing: 'Agreeing',
  agreeing7point: 'Agreeing',
  expectations: 'Expectations',
  standards: 'Standards',
  rolefit: 'Role fit',
  skillLevel1: 'Skill level 1',
  skillLevel2: 'Skill level 2',
  skillLevel3: 'Skill level 3',
  skillLevel4: 'Skill level 4',
  positiveFrequency4: 'Frequency of positive phenomenon (4-point)',
  scale1to5: 'Scale 1-5',
  quality: 'Quality',
  yesNoPositive: 'Yes / No (positive)',
  yesNoNegative: 'Yes / No (negative)',
  written: 'Written',
  custom: 'Custom',
  customOption: 'Custom',
  scale: 'Scale',
  translations: 'Translations',
  questionRemoved: 'Question removed successfully',
  questionAdded: 'Question added successfully',
  quickActions: 'Quick actions',
  takeAction: 'Take action',
  whyItMatters: 'Why it matters',
  teamDiscussionTips: 'Team discussion tips',
  oneOnOneTips: '1-1 discussion tips',
  deleteDraft: 'Delete draft',
  draftDeleted: 'Draft deleted',
  noCustomQuestions: 'No custom questions',
  notScheduled: 'Not scheduled',
  changeOnScheduleTab: 'Change this setting on the Schedule tab',
  thanksForAnswer: 'Thanks for answering!',
  noPulseSurveyActive: 'No pulse surveys active',
  limitedSurveyAnsweredDescription: 'You can still add comments and edit your answers below.',
  loginWithCode: 'Log in with code',
  invalidLoginCode:
    'The login code is not valid. Check the code or contact your organization admin for help.',
  answerRate_count: 'Replied during last survey',
  answerRate_countLag1: 'Replied during last two surveys',
  answerRate_countLag2: 'Replied during last three surveys',
  answerRate_countLag3: 'Replied during last four surveys',
  answerRate_countLagMore: 'Replied more than four surveys ago',
  answerRate_countNever: 'Never replied',
  percentages: 'Percentages',
  count: 'Count',
  counts: 'Counts',
  number: 'No.',
  billingInterval_year: 'Annual billing',
  billingInterval_month: 'Monthly billing',
  plan_feedback: 'Personal feedback',
  plan_pulse: 'Engagement',
  plan_pulse_and_feedback: 'Engagement & Performance',
  paymentMethod_card: 'Credit card',
  paymentMethod_invoice: 'Invoice',
  paymentMethod: 'Payment method',
  billingAddress: 'Billing address',
  confirmSubscription: 'Confirm subscription',
  currentlySubscribed: 'You currently have an active subscription',
  nextBillDueOn: (date: string) => `Next bill due on ${date}`,
  orderEndsOn: (date: string) => `Your subscription will end on ${date}`,
  chooseBillingPeriod: 'Choose billing period',
  billingPeriod: 'Billing period',
  subscriptionPlanTitle: 'Subscription plan',
  fifteenOff: '15% off',
  selectSubscriptionPlanTitle: 'Choose subscription plan',
  finVat: 'Finnish VAT applies',
  finVatHelp: `If the buyer is resident in Finland, Finnish value added tax (${FI_VAT_PERCENTAGE} %) is added.`,
  upgradeTitle: 'Ready to take it to the next level?',
  eur: (eur: number) => `€${roundPrice(eur)}`,
  product: 'Product',
  summary: 'Summary',
  intervalTitle: 'Billing frequency',
  yearFrequency: 'Annual',
  yearPrice: (unitPriceEur: number) => `€${roundPrice(unitPriceEur)} / user / year`,
  monthFrequency: 'Monthly',
  monthPrice: (unitPriceEur: number) => `€${roundPrice(unitPriceEur)} / user / month`,
  users: 'Users',
  monthlyCost: 'Monthly cost',
  annualCost: 'Annual cost',
  pricingBasis: 'Pricing basis',
  yourBill: 'You will be billed',
  vat: 'VAT',
  groupMoveConfirmation: (childGroupName: string, parentGroupName: string) =>
    `Are you sure you want to move ${childGroupName} under ${parentGroupName}? All members of the group will be added to the new parent group`,
  done: 'Done',
  reorganize: 'Reorganize',
  reorganizingGroups: 'Reorganize groups by dragging them',
  containsOneGroup: 'Contains one group',
  containsGroups: (count: number) => `Contains ${count} groups`,
  parentGroup: 'Parent group',
  feedbackActivation: 'Feedback activation',
  feedbackActivationInfoTitle: 'Ensure everyone gets quality feedback, year-round',
  feedbackActivationStepOne:
    'We analyse the network of relationships between the employees in your company',
  feedbackActivationStepTwo:
    'Our AI model combines the network data, pulse results and user feedback activity to give everyone smart suggestions who they could praise or ask feedback from. Suggestions are sent in Slack or MS Teams.',
  feedbackActivationRequestCriteria:
    'We nudge people to send out pre-built, smart and targeted feedback requests. Target level: users get feedback from 3 people every 3 months.',
  feedbackActivationPraiseCriteria:
    'We automatically remind people to share praise, give thanks and show appreciation to colleagues, if they don’t do it proactively.',
  feedbackActivationAskForConnections: 'Create feedback network',
  feedbackActivationAskForConnectionsWhen:
    'Users take a one-minute survey in Slack or MS Teams to create a feedback network that is used for targeted feedback suggestions. Updates are collected automatically every 6 months.',
  collectPersonalFeedback: 'Collect personal feedback',
  activationTypes: 'Activation types',
  activationTypesRecommendedOn:
    'To get the full benefits of feedback automation, we recommend turning all activation types on',
  overview: 'Overview',
  previousSurvey: 'previous survey',
  lastAsked: 'Last asked',
  'pulseUnlimitedVisibilitySetting_workspace-admins': 'Workspace admins',
  'pulseUnlimitedVisibilitySetting_everyone-in-workspace': 'All users in workspace',
  defaultPulseResultVisibility:
    'Users can always see the results of their own groups, including the combined results for the workspace. Group admins can always see results in sub-groups.',
  allPulseResultsVisibleTo: 'Show survey results of all groups to',
  default: 'Default',
  allowed: 'Allowed',
  notAllowed: 'Not allowed',
  hidingResultsReason: 'Hiding results in small groups protects the anonymity of its members.',
  pulseCommentVisibilitySettingInfo:
    'Depending on this setting, users can post anonymous pulse comments that are visible to (1) all workspace members, (2) members or admins of their own groups, (3) admins of their own groups, or (4) workspace admins only.',
  showExample: 'Show example',
  heatmapDesktopOnly:
    'Pulse result heatmap is only viewable in a wider viewport. Use a desktop browser or try using your device in landscape mode.',
  removeDraft: 'Remove draft',
  time: 'time',
  rows: 'Rows',
  signUp: 'Sign up',
  withEmail: 'with email',
  focusOnGroup: 'Focus on group',
  focusOnMeasurement: 'Focus on measurement',
  selectGroup: 'Select group',
  selectMeasurement: 'Select measurement',
  pivotDataBy: 'Pivot by',
  heatmapType: 'Heatmap type',
  demoFeedbackPrompt: 'Hey, I would like you to give me some feedback.',
  allDone: 'All done!',
  demoAnswerPulse: 'Answer pulse',
  demo: 'demo',
  myScore: 'My score',
  groupScore: 'Group score',
  hideMyScore: 'Hide my score',
  showMyScore: 'Show my score',
  showBenchmark: 'Show benchmark',
  hideBenchmark: 'Hide benchmark',
  numberOfUsers: (amount: number) => `${amount} user${amount !== 1 ? 's' : ''}`,
  answerSent: 'Answer sent',
  networkSurvey: 'Network survey',
  leadershipStyle: 'Leadership style',
  replyBy: (date: string) => `Please reply by ${date}`,
  onaDescription:
    'Network surveys are used to analyse the connections between people: who works with whom, who helps others, how information flows, who influences culture, and how expertise is distributed.',
  enableFeedbackLink: 'Enable feedback link',
  disableFeedbackLink: 'Disable feedback link',
  feedbackLinkDescription:
    'If you want to request feedback from people not using Teamspective, you can create an open feedback link. People you share the link with can give you reinforcing and redirecting feedback without a Teamspective account.',
  feedbackLinkEnabledInstruction: 'Share this link with people to get feedback.',
  feedbackLinkDisabledInstruction:
    'You have disabled open feedback. Click the button below to enable the feedback link.',
  feedbackLink: 'Feedback link',
  tryOurKeyFeatures: 'Try our key features with demo content',
  showFeatureDemos: 'Show feature demos on home page',
  featureDemosHidden: 'Feature demos hidden',
  reEnableIn: 'You can re-enable them in',
  networkGraph: 'Network graph',
  hideOthers: 'Hide non-selected',
  zoomIn: 'Zoom in',
  zoomOut: 'Zoom out',
  zoomToFit: 'Zoom to fit',
  fullScreen: 'Full screen',
  feedbackType: 'Feedback type',
  evaluationType: 'Template type',
  employeeEvaluation: 'Manager-to-employee',
  managerEvaluation: 'Employee-to-manager',
  externalEvaluation: 'External feedback',
  selfEvaluation: 'Self-reflection',
  otherEvaluation: 'Feedback from other colleague',
  peerEvaluation: 'Peer-to-peer',
  performanceOverview: 'Performance overview',
  managementPermissions: 'Management permissions',
  addFromGroup: 'Add from group',
  permissions: 'Permissions',
  user: 'User',
  managingPerson: 'Managing person',
  managedPeople: 'Managed people',
  peopleConnected: 'people connected',
  individualImpact: 'Individual impact',
  connectionType: 'Connection type',
  groupConnections: 'Group connections',
  clusterAnalysis: 'Cluster analysis',
  clusterSize: 'Cluster size',
  zScoreDescription: [
    'These four scores are calculated to every member in the workspace, based on incoming connections in network surveys. The average score of all users is always zero. 68.24 % of all users are within the range of -1…1 in each score, and 95.44 % are within the range of -2…2. This means that scores over 2 or below -2 are extremely rare.',
    '‘Demand’ indicates how much external operational or managerial burden is directed to the person by others.',
    '‘Influence’ indicates the person’s ability to lead and clarify work, by calculating the ‘centrality’ of the person in the networks.',
    '‘Supporting others’ indicates how much the person contributes to people’s wellbeing and good spirits.',
    '‘Recognition’ indicates how much the person’s work and performance is admired.',
    'The radar chart visualises the amount of nominations the person has in each question of network survey. This indicates the type of impact the person has on other people.',
  ],
  peopleConnectedTo: (name: string) => `People connected to ${name}`,
  peopleConnectedToDescription: (name: string) =>
    `See how ${name} impacts others and use the insights to prevent employee churn, develop the person's role in the organization, and leverage their strengths in change management.`,
  significantConnectionDescription: (source: string, target: string) =>
    `${target} is among the most significant connections of ${source}`,
  startNetworkSurvey: 'Start network survey',
  participants: 'Participants',
  significance: 'Significance',
  howToReadGroupLinkSignificance:
    'A high significance means that the connection is stronger than predicted by random chance. The graph displays only the most significant group connections, while the matrix displays the significance of every connection.',
  recognition: 'Recognition',
  notificationPreferences: 'Notifications',
  confirmFeedbackUnsubscribe:
    'Are you sure? You will not find out if a colleague writes you some feedback.',
  feedbackNotifications: 'Feedback Notifications',
  feedbackNotificationDescription: 'Your colleagues feedback, feedback requests, and comments.',
  pulseRemindersNotifications: 'Survey Reminders',
  pulseRemindersNotificationDescription: 'Reminders to respond to surveys.',
  pulseResultsNotifications: 'Survey Results',
  pulseResultsNotificationDescription: 'Notifications that new survey results are available',
  recommendationsNotifications: 'Tips & Recommendations',
  recommendationsNotificationDescription: 'Timely advice to make the most of our features.',
  announcementsNotifications: 'Features & announcements',
  announcementsNotificationDescription:
    'New feature updates, as well as occasional company announcements.',
  researchNotifications: 'Research',
  researchNotificationDescription:
    'Invitations to participate in surveys and usability tests. Only a few per year.',
  newsletterNotifications: 'Teamspective Newsletter',
  newsletterNotificationDescription: 'New articles and other content by Teamspective.',
  welcomeToTeamspective: 'Welcome to Teamspective',
  rememberMe: 'Keep me logged in in this browser',
  tocRequired: 'Please accept the terms of contract to use the service',
  surveysOnly: 'surveys only',
  skip: 'Skip',
  skipNow: 'Skip now',
  kpi: 'KPI',
  seeAndEdit: 'See and edit',
  everyNWeeks: (n: number) => (n <= 1 ? 'Every week' : `Every ${n} weeks`),
  measurement: 'Measurement',
  groups: 'Groups',
  responseStatisticsByGroup: 'Response statistics by group',
  questionsAskedFromWorkspace: 'Questions asked from whole workspace',
  questionsScheduledAmount: 'questions scheduled',
  off: 'Off',
  selectMeasurementTooltip:
    'Select what results you want to view. Each KPI is measured through several Themes, and each Theme is measured with multiple questions.',
  questionsExplainingScore: 'Questions explaining score',
  themesExplainingScore: 'Themes explaining score',
  schedulingTip:
    'Each survey has max 6 questions because response rates start to significantly decrease if survey is longer. To get results for all questions faster, set pulse survey frequency to weekly or reduce number of scheduled questions. For a more complete data set you can complement repeating surveys with manually triggered annual surveys.',
  everyThingAskedEvery: (thing: string) => `Each ${thing} asked`,
  belongsToThemes: 'Belongs to themes',
  visibileIn: 'Visible in',
  addToSchedule: 'Add to schedule',
  removeFromSchedule: 'Remove from schedule',
  scheduledForNextRound: 'Scheduled for next survey',
  nth: (n: number) => `${n}${n === 1 ? 'st' : n === 2 ? 'nd' : n === 3 ? 'rd' : 'th'}`,
  editNextPulseSurvey: 'Edit next pulse survey',
  editChoices: 'Edit choices',
  defaultSchedule: 'Default schedule',
  selectQuestions: 'Select questions',
  selectQuestionsNotScheduledOtherwise: 'Select questions not scheduled otherwise',
  unscheduled: 'Unscheduled',
  unschedule: 'Unschedule',
  seeFullSchedule: 'See full schedule',
  select: 'Select',
  recommended: 'Recommended',
  selectQuestionsFromLastRound: 'Select questions from last survey',
  slackAllowJoinWorkspace: 'Allow Slack workspace members to join Teamspective workspace',
  customSchedule: 'Custom schedule',
  customScheduleWarning: (customLimit: number) =>
    `Custom scheduling is limited to ${customLimit} questions at a time. Choose ‘Default’ and let our automation work its magic whenever possible!`,
  resources: 'Resources',
  pulseSurvey: 'Pulse survey',
  pulseSurveyOnboardingDescription:
    'Pulse Survey is the centerpiece of a healthy feedback culture, helping you collect employee input and tackle the right issues across teams. Every Teamspective workspace comes with a pulse survey, which you can modify and customise in settings.',
  soundsGood: 'Sounds good',
  workspaceNameWithExample: 'Workspace name',
  workspaceCreateDescription:
    "It's free for you to create a workspace and try our solutions. You will become the workspace admin, and you can add other admins.",
  lookingForExistingWorkspaceTitle: 'Are you looking for an existing workspace?',
  lookingForUnlistedWorkspaceTitle: 'Looking for a workspace not listed here?',
  lookingForExistingWorkspaceDescription:
    'We found no workspaces that you can join based on your email domain. If you know your company is using Teamspective, ask the HR or other admin users for an invitation.',
  connectSlack: 'Connect Teamspective and Slack',
  connectMsTeams: 'Connect Teamspective and Microsoft Teams',
  slackConnectDescription:
    'Teamspective’s superior in-Slack usability is one of our most unique features.',
  slackConnectPoints: [
    'Add users directly from Slack',
    'Answer pulse surveys directly in Slack',
    'Give and request feedback directly in Slack',
  ],
  inviteUsersEncouragement: 'Get the most out of Teamspective by inviting your team members.',
  freeUsersDescription: 'Teamspective is free for up to 30 people.',
  slackChannelPulseTitle: 'Choose a Slack channel for pulse survey',
  slackChannelPulseDescription:
    'Make Teamspective easy to find for the right people. The first Pulse surveys are sent to this channel.',
  slackAdminRightsRequired: 'Requires Slack admin rights',
  slackConnectAdminRights:
    'You can only complete this step with Slack admin rights. If you don’t have them, Skip for now and invite such a person in the next step',
  pulseSurveyImageCaption: 'The pulse survey looks like this',
  workspaceSetupReady: 'Your Teamspective workspace is all set!',
  startExploring: 'Initialize 360 feedback',
  createTemplates: 'Create templates',
  columns: 'Columns',
  addToMsTeams: 'Add to Microsoft Teams',
  msTeamsConnected: 'Microsoft Teams is now connected to your workspace',
  heatmaps: 'Heatmaps',
  defaultPulseCommentVisibility: 'Default option for comment visibility when user adds comments',
  adminsOfUsersGroups: "Admins of user's groups",
  membersOfUsersGroups: "Members of user's groups",
  defaultCommentVisibilityTooltip: `If the user is not member of any groups, or the user's groups are below the redaction size limit, the default option is ${getQuotedString(
    'Workspace admins',
    'en'
  )}`,
  selectMsTeamsTeamToConnect: 'Select Microsoft Teams team to connect',
  detectedNetworkRoles: 'Detected network roles',
  networkProfile: 'Network profile',
  myNetworkProfile: 'My network profile',
  evaluationRequestsSent: ({ multiple, scheduled }: { multiple: boolean; scheduled: boolean }) =>
    `Evaluation request${multiple ? 's' : ''} ${scheduled ? 'scheduled' : 'sent'}`,
  noQuestionnaire:
    "Looks like there's a problem with your workspace's feedback templates. Contact your workspace admin to fix the issue.",
  externalEvaluationHint:
    'To request feedback from outside your Teamspective workspace, use the web user interface.',
  editAnswer: 'Edit answer',
  evaluateLater: 'Write feedback later',
  median: 'Median',
  fiveStepPercentileImpact_0: 'Base level',
  fiveStepPercentileImpact_1: 'Moderate',
  fiveStepPercentileImpact_2: 'Elevated',
  fiveStepPercentileImpact_3: 'High',
  fiveStepPercentileImpact_4: 'Exceptional',
  yourAnswers: 'Your answers',
  percentileRank: 'Percentile rank',
  topTenInGroup: 'top 10% in group',
  taxId: 'Tax ID',
  promoCode: 'Promo code',
  discount: 'Discount',
  subscriptionMembersInfo:
    'When new people join your workspace or inactive members become active, they’ll be added to your next bill.',
  addons: 'Add ons',
  organisationalNetworkAnalysis: 'Organisational Network Analysis',
  hrisIntegration: 'HRIS Integration',
  optional: 'optional',
  workspaceDeactivatedAdmin: (workspaceName: string) =>
    `Your workspace ${workspaceName} has been deactivated due to a period of inactivity. Your data is still stored and you can re-enable the workspace.`,
  workspaceDeactivatedMember: (workspaceName: string) =>
    `Your workspace ${workspaceName} has been deactivated due to a period of inactivity. Contact a workspace admin to continue using Teamspective.`,
  activateWorkspace: 'Activate workspace',
  switchWorkspace: 'Switch workspace',
  actionRequired: 'Action required',
  now: 'Now',
  notInUse: 'Not in use',
  xAxis: 'X axis',
  yAxis: 'Y axis',
  timeRange: 'Time range',
  noEvaluationsInSelectedTimeRange: 'No feedback requests in selected time range',
  byEvaluation: 'By feedback',
  byEvaluee: 'By user',
  byQuestion: 'By question',
  byTemplate: 'By template',
  evaluatedUsers: 'Users with feedback',
  usersWithPendingFeedbackRequests: 'Users with pending feedback requests',
  usersWithExpiredFeedbackRequests: 'Users with expired feedback requests',
  notEvaluatedUsers: 'Users without feedback',
  group: 'Group',
  showGroups: 'Show groups',
  hideGroups: 'Hide groups',
  pendingFeedbackRequests: 'Pending feedback requests',
  completedEvaluations: 'Completed feedback requests',
  expiredEvaluations: 'Expired feedback requests',
  selectedEvaluations: 'Selected',
  deleteSelectedEvaluations: 'Delete selected',
  rescheduleSelected: 'Reschedule selected',
  areYouSureCreateNewWorkspace: "Are you sure? You won't be able to join another workspace later.",
  createNewCompanyWorkspace: 'Create new company workspace',
  howToFindWorkspaceAdvice: `If your company should have a workspace but you cannot join it here, it may be because of these reasons:

• The admin has not added you to the workspace yet.
• The email address you used does not match the profile created by the workspace admin.
• You are not using the correct invite link.

Please contact your HR to check on these.`,
  tryDifferentEmail: 'Try different email',
  wantToCreateNewCompanyWorkspace: 'Do you want to start a new workspace?',
  or: 'Or',
  joinWorkspaceBelow: 'Join a workspace below',
  invitationsFor: 'Invitations for',
  noInvitationsFor: 'No invitations for',
  completeYourProfile: 'Complete your profile',
  youAreLoggedInAs: 'You are logged in as',
  completeProfileInstructions:
    'Please follow these steps to create your profile and find your workspace',
  nameOfCompanyOrTeam: "What's the name of your company or team?",
  nameOfCompanyOrTeamHelp:
    'This will be the name of your Teamspective workspace – choose something that members will recognize.',
  reaction_agree: 'I agree',
  reaction_thanks: ' Thank you for the comment',
  reaction_team_discussion: "Let's bring this up in the team",
  reaction_personal_discussion: "Let's talk 1-1",
  reaction_important: 'I think this is important',
  addReaction: 'Add reaction',
  selectTime: 'Select time',
  startingFrom: 'Starting from',
  exportSuccess: 'Export successful',
  exportCanceled: 'Export canceled',
  exporting: 'Exporting',
  exportingSlide: 'Exporting slide',
  resultsOverTime: 'Results over time',
  notes: 'Notes',
  connectSsoDescription:
    'Connect your SSO provider and Teamspective workspace to enable Single Sign-On (SSO). When SSO is enabled, users in your workspace can not log in using other login methods.',
  ssoConnectedDescription: (ssoProvider: string) =>
    `Your workspace uses ${ssoProvider} for Single Sign-On (SSO). Other authentication methods are disabled for users. To make changes to your integration, contact Teamspective support.`,
  activate: 'Activate',
  instructions: 'Instructions',
  selectTopics: 'Select topics',
  membersOf: 'Members of',
  publishToUser: 'Publish to user',
  chooseEvaluators: 'Choose people and templates',
  addMultipleEvaluatorsTip:
    'Collect feedback from at least three people to ensure high-quality results',
  checkEvaluatorEmail:
    'Make sure you have provided the correct email address to the external feedback provider.',
  sync: 'Sync',
  hrisIntegrationActive: 'HRIS integration is active',
  hrisIntegrationNotInUse:
    'There is no active HRIS integration active in your workspace. With an integration you can automate user and group management in Teamspective. Activate an integration below or contact your customer success representative or Teamspective support for more information on integrations.',
  integrationLabel: 'System',
  integrationActivated: 'The integration with your HRIS is now active',
  integrationImmediateSyncDescription:
    'As a final step we would need to sync your employees and teams. Click Sync now below to immediately import your employees and teams.',
  integrationImmediateSyncWarning:
    'Warning: please skip this step if you have existing pulse results in your workspace! Reach out to us via the in-app-chat or hello@teamspective.com and we’ll help you finalize the setup.',
  integrationSyncUsers: 'Sync users',
  integrationSyncTeams: 'Sync groups',
  integrationSyncSuccessful: 'Integration succesfully synchronized',
  integrationSyncDelayed:
    'Waiting for integration sync. This may take a few minutes. Hang on while we fetch data.',
  integrationLastRun: 'Last sync',
  allQuestions: 'All questions',
  showOnlyUnpublished: 'Only show unpublished feedback',
  unpublished: 'Unpublished',
  unpublishedResults: 'Unpublished results',
  resultsForGroup: (groupName: string) => `Results for ${groupName}`,
  resultsForCohort: 'Results for responders with selected value',
  resultsForUser: 'Results for',
  evaluationOverviewRequiresThemes: 'To see overview, you need to add themes to questions.',
  published: 'Published',
  notPublished: 'Not published',
  publishNewResultsToUser: 'Publish new results to the user',
  allResultsArePublishedToUser: 'All results are moderated and published',
  evaluationPublishing_discouraged:
    'To ensure high-quality results and anonymity, we encourage having three or more feedback providers.',
  evaluationPublishing_moderate:
    'You can hide individual comments from the recipient. Average scores are always shown to the recipient.',
  iHaveModeratedCommentsAbove: 'I have moderated the comments above',
  commentRedacted: 'Comment set to hidden',
  commentShown: 'Comment set to visible',
  notToBeShownToUser: 'Not to be visible to user',
  toBeShownToUser: 'To be visible to user',
  shownToUser: 'Visible to user',
  notShownToUser: 'Not visible to user',
  workspaceRole: 'Workspace role',
  productPromoTitle: 'Easily implement Teamspective in your team',
  productPromoDescription:
    'You have just provided valuable assistance in strengthening the performance of your partner organization. Do the same in your team!',
  customerResults: 'Teamspective customer results',
  feedbackSatisfaction: 'Feedback satisfaction',
  increaseInProductivity: 'Increase of productivity',
  annualRoi: 'Annual ROI',
  bookDemo: 'Book a demo',
  allUsers: 'Everyone',
  requestSelfEvaluation: 'Request a self-reflection',
  publishFewEvaluationsClause:
    'I understand that publishing these results may compromise feedback provider anonymity',
  evaluationTemplate: 'Feedback template',
  evaluationTemplates: 'Feedback templates',
  template: 'Template',
  templates: 'Templates',
  templateName: 'Template name',
  templateInformation: 'Template information',
  otherTemplates: 'Other templates',
  addNewQuestionToThisTemplate: 'Add new question to this template',
  editing: 'Editing',
  includeInEvaluationTemplates: 'Include in feedback templates',
  selectTemplates: 'Select templates',
  deleteQuestionConfirmation:
    'Do you want to permanently delete this question? If you continue, this question will be removed from all feedback templates.',
  defaultQuestion: 'Default',
  deprecatedQuestion: 'A newer version of this question is available and recommended instead',
  unselectedDefaultQuestion: 'We highly recommend enabling all default questions.',
  copyToNewQuestion: 'Copy to new question',
  createQuestionSuccess: 'Question created successfully',
  requested: 'Requested',
  viewingOthersEvaluations: "You are viewing another user's feedback as administrator",

  youHaveNPendingFeedbackRequests: (n: number) =>
    n === 1 ? 'You have one pending feedback request' : `You have ${n} pending feedback requests`,
  latestScoreByPerson: 'Latest score by person',
  withinSixMonths: 'Within six months',
  existingWorkspaceJoinInstruction:
    "Does your company have a subscription already? Make sure you are invited to join your organisation's workspace by contacting the admin user.",
  whistleblowerIncluded:
    'Each product includes a Whistleblower function that complies with EU legislation',
  account: 'Account',
  personalFeedback: 'Personal feedback',
  snoozeNotification: 'Snooze for a week',
  muteNotification: 'Mute this request',
  notificationSnoozed: 'You have snoozed notifications about this request for a week.',
  notificationMuted: 'You have muted further notifications about this request.',
  addedToWorkspaceTitle: (workspaceName: string) => `You have been added to ${workspaceName}`,
  addedToWorkspaceBody: (workspaceName: string) => [
    `:white_check_mark: You were added to a Teamspective workspace, ${workspaceName}.`,
    "We just thought you should know, and you don't need to do anything about it right now.",
  ],
  feedbackRequestTitle: (requesterName: string) =>
    `You have a new feedback request from ${requesterName}`,
  feedbackRequestBody: (requesterName: string, personalMessage: string | null) => [
    `*You have a new feedback request from ${requesterName}*.`,
    personalMessage
      ? `:speech_balloon: There was a personal message attached: _${personalMessage}_`
      : '',
    ':dart: We will help you plan, write and share your feedback',
  ],
  giveFeedback: 'Give feedback',
  youHaveFeedbackTitle: (providerName: string) => `You have new feedback from ${providerName}`,
  youHaveFeedbackBody: (providerName: string) =>
    `Good news! You have new feedback from ${providerName}.`,
  youHaveAnonymousFeedbackTitle: 'You have new feedback',
  youHaveAnonymousFeedbackBody: 'Good news! You have new feedback.',
  youHavePraiseTitle: (providerName: string) => `You have been praised by ${providerName}!`,
  youHavePraiseBody: (providerName: string) =>
    `Good news! You have been praised by ${providerName}.`,
  seePraise: 'See praise',
  requestCommentReceivedTitle: (commentAuthor: string) =>
    `${commentAuthor} just added a new comment to your earlier feedback discussion`,
  seeDiscussionAndReply: 'See discussion & reply',
  anonymousChatMessageReceivedTitle: (workspaceName: string) =>
    `Your workspace ${workspaceName} has received feedback`,
  anonymousChatMessageReceivedBody: (
    workspaceName: string,
    channelName: string,
    providerName: string | null
  ) =>
    `${workspaceName} has new management feedback in channel ${channelName}${
      providerName ? ` from ${providerName}` : ''
    }.`,
  anonymousChatReminderTitle: (workspaceName: string) =>
    `Reminder: Your workspace ${workspaceName} has received feedback`,
  anonymousChatReminderBody: (workspaceName: string) =>
    `Your workspace ${workspaceName} has received feedback in Teamspective, but no one has responded yet. Please remember to review and acknowledge the feedback by replying.`,
  replyToFeedback: 'Reply to feedback',
  workspaceFeedbackCommentReceivedTitle: (workspaceName: string, isFeedbackProvider: boolean) =>
    `${isFeedbackProvider ? 'Your feedback' : 'Feedback'} for ${workspaceName} has a new comment.`,
  seeDiscussion: 'See discussion',
  feedbackReminderTitle: (requesterName: string) =>
    `You have a pending feedback request from ${requesterName}.`,
  feedbackReminderHint: (requesterName: string) =>
    `If you're still thinking what to say, let ${requesterName} know you'll reply soon and haven't forgotten. You will also get a lot of guidance in Teamspective while writing your feedback – so go and take a look!`,
  pulseReminderTitle: 'Pulse reminder',
  pulseGreeting: 'Hi!',
  pulseReminderBody: (teamName: string, closingTime: string) => [
    `:heartbeat: Your input is needed to get your team’s (${teamName}) pulse results ready.`,
    `:bell: This pulse closes on ${closingTime}`,
    ':thinking_face: So take a few moments to reflect this week, and to let your team know how you’re doing, what’s good and what worries you.',
  ],
  evaluationRequestWithPendingBody:
    'Note that you still have a previous feedback request that you have not answered. If that is no longer relevant, you may skip the feedback request to start the next one.',
  answer: 'Answer',
  evaluationExpires: (expires: string) => `The due date for the evaluation is ${expires}`,
  evaluationReminderTitle: 'Your feedback is needed',
  evaluationReminderBody: (pendingEvaluationsHeading: string, expires: string) =>
    `${pendingEvaluationsHeading}! The due date is ${expires}.`,
  evaluationFinalReminderTitle: 'Last chance to answer – your feedback is needed',
  evaluationFinalReminderBody: (pendingEvaluationsHeading: string) =>
    `${pendingEvaluationsHeading}! Answer time ends in one hour.`,
  evaluationExtendedTitle: 'Extra time to answer',
  evaluationExtendedBody: (formattedDeadline: string, variant: 'plain' | 'emoji') =>
    `You missed the first deadline, but you got extra time – answer by ${formattedDeadline}.${variant === 'emoji' ? ' :ring_buoy:' : ''}`,
  evaluationExtendedLastChanceTitle: 'Last chance to answer – your feedback is needed',
  evaluationExtendedLastChanceBody: (timeLeftHours: number, variant: 'plain' | 'emoji') =>
    `${variant === 'emoji' ? ':bell: ' : ''}Only ${timeLeftHours} hour${timeLeftHours === 1 ? '' : 's'} to the final deadline! Take a moment to share your opinions.${variant === 'emoji' ? ' :pray:' : ''}`,
  pulseFinalReminderTitle: 'Last chance to answer – the pulse closes in 1 hour',
  pulseFinalReminderBody: (closingTime: string) =>
    `Last chance to answer the pulse! It closes in one hour, ${closingTime}.`,
  pulseResultsReadyTitle: 'Pulse results are ready',
  pulseResultsReadyBody: (teamName: string, answerCount: number) =>
    `Your workspace's (${teamName}) pulse results are ready. You got ${answerCount} answers in this pulse survey.`,
  pulseResultsReadyComments: (commentCount: number) =>
    `${commentCount} new comment${commentCount > 1 ? 's are' : ' is'} visible to you`,
  pulseResultsReadyHint: 'We warmly recommend you to discuss the results when appropriate.',
  networkSurveyReminderTitle: 'Network survey reminder',
  hey: 'Hey',
  networkSurveyReminderBody: "Please remember to reply to your company's network questionnaire.",
  networkSurveyReminderMentions: (mentionCount: number) =>
    `You have been mentioned ${mentionCount} times by your colleagues already, but we’re still missing your connections.`,
  networkSurveyReminderNudge:
    'The questionnaire will be closing soon, but we’re still missing your connections.',
  invitationAndTeamTitle: 'Welcome to Teamspective',
  invitationAndTeamGreeting: 'Hello from Teamspective!',
  invitationAndTeamBody: (
    teamName: string,
    tosLink: (text: string) => string,
    privacyPolicyLink: (text: string) => string
  ) =>
    `Your Slack account is now linked to a workspace ${teamName}. By using Teamspective, you agree to comply with the ${tosLink(
      'Terms of Service'
    )} and ${privacyPolicyLink('Privacy Policy')}.`,
  invitationAndTeamBenefits:
    'Teamspective helps you participate in creating a thriving workplace in 3 ways:',
  invitationAndTeamBenefitsPulse:
    'Pulse surveys: Share your ideas and experiences safely and easily to tackle issues in teamwork and leadership',
  invitationAndTeamBenefitsFeedback:
    'Personal feedback: Have meaningful conversations with everyone you work with, get more guidance and recognition by actively asking for feedback',
  invitationAndTeamBenefitsNetwork:
    'Network analysis: Help improve how your company works by reflecting on your collaboration and interactions with colleagues',
  invitationAndTeamEnding:
    'You can use Teamspective right here in Slack but you can also access our web app. Have a great day, and stay tuned for more!',
  pulseCommentReactionTitle: 'People are reacting to your pulse comment',
  pulseCommentReactionBody: (reactionCount: number, reactionsInMultipleComments: boolean) =>
    `${reactionCount > 1 ? 'There are new reactions' : 'There is a new reaction'} to your comment${
      reactionsInMultipleComments ? 's' : ''
    }`,
  seeCommentsAndReactions: 'See comments and reactions',
  pulseCommentReplyTitle: 'New reply to your comment',
  pulseCommentReplyBody: 'Your survey comment has a new reply.',
  pulseCommentReplierWrote: (replierName: string | null) =>
    `${replierName ?? 'A colleague'} wrote:`,
  updatesInYourWorkspaceTitle: 'Updates in your workspace',
  updatesInYourWorkspaceIntro: (workspaceName: string) =>
    `Hi! Here's a summary of recent automated user changes. You are receiving them because you are an admin user of workspace ${workspaceName}.`,
  updatesInYourWorkspaceAddedUsers: 'New users invited to Teamspective and your workspace',
  updatesInYourWorkspaceAddedUsersNote:
    "These users don't belong to any team yet. You might want to add them!",
  updatesInYourWorkspaceInvitedUsers: 'Users invited to your workspace',
  updatesInYourWorkspaceInvitedUsersNote:
    "These users were found from Teamspective but weren't part of your workspace. They have been added to it. You might want to add them to the correct teams.",
  updatesInYourWorkspaceDeactivatedUsers: 'Users deactivated',
  updatesInYourWorkspaceDeactivatedUsersNote:
    'These users were no longer active according to imported data and were also deactivated from your Teamspective workspace.',
  feedbackSharedTitle: 'Some feedback was shared with you',
  feedbackSharedBody: (sharerName: string) => `${sharerName} shared some feedback with you.`,
  selfReflectionSharedTitle: 'Some self-reflection was shared with you',
  selfReflectionSharedBody: (sharerName: string) =>
    `${sharerName} shared their self-reflection with you.`,
  seeSelfReflection: 'See self-reflection',
  workspaceActivationTitle: 'Workspace feedback activations completed',
  workspaceActivationBody: (workspaceName: string) =>
    `Hi, we just sent the following feedback activations in your workspace ${workspaceName}:`,
  workspaceActivationListConnections: (activatedListConnections: number) =>
    `List connections: ${activatedListConnections} people`,
  workspaceActivationRequests: (activatedRequests: number) =>
    `Request feedback: ${activatedRequests} people`,
  workspaceActivationPraise: (activatedPraise: number) => `Send praise: ${activatedPraise} people`,
  evaluationsPublishedTitle: 'You have new feedback',
  seeEvaluations: 'See feedback',
  evaluationsPublishedHint:
    'Take a moment to review them and to learn about new growth opportunities. It’s also advised to review the feedback with a trusted colleague, manager or coach.',
  evaluationsReviewableTitle: 'You have new feedback to review',
  evaluationsReviewableBody: (evalueeName: string) =>
    `You have new feedback to review for ${evalueeName}`,
  review: 'Review',
  evaluationsReviewableBodyMore: (moreCount: number) => `...and ${moreCount} more.`,
  plusMore: (moreCount: number) => `+ ${moreCount} more`,
  evaluationsReviewableHint:
    'Before publishing the results, please wait until all feedback has been gathered. This ensures the quality and anonymity of results published to the user.',
  selectYourProducts: 'Select your products',
  selectProductRecommendation: 'We recommend starting with Pulse Surveys, but you know best',
  costPerUser: 'Cost per user',
  annualBillingWithDiscount: 'Annual payment, 15% off',
  monthlyBilling: 'Monthly payment',
  freeOptionDescription:
    '14-day free trial for companies of any size. Free for companies under 30 employees.',
  onboardingTabProfile: 'Profile',
  onboardingTabProducts: 'Products',
  onboardingTabWorkspace: 'Workspace',
  openFeatureList: 'Open feature list',
  closeFeatureList: 'Close feature list',
  securityAndCompliance: 'Security and compliance',
  includedServices: 'Included services',
  slack: 'Slack',
  googleCalendar: 'Google Calendar',
  msTeams: 'Microsoft Teams',
  email: 'Email',
  hrisIntegrationsAndCohorts:
    'HRIS integration and user cohorts (e.g. Personio, Hibob, Bamboo, Workday, and many others)',
  gdprCcpaCompliant: 'Fully compliant with GDPR and CCPA',
  dedicatedAccountManager: 'Dedicated Account Manager',
  leaderChatSupport: 'Chat support for all leaders',
  webinars: 'Webinars',
  workspaceReadyStartExploring:
    'Teamspective helps you enhance organizational health, team productivity, and wellbeing with actionable people analytics',
  featuredInSlackAppDirectory: 'Featured in Slack App Directory',
  importUsersAutomatically: 'Import users automatically',
  higherResponseRates: 'Higher response rates',
  zeroTimeNotificationAction: 'In zero time from survey notification to action',
  continueWithoutConnecting: 'Continue without connecting',
  bookAProductKickOff: 'Book a product kick-off',
  productKickOffDescription:
    'Starting with 360 feedback or Organizational Network Analysis requires a FREE 45 min setup meeting with us. Pick a day from the calendar and continue.',
  profileCreationDescription: 'Get started in just a few minutes',
  selectYourWorkspace: 'Select your workspace',
  otherCommercialOptions: 'Looking for other commercial options? Contact us.',
  whistleBlowerIncluded: 'whistleblower channel solution included in all products',
  free: 'Free',
  selectAtLeastOneProduct:
    'Select at least one product to continue. If you want more information about our products, please contact sales.',
  onlyFewUsersAdded: 'Only a few users added',
  fewUsersDescription:
    'At least three users per workspace are required to ensure anonymity of the results. The low number of users will limit the user experience considerably.',
  companySize: 'Company size',
  yourFeedbackRequestWasSentTo: (receiverName: string) =>
    `Your feedback request was sent to ${receiverName}!`,
  somethingWentWrongRequestNotSent:
    'Something went wrong and your request was not sent :disappointed:',
  heresYourMessage: "Here's your message in case you need it:",
  requestFeedback: 'Request feedback',
  whoDoYouWantFeedbackFrom: 'Who do you want to get feedback from?',
  selectPerson: 'Select person',
  addCustomMessage: 'Add a custom message',
  whoCanSeeThisFeedback: 'Who can see this feedback?',
  close: 'Close',
  whoDoYouWantFeedbackTo: 'Who do you want to give feedback to?',
  selectedUserNotInTeamspective: 'Selected user could not be found from Teamspective.',
  selectedUserNotInWorkspace: 'Selected user could not be found in your Teamspective workspace.',
  somethingWentWrongDraftNotCreated:
    'Something went wrong and draft for writing feedback could not be created :disappointed:',
  timeToReplyTooSoon: 'Time to reply must be at least one day from now.',
  yourEvaluationRequestWasSentTo: (receiverName: string) =>
    `Your evaluation request was sent to ${receiverName}!`,
  whoDoYouWantEvaluationFrom: 'Who do you want feedback from?',
  selectEvaluationType: 'Select type',
  selectDateTimeToReply: 'Select time to reply',
  askForEvaluation: 'Ask for 360 feedback',
  sendToPublicSlackChannelLabel: 'Also send praise to public Slack channel',
  writeYourPraise: 'Write your praise',
  praiseHint:
    'Say thanks, show appreciation, and give recognition for great work. Describe a situation where excellence was demonstrated. What did you observe? How did you feel at that moment? Was there some need (team’s or yours) that was met with this behaviour? Now is a great time to show your appreciation.',
  feedbackSentRecipientsNotified:
    'Your feedback has been sent and the recipients have been notified.',
  sendAnonymousChat: 'Send anonymous chat message',
  oneTopicNeedsToBeAnswered: 'At least one feedback topic needs to be answered.',
  feedbackSentTo: (receiverName: string) => `Your feedback to ${receiverName} has been sent!`,
  provideFeedback: 'Provide feedback',
  requestFeedbackFromHomeTab: 'You can request feedback from the home tab of the application.',
  feedbackRequestSentAt: (requestCreated: string) => `Feedback request sent ${requestCreated}`,
  visibility: 'visibility',
  visibleToYouAndRecipient: 'Visible to you and recipient.',
  allEvaluationsCompleted: 'All done',
  evaluationCompleteFor: (evalueeName: string) => `Feedback complete for ${evalueeName}`,
  evaluationRequestExpiredOn: (expiredAt: string) =>
    `This feedback request has expired on ${expiredAt}`,
  evaluationRequestExpiredFor: (evalueeName: string) =>
    `The feedback request for ${evalueeName} has expired`,
  evaluationRequestSkipped: 'Feedback request skipped',
  evaluating: (evalueeName: string) => `Feedback for ${evalueeName}`,
  evaluatingStarted: (evalueeName: string) => `Feedback to ${evalueeName} started`,
  selfEvaluationStarted: 'Self-reflection started',
  evaluationSummary: 'Summary of feedback',
  reviewAndSendEvaluation: 'Review and send your feedback',
  sendEvaluation: 'Send feedback',
  evaluationRequested: 'Feedback requested',
  evaluationIn: (teamName: string) => `Feedback in ${teamName}.`,
  expiresAt: (expiresAt: string) => `Expires ${expiresAt}.`,
  onlyPendingEvaluation: 'This is your only pending feedback request.',
  pendingEvaluationsCount: (pendingCount: number) =>
    `You have ${pendingCount} pending feedback requests.`,
  feedbackActivation_done:
    'Thanks! We’ll give you better feedback suggestions based on this information. :dart:',
  survey_done:
    ':white_check_mark: Your answers have been saved. Thanks for taking part in the network survey!',
  feedbackActivation_prompt:
    ':handshake: Tell us who your most relevant colleagues are, so we can give you and them more relevant recommendations in the future.',
  survey_prompt:
    'Your company is studying its social network! Please take a brief moment to name your connections. By combining answers we can map your company’s information flows, silos and bottlenecks.\n\nYour input is highly valuable for the quality of the analysis – please respond at your earliest convenience!',
  mutedForMonth: "Got it. We won't ask this for a month. Have a nice day!",
  selectPeople: 'Select people...',
  fillBasedOnRecentMonths: 'Please think about the most recent months, and fill in these fields.',
  muteForMonth: 'Mute for a month',
  praiseRewardMessage0: `:raised_hands: Great job helping your co-worker get recognition for their work and efforts. Everyone needs some recognition to stay motivated and engaged.

A habit of giving positive reinforcement is a powerful thing for your team, so remember to do it again soon!`,
  praiseRewardMessage1:
    ':sunglasses: You rock! The world needs people like you who don’t hold back from saying thanks and giving others recognition. Did we already say you rock? Because you do.',
  praiseActivationMessage0: `:wave: Hi! I occasionally remind you to give others recognition for their great work and make them feel appreciated.

So who’s that special someone and what did they do?`,
  praiseActivationMessage1: `Do you like to be liked? Sure you do, everyone does!

Try saying thanks to someone for helping you out, or to point out someone who’s done an outstanding job.`,
  praiseActivationMessage2:
    'Here’s a chance to make your and someone else’s day just a bit better.',
  praiseActivationMessage3: `Do you know what’s best about praise? It’s that it feels good for all parties involved.

Here’s a chance to make your and someone else’s day just a bit better.`,
  praiseActivationMessage4: `Hi! Has someone recently helped you with your work?

It would be great for them to hear you say it, and here’s your chance!`,
  praiseActivationMessage5:
    ':wave: Hi! Has someone recently done a great job worth some recognition?',
  praiseActivationMessage6: `:wave: Hi! Has someone recently done a great job worth some recognition?

It would be great for them to hear you say it, and here’s your chance!`,
  praiseActivationMessage7:
    'Try saying thanks to someone for helping you out, or to point out someone who’s done an outstanding job. :star-struck:',
  praiseActivationMessage8:
    ':bulb: Getting feedback and recognition makes work more enjoyable and motivating. I’m sending you this message to remind that also you can contribute to this at your workplace.',
  praiseActivationMessage9:
    ':sunny: Here’s a chance to make your and someone else’s day just a bit better',
  quickSuggestions: 'Quick suggestions',
  praiseFor: (name: string) => `Praise for ${name}`,
  startWriting: 'Start writing',
  askFeedbackFrom: (name: string) => `Ask from ${name}`,
  feedbackActivationMessage: (
    currentCount: number
  ) => `:speech_balloon: *Hey, it's time to ask for feedback*

Quality feedback from colleagues, customers or manager leads to better performance, smoother collaboration, and healthier relationships.

How much feedback is enough? We’ve seen that getting feedback from 3 people every 3 months is enough to sustain a healthy feedback culture.

In the last three months you have ${
    currentCount === 0
      ? 'not asked for any feedback'
      : currentCount === 1
        ? 'asked for feedback once'
        : currentCount === 2
          ? 'asked for feedback two times'
          : `asked for feedback ${currentCount} times`
  }.`,
  wasSummaryUseful: 'Did you find the summary useful?',
  wasGuidanceUseful: 'Did you find the guidance useful?',
  pulseSummaryIntro: (messagingPlatformName: string) =>
    `We have created a quick summary of the pulse results for your teams. Click on a team name below to view it in ${messagingPlatformName}.`,
  createAccountBeforeLogin: 'You must create an account before trying to log in',
  loginCommandHelp: 'You can get login link to Teamspective with this command.',
  loginLinkText: (link: string) =>
    `Hi! Here's your login link: *<${link}|:point_right: Login to Teamspective>*. It is valid for one hour. Have a nice day!`,
  loginLinkIntro:
    "Hi! Here's your login link to Teamspective. It is valid for one hour. Have a nice day!",
  loginLinkLabel: 'Login to Teamspective',
  yourLoginLink: 'Your login link',
  createAccountBeforePraise: 'You must create an account before trying to praise',
  praiseHelp: `You may send public praise to a colleague with this command.

Please use this command like this:
\`/praise <recipient(s)> <message>\`

For example:
\`/praise @ron You rock!\`

Note that Slack mentions will be filtered out from the message, so use plain names in the praise message.`,
  noPraiseChannelSet:
    "Your team has no praise channel set! Please choose a channel in your team's settings.",
  noPraisedUsers: "You didn't name any users.",
  cannotPraiseSelfWithOthers:
    'You cannot include yourself in the praise recipients when also praising other people.',
  praiseInvalidUsers: (userNames: string, message: string) =>
    `Sorry, your praise couldn't be sent because ${userNames} has no Teamspective account or does not belong to your Teamspective workspace. Here's your message in case you need it: \n"${message}"`,
  thanksPraiseSent: 'Thanks! Your praise has been sent.',
  thanksPraiseSentToChannel: (channel: string) =>
    `Thanks! Your praise has been sent and shared to #${channel}.`,
  selfPraiseMessages: [
    "You're looking great today!",
    'Way to go!',
    'Good job!',
    'I believe in you!',
    'Keep up the good work!',
    'Be proud of who you are!',
    'Praise you!',
  ],
  developmentFramework: 'Development framework',
  viewFramework: 'View framework',
  editAnswers: 'Edit answers',
  pleaseReviewAndSendEvaluation: 'Please review and send your feedback',
  networkSurveyEnded: 'This network survey has ended.',
  answerRequired: 'This answer is required',
  selectPraiseRecipients: (justOne: boolean) => `Select praise recipient${justOne ? '' : '(s)'}`,
  selectAtLeastOneRecipient: 'Select at least one recipient',
  pleaseWriteSomething: 'Please write something',
  sharePraiseInMsTeamsChannel: (channelName: string) =>
    `Also share praise in MS Teams channel (${channelName})`,
  surveyHasClosedResults: (surveyName: string) =>
    `${surveyName} has closed and the survey results are ready!`,
  seeTheResultsMarkdown: (link: string) => `Start going through [your team's results](${link}).`,
  here: 'here',
  selectDateToReply: 'Select date to reply',
  selectTimeToReply: 'Select time to reply',
  requiredAndInFuture: 'Required and must be in future',
  required: 'Required',
  msTeamsIntro: (landingPageLink: (label: string) => string) =>
    `Hi! This is the ${landingPageLink(
      'Teamspective'
    )} bot. I'll keep you updated on your Teamspective notifications and enable responding to pulse questionnaires right here in Microsoft Teams.`,
  msTeamsSupportedCommands: 'Supported commands that I respond to are',
  quotes: [
    'Like any other skill, feedback gets easier the more you do it.',
    "When did you last ask a teammate how they're doing?",
    'Lift people up and they will elevate you too.',
  ],
  requestOrWriteFeedback: 'Request or write feedback',
  msTeamsInvalidCommand: 'Sorry, I did not get that. My supported commands are',
  msTeamsInvalidCommandHelp: (landingPageLink: (label: string) => string) =>
    `For more information about Teamspective, use the _help_ command or visit ${landingPageLink(
      'the Teamspective website'
    )}.`,
  goodNews: 'Good news!',
  msTeamsAccountLinked: (email: string) =>
    `Your Teamspective account (_${email}_) is now linked to your Microsoft Teams account. You will receive notifications and can answer pulse questionnaires right here from now on. Type the command _help_ for more available commands and info.`,
  ownGroups: 'Own groups',
  otherGroups: 'Other groups',
  consecutiveAnswers: 'Consecutive answers',
  answeringTimeSec: 'Answering time (sec)',
  resultsOut: 'Results out',
  reopenEvaluation: 'Reopen feedback request',
  reopenEvaluationInstruction:
    'The feedback request will be resent to the feedback provider who can review and update their answers.',
  userInstruction: 'User instruction',
  adminInstruction: 'Admin instruction',
  addNewChannel: 'Add new channel',
  manageChannels: 'Manage channels',
  anonymousChatRecipientsForChannel: (channelName: string) =>
    `Anonymous chat message recipients for ${channelName} channel`,
  messageForUser: 'Message for user',
  messageForRecipient: 'Message for recipient',
  thankYouForFeedback: 'Thank you for your input',
  anonymousChatChannels: 'Anonymous chat channels',
  inactive: 'Inactive',
  channelCreated: 'Channel created',
  channelUpdated: 'Channel updated',
  addAnonymousChatChannel: 'Add anonymous chat channel',
  guidanceToUser: 'Guidance to user',
  guidanceToFeedbackRecipient: 'Guidance to feedback recipient',
  anonymousChatActivated: 'Anonymous chat activated',
  activateAnonymousChat: 'Activate Anonymous chat',
  activateAnonymousChatDescription:
    "Anonymous chat channels enable anonymous communication from employees to your company's management. Activate the whistleblower channel according to the EU whistleblowing directive and create other customised channels in the Anonymous chat settings.",
  anonymousChatChannelManagementDescription:
    'You can manage the channels available for anonymous chat here. Channels without recipients are deactivated and are not visible to users in your workspace.',
  noActiveAnonymousChatChannels: 'No anonymous chat channels are active.',
  deleteEvaluationRequestsTitle: 'Delete feedback requests?',
  deleteEvaluationRequestsDescription:
    'Users will no longer be able to answer. Possible answers to questions are deleted.',
  evaluationRequestRescheduled: 'Feedback request rescheduled',
  evaluationRequestDeleted: 'Feedback request deleted',
  evaluationRequestNotAvailable: 'Feedback request not available',
  evaluationRequestNotAvailableDescription:
    'This feedback request is no longer available. It may have been deleted',
  passiveDataSources: 'Passive data sources',
  extendNetworkSurveyRound: 'Extend network survey round',
  increaseAnsweringTime: 'Extend time to answer',
  team: 'Team',
  admin: 'Admin',
  me: 'Me',
  sortBy: 'Sort by',
  userSettings: 'User settings',
  myActivity: 'My activity',
  takeActionYouHaveNPendingTasks: (n: number) =>
    n !== 1
      ? `Take action! You have ${n} pending tasks.`
      : 'Take action! You have one pending task.',
  activity: 'Activity',
  person: 'Person',
  details: 'Details',
  activityType_writeFeedback: 'Write feedback',
  activityType_evaluate: 'Write feedback',
  activityType_receivedFeedback: 'Received feedback',
  activityType_receivedEvaluation: 'Received feedback',
  activityType_receivedFeedbackComment: 'New feedback comment',
  activityType_waitingForFeedback: 'Waiting for feedback',
  activityType_waitingForEvaluation: 'Waiting for 360 feedback',
  openList: 'Open list',
  closeList: 'Close list',
  evaluationChart: 'Grid',
  evaluationTable: 'Table',
  myEvaluations: 'My 360 Feedback',
  feedback360: '360 Feedback',
  customizeSurveys: 'Customize surveys',
  channelName: 'Channel name',
  slackChannelName: 'Slack channel name',
  link: 'Link',
  customGuidance: 'Custom guidance',
  addCustomGuidance: 'Add custom guidance',
  pulseCustomGuidanceDescription:
    'Show guidance to user based on their answers. Select the question, guidance text and answer choices to trigger on. The text will be shown in the answer summary.',
  addNewGuidance: 'Add new guidance',
  discoverYourNetwork: 'Discover your network',
  organizeBy: 'Organize by',
  observe: 'Observe',
  clusters: 'Clusters',
  engagement: 'Engagement',
  quartile_top: 'Highest 25%',
  quartile_topMid: 'Upper mid 25%',
  quartile_bottomMid: 'Lower mid 25%',
  quartile_bottom: 'Lowest 25%',
  timeline: 'Timeline',
  typeToSearchNames: 'Type to search names',
  remindersSent: 'Reminders sent',
  networkSurveyReminderAction: 'Send reminder',
  networkSurveyReminderChannelSelect: 'Select notification channel',
  networkSurveyReminderInfo:
    "The reminder will only be sent to people who haven't responded to the survey.",
  impact: 'Impact',
  answersSoFar: 'answers so far',
  refer: 'Refer & Earn',
  aiDelayNote:
    'Note that AI results may not appear immediately. It takes a while to process data when AI analysis is enabled.',
  activateAiSentimentAnalysis: 'Activate AI sentiment analysis',
  sentimentAnalysisDescription:
    'This feature automatically analyses all your comments so that you can easily notice development ideas and what topics gain most attention.',
  activateAiFeedbackAnalysis: 'Activate AI Feedback analysis',
  aiFeedbackAnalysisDescription:
    "This feature analyzes the person's feedback and presents the strengths and development areas found in them, and lists what should be started, stopped and continued at work. The findings are shown to the feedback recipient and the administrators of the workspace.",
  allowDiscussionsInSlack: 'Allow discussions in Slack',
  discussionsInSlackDescription:
    "Enable Discussions on your team's Slack workspace. Participants can then join the conversation seamlessly in their own channel in Slack or the Teamspective Web App.",
  openPulseSettings: 'Open pulse settings',
  open360FeedbackSettings: 'Open 360 Feedback settings',
  openWorkspaceIntegrationSettings: 'Open Workspace Integration settings',
  discussions: 'Discussions',
  myDiscussions: 'My discussions',
  addDiscussion: 'Add discussion',
  editDiscussion: 'Edit discussion',
  discussionStartedBy: 'Discussion started by',
  limitedVisibility: 'Limited visibility',
  discussionsLimitedVisibilityDescription:
    'Visible to all participants and hosts in this discussion.\nManagers of any participant can see this discussion',
  addNote: 'Add note',
  discussionName: 'Discussion name',
  writeName: 'Write name',
  host: 'Host',
  discussionParticipantNote:
    'As you are creating a discussion for others, you must have permissions to manage each participant. You will only see these people in the participant list.',
  noteSyncedToSlackChannel:
    'This note is synced to the linked Slack channel. Comments will be visible in both locations.',
  slackBotRemovedFromChannel:
    'Teamspective bot removed from channel. Please add it back to continue syncing notes.',
  add: 'Add',
  leaveAComment: 'Leave a comment…',
  selectDiscussionTemplate: 'Select template',
  discussionTemplates: 'Discussion templates',
  addTemplate: 'Add template',
  editDiscussionTemplate: 'Edit discussion template',
  deleteDiscussionTemplate: 'Delete discussion template',
  discussionsFilter_participating: 'Participating',
  discussionsFilter_hosting: 'Hosting',
  discussionsFilter_all: 'All visible',
  discussionsFilter_managedMissing: 'People without discussion',
  content: 'Content',
  nTranslations: (n: number) => `${n} translation${n === 1 ? '' : 's'}`,
  editEvaluationTemplate: 'Edit feedback template',
  editEvaluationTemplatesAndQuestionsAt: 'Edit feedback templates and questions at',
  addNewTemplate: 'Add new template',
  startNewEvaluations: 'Create new requests',
  discardEvaluationDraft: 'Discard draft',
  discardEvaluationDraftConfirm: 'Are you sure you want to delete the draft?',
  selectEvaluees: 'Collect feedback for',
  preselectTemplates: 'Preselect templates',
  heading: 'Heading',
  bold: 'Bold',
  italic: 'Italic',
  underline: 'Underline',
  strikethrough: 'Strikethrough',
  bulletedList: 'Bulleted list',
  numberedList: 'Numbered list',
  checkList: 'Check list',
  sensitivity: 'Sensitivity',
  dataSource: 'Data source',
  doubleClickToExpand: 'Double click to expand',
  nExpandedGroups: (n: number) => `${n} expanded group${n === 1 ? '' : 's'}`,
  'evaluationAnswerAnalysisFramework_start-stop-continue': 'Start Stop Continue',
  'evaluationAnswerAnalysisFramework_strengths-development-areas':
    'Strengths and development areas',
  evaluationAnswerAnalysisType_start: 'Start',
  evaluationAnswerAnalysisType_stop: 'Stop',
  evaluationAnswerAnalysisType_continue: 'Continue',
  evaluationAnswerAnalysisType_strength: 'Strengths',
  evaluationAnswerAnalysisType_developmentArea: 'Development areas',
  mentionedNTimes: (n: number) => (n === 1 ? 'Mentioned once' : `Mentioned ${n} times`),
  plusNOtherAnswers: (n: number) => (n === 1 ? `+${n} other answer` : `+${n} other answers`),
  groupIsFromIntegrationDescription: (integrationName: string | null) =>
    `Group is imported from a 3rd party system${integrationName ? ` ${integrationName}` : ''}.`,
  feedbackSettings: 'Personal Feedback settings',
  evaluationSettings: '360 Feedback settings',
  multipleGroups: 'Multiple groups',
  cohorts: 'Cohorts',
  commentsSummary: 'Comments summary',
  noSuggestions: 'No suggestions',
  manageFeedbackDescription: (scope: string) =>
    `May access unpublished or published feedback and non-private feedback ${scope}. May publish unpublished feedback, reopen expired feedback requests, or delete pending feedback requests. May create discussions.`,
  shortSummaryAvailableOnlyForLatestResults: 'Short summary available only for latest results.',
  addInsightsFrom360Feedback: 'Add insights from 360 Feedback',
  collectingInsights: 'Collecting insights…',
  canTakeUpTo60Seconds: 'Can take up to 60 seconds',
  anonymous: 'Anonymous',
  evaluationComments: 'Written feedback',
  workspaceAverage: 'Workspace average',
  answerChoiceIcons: 'Answer choice icons',
  answerChoiceIconsUpdated: 'Answer choice icons updated',
  settingAffectsSlackAndMsTeamsOnly:
    'This setting affects Teamspective for Slack and Microsoft Teams only.',
  editable: 'Editable',
  discussionNoteEditable: (days: number) =>
    `This note can be edited ${days} days after it was created.`,
  discussionCommentEditable: (days: number) =>
    `This comment can be edited ${days} days after it was created.`,
  discussionUpdated: (discussionTitle: string) => `Discussion ${discussionTitle} updated`,
  discussionUpdatedMore: (moreCount: number) => `...and ${moreCount} more changes.`,
  discussionAction_creator_discussion_created: (creator: string, discussionTitle: string) =>
    `${creator} created a discussion ${discussionTitle}`,
  discussionAction_discussion_created: (discussionTitle: string) =>
    `Discussion ${discussionTitle} created`,
  discussionAction_note_created: 'Note created to discussion',
  discussionAction_author_note_created: (author: string) =>
    `${author} created a note to discussion`,
  discussionAction_note_updated: 'Note updated in discussion',
  discussionAction_author_comment_created: (author: string) =>
    `${author} created a comment in discussion`,
  discussionAction_comment_created: 'Comment created in discussion',
  emailFallbackUrlDescription:
    'If you’re having trouble with the buttons above, copy and paste some URL below into your web browser.',
  recalculateGraph: 'Recalculate graph',
  dynamic: 'Dynamic',
  always: 'Always',
  showConnections: 'Show connections',
  betweenSelected: 'Between selected',
  allConnections: 'All connections',
  secondDegreeConnections: '2nd degree connections',
  selectGroupMembers: 'Select group members',
  addQuestions: 'Add questions',
  startNew: 'Start new',
  connectToSlack: 'Connect to Slack',
  discussionsConnectToSlackDescription:
    "Enable Discussions on your team's Slack workspace and name the channel.",
  channelAlreadyExists: 'A channel with that name already exists',
  slackChannelCreatedWithName: 'Slack channel created with a name',
  discussionsSlackSync: 'Discussions Slack sync',
  defaultTemplates: 'Default templates',
  continue: 'Continue',
  expires: 'Expires',
  expired: 'Expired',
  completed: 'Completed',
  lastNotifiedAt: (date: string, channel: string) => `Last reminder sent on ${date} via ${channel}`,
  extendedNotifiedAt: (date: string, channel: string) =>
    `Response time extended. Last reminder sent on ${date} via ${channel}`,
  notificationNotYetSent: 'Notification not yet sent',
  evaluationReminderDaysBefore: `Reminders are sent to evaluators ${listItemsInNaturalLanguage.en(evaluationRemindersDaysBefore.map(String))} days before the expiration date.`,
  notificationChannel: {
    slack: 'Slack',
    'ms-teams': 'Microsoft Teams',
    email: 'Email',
    optout: 'Not available',
    error: 'Error',
    demo: 'Teamspective demo notifier',
  },
  errorInSendingReminder: 'Error in sending reminder. Support has been notified.',
  userHasDisabledNotifications: 'User has disabled notifications',
  automaticEvaluationRescheduling: 'Automatic feedback request rescheduling',
  extendDeadlineByHours: (hours: number) =>
    hours === 0 ? 'None' : `Extend deadline by ${hours} hours`,
  peopleSelected: (n: number) => `${n} people selected`,
  evaluationRescheculeInfo:
    'Add automatic extension for expired feedback requests. Notification of the new response time is sent when it starts and a reminder 6 hours before it ends, but only during office hours (from 8 a.m. to 4 p.m).',
  showEvaluatorNamesTo: 'Show feedback provider names to',
  evaluatorNameVisibilityTooltip: `By default the recipients don't see the names of people they're receiving feedback
from.

Everyone (also recipient: Everyone can see the names of the feedback providers including the recipients.
Workspace admin only: Only workspace admins and observers can see the names of evaluators unless they're the recipient.

All permission holders: The people who have been granted Feedback management permission on the "Permissions" tab can see the names of evaluators. Recipients can't see the names.

Never show: Always hide the names of the evaluators from everyone including the "Evaluation requests" tab and reports.`,
  evaluatorNameVisibility_all: 'Everyone (also recipient)',
  evaluatorNameVisibility_admins: 'Workspace admin only',
  evaluatorNameVisibility_finegrained: 'Permission holders',
  evaluatorNameVisibility_never: 'Never show',
  changeEvaluationNameVisibilityWarning: (from: string, to: string) =>
    `You're changing the feedback provider name visibility for all workspace members from "${from}" to "${to}". This has an impact on all feedback, including the published ones. This action will be recorded in the audit log.`,
  addNewTheme: 'Add new theme',
  editTheme: 'Edit theme',
  themeInformation: 'Theme information',
  pinToResultsOverview: 'Pin to results overview',
  themeName: 'Theme name',
  pulseThemeUpdated: 'Pulse theme updated',
  pulseThemeCreated: 'Pulse theme created',
  hours: 'hours',
  days: 'days',
  tasks: 'Tasks',
  newFeedback: 'New feedback',
  yourRequests: 'Your requests',
  noPendingTasks: 'No pending tasks. Enjoy your day!',
  noNewFeedback: 'No new feedback.',
  nothingHere: 'Nothing here.',
  hideArchived: 'Hide archived',
  archiveTemplate: 'Archive template',
  archiveTemplateDescription:
    "Archived templates can't be used for new feedback, but feedback created with them is still accessible in the results.",
  unarchiveTemplate: 'Restore template',
  unarchiveTemplateDescription: 'Restore template from the archive to use it again.',
  singleSignOn: 'Single Sign-On',
  chooseYourSsoProvider: 'Choose your SSO provider',
  usingOtherSsoProvider: 'Using another SSO provider? Contact Teamspective support.',
  noActivity: 'No activity',
  readyToPublish: 'Ready to publish',
  waitingForAnswers: 'Waiting for answers',
  initialize360FeedbackCta:
    'Before sending 360 feedback requests, you need templates. Click below to get started.',
  hidden: 'Hidden',
  kpis: 'KPIs',
  keyMetrics: 'Key metrics',
  lastEdited: 'Last edited',
  lastEditedBy: 'Last edited by',
  notifyOnPublishableFeedback: 'Notify reviewers when new feedback is ready to publish',
  pulseThemeDescription:
    'Themes help you track trends in specific subject areas. A theme combines several questions, and its score is the average of these questions. You can create custom themes and decide which themes different groups will see on their results page.',
  managers: 'Managers',
  managerAccessDescription: `The managing person may:
 • View 360 feedback (published or unpublished)
 • Manage 360 feedback (redact, reopen, delete, publish)
 • View non-private personal feedback
 • View discussions
 • Manage discussions (create, modify or add AI insights)`,
  missingPermissions: 'Missing permissions',
  discussionForOthersPermissionInfo:
    'You need permission to manage a discussion for others. You are not permitted to manage these people:',
  discussionForOthersHowToFix:
    'To change your management permissions, please contact your workspace administrator.',
  defaultVisibilityForPersonalFeedback: 'Default visibility for personal feedback',
  noAnswer: 'No answer',
  feedbackProvideCta:
    'You\'ve been invited to share your feedback! Just click "Answer" to get started.',
  surveyInformation: 'Survey information',
  addNewSurvey: 'Add new survey',
  survey: 'Survey',
  passive: 'Passive',
  startDate: 'Start date',
  endDate: 'End date',
  addQuestionsToSurvey: 'Add questions to survey',
  createQuestionsTip: 'You can create more questions at the Customize surveys page',
  edited: 'Edited',
  numericAnswers: 'Numeric answers',
  writtenAnswers: 'Written answers',
  remindSelectedEvaluators: 'Remind selected evaluators',
  remindSelectedEvaluatorsDescription:
    'Send a reminder to the selected evaluators. Note that users who have disabled notifications will not receive the reminder.',
  connectGoogleCalendar: 'Connect Google Calendar',
  toConnectGoogleCalendar:
    'To connect Google Calendar, you need to add workspace delegated permissions for our service account.',
  requiredSteps: 'Required steps',
  domainWideDelegationInstructions: `1. Go to Google Workspace Admin Console
2. Navigate to Security → API Controls → Domain-wide Delegation
3. Add new API client with these details:`,
  superAdminEmail: 'Email of a Google Workspace super admin',
  connect: 'Connect',
  googleClientId: 'Client ID',
  copy: 'Copy',
  copied: 'Copied',
  emailDoesNotMatchUser:
    "For security reasons, the email must be one of the signed-in user's emails",
  userNotSuperAdmin: 'The user is not a super admin in Google Workspace',
  thisEmailAlreadyAdded: 'This email has already been added',
  questionnaireInfo: 'Questionnaire info',
  deleteThisSurvey: 'Delete this survey',
  kpiExplainer: 'KPIs are the average of the latest Theme scores belonging to that KPI.',
  themeExplainer:
    'Theme scores are the average of the latest results for questions belonging to that Theme, limited to the last 6 months.',
  selectSurvey: 'Select survey',
  surveys: 'Surveys',
  selectFeedbackRecipients: 'Select feedback recipients',
  resultsByQuestion: 'Results by question',
  allFeedbackProviders: 'All feedback providers',
  connectionWeak: 'Weak',
  connectionMedium: 'Medium',
  connectionClose: 'Close',
  personalConnections: 'Personal connections',
  personalConnectionsDescription:
    'The number of personal connections a leader has with their direct reports, peers, and external stakeholders.',
  totalPersonalConnections: 'Total amount of personal connections',
  workspaceMedian: 'Workspace median',
  connectionStrength: 'Connection strength',
  seniorManagement: 'Senior management',
  otherColleagues: 'Other colleagues',
  directReports: 'Direct reports',
  collaborationOfDirectReports: 'Collaboration of direct reports',
  collaborationOfDirectReportsDescription:
    'Measures how well team members collaborate directly with each other, rather than relying on the leader.',
  peerAverage: 'Peer average',
  leaderCentrality: 'Leader centrality',
  leaderCentralityDescription:
    'Indicates how often the leader acts as a key link between team members.',
  connectionStrengthPerDirectReport: 'Connection strength per direct report',
  connectionStrengthPerDirectReportDescription:
    'Shows how strong connections the leader has with direct reports, and how evenly their focus is distributed.',
  leaderName: 'Leader name',
  crossTeamConnections: 'Cross-team connections',
  leaderShareOfConnections: 'Leader share of connections',
  crossTeamConnectionsDescriptions:
    "Measures how much the team's cross-team connections depend on the leader.",
  surveySummary: 'Survey summary',
  themesCovered: (n: number) => `${n} theme${n === 1 ? '' : 's'} covered`,
  notStarted: 'Not started',
  none: 'None',
  employeeInsights: 'Employee insights',
  showExternalPeople: 'Show external people',
  featureComingSoon: 'This feature is coming soon',
  last4Months: 'Last 4 months',
  dataSources: 'Data sources',
  globalConnectors: 'Global connectors',
  globalConnectorsDescription:
    'In high demand, connecting different parts of the organization to each other.',
  localConnectors: 'Local connectors',
  localConnectorsDescription:
    'In high demand, are central connectors within a certain part of the organization.',
  connectorPotential: 'Connector potential',
  connectorPotentialDescription:
    'Low-medium in demand, still well connected with other central individuals in their collaboration cluster.',
  highlight: 'Highlight',
  centralityInCollaborationCluster: 'Centrality in collaboration cluster',
  howToReadDemandCentralityScatterplot_demand:
    'Indicates how much demand a person experiences based on the volume of meetings and inbound messages they receive. A higher score suggests greater demands on their time and attention. Excess demand may increase risk of becoming overloaded or a bottleneck for execution.',
  howToReadDemandCentralityScatterplot_centrality:
    "Measures how central the person is within cluster of closely collaborating individuals. The score is based on the volume of their meetings and messages, weighted by the centrality of the people they interact with. A higher score means they are more central to the cluster's work. A lower score may indicate a less central role, or a role in connecting several clusters to each other.",
  externalUsers: 'External users',
  others: 'Others',
  insights: 'Insights',
  networkPassiveCtaAISummary: 'AI Summary',
  networkPassiveCtaAISummaryBody:
    'Coming soon, you will receive summarized key insights and recommendations based on these Collaboration insights. Stay tuned!',
  hiddenTeam: 'Hidden team',
  workspaceCommentVisibilityAdminsOnlyAllRedacted: (redactionLimit: number) =>
    `The teams this comment was intended for got ${redactionLimit} or less answers. Only workspace admins can see this comment.`,
  heatmapBeta: 'Heatmaps (beta)',
  numberOfResponses: 'Number of responses',
  hierarchy: 'Group hierarchy',
  allSurveys: 'All surveys',
  managerManageUserFeedback: 'Use management permissions from user manager info',
  managerManageUserFeedbackDescription:
    'Management permissions can be given automatically based on manager information from HRIS data or other data sources.',
}

const fi: typeof en = {
  yes: 'Kyllä',
  no: 'Ei',
  ok: 'OK',
  back: 'Palaa',
  next: 'Seuraava',
  cancel: 'Peru',
  settings: 'Asetukset',
  mySettings: 'Omat asetukset',
  submit: 'Lähetä',
  running: 'Käynnissä',
  schedule: 'Aikataulu',
  scheduled: 'Ajastettu',
  automated: 'Automaattinen',
  singleRound: 'Yksittäiskierros',
  singleSurvey: 'Yksittäiskysely',
  singleSurveys: 'Yksittäiskyselyt',
  surveyName: 'Kyselyn nimi',
  surveyNameTip:
    'Voit antaa kyselylle nimen. Se helpottaa kyselyn löytämistä tuloksista ja kyselypohjista.',
  editingSingleRound: 'Muokataan ajastettua yksittäiskierrosta',
  saveSingleRound: 'Tallenna ajastettu yksittäiskierros',
  deleteSingleRound: 'Oletko varma, että haluat poistaa tämän ajastetun kyselyn?',
  pulseSingleRoundScheduled: 'Kysely ajastettu',
  surveyPublished: 'Kysely on julkaistu',
  pulseSingleRoundDeleted: 'Ajastettu kysely poistettu',
  pulseSingleRoundTemplatePlaceholder: 'Menneet yksittäiskierrokset tulevat tänne',
  pulseSingleRoundTemplateTip: 'Käytä jotain aiempaa kyselyä lähtökohtana tälle kyselylle.',
  newSurvey: 'Uusi kysely',
  editSurvey: 'Muokkaa kyselyä',
  surveyActivity: 'Aktiivisuus',
  actions: 'Toiminnot',
  kpi_wellbeing: 'Hyvinvointi',
  kpi_engagement: 'Työn imu',
  enps: 'eNPS',
  enpsExpanded: 'Employee Net Promotion Score',
  enpsExplanation:
    'eNPS on suosittelijoiksi luokiteltujen työntekijöiden prosenttiluku vähennettynä arvostelijoiksi luokiteltujen työntekijöiden prosenttiluvulla. Luokittelu perustuu kysymykseen ”Asteikolla 0-10, kuinka todennäköisesti suosittelisit organisaatiotasi hyvänä työpaikkana?” Suosittelijat vastaavat 9 tai 10, arvostelijat 6 tai alle. Muut vastaajat vaikuttavat vain vastaajien kokonaismäärään.',
  whatIsEnps: 'Mikä on eNPS?',
  answerCount: 'Vastausten määrä',
  linkCopied: 'Linkki kopioitu leikepöydälle',
  feedbackVisibleWithLink: 'Kaikki linkin saaneet voivat nähdä tämän palautteen.',
  share: 'Jaa',
  shareFeedback: 'Jaa palaute',
  feedbackSharedBy: (name: string): string => `${name} jakoi kanssasi tämän palautteen`,
  copyLink: 'Kopioi linkki',
  once: 'Kerran',
  weekly: 'Viikottain',
  biweekly: 'Joka toinen viikko',
  monthly: 'Kuukausittain',
  bimonthly: 'Joka toinen kuukausi',
  quarterly: 'Kvartaaleittain',
  quarterlyApproximate: 'Kvartaaleittain',
  onceExtra: 'Kysytään seuraavassa kyselyssä',
  monthlyExtra: '4 viikkoa',
  bimonthlyExtra: '8 viikkoa',
  quarterlyExtra: '13 viikkoa',
  quarterlyApproximateExtra: '12 viikkoa',
  comments: 'Kommentit',
  commentsInGroup: (groupName: string) => `Kommentit ryhmässä ${groupName}`,
  commentsWithTopic: (topic: string) => `Kommentit aiheella "${topic}"`,
  all: 'Kaikki',
  insufficientDataForMetric: 'Liian vähän dataa tämän metriikan laskemiseen',
  workspacePulseParticipation: 'Engagement-kyselyihin osallistuminen',
  notParticipatingInPulse: 'Ei osallistu Engagement-kyselyyn',
  groupPulseParticipation: 'Pulssikyselyn vastaukset lasketaan mukaan ryhmän tuloksiin',
  createGroup: 'Luo ryhmä',
  createWorkspace: 'Luo työtila',
  createYourWorkspace: 'Luo työtilasi',
  name: 'Nimi',
  search: 'Hae',
  reset: 'Tyhjennä',
  leave: 'Poistu',
  confirmLeave: 'Oletko varma, että haluat poistua?',
  reinforcingSynonyms: 'Tunnustusta, kehuja, arvostusta',
  redirectingSynonyms: 'Pyyntöjä, neuvoja, ohjeita',
  more: 'Lisää',
  showMore: 'Näytä lisää',
  workspaces: 'Työtilat',
  profile: 'Profiili',
  save: 'Tallenna',
  saved: 'Tallennettu',
  logout: 'Kirjaudu ulos',
  logoutConfirm: 'Kirjaudutaanko ulos?',
  areYouSure: 'Oletko varma?',
  join: 'Liity',
  joinGroup: 'Liity ryhmään',
  joinWorkspace: 'Liity työtilaan',
  joinableWorkspaces: 'Avoimia työtiloja',
  create: 'Luo',
  createPulseSurvey: 'Ajasta pulssikyselyt',
  slackIntegration: 'Slack-integraatio',
  slackWorkspaceAlreadyLinked:
    'Slack-työtilaa ei voitu linkittää, koska se on jo liitetty toiseen Teamspective-työtilaan.',
  msTeamsIntegration: 'Microsoft Teams -integraatio',
  integrations: 'Integraatiot',
  addSlackToWorkspaceDescription: 'Lisää Teamspective Slack–työtilaasi Slackin admin-käyttäjänä.',
  addMsTeamsToWorkspaceDescription:
    'Lisää Teamspective MS Teams –työtilaasi Teamsin admin-käyttäjänä.',
  frontPage: 'Etusivu',
  feedback: 'Palaute',
  contactUs: 'Ota yhteyttä',
  edit: 'Muokkaa',
  expand: 'Laajenna',
  hide: 'Piilota',
  pulse: 'Pulssi',
  engagementProductName: 'Engagement',
  backToFrontPage: 'Takaisin etusivulle',
  exitDemo: 'Poistu demosta',
  pulseDescription:
    'Merkitykselliset keskustelut alkavat kuuntelemalla. Luo psykologista turvallisuutta, läpinäkyvyyttä ja luottamusta pulssikyselyllä.',
  pulseCta: 'Aloita pulssikyselyn suunnittelu',
  pulseSingleRoundCta: 'Aja yksittäinen pulssikierros',
  requestFeedbackDescription:
    'Palautekulttuuri voi syntyä vain jos palautetta haetaan aktiivisesti.',
  slackInstalled: 'Yhdistetty Slack-työtilaan',
  slackTokenInvalidShort: 'Ongelmia työtilan Slack-yhteydessä.',
  slackTokenInvalid:
    'Ongelmia työtilan Slack-yhteydessä. Ole hyvä ja paina "Add to Slack" yhdistääksesi Slackin uudelleen.',
  tokenScopesMissing:
    'Teamspective tarvitsee lisää oikeuksia Slackiin. Ole hyvä ja paina "Add to Slack" yhdistääksesi Slackin uudelleen. Oikeuksia tarvitaan seuraaviin toimintoihin:',
  msTeamsInstalled: 'Yhdistetty MS Teams -tiimiin',
  sendRequestFailed:
    'Jokin meni pieleen ja palautepyyntöjäsi ei voitu lähettää. Huomioithan, että ilmaiset käyttäjät voivat lähettää korkeintaan 5 palautepyyntöä yhden viikon aikana.',
  addFrom_slack: 'Lisää Slackista',
  addFrom_msteams: 'Lisää MS Teamsista',
  addUsersFrom_slack: 'Lisää käyttäjiä Slackista',
  'addUsersFrom_ms-teams': 'Lisää käyttäjiä MS Teamsista',
  inviteByEmail: 'Kutsu sähköpostitse',
  selectAll: 'Valitse kaikki',
  alreadyAdded: 'Tämä käyttäjä on jo lisätty',
  invite: 'Kutsu',
  egAcmeInc: `Esim. ${getQuotedString('Acme Inc.', 'fi')}`,
  workspaceCreationSuccess: 'Työtila luotu onnistuneesti',
  groupCreationSuccess: 'Ryhmä luotu onnistuneesti',
  typeSomething: 'Kirjoita jotain',
  answered: 'Vastattu',
  theme: 'Teema',
  themes: 'Teemat',
  selectTheme: 'Valitse teema',
  newTheme: 'Uusi teema',
  themeAmount: 'teemaa',
  noThemes: 'Ei teemoja',
  allThemes: 'Kaikki teemat',
  comment: 'Kommentti',
  writeSomething: 'Kirjoita jotain...',
  commentSuccess: 'Kiitos – nimetön viestisi on tallennettu.',
  resultsPublished: 'Tulokset julkaistaan',
  timezone: 'Aikavyöhyke',
  timeToReply: 'Vastausaika',
  answerTimeBegins: 'Vastausaika alkaa',
  answerTimeEnds: 'Vastausaika päättyy',
  confirm: 'Vahvista',
  confirmSelection: 'Vahvista valinta',
  pulseSurveyMin24h: 'Pulssikyselyn keston täytyy ylittää 24 tuntia.',
  pulseQuestions: 'Pulssikysymykset',
  question: 'Kysymys',
  questionName: 'Kysymyksen nimi',
  questions: 'Kysymykset',
  nQuestions: (n: number) => (n === 1 ? '1 kysymys' : `${n} kysymystä`),
  never: 'Ei koskaan',
  unsavedChanges: 'Tallentamattomia muutoksia',
  noActivePulseSurvey: 'Pulssikyselyyn ei ole ajastettu kyselyitä alkavaksi',
  onMondays: 'Maanantaisin',
  onTuesdays: 'Tiistaisin',
  onWednesdays: 'Keskiviikkoisin',
  onThursdays: 'Torstaisin',
  onFridays: 'Perjantaisin',
  resumePulse: 'Käynnistä kysely uudelleen',
  stopPulse: 'Pysäytä kysely',
  stopAndPublishConfirm: 'Haluatko varmasti sulkea tämän kyselyn ja julkaista tulokset?',
  stopPulseConfirm: 'Vastaukset julkaistaan ja tulevia kyselyitä ei enää ajasteta.',
  stopPulseConfirmSingleRound: 'Vastaukset julkaistaan ja kyselyyn ei voi enää vastata.',
  cancelPulse: 'Poista pulssiajastus',
  cancelPulseConfirm: 'Tulevien pulssikyselyiden ajastus poistetaan.',
  cancelSingleRoundConfirm: 'Ajastettu kysely poistetaan.',
  surveySettings: 'Kyselyasetukset',
  preview: 'Esikatsele',
  changesSaved: 'Muutokset tallennettu',
  cancelChanges: 'Peru muutokset',
  affectsNext: 'Muutokset vaikuttavat alkaen seuraavasta pulssikyselystä.',
  pulseThemes: 'Pulssikysymykset',
  open: 'Avaa',
  noOneHere: 'Ei jäseniä',
  pulseSurveyFrequency: 'Pulssikyselyiden tiheys',
  noResults: 'Ei tuloksia',
  noComments: 'Ei kommentteja',
  noNewComments: 'Ei uusia kommentteja',
  newComment: 'Uusi kommentti',
  previousComments: 'Aiemmat kommentit',
  showPreviousComments: 'Näytä aiemmat kommentit',
  requestCommentHeader: 'Lisää kommentti tähän keskusteluun',
  send: 'Lähetä',
  commentDeleteConfirmation: 'Oletko varma, että haluat poistaa tämän kommentin?',
  seeComment: 'Katso kommentti',
  seeNote: 'Katso muistiinpano',
  delete: 'Poista',
  replyDeletedSuccessfully: 'Vastaus poistettu onnistuneesti',
  respondAnonymously: 'Vastaa anonyymisti',
  score: 'Tulos',
  scores: 'Tulokset',
  schedulePulse: 'Aikatauluta pulssi',
  scheduleSingleRound: 'Aikatauluta yksittäiskierros',
  chooseQuestions: 'Valitse kysymykset ensin',
  waitingForfirstResults: 'Odotetaan ensimmäisiä tuloksia',
  firstResultsOn: (formattedDate: string) => `Ensimmäiset tulokset ${formattedDate}`,
  inviteUsers: 'Kutsu käyttäjiä työtilaan',
  confirmRemove: 'Oletko varma, että haluat poistaa tämän käyttäjän ryhmästä?',
  memberSince: (since: string): string => `Jäsen ${since} lähtien`,
  members: 'Jäsenet',
  memberCount: 'jäsentä',
  addUser: 'Lisää henkilö',
  addUsers: 'Lisää henkilöt',
  addUsersIndefinite: 'Lisää henkilöitä',
  groupRemoveUser: 'Poista ryhmästä',
  workspaceRemoveUser: 'Poista työtilasta',
  workspaceRemovalSuccess: 'Käyttäjä poistettu ryhmästä onnistuneesti',
  leaveWorkspace: 'Poistu työtilasta',
  leaveWorkspaceSuccess: 'Olet poistunut työtilasta',
  export: 'Export',
  exportingCompatiblePpt:
    'Luotu powerpoint-tiedosto on yhteensopiva useimpien ohjelmistojen kanssa.',
  existing: 'jo lisättyä',
  notifyUsersOfInvite: 'Lähetä uusille käyttäjille ilmoitus kutsusta',
  allChannelMembersOnTeam: 'kaikki jo lisätty',
  defaultLanguage: 'Oletuskieli',
  bringSelectedChannelsAsGroups: 'Käytä valittuja kanavia Teamspectiven ryhminä',
  whatIsAGroup: 'Mikä on ryhmä?',
  readMore: 'Lue lisää',
  atLeastThreeUsersReqired:
    'Työtilassa pitää olla ainakin kolme käyttäjää tulosten anonymiteetin varmistamiseksi',
  groupsCanBeEditedLater: 'Voit muokata ryhmiä ja lisätä hierarkiaa myöhemmin',
  whoCanSeeThis: 'Kuka näkee vastaukseni?',
  whoSeesThis:
    'Ryhmän jäsenet näkevät anonymisoidut tulokset pulssikyselyn jälkeen. Kukaan ei näe mitä juuri sinä olet vastannut.',
  newPulseResults: 'Uusia pulssituloksia',
  pulseIsScheduledToBegin: 'Pulssi on ajastettu alkamaan',
  previewOfPulseSurveySchedule: 'Pulssikyselyiden aikataulun esikatselu',
  choice: 'Valinta',
  choices: 'Vaihtoehdot',
  week: 'Viikko',
  weekAmount: 'Viikkoa',
  detractors: 'Arvostelijat',
  passives: 'Passiiviset',
  promoters: 'Suosittelijat',
  answerDistribution: 'Vastausjakauma',
  scoreDistribution: 'Tulosjakauma',
  distribution: 'Jakauma',
  seeResults: 'Katso tulokset',
  seeEarlierResults: 'Katso aiemmat tulokset',
  manageSubscription: 'Hallitse tilausta',
  manageSubscriptionPortalInfo:
    'Tilausten hallintasivu on Stripe–palvelussa. Sinut kirjataan sisään uudella välilehdellä.',
  startInSettings: 'Muokkaa pulssiasetuksia ja käynnistä',
  betterWithOthers: 'Teamspective toimii paremmin muiden kanssa',
  freeForWorkspacesBelow: `Ilmainen alle ${FREE_WORKSPACE_SIZE} jäsenen työtiloille.`,
  workspaceName: 'Työtilan nimi',
  slackOptOutOk: 'Ok! Voit löytää Slack-integraation halutessasi myöhemmin työtilasi asetuksista.',
  demoPulseResults: 'Katsele pulssituloksia',
  groupName: 'Ryhmän nimi',
  egGroupName: 'esim. Markkinointi',
  new: 'Uusi',
  whyAddSlack: 'Kokeile Slack-integraatiotamme',
  whyAddSlackDescription:
    'Lisää käyttäjät suoraan Slackistä – he voivat vastata pulssiin ja vastaanottaa ilmoituksia poistumatta Slackista.',
  latest: 'Uusin',
  weeks: 'Viikot',
  months: 'Kuukaudet',
  month: 'Kuukausi',
  quarters: 'Kvartaalit',
  lastQuarter: 'Viime 3kk',
  lastHalf: 'Viime 6 kk',
  chooseProvidersAndTopics: 'Valitse palautteen antajat ja aiheet',
  pleaseAddTopics: 'Valitse aiheet jokaiselle palautteen antajalle.',
  pleaseAddMessages: 'Suosittelemme viestin lisäämistä jokaiseen palautepyyntöön.',
  lookingGood: 'Hyvältä näyttää!',
  tooManyTopics: 'Suosittelemme pyyntöjen aiheiden rajoittamista neljään.',
  workspaceChangeNameConfirmation: 'Nimi muuttuu kaikille työtilan jäsenille.',
  groupChangeNameConfirmation: 'Ryhmän nimi muuttuu kaikille ryhmän jäsenille.',
  deleteGroup: 'Poista ryhmä',
  groupNameToConfirm: 'Kirjoita ryhmän nimi vahvistaaksesi sen pysyvä poistaminen',
  irreversibleAction: 'Tämä toiminto on peruuttamaton!',
  groupDeletedSuccess: 'Ryhmä poistettu onnistuneesti',
  powerpointExport: 'Powerpoint–export',
  latestResult: 'Viimeisin tulos',
  latestResults: 'Viimeisimmät tulokset',
  combinedResults: 'Yhdistetyt tulokset',
  trend: 'Muutos',
  previous: 'Aiemmat tulokset',
  benchmarkValues: 'Vertailuarvot',
  benchmarkDescription:
    'Vertailuarvot ovat globaaleja keskiarvoja Teamspectiven asiakkailta, joihin kuuluu yrityksiä ohjelmistoteollisuudesta, konsultoinnista sekä muilta korkeaa asiantuntemusta vaativilta aloilta. Tiimit voivat helposti arvioida omia tuloksiaan vertailuarvoja sekä yrityksen tuloksia vasten.',
  language: 'Kieli',
  workspaceRole_admin: 'Työtilan hallinnoija',
  workspaceRole_observer: 'Työtilan tarkastelija',
  workspaceRole_analyst: 'Työtilan analyytikko',
  workspaceRole_member: 'Työtilan jäsen',
  workspaceRoleDescription_admin: 'Täysi pääsy ja hallinta',
  workspaceRoleDescription_observer: 'Täysi pääsy',
  workspaceRoleDescription_analyst: 'Täysi pääsy kyselytuloksiin',
  workspaceRoleDescription_member: 'Rajoitettu pääsy',
  groupAdministrator: 'Ryhmän hallinnoija',
  groupAdministratorToggle: 'Tee tästä käyttäjästä ryhmän hallinnoija',
  groupBy: 'Ryhmittele',
  filterBy: 'Suodata',
  tryRequesting: 'Kokeile palautteen pyytämistä',
  youHaveNewFeedback: 'Sinulla on uutta palautetta',
  feedbackWithUnreadComments: 'Lukemattomia kommentteja',
  collectingFeedbackFrom: 'Kerätään palautetta muilta',
  sendPraise: 'Lähetä kehuja',
  reinforcingOpenFeedback: 'Vahvistava avoin palaute',
  praiseSent: 'Kehu lähetetty!',
  feedbackSent: 'Palaute lähetetty!',
  singleFocusAreaDescription:
    'Teamspective on tunnistanut tämän teeman mahdolliseksi kehityskohdaksi tiimillenne.',
  playbookAvailable: 'Teemalle on saatavilla opas.',
  playbook: 'Opas',
  notFoundPageTitle: 'Sivua ei löytynyt',
  reinforcingFeedback: 'Vahvistava palaute',
  redirectingFeedback: 'Ohjaava palaute',
  writeFeedback: 'Kirjoita palautetta',
  feedbackInstructionsNone: 'Valitse sopiva aihe ja kirjoita vahvistavaa tai ohjaavaa palautetta',
  feedbackInstructionsOne:
    'Palaute kirjoitettu yhdestä aiheesta, hienoa! Jatka jos sinulla on vielä asiaa.',
  feedbackInstructionsTwo: 'Jo 2 palautetta, upeeta! Löytyykö vielä lisää?',
  feedbackInstructionsThree: 'Kiitos palautteesta! Jatka palautteen kirjoittamista tai lähetä',
  feedbackInstructionsTooMany:
    'Neljä riittää! Täytä vain ne laatikot, joissa sinulla on jotain arvokasta jaettavaa.',
  reviewUpdatedPrivacyPolicy: (formattedDate: string): string =>
    `Olemme päivittäneet tietosuojaselosteemme ${formattedDate}.`,
  reviewPrivacyPolicy: 'Katso tietosuojaseloste',
  addCustomTopic: 'Lisää oma aihe',
  topicName: 'Aiheen nimi',
  topicDescription: 'Aiheen kuvaus',
  description: 'Kuvaus',
  generalTopics: 'Yleiset aiheet',
  customerTopics: 'Asiakastyöaiheet',
  customTopics: 'Omat aiheet',
  workspaceTopics: 'Työtilan aiheet',
  addTopic: 'Lisää aihe',
  addWorkspaceTopic: 'Lisää työtilan aihe',
  createSuccess: (label: string) => `Aihe ${label} luotu onnistuneesti`,
  choose: (amount: number, total: number) => `Valitse aiheet... (${amount}/${total})`,
  addDescriptionOptional: 'Lisää kuvaus (vapaaehtoinen)',
  sendToPublicSlackChannel: (channelName: string): string =>
    `Lähetä kehu myös julkiseen Slack–kanavaan #${channelName}`,
  sendToPublicMsTeamsChannel: (channelName: string): string =>
    `Lähetä kehu myös julkiseen MS Teams –kanavaan ${channelName}`,
  sendToPublicSlackAndMsTeamsChannel: (slackChannel: string, msTeamsChannel: string): string =>
    `Lähetä kehu myös julkisille kanaville #${slackChannel} (Slack) ja ${msTeamsChannel} (MS Teams)`,
  promotersMinusDetractors: 'suosittelijat − arvostelijat',
  allResponders: 'kaikki vastaajat',
  ending: 'Päättyy',
  ended: 'Päättyi',
  surveyEnding: (ending: string): string => `Kysely päättyy ${ending}`,
  nextPulseSurveyStarting: (starting: string): string => `Seuraava pulssikysely alkaa ${starting}`,
  noPulseRoundScheduled: 'Pulssikyselyä ei ole ajastettu',
  break: 'Tauko',
  userCreatedCustomTopic: (name: string): string => `Käyttäjän ${name} luoma aihe`,
  noSlackPraiseChannelChosen:
    'Et ole vielä valinnut Slack–kanavaa johon julkiset kehut lähetetään.',
  noMsTeamsPraiseChannelChosen:
    'Et ole vielä valinnut MS Teams –kanavaa johon julkiset kehut lähetetään.',
  chooseChannel: 'Valitse kanava',
  changeChannel: 'Muuta kanava',
  praiseWillBeSentToSlackChannel: 'Julkisiksi merkityt kehut lähetetään myös Slack–kanavalle',
  praiseWillBeSentToTeamsChannel: 'Julkisiksi merkityt kehut lähetetään myös MS Teams -kanaville',
  login: 'Kirjaudu',
  joinWorkspaceConfirm: 'Oletko varma, että haluat liittyä tähän työtilaan',
  joinWorkspaceSuccess: 'Olet liittynyt uuteen työtilaan',
  workspaceIsOpen: 'Tämä työtila on sinulle avoin',
  noOpenWorkspaces: 'Ei avoimia työtiloja',
  feedbackRequestSent: 'Palautepyyntösi on lähetetty!',
  evaluationRequests: 'Palautepyynnöt',
  amountNotified: (amount: number): string =>
    `Olemme välittäneet pyyntösi ${amount} vastaanottajalle. Nyt odotellaan rauhassa vastauksia!`,
  lastChoiceWasWhatWhen: (what: string, when: string): string =>
    `Vastasit viimeksi ${what} ${when}`,
  unknownCoupon: 'Tuntematon kuponki',
  purchase: 'Osta',
  cardDetails: 'Kortin tiedot',
  cardDeclined: 'Kortin tietoja ei hyväksytty. Ole hyvä ja yritä uudelleen.',
  cardConfirmationFailed: 'Korttiasi ei voitu varmentaa. Ole hyvä ja yritä uudelleen.',
  createAccount: 'Luo profiili',
  createAccountFirst: 'Create account first',
  publishFeedback: 'Lähetä palaute',
  sendFeedback: 'Lähetä palautetta',
  selfReflectSuccess: 'Hienoa työtä! Itsereflektio tallennettu.',
  feedbackSuccess: 'Hienoa työtä! Palautteesi on lähetetty.',
  begin: 'Aloita',
  seePreviewAndSave: 'Esikatsele ja tallenna',
  seePreviewAndSend: 'Esikatsele ja lähetä',
  followTheseGuidelines: 'Seuraa näitä ohjeita',
  youCanAskForFeedbackToo:
    'Tiesitkö, että voit myös pyytää palautetta kollegoiltasi Teamspectivessä?',
  thanksInAdvance: 'Kiitos etukäteen!',
  feedbackFor: (name: string) => `Palaute henkilölle ${name}`,
  guideLineRows: `Paras palaute on rehellistä, tarkoittaa hyvää ja tukeutuu esimerkkeihin
Tällä sivulla voit lähettää vahvistavaa ja ohjaavaa palautetta pyytäjän valitsemista aiheista
Vahvistava palaute tarkoittaa kannustusta, arvostuksen osoittamista tai kiittämistä
Ohjaava palaute tarkoittaa ehdotusten antamista, pyyntöjen esittämistä ja neuvontaa
Kaikessa palautteessa on parasta keskittyä konkreettiseen käyttäytymiseen henkilön ominaisuuksien sijaan
Tällä sivulla ei anneta numeerisia arvioita, vaan sanallista palautetta. Ja me autamme sinua muotoilussa.`,
  seePreview: 'Esikatsele',
  markAsNonPrivate: 'Aseta ei-yksityiseksi',
  markAsNonPrivateConfirmation:
    'Oletko varma, että haluat asettaa tämän palautteen ei-yksityiseksi?',
  private: 'Yksityinen',
  limited: 'Rajoitettu',
  public: 'Julkinen',
  visibleTo: 'Näkyy käyttäjille',
  visibleToProviderAndRecipient: 'Näkyy palautteen antajalle ja vastaanottajalle',
  feedbackVisibility_private: 'Ei nähtävissä muille, ellei palautetta jaeta',
  feedbackVisibility_limited: 'Näkyy käyttäjille, joilla on oikeudet lukea palautteet',
  feedbackVisibility_public: 'Työtilassa julkinen',
  whoCanSeeFeedbackVisibility_private: 'Vain palautteen osapuolet. He voivat jakaa sen muille',
  whoCanSeeFeedbackVisibility_limited: 'Käyttäjät, joilla on oikeudet lukea palautteet',
  feedbackVisibilityHint: 'Palaute näkyy aina sinulle ja vastaanottajalle.',
  sharedWith: 'Jaettu käyttäjille',

  suspiciouslyShort: 'Kirjoitit aika lyhyesti – oletko varma että haluat lähettää palautteen?',
  accessTitle: 'Ketkä voivat liittyä?',
  invitees: 'Jäsenten kutsumat',
  inviteesDescription: 'Jäsen voi halutessaan kutsua kenet tahansa jäseneksi.',
  allowDomains: 'Tietyillä sähköpostiosoitteilla',
  adminSelfRemoveConfirm:
    'Oletko varma, että haluat poistaa oman hallinnointioikeutesi? Et voi enää hallita tätä ryhmää.',
  addDomain: 'Lisää sähköpostipääte',
  allowDomain: (domain: string): string =>
    `Salli ${domain} -sähköpostiosoitteen haltijoiden liittyminen`,
  updated: 'Asetukset päivitetty',
  feedbackToManagementTitle: (wsName: string) => `Palautetta työtilalle ${wsName}`,
  feedbackFromMeToManagement: (wsName: string) => `Minun palautteeni työtilalle ${wsName}`,
  emptyFeedbackListMy:
    'Tulet näkemään tässä vastaanottamasi ja antamasi palautteen. Tällä hetkellä niitä ei ole.',
  emptyFeedbackListOthers: (name: string) =>
    `Tulet näkemään tässä henkilön ${name} vastaanottamat palautteet. Tällä hetkellä niitä ei ole.`,
  emptyAboutWorkspace: 'Tulet näkemään tässä työtilan palautteen. Tällä hetkellä sitä ei ole.',
  emptyToWorkspace:
    'Tulet näkemään tässä työtilalle antamasi palautteen. Tällä hetkellä sitä ei ole.',
  loadMore: 'Lataa lisää',
  exampleFirstName: 'Essi',
  exampleLastName: 'Esimerkki',
  firstName: 'Etunimi',
  firstNameRequired: 'Etunimi vaaditaan',
  lastName: 'Sukunimi',
  lastNameRequired: 'Sukunimi vaaditaan',
  writeNameOrEmailConnections: 'Kirjoita nimi tai sähköpostiosoite yhteyksistäsi',
  writeNameOrEmailAnyDomain: 'Kirjoita nimi tai mikä tahansa sähköpostiosoite',
  needValidEmailToInvite: 'Kirjoita sähköpostiosoite kutsuaksesi käyttäjän',
  exactMatch: 'Tarkka osuma',
  exactMatchAlreadySelected: 'Käyttäjä on jo lisätty',
  willBeInvited: 'Tämä käyttäjä kutsutaan',
  fiCommunicateIn: 'suomeksi',
  enCommunicateIn: 'englanniksi',
  esCommunicateIn: 'espanjaksi',
  svCommunicateIn: 'ruotsiksi',
  noCommunicateIn: 'norjaksi',
  dkCommunicateIn: 'tanskaksi',
  itCommunicateIn: 'italiaksi',
  deCommunicateIn: 'saksaksi',
  frCommunicateIn: 'ranskaksi',
  plCommunicateIn: 'puolaksi',
  cnCommunicateIn: 'kiinaksi',
  ukCommunicateIn: 'ukrainaksi',
  confirmDeleteProvider: 'Oletko varma että haluat poistaa käyttäjän?',
  thisIsYou: 'Sehän olet sinä! Pohdiskele hetki rauhassa.',
  noChannel: 'Ei kanavaa',
  chooseSlackChannel: 'Valitse Slack-kanava',
  chooseMsTeamsChannel: 'Valitse MS Teams -kanava',
  loading: 'Haetaan',
  endingAt: (ending: string) => `Päättyy ${ending}`,
  personalMessage: 'Henkilökohtainen viesti',
  addPersonalMessage: 'Lisää henkilökohtainen viesti',
  feedbackForMeFrom: (userName: string): string => `${userName} lähetti sinulle palautetta`,
  feedbackForMeAnonymous: 'Nimetön palaute sinulle',
  sentFeedbackTo: (userNames: string[]): string =>
    `Lähetit palautetta henkilölle ${listItemsInNaturalLanguage.fi(userNames)}`,
  anonymousFeedbackTo: (name: string) => `Nimetön palaute käyttäjälle ${name}`,
  xSentFeedbackToY: (x: string, y: string): string => `${x} lähetti palautetta käyttäjälle ${y}`,
  topic: 'Aihe',
  saving: 'Tallennetaan...',
  youWillBeAdminOfGroup: 'Sinusta tulee ryhmän hallinnoija',
  redacted: 'Vähän vastauksia – piilotettu tietosuojasyistä',
  level: 'Taso',
  start: 'Aloita',
  amountEvaluations: (n: number) => `${n} palaute${n !== 1 ? 'tta' : ''}`,
  evaluationTitle: (evalueeName: string) => `Palaute henkilölle ${evalueeName}`,
  evaluationRequestIntro: ({
    evalueeName,
    evalueeEmail,
  }: {
    evalueeName: string
    evalueeEmail?: string
  }) =>
    `Sinua pyydetään antamaan palautetta henkilölle ${evalueeName}${evalueeEmail ? ` (${evalueeEmail})` : ''}.`,
  selfEvaluationRequestIntro: 'Sinua pyydetään täyttämään itsereflektio.',
  evaluationVisibilityInfo: {
    all: 'Antamasi vastaukset näytetään palautteen saajalle ja esihenkilöille yhdistettynä nimeesi.',
    finegrained:
      'Antamasi vastaukset näytetään ilman nimeäsi palautteen saajalle. Esihenkilöt näkevät antamasi vastaukset yhdistettynä nimeesi.',
    admins:
      'Antamasi vastaukset näytetään ilman nimeäsi palautteen saajalle. Työtilan ylläpitäjät näkevät vastauksesi yhdistettynä nimeesi.',
    never: 'Antamasi vastaukset ovat anonyymejä.',
  },
  evaluationSubmitSuccess: 'Palaute lähetetty onnistuneesti.',
  privacy: 'Yksityisyys',
  evaluationThankYou: (evalueeName: string) =>
    `Kiitos palautteen kirjoittamisesta henkilöle ${evalueeName} Teamspectivessa!`,
  selfEvaluationThankYou: 'Kiitos itsereflektiosta!',
  respondent: 'Vastaaja',
  respondentsCount: 'Vastaajaa',
  orFewer: 'tai vähemmän',
  participationInEachPulseSurvey: 'Osallistuminen kyselyittäin',
  resultPublishedAfterRoundEnds: 'Tulokset julkaistaan täällä kierroksen päätyttyä.',
  startImmediately: 'Aloita heti',
  writeEvaluation: 'Kirjoita palaute',
  evaluee: 'Kerätään palautetta henkilölle',
  requestingFeedbackFrom: 'Pyydetään palautetta henkilöltä',
  evaluator: 'Palautteen antaja',
  results: 'Tulokset',
  ofAllEvaluations: 'kaikista palautteista',
  ofAllUsers: 'kaikista käyttäjistä',
  allowRedirectingOpenFeedback: 'Salli pyytämätön ohjaava palaute',
  allowRequestingEvaluationForMe: 'Salli palautteen pyytäminen itselle',
  allowViewingOwnNumericEvaluationsByTheme:
    'Salli työntekijöiden nähdä heille julkaistujen palautteiden teemojen numeeriset keskiarvot',
  allowViewingOwnNumericEvaluationsByQuestion:
    'Salli työntekijöiden nähdä numeeriset keskiarvot kysymyksen tarkkuudella',
  errorAddUsers: `Käyttäjien lisääminen epäonnistui. Huomaa, että ilmaisilla työtiloilla on ${FREE_WORKSPACE_SIZE} hengen maksimimäärä.`,
  errorSendRequests: 'Valitettavasti tapahtui virhe ja palautepyyntöjä ei lähetetty.',
  anonymousChat: 'Anonyymi chat',
  anonymousChatEnabled: 'Anonyymi chat on käytössä',
  channelsActive: (n: number) =>
    n > 0 ? `${n} kanava${n > 1 ? 'a' : ''} käytössä` : 'Yhtään kanavaa ei ole käytössä',
  sendFeedbackToManagement: 'Lähetä palautetta johdolle',
  sendFeedbackAnonymously: 'Lähetä palaute anonyymisti',
  anonymousChatCanBeSeen: (wsName: string, recipientNames: string[]) =>
    `Chatin voivat nähdä seuraavat työtilan ${wsName} jäsenet: ${listItemsInNaturalLanguage.fi(
      recipientNames
    )}`,
  anonymousFeedback: 'Anonyymi palaute',
  feedbackFrom: 'Palautetta henkilöltä',
  sendCommentAnonymously: 'Lähetä kommentti anonyymisti',
  yourCommentWillBeAnonymous: 'Kommenttisi on anonyymi',
  yourNameWillBeVisible: 'Nimesi näytetään kommentissa',
  sentTo: 'Vastaanottajat:',
  seeFeedback: 'Katso palaute',
  draft: 'Luonnos',
  continueDraft: 'Jatka luonnosta',
  myFeedback: 'Minun palautteeni',
  commentAmount: (n: number) => `${n} kommentti${n !== 1 ? 'a' : ''}`,
  sharedFeedback: 'Jaettu palaute',
  filterByQuestion: 'Suodata kysymyksen perusteella',
  feedbackToMe: 'Palaute minulle',
  feedbackByMe: 'Palaute muille',
  feedbackToMeOrByMe: 'Palaute minulle ja muille',
  selectFeedbackToShare: 'Valitse jaettava palaute',
  nSelected: (n: number) => `${n} valittu`,
  nQuestionsSelected: (n: number) => `${n} kysymys${n !== 1 ? 'tä' : ''} valittu`,
  nQuestionsTargeted: (n: number) => `${n} kysymys${n !== 1 ? 'tä' : ''} kohdennettu`,
  feedbackToShare: 'Jaettava palaute',
  feedbackShareSuccess: 'Palautteesi on jaettu ja vastaanottajille ilmoitettu.',
  shareWith: 'Jaa henkilöille',
  demand: 'Kuormittuneisuus',
  influence: 'Vaikutusvalta',
  support: 'Tuki',
  knowledgeBase: 'Tietopankki',
  howToReadThisChart: 'Kuinka lukea tätä kuvaajaa',
  howToReadOnaScatterplots: `Tämä kuvaaja näyttää yksittäisten työntekijöiden paikan kahdella mittarilla: vaikutusvalta ja kysyntä. Kiinnostavia havaintoja voi tehdä tarkkailemalla poikkeavia yksilöitä:
  • Ihmiset mittareiden ääripäissä
  • Korkealla yhdellä mittarilla mutta matalalla toisella
  • Odottamaton paikka kuvaajalla suhteessa asemaan työpaikalla`,
  filterByGroups: 'Suodata ryhmittäin',
  showNames: 'Näytä nimet',
  network: 'Yhteistyö',
  average: 'Keskiarvo',
  anonymousChatRecipients: 'Anonyymin chatin vastaanottajat',
  anonymousChatRecipientsUpdatedMessage: 'Anonyymin chatin vastaanottajat päivitetty.',
  update: 'Päivitä',
  everyone: 'Kaikki',
  lastYear: 'Viime vuosi',
  lastMonth: 'Viime kuukausi',
  'in_all-time': 'koko ajalta',
  'in_last-year': 'viime vuonna',
  'in_last-six-months': 'viime 6 kuukauden aikana',
  'in_last-quarter': 'viime 3 kuukauden aikana',
  'in_last-month': 'viime kuussa',
  undo: 'Peru',
  responses: 'vastausta',
  noResponses: 'ei-vastaukset',
  responseRate: 'vastausprosentti',
  nResponses: (n: number) => `${n} vastaus${n !== 1 ? 'ta' : ''}`,
  questionAmount: (n: number | string) => (n === 1 ? '1 kysymys' : `${n} kysymystä`),
  seeResponses: 'Katso vastaukset',
  resetFilters: 'Poista suodattimet',
  evaluationsForUser: (name: string) => `Palaute henkilölle ${name}`,
  evalueeNotEvaluatedWarning: (n: number) =>
    `${n} henkilö${n !== 1 ? 'ä' : ''} on ilman palautteen antajaa. Jatketaanko silti?`,
  networkSurveyRoundResponses: 'Verkostokyselyn vastaukset',
  networkSurveyNotPublishedOrUserHasNotResponded:
    'Verkostokyselyn täytyy olla julkaistu ja sinun täytyy olla vastannut jotta voit nähdä tuloksesi. Pahoittelut!',
  pending: 'Odottaa',
  complete: 'Valmis',
  status: 'Tila',
  date: 'Päivämäärä',
  pleaseUseYourCompanySlackAccountToLogin: 'Käytä työpaikkasi Slack-tunnusta kirjautumiseen',
  pleaseUseYourCompanyGoogleAccountToLogin: 'Käytä työpaikkasi Google-tunnusta kirjautumiseen',
  show: 'Näytä',
  filters: 'Suodattimet',
  clearFilters: 'Tyhjennä suodattimet',
  clear: 'Tyhjennä',
  clearAll: 'Tyhjennä kaikki',
  clearAllSelections: 'Poista kaikki valinnat',
  feedbackStats: 'Palautetilastot',
  feedbackActivation: 'Palauteaktivointi',
  allResults: 'Kaikki tulokset',
  viewAllResults: 'Näytä kaikki tulokset',
  personal: 'Henkilökohtainen',
  workspace: 'Työtila',
  subscription: 'Tilaus',
  startNextPulseSurveyNow: 'Aloita seuraava pulssikysely nyt',
  nextPulseSurveyWillBeScheduledOn: 'Seuraava pulssikysely ajastetaan alkamaan',
  nextPulseSurveyIsScheduledOn: 'Seuraava pulssikysely on ajastettu alkamaan',
  pulseSurveyWillBeginAndEnd: 'Pulssikysely käynnistyy välittömästi ja päättyy',
  manageUsers: 'Hallitse käyttäjiä',
  addToGroup: 'Lisää ryhmään',
  accountLinked: (service: 'Slack' | 'MS Teams') =>
    `Käyttäjä on yhdistänyt ${service}-tunnuksensa Teamspectiveen`,
  userLimitedAccess: 'Käyttäjä voi vain vastata kyselyihin',
  groupCount: (n: number) => (n === 1 ? '1 ryhmä' : `${n} ryhmää`),
  groupMembers: (n: number) => (n === 1 ? '1 jäsen' : `${n} jäsentä`),
  workspaceInformation: 'Työtilan tiedot',
  noWorkspaceGroups: 'Työtilassa ei ole ryhmiä',
  whyWorkspaceGroups: 'Jakamalla käyttäjät ryhmiin voit tarkastella pulssituloksia ryhmittäin.',
  genericErrorMessage: 'Valitettavasti tapahtui virhe. Tiimimme on saanut tästä tiedon.',
  genericErrorMessageTitle: 'Valitettavasti tapahtui virhe',
  genericErrorMessageDescription: 'Tiimimme on saanut tästä tiedon. Yritä uudelleen myöhemmin.',
  selectUser: 'Valitse käyttäjä',
  selectUsers: 'Valitse käyttäjiä',
  selectChannels_slack: 'Valitse kanavia',
  'selectChannels_ms-teams': 'Valitse tiimejä',
  demoWriteFeedback: 'Kirjoita palautetta',
  reply: 'Vastaa',
  pulseComments: 'Pulssin kommentit',
  allComments: 'Kaikki kommentit',
  noPulseComments: 'Ei pulssikommentteja',
  relatedTopic: 'Liittyvä aihe',
  incompleteSentimentAnalysisDescription:
    'Analysoidaan kommenttien aiheita. Tämä voi kestää muutaman minuutin.',
  incompleteEvaluationSummaryDescription:
    'Analysoidaan palautetta. Tämä voi kestää muutaman minuutin.',
  topicsFoundInComments: 'Kommenteista löydetyt aiheet',
  noTopicsFound: 'Valinnastasi ei löytynyt yhteisiä aiheita',
  commentSummaryInfo:
    'Tämä yhteenveto on tehty tekoälyn avulla. Haitallinen sisältö on poistettu yhteenvetoa tehtäessä. Suosittelemme lukemaan kaikki kommentit täyden ymmärryksen saamiseksi vastauksista.',
  selectedTopic: 'Valittu aihe',
  sentiment: 'Sentimentti',
  idea: 'Idea',
  ideas: 'Ideat',
  noReplies: 'Ei vastauksia',
  visibleToAdmins: 'Näkyvät hallinnoijille',
  visibleToWorkspace: 'Näkyvät työtilalle',
  ownComments: 'Omat kommentit',
  workspaceCommentVisibilityAdminsOnly: 'Kommentti näkyy vain työtilan hallinnoijille.',
  workspaceCommentVisibility: 'Kommentti näkyy työtilan kaikille jäsenille.',
  teamsCommentVisibilityAdminsOnly:
    'Kommentti tulee näkyviin siihen liittyvien ryhmien sekä työtilan hallinnoijille.',
  teamsCommentVisibility:
    'Kommentti tulee näkyviin siihen liittyvien ryhmien jäsenille sekä työtilan hallinnoijille.',
  allowedPulseCommentsVisibility: 'Kyselyn kommentit voi tehdä näkyväksi',
  'allowedPulseCommentsVisibility_workspace-members': 'Kaikille työtilan käyttäjille',
  'allowedPulseCommentsVisibility_teams-members': 'Jäsenille tai hallinnoijille käyttäjän ryhmissä',
  'allowedPulseCommentsVisibility_admins-only': 'Hallinnoijille käyttäjän ryhmissä',
  'allowedPulseCommentsVisibility_workspace-admins-only': 'Vain työtilan hallinnoijat',
  emailAddress: 'Sähköpostiosoite',
  emailUpdateInstruction:
    'Päivittääksesi sähköpostiosoitteesi Teamspectivessä, ota yhteyttä tukeemme osoitteessa',
  sharedFeedbackVerb: 'Jakoi palautetta',
  gavePraise: 'Antoi kehuja',
  firstResults: 'Ensimmäiset tulokset',
  answers: 'vastausta',
  pulseInsightsTitle: 'Oivalluksia ja ehdotuksia',
  pulseInsightsPositiveTitle: 'Jatka samaan malliin',
  pulseInsightsNegativeTitle: 'Kiinnitä huomiota',
  insightsNoIssues: (groupName: string) =>
    `Ei merkittäviä kehityskohteita ryhmässä ${groupName}. Huomaathan, että jokaiselle ryhmälle on eri suositukset perustuen niiden tuloksiin.`,
  fromLastRound: 'muutos viime kyselystä',
  inLastTwoRounds: 'kahden kyselyn aikana',
  versusBenchmark: 'vertailuarvoon',
  comparedToWorkspace: 'verrattuna työtilaan',
  reinforcing_cue: 'Vahvistava palaute',
  benchmark: 'Vertailuarvo',
  reinforcing_example: 'Oli hienoa kun sinä...\nIhailen/arvostan sitä, kun...',
  reinforcing_explanation:
    'Anna vahvistavaa palautetta, osoita arvostusta, kiitä, anna tunnustusta hyvästä työstä, juhlista voittoja. Tässä ohjeita:',
  reinforcing_explanationItems: [
    'Kerro huomaamastasi toiminnasta: Mitä tehtiin, ja mikä siinä oli erilaista tavanomaiseen verrattuna? Ole yksityiskohtainen esimerkeissäsi.',
    'Kuvaile vaikutuksia: Mitä tapahtui toiminnan ansiosta? Tunsitko helpotusta, iloa, ylpeyttä, tyydyttyneisyyttä, tai jotain muuta positiivista tunnetta? Tuliko joku sinun tai tiimin tarve täytetyksi?',
    'Viimeistele tyylillä, esim. “Näytät loistavaa esimerkkiä” tai “Yritän itsekin oppia samoja temppuja” tai “Kiitos kun jaksat panostaa tähän” tai ihan vain “On ilo työskennellä kanssasi”',
  ],
  redirecting_cue: 'Ohjaava palaute',
  redirecting_example: 'Voisimme miettiä yhdessä, kuinka...\nVoisitko kokeilla...',
  redirecting_explanation:
    'Anna ohjaavaa palautetta, ideoita ja ehdotuksia jatkoon. Tässä ohjeita:',
  redirecting_explanationItems: [
    'Kerro huomioistasi neutraalisti: kuvaile sopivan yksityiskohtaisesti, mitä tapahtui. Keskity konkreettiseen toimintaan ja tilanteeseen, äläkä henkilöön tai hänen ominaisuuksiinsa.',
    'Kuvaile vaikutuksia: Mitä tapahtui (tai ei tapahtunyt) toiminnan ansiosta? Kuvaile tuntemuksiasi, jos koet sopivaksi.',
    'Esitä konkreettinen pyyntö: Kerro, mikä toiminta tuottaisi uskoaksesi parempia tuloksia seuraavalla kerralla vastaavassa tilanteessa. Muista, että pyytäminen tai ehdottaminen on toisenlaista kuin vaatiminen.',
  ],
  explanationMoreTipsLink: 'Saadaksesi lisää vinkkejä vaikuttavan palautteen antamiseen,',
  readHere: 'lue täältä',
  topics: 'Aiheet',
  chooseTopic: 'Valitse aihe',
  addAnotherTopic: 'Lisää aiheita',
  favouriteTopicsTabDescription:
    'Valitse aiheet jotka korostetaan kun sinulle kirjoitetaan palautetta',
  redactionLimitText: 'Näytä kyselyiden tulokset ryhmissä, joissa on vähintään',
  wantsFeedbackOnTopic: (name: string) => `${name} toivoo palautetta tästä aiheesta.`,
  selfReflection: 'Itsereflektio',
  preferredTopics: 'Ensisijaiset aiheet',
  choosePreferredTopics: 'Valitse ensisijaiset aiheet',
  lastDays: (d: number) => `Viime ${d} päivää`,
  requesters: 'pyytäjää',
  requester: 'pyytäjä',
  provider: 'antaja',
  providers: 'antajaa',
  recipient: 'vastaanottaja',
  recipients: 'vastaanottajaa',
  replyNoun: 'vastaus',
  replies: 'vastausta',
  request: 'pyyntö',
  requests: 'pyyntöä',
  responsiveness: 'Vastasi pyyntöihin',
  askedForFeedback: 'Pyytänyt palautetta',
  providerRate: 'Antanut palautetta tai kehuja',
  gotFeedback: 'Saanut palautetta',
  sentPraise: 'Antoi kehuja',
  sentFeedback: 'Antoi palautetta',
  people: 'Ihmiset',
  peopleAmount: (n: number) => (n === 1 ? '1 ihminen' : `${n} ihmistä`),
  role: 'Rooli',
  allTopics: 'Kaikki aiheet',
  praise: 'Kehu',
  collapseAll: 'Sulje kaikki',
  expandAll: 'Laajenna kaikki',
  feedbackTimeline: 'Palaute aikajärjestyksessä',
  praiseFrom: 'Kehu käyttäjältä',
  viewAndComment: 'Katso ja kommentoi',
  yourFeedbackNeeded: 'Palautettasi tarvitaan',
  allPeople: 'Kaikki ihmiset',
  you: 'sinä',
  from: 'Käyttäjältä',
  unseenComments: 'Uusi kommentti',
  type: 'Tyyppi',
  shareableFeedback: 'Jaettava palaute',
  customQuestion: 'Oma kysymys',
  customQuestions: 'Omat kysymykset',
  oldQuestions: 'Vanhat kysymykset',
  oldQuestionsTip:
    'Emme suosittele näitä vanhoja kysymyksiä. Uuden datamallin seurauksena niitä ei käytetä enää tulosten laskemiseen. Poista vanhat kysymykset, jos niille ei ole erityistä tarvetta.',
  oldQuestionsTipContinuation:
    'Vanhat kysymykset poistuvat järjestelmästä, kun poistat valinnan listalta ja painat OK. Valintaa ei voi perua. Olemassa olevat vastaukset säilyvät pulssikyselyn tulosnäkymässä.',
  choicesNeedToBeUnique: 'Jokaisen vastausvaihtoehdon on oltava erilainen',
  createNewQuestion: 'Luo uusi kysymys',
  createNewTemplate: 'Luo uusi palautepohja',
  editQuestion: 'Muokkaa kysymystä',
  howToDesignASurveyQuestion: 'How to design a survey question',
  addChoice: 'Lisää vastausvaihtoehto',
  frequencyOfPositivePhenomenon_1: 'Ei lähes koskaan',
  frequencyOfPositivePhenomenon_2: 'Harvoin',
  frequencyOfPositivePhenomenon_3: 'Joskus',
  frequencyOfPositivePhenomenon_4: 'Usein',
  frequencyOfPositivePhenomenon_5: 'Lähes aina',
  frequencyOfNegativePhenomenon_1: 'Lähes aina',
  frequencyOfNegativePhenomenon_2: 'Usein',
  frequencyOfNegativePhenomenon_3: 'Joskus',
  frequencyOfNegativePhenomenon_4: 'Harvoin',
  frequencyOfNegativePhenomenon_5: 'Ei lähes koskaan',
  satisfaction_1: 'Ei lainkaan tyytyväinen',
  satisfaction_2: 'Jonkin verran tyytyväinen',
  satisfaction_3: 'Kohtalaisen tyytyväinen',
  satisfaction_4: 'Hyvin tyytyväinen',
  satisfaction_5: 'Täysin tyytyväinen',
  agreeing_1: 'Täysin eri mieltä',
  agreeing_2: 'Eri mieltä',
  agreeing_3: 'Neutraali',
  agreeing_4: 'Samaa mieltä',
  agreeing_5: 'Täysin samaa mieltä',
  agreeing7point_1: 'Täysin eri mieltä',
  agreeing7point_2: 'Eri mieltä',
  agreeing7point_3: 'Jokseenkin eri mieltä',
  agreeing7point_4: 'En samaa enkä eri mieltä',
  agreeing7point_5: 'Jokseenkin samaa mieltä',
  agreeing7point_6: 'Samaa mieltä',
  agreeing7point_7: 'Täysin samaa mieltä',
  expectations_1: 'Ei hyväksyttävä',
  expectations_2: 'Tarvitsee parannusta',
  expectations_3: 'Vastaa odotuksia',
  expectations_4: 'Ylittää odotukset',
  expectations_5: 'Merkittävästi ylittää odotukset',
  standards_1: 'Ei täytä standardeja',
  standards_2: 'Joskus täyttää standardit',
  standards_3: 'Täyttää standardit',
  standards_4: 'Täyttää korkeat standardit',
  standards_5: 'Säännöllisesti täyttää korkeat standardit',
  rolefit_1: 'Vaatii parannusta',
  rolefit_2: 'Kehittyy',
  rolefit_3: 'Täyttää odotukset',
  rolefit_4: 'Ylittää usein odotuksia',
  rolefit_5: 'Roolimalli muille',
  skillLevel1_1: 'Aloittelija',
  skillLevel1_2: 'Kehittymässä',
  skillLevel1_3: 'Normaali',
  skillLevel1_4: 'Edistynyt',
  skillLevel1_5: 'Asiantuntija',
  skillLevel2_1: 'Matala',
  skillLevel2_2: 'Perustaso',
  skillLevel2_3: 'Normaali',
  skillLevel2_4: 'Korkea',
  skillLevel2_5: 'Poikkeuksellinen',
  skillLevel3_1: 'Riittämätön',
  skillLevel3_2: 'Keskimääräistä heikompi',
  skillLevel3_3: 'Hyvä',
  skillLevel3_4: 'Keskimääräistä parempi',
  skillLevel3_5: 'Poikkeuksellinen',
  skillLevel4_1: 'Aloittelija',
  skillLevel4_2: 'Perustason osaaminen',
  skillLevel4_3: 'Suoriutuu itsenäisesti',
  skillLevel4_4: 'Asiantuntija',
  skillLevel4_5: 'Parantaa muidenkin suoritusta',
  scale1to5_1: '1',
  scale1to5_2: '2',
  scale1to5_3: '3',
  scale1to5_4: '4',
  scale1to5_5: '5',
  quality_1: 'Hyvin heikko',
  quality_2: 'Heikko',
  quality_3: 'Kohtalainen',
  quality_4: 'Hyvä',
  quality_5: 'Erinomainen',
  yesNo_0: 'Ei',
  yesNo_1: 'Kyllä',
  deleteQuestion: 'Poista kysymys',
  frequencyOfPositivePhenomenon: 'Yleisyys (positiivinen ilmiö)',
  frequencyOfNegativePhenomenon: 'Yleisyys (negatiivinen ilmiö)',
  satisfaction: 'Tyytyväisyys',
  agreeing: 'Samanmielisyys',
  agreeing7point: 'Samanmielisyys',
  expectations: 'Odotukset',
  standards: 'Standardit',
  rolefit: 'Rooliin mukautuminen',
  skillLevel1: 'Taitotaso 1',
  skillLevel2: 'Taitotaso 2',
  skillLevel3: 'Taitotaso 3',
  skillLevel4: 'Taitotaso 4',
  positiveFrequency4: 'Yleisyys (positiivinen ilmiö, 4-portainen)',
  scale1to5: 'Skaala 1-5',
  quality: 'Laatu',
  yesNoPositive: 'Kyllä/Ei (positiivinen)',
  yesNoNegative: 'Kyllä/Ei (negatiivinen)',
  written: 'Teksti',
  custom: 'Vapaavalintainen asteikko',
  customOption: 'Mukautettu',
  scale: 'Skaala',
  translations: 'Käännökset',
  questionRemoved: 'Kysymys poistettu onnistuneesti',
  questionAdded: 'Kysymys lisätty onnistuneesti',
  quickActions: 'Pikatoiminnot',
  takeAction: 'Toimi näin',
  whyItMatters: 'Miksi tällä on merkitystä',
  teamDiscussionTips: 'Vinkkejä tiimikeskusteluihin',
  oneOnOneTips: 'Vinkkejä henkilökohtaisiin keskusteluihin',
  deleteDraft: 'Poista luonnos',
  draftDeleted: 'Luonnos poistettu',
  noCustomQuestions: 'Ei omia kysymyksiä',
  notScheduled: 'Ei ajastettu',
  changeOnScheduleTab: 'Muuta tätä asetusta Aikataulu-osiossa',
  thanksForAnswer: 'Kiitos vastauksistasi!',
  noPulseSurveyActive: 'Pulssikyselyä ei ole käynnissä.',
  limitedSurveyAnsweredDescription: 'Voit vielä kommentoida tai muuttaa vastauksiasi alta.',
  loginWithCode: 'Kirjaudu koodilla',
  invalidLoginCode:
    'Kirjautumiskoodia ei tunnistettu. Tarkista koodi tai ota yhteys organisaatiosi hallintoon.',
  answerRate_count: 'Vastasi viime kyselyyn',
  answerRate_countLag1: 'Vastasi kahden viime kyselyn aikana',
  answerRate_countLag2: 'Vastasi kolmen viime kyselyn aikana',
  answerRate_countLag3: 'Vastasi neljän viime kyselyn aikana',
  answerRate_countLagMore: 'Ei ole vastannut viime neljään kyselyyn',
  answerRate_countNever: 'Ei ole vastannut koskaan',
  percentages: 'Prosenttiosuudet',
  count: 'Määrä',
  counts: 'Määrät',
  number: 'lkm.',
  billingInterval_year: 'Vuosittainen laskutus',
  billingInterval_month: 'Kuukausittainen laskutus',
  plan_feedback: 'Henkilökohtainen palaute',
  plan_pulse: 'Engagement',
  plan_pulse_and_feedback: 'Engagement & Performance',
  paymentMethod_card: 'Luottokortti',
  paymentMethod_invoice: 'Lasku',
  paymentMethod: 'Maksutapa',
  billingAddress: 'Laskutusosoite',
  confirmSubscription: 'Vahvista tilaus',
  currentlySubscribed: 'Sinulla on tällä hetkellä aktiivinen tilaus',
  nextBillDueOn: (date: string) => `Seuraava lasku ${date}`,
  orderEndsOn: (date: string) => `Tilauksesi päättyy ${date}`,
  chooseBillingPeriod: 'Valitse laskutusjakso',
  billingPeriod: 'Laskutusjakso',
  subscriptionPlanTitle: 'Tilauksen tyyppi',
  fifteenOff: 'Alennus -15%',
  selectSubscriptionPlanTitle: 'Valitse tilauksen tyyppi',
  finVat: 'Ostoon kohdistuu suomen ALV',
  finVatHelp: `Jos ostaja sijaitsee Suomessa, hintaan lisätään arvonlisävero (${String(FI_VAT_PERCENTAGE).replace('.', ',')} %).`,
  upgradeTitle: 'Oletko valmis seuraavalle tasolle?',
  eur: (eur: number) => `${roundPrice(eur)}€`,
  product: 'Tuote',
  summary: 'Yhteenveto',
  intervalTitle: 'Laskutustiheys',
  yearFrequency: 'Vuosittain',
  yearPrice: (eur: number) => `${roundPrice(eur)}€ / käyttäjä / vuosi`,
  monthFrequency: 'Kuukausittain',
  monthPrice: (eur: number) => `${roundPrice(eur)}€ / käyttäjä / kk`,
  users: 'Käyttäjiä',
  monthlyCost: 'Kuukausihinta',
  annualCost: 'Vuosihinta',
  pricingBasis: 'Laskutusperuste',
  yourBill: 'Sinulta laskutetaan',
  vat: 'ALV',
  groupMoveConfirmation: (childGroupName: string, parentGroupName: string) =>
    `Tahdotko varmasti siirtää ryhmän ${childGroupName} ryhmän ${parentGroupName} alle? Kaikki ryhmän jäsenet lisätään myös ryhmään ${parentGroupName}.`,
  reorganize: 'Järjestele',
  reorganizingGroups: 'Järjestele ryhmiä raahaamalla',
  containsOneGroup: 'Sisältää yhden ryhmän',
  containsGroups: (count: number) => `Sisältää ${count} ryhmää`,
  done: 'Valmis',
  parentGroup: 'Kuuluu ryhmään',
  feedbackActivationInfoTitle:
    'Varmista, että jokainen työntekijä saa laadukasta palautetta ympäri vuoden',
  feedbackActivationStepOne: 'Rakennamme verkoston yrityksenne työntekijöiden välisistä suhteista',
  feedbackActivationStepTwo:
    'AI-malli yhdistää verkostodatan, pulssitulokset ja käyttäjien palauteaktiivisuuden, minkä tuloksena tarjoamme jokaiselle käyttäjälle fiksuja suosituksia keitä he voisivat kehua tai keneltä pyytää palautetta. Suositukset lähetetään Slackin tai MS Teamsin kautta.',
  feedbackActivationRequestCriteria:
    'Kehotamme käyttäjiä lähettämään esitäytettyjä, älykkäitä ja kohdennettuja palautepyyntöjä. Tavoitetaso: jokainen saa 3 palautetta 3 kk aikana.',
  feedbackActivationPraiseCriteria:
    'Muistutamme automaattisesti ihmisiä jakamaan kehuja, antamaan kiitosta ja näyttämään arvostustaan työkavereille, jos he eivät tee sitä proaktiivisesti.',
  feedbackActivationAskForConnections: 'Luo palauteverkosto',
  feedbackActivationAskForConnectionsWhen:
    'Käyttäjät vastaavat 1 minuutin kyselyyn Slackissa tai MS Teamsissa ja luovat palauteverkoston, jonka pohjalta kohdennamme palautteen suosituksia. Verkosto päivitetään 6 kk välein.',
  collectPersonalFeedback: 'Kerää henkilökohtaista palautetta',
  activationTypes: 'Aktivointityypit',
  activationTypesRecommendedOn:
    'Kytke kaikki aktivointityypit päälle saadaksesi täydet hyödyt palautteen automaatiosta',
  overview: 'Yhteenveto',
  previousSurvey: 'edellinen kysely',
  lastAsked: 'Kysytty viimeksi',
  'pulseUnlimitedVisibilitySetting_workspace-admins': 'Työtilan hallinnoijille',
  'pulseUnlimitedVisibilitySetting_everyone-in-workspace': 'Kaikille työtilan käyttäjille',
  defaultPulseResultVisibility:
    'Käyttäjät näkevät aina tulokset ryhmissä joihin itse kuuluvat, sisältäen työtilan yhteistulokset. Ryhmien hallinnoijat näkevät aina tulokset niiden ala-ryhmissä, mikäli niitä on.',
  allPulseResultsVisibleTo: 'Näytä kaikkien ryhmien tulokset',
  default: 'Perusasetus',
  allowed: 'Sallittu',
  notAllowed: 'Ei sallittu',
  hidingResultsReason: 'Tulosten piilottaminen pienissä ryhmissä suojaa käyttäjien nimettömyyttä',
  pulseCommentVisibilitySettingInfo:
    'Asetuksesta riippuen käyttäjät voivat lisätä nimettömiä kommentteja, jotka näkyvät (1) työtilan kaikille jäsenille, (2) käyttäjän ryhmien jäsenille tai hallinnoijille, (3) käyttäjän ryhmien hallinnoijille, tai (4) vain työtilan hallinnoijille.',
  showExample: 'Näytä esimerkki',
  heatmapDesktopOnly:
    'Täydet pulssitulokset ovat katsottavissa vain leveämmällä ruudulla. Käytä tietokoneselainta tai käännä laite vaakatasoon.',
  removeDraft: 'Poista luonnos',
  time: 'aika',
  rows: 'Rivit',
  signUp: 'Luo tili',
  withEmail: 'sähköpostilla',
  focusOnGroup: 'Tarkenna ryhmään',
  focusOnMeasurement: 'Tarkenna mittariin',
  selectGroup: 'Valitse ryhmä',
  selectMeasurement: 'Valitse mittari',
  pivotDataBy: 'Pivotoi tiedolla',
  heatmapType: 'Taulukon tyyppi',
  demoFeedbackPrompt: 'Hei, haluaisin sinulta palautetta',
  allDone: 'Valmista tuli!',
  demoAnswerPulse: 'Vastaa pulssikyselyyn',
  demo: 'demo',
  myScore: 'Minun tulos',
  groupScore: 'Ryhmän tulos',
  showMyScore: 'Näytä minun tulokseni',
  hideMyScore: 'Piilota minun tulokseni',
  showBenchmark: 'Näytä vertailuarvo',
  hideBenchmark: 'Piilota vertailuarvo',
  numberOfUsers: (amount: number) => `${amount} käyttäjä${amount !== 1 ? 'ä' : ''}`,
  answerSent: 'Vastaus lähetetty',
  networkSurvey: 'Verkostokysely',
  leadershipStyle: 'Johtamistyyli',
  replyBy: (date: string) => `Vastaathan ${date} mennessä`,
  onaDescription:
    'Verkostokyselyllä tutkitaan yhteyksiä ihmisten välillä: kuka auttaa ketä, kuka auttaa toisia, miten tieto kulkee, kuka vaikuttaa kulttuuriin ja miten osaaminen jakautuu.',
  enableFeedbackLink: 'Luo palautelinkki',
  disableFeedbackLink: 'Poista palautelinkki käytöstä',
  feedbackLinkDescription:
    'Jos tahdot saada palautetta ihmisiltä, joilla ei ole Teamspective-tunnusta, voit luoda avoimen palautelinkin. Linkin vastaanottaneet henkilöt voivat antaa sinulle vahvistavaa ja ohjaavaa palautetta ilman Teamspective-tunnusta.',
  feedbackLinkEnabledInstruction: 'Jaa tämä linkki saadaksesi palautetta.',
  feedbackLinkDisabledInstruction:
    'Olet estänyt avoimen palautteen. Voit käynnistää sen uudelleen tästä saadaksesi palautelinkin käyttöön.',
  feedbackLink: 'Palautelinkki',
  tryOurKeyFeatures: 'Kokeile tärkeimpiä ominaisuuksia demo-sisällöllä',
  showFeatureDemos: 'Näytä demo-ominaisuudet etusivulla',
  featureDemosHidden: 'Demo-ominaisuudet piilotettu',
  reEnableIn: 'Voit palauttaa ne sivulla',
  networkGraph: 'Verkostograafi',
  hideOthers: 'Piilota ei-valitut',
  zoomIn: 'Suurenna',
  zoomOut: 'Pienennä',
  zoomToFit: 'Keskitä',
  fullScreen: 'Koko näyttö',
  feedbackType: 'Palautteen tyyppi',
  evaluationType: 'Palautepohjan tyyppi',
  employeeEvaluation: 'Esihenkilöltä alaiselle',
  managerEvaluation: 'Alaiselta esihenkilölle',
  externalEvaluation: 'Ulkopuolinen palaute',
  peerEvaluation: 'Vertaispalaute',
  otherEvaluation: 'Palaute muulta kollegalta',
  selfEvaluation: 'Itsereflektio',
  performanceOverview: 'Yleiskuva',
  managementPermissions: 'Hallintaoikeudet',
  addFromGroup: 'Lisää ryhmästä',
  permissions: 'Oikeudet',
  user: 'Käyttäjä',
  managingPerson: 'Hallinnoija',
  managedPeople: 'Hallinnoidut henkilöt',
  peopleConnected: 'henkilöä yhdistetty',
  individualImpact: 'Yksilön vaikutus',
  connectionType: 'Yhteystyyppi',
  groupConnections: 'Ryhmien yhteydet',
  clusterAnalysis: 'Klusterianalyysi',
  clusterSize: 'Klusterin koko',
  zScoreDescription: [
    'Nämä neljä lukua lasketaan jokaiselle työtilan jäsenelle, perustuen muiden henkilöiden vastauksiin verkostokyselyissä. Keskimääräinen lukuarvo on aina nolla. Jokaisen kysymyksen kohdalla 68,24 % kaikista käyttäjistä saa lukuarvon väliltä -1…1, ja 95.44 % väliltä -2…2. Tämä tarkoittaa että lukuarvot yli 2 ja alle -2 ovat hyvin harvinaisia.',
    '‘Kuormittuneisuus’ osoittaa henkilöön kohdistuvaa painetta, joka tulee muilta ihmisiltä yhteistyöstä tai johtamisesta.',
    '‘Vaikutusvalta’ osoittaa henkilön kykyä johtaa ja selkiyttää työtä, perustuen henkilön ‘keskeisyyteen’ verkostoissa.',
    '‘Muiden tukeminen’ viittaa henkilön kykyyn tukea ihmisten hyvinvointia ja luoda hyvää yhteishenkeä.',
    '‘Tunnustus’ osoittaa miten paljon henkilön työtä ja suoriutumista ihaillaan.',
    'Tutkakaavio esittää henkilön saamat nimeämiset verkostokyselyn eri kysymyksissä. Tämä havainnollistaa henkilön tapoja vaikuttaa verkoston muihin jäseniin.`',
  ],
  peopleConnectedTo: (name: string) => `Yhteydet käyttäjään ${name}`,
  peopleConnectedToDescription: (name: string) =>
    `Tutki miten ${name} vaikuttaa muihin. Havaintojen avulla voit kehittää henkilön roolia organisaatiossa, hyödyntää heidän vahvuuksiaan ja yhteyksiään muutosjohtamisessa, sekä pienentää mahdollisen irtisanoutumisen vaikutusta muihin.`,
  significantConnectionDescription: (source: string, target: string) =>
    `${target} on yksi henkilön ${source} tärkeimmistä yhteyksistä`,
  startNetworkSurvey: 'Aloita verkostokysely',
  participants: 'Osallistujat',
  significance: 'Merkittävyys',
  howToReadGroupLinkSignificance:
    'Korkea merkittävyys tarkoittaa, että ryhmien välinen yhteys on suurempi kuin satunnaisesti voisi odottaa. Graafissa näytetään vain merkittävimmät yhteydet. Matriisissa voi nähdä kaikkien yhteyksien merkittävyydet.',
  recognition: 'Tunnustus',
  notificationPreferences: 'Ilmoitukset',
  confirmFeedbackUnsubscribe: 'Oletko varma? Et saa sinulle kirjoitetusta palautteesta tietoa.',
  feedbackNotifications: 'Uudet palautteet',
  feedbackNotificationDescription:
    'Kollegoitesi lähettämät palautteet, palautepyynnöt ja kommentit.',
  pulseRemindersNotifications: 'Kyselymuistutukset',
  pulseRemindersNotificationDescription: 'Muistutukset vastata kyselyihin.',
  pulseResultsNotifications: 'Kyselytulokset',
  pulseResultsNotificationDescription: 'Ilmoitus siitä, että uusia kyselytuloksia on saatavilla.',
  recommendationsNotifications: 'Neuvot & Suositukset',
  recommendationsNotificationDescription:
    'Sinulle ajankohtaisia neuvoja, joilla saat kaiken irti ominaisuuksistamme.',
  announcementsNotifications: 'Uudet ominaisuudet & tiedotteet',
  announcementsNotificationDescription:
    'Tiedot uusista ominaisuuksista ja ajoittaisia yhtiötiedotteita.',
  researchNotifications: 'Tutkimus',
  researchNotificationDescription:
    'Kutsut osallistua kyselyihin ja käytettävyystesteihin. Korkeintaan pari kertaa vuodessa.',
  newsletterNotifications: 'Uutiskirje',
  newsletterNotificationDescription: 'Uudet artikkelit ja muu tuottamamme sisältö.',
  welcomeToTeamspective: 'Tervetuloa Teamspectiveen!',
  rememberMe: 'Muista minut tässä selaimessa',
  tocRequired: 'Ole hyvä ja hyväksy käyttäjäehdot käyttääksesi palvelua',
  surveysOnly: 'vain kyselyt',
  skip: 'Ohita',
  skipNow: 'Ohita nyt',
  kpi: 'KPI',
  seeAndEdit: 'Katso ja muokkaa',
  everyNWeeks: (n: number) => (n === 1 ? 'Joka viikko' : `${n} viikon välein`),
  measurement: 'Mittari',
  groups: 'Ryhmät',
  responseStatisticsByGroup: 'Vastaustilastot ryhmittäin',
  questionsAskedFromWorkspace: 'Kysely koko työtilalle',
  questionsScheduledAmount: 'kysymystä ajastettu',
  off: 'Pois',
  selectMeasurementTooltip:
    'Valitse mitä mittausta haluat tarkastella. Jokainen KPI lasketaan useasta teemasta, ja jokainen teema lasketaan useasta kysymyksestä.',
  questionsExplainingScore: 'Tulosta selittävät kysymykset',
  themesExplainingScore: 'Tulosta selittävät teemat',
  schedulingTip:
    'Joka kyselyssä on maksimissaan 6 kysymystä koska vastausprosentti laskee tätä pidemmillä kyselyillä.  Vähennä ajastettujen kysymysten määrää tai tihennä kyselyväliä saadaksesi nopeammin täydet tulokset. Voit myös täydentää jatkuvien kyselyiden tuloksia käsin ajastetuilla vuosittaisilla kyselyillä.',
  everyThingAskedEvery: (thing: string) => `Jokainen ${thing} kysytään`,
  belongsToThemes: 'Kuuluu teemoihin',
  visibileIn: 'Näkyvyys:',
  addToSchedule: 'Lisää aikatauluun',
  removeFromSchedule: 'Poista aikataulusta',
  scheduledForNextRound: 'Ajastettu seuraavaan kyselyyn',
  nth: (n: number) => `${n}.`,
  editNextPulseSurvey: 'Muokkaa seuraavaa pulssikyselyä',
  editChoices: 'Muokkaa valintoja',
  defaultSchedule: 'Oletusaikataulu',
  selectQuestions: 'Valitse kysymyksiä',
  selectQuestionsNotScheduledOtherwise: 'Valitse kysymykset joita ei ajastettu',
  unscheduled: 'Ajastamaton',
  unschedule: 'Poista ajastus',
  seeFullSchedule: 'Katso koko aikataulu',
  select: 'Valitse',
  selectQuestionsFromLastRound: 'Valitse kysymykset viime kyselystä',
  slackAllowJoinWorkspace: 'Salli Slack-työtilan jäsenten liittyä Teamspective-työtilaan',
  customSchedule: 'Muokkaa aikataulua',
  customScheduleWarning: (customLimit: number) =>
    `Aikataulua voi muokata vain ${customLimit} kysymykselle kerrallaan. Valitse Perusasetus ja anna automaation hoitaa hommansa aina kun mahdollista!`,
  recommended: 'Suositus',
  resources: 'Resursseja',
  pulseSurvey: 'Pulssikysely',
  pulseSurveyOnboardingDescription:
    'Pulssikysely on terveen palautekulttuurin kivijalka, jonka avulla kerätään henkilöstön palautetta ja tunnistetaan oikeat haasteet tiimeissä. Jokaisessa Teamspective-työtilassa on pulssikysely, jonka asetuksia voi muokata.',
  soundsGood: 'Kuulostaa hyvältä',
  workspaceNameWithExample: 'Työtilan nimi (esim. yrityksesi nimi)',
  workspaceCreateDescription:
    'Työtilan luominen ja Teamspectiven kokeileminen on ilmaista. Saat työtilan admin-tunnukset, ja voit halutessasi lisätä muita admin käyttäjiä.',
  lookingForExistingWorkspaceTitle: 'Etsitkö jo olemassa olevaa työtilaa?',
  lookingForUnlistedWorkspaceTitle: 'Etsitkö työtilaa joka ei ole listassa?',
  lookingForExistingWorkspaceDescription:
    'Emme löytäneet yhtään työtilaa, johon voisit liittyä sähköposti domainisi perusteella. Jos tiedät yrityksesi käyttävän Teamspectivea, pyydä kutsu työtilaan HR:ltä tai muulta admin-käyttäjältä.',
  connectSlack: 'Yhdistä Teamspective ja Slack',
  connectMsTeams: 'Yhdistä Teamspective ja Microsoft Teams',
  slackConnectDescription: 'Saamme asiakkailtamme kiitosta erinomaisesta Slack-käyttökokemuksesta',
  slackConnectPoints: [
    'Vastaa pulssikyselyihin sekunneissa siirtymättä selaimeen',
    'Vastaanota ilmoitukset Slackiin',
    'Lähetä palautetta ja lisää käyttäjiä suoraan Slackissa',
  ],
  inviteUsersEncouragement:
    'Saat suurimman hyödyn Teamspectivesta kutsumalla tiimisi jäsenet työtilaan.',
  freeUsersDescription: 'Teamspective on ilmainen käyttää 30 käyttäjään asti.',
  slackChannelPulseTitle: 'Valitse Slack-kanava pulssikyselyn jakamista varten',
  slackChannelPulseDescription:
    'Tee muille helpoksi löytää Teamspective. Ensimmäiset kyselyt lähetetään valitsemallesi kanavalle.',
  slackAdminRightsRequired: 'Tarvitse Slack admin-oikeudet',
  slackConnectAdminRights:
    'Tarvitset Slack admin oikeudet tähän toimenpiteeseen. Jos sinulla ei ole niitä, Ohita tämä vaihe ja kutsu admin käyttäjä palveluun seuraavassa vaiheessa.',
  pulseSurveyImageCaption: 'Pulssikysely näyttää tältä',
  workspaceSetupReady: 'Teamspective-työtilasi on valmis!',
  startExploring: 'Aloita 360 palaute',
  createTemplates: 'Luo palautepohjat',
  columns: 'Sarakkeet',
  addToMsTeams: 'Lisää Microsoft Teamsiin',
  msTeamsConnected: 'Microsoft Teams on nyt yhdistetty työtilaasi',
  heatmaps: 'Ryhmien tulokset',
  defaultPulseCommentVisibility:
    'Oletusvalinta kommentin näkyvyydelle käyttäjän lisätessä kommenttia',
  adminsOfUsersGroups: 'Ylläpitäjät käyttäjän ryhmissä',
  membersOfUsersGroups: 'Jäsenet käyttäjän ryhmissä',
  defaultCommentVisibilityTooltip: `Jos käyttäjä ei ole minkään ryhmän jäsen, tai jos käyttäjän ryhmien koot ovat piilotusrajan alapuolella, oletusvalinta on ${getQuotedString(
    'Työtilan hallinnoijat',
    'fi'
  )}`,
  selectMsTeamsTeamToConnect: 'Valitse yhdistettävä Microsoft Teams -tiimi',
  detectedNetworkRoles: 'Havaitut verkostoroolit',
  networkProfile: 'Verkostoprofiili',
  myNetworkProfile: 'Minun verkostoprofiili',
  evaluationRequestsSent: ({ multiple, scheduled }: { multiple: boolean; scheduled: boolean }) =>
    `Palautepyyn${multiple ? 'nöt' : 'tö'} ${scheduled ? 'ajastettu' : 'lähetetty'}`,
  noQuestionnaire:
    'Työtilasi palautepohjassa on ongelma. 😞 Ota yhteys työtilasi ylläpitäjään ongelman ratkaisemiseksi.',
  externalEvaluationHint:
    'Käytä selainkäyttöliittymää jos haluat pyytää palautetta Teamspective-työtilan ulkopuolelta.',
  editAnswer: 'Muokkaa vastausta',
  evaluateLater: 'Anna palautetta myöhemmin',
  median: 'Mediaani',
  fiveStepPercentileImpact_0: 'Perustaso',
  fiveStepPercentileImpact_1: 'Kohtalainen',
  fiveStepPercentileImpact_2: 'Kohonnut',
  fiveStepPercentileImpact_3: 'Korkea',
  fiveStepPercentileImpact_4: 'Poikkeuksellinen',
  yourAnswers: 'Sinun vastauksesi',
  percentileRank: 'Prosenttipiste',
  topTenInGroup: 'korkein 10% ryhmässä',
  taxId: 'Veronumero',
  promoCode: 'Alennuskoodi',
  discount: 'Alennus',
  subscriptionMembersInfo:
    'Kun työtilaan lisätään käyttäjiä tai epäaktiivisia käyttäjiä aktivoidaan, heidät lisätään tilauksen seuraavalle laskulle',
  addons: 'Laajennukset',
  organisationalNetworkAnalysis: 'Organisaation verkostoanalyysi',
  hrisIntegration: 'HRIS-integraatio',
  optional: 'ei pakollinen',
  workspaceDeactivatedAdmin: (workspaceName: string) =>
    `Työtilasi ${workspaceName} on poissa käytöstä epäaktiivisuuden vuoksi. Työtilan tiedot ovat tallessa ja voit aktivoida sen uudelleen koska vain.`,
  workspaceDeactivatedMember: (workspaceName: string) =>
    `Työtilasi ${workspaceName} on poissa käytöstä epäaktiivisuuden vuoksi. Ota yhteys työtilasi admin-käyttäjään jatkaaksesi Teamspectiven käyttöä.`,
  activateWorkspace: 'Aktivoi työtila',
  switchWorkspace: 'Vaihda työtilaa',
  actionRequired: 'Vaatii toimenpidettä',
  now: 'Nyt',
  notInUse: 'Ei käytössä',
  xAxis: 'X-akseli',
  yAxis: 'Y-akseli',
  timeRange: 'Aikaväli',
  noEvaluationsInSelectedTimeRange: 'Ei palautetta valitulla aikavälillä',
  byEvaluation: 'Palautteet',
  byQuestion: 'Kysymykset',
  byTemplate: 'Palautepohjat',
  byEvaluee: 'Käyttäjät',
  evaluatedUsers: 'Käyttäjät joilla on palautetta',
  usersWithPendingFeedbackRequests: 'Käyttäjät joilla on keskeneräisiä palautepyyntöjä',
  usersWithExpiredFeedbackRequests: 'Käyttäjät joilla on vanhentuneita palautepyyntöjä',
  notEvaluatedUsers: 'Käyttäjät joilla ei ole palautetta',
  group: 'Ryhmä',
  showGroups: 'Näytä ryhmät',
  hideGroups: 'Piilota ryhmät',
  pendingFeedbackRequests: 'Keskeneräiset palautepyynnöt',
  completedEvaluations: 'Valmiit palautteet',
  expiredEvaluations: 'Vanhentuneet palautepyynnöt',
  selectedEvaluations: 'Valitut palautteet',
  deleteSelectedEvaluations: 'Poista valitut palautteet',
  rescheduleSelected: 'Ajasta uudelleen valitut',
  areYouSureCreateNewWorkspace: 'Oletko varma? Et voi liittyä näihin työtiloihin myöhemmin',
  createNewCompanyWorkspace: 'Luo yritykselle uusi työtila',
  howToFindWorkspaceAdvice: `Jos yritykselläsi pitäisi olla työtila mutta et voi liittyä siihen tässä, nämä voivat olla mahdollisia syitä:

  • Työtilan hallinnoija ei ole lisännyt sinua työtilaan
  • Sähköpostiosoitteesi ei täsmää profiiliin jonka työtilan hallinnoija on lisännyt
  • Et käyttänyt kutsulinkkiä

Selvitä HR:ltä, voisiko kyse olla jostain näistä.`,
  tryDifferentEmail: 'Kokeile toista osoitetta',
  wantToCreateNewCompanyWorkspace: 'Haluatko aloittaa uuden työtilan?',
  or: 'tai',
  joinWorkspaceBelow: 'Liity työtilaan alta',
  invitationsFor: 'Kutsut käyttäjälle',
  noInvitationsFor: 'Ei kutsuja käyttäjälle',
  completeYourProfile: 'Täydennä profiilisi',
  youAreLoggedInAs: 'Olet kirjautunut käyttäjänä',
  completeProfileInstructions: 'Seuraa ohjeita täydentääksesi profiilisi ja löytääksesi työtilasi',
  nameOfCompanyOrTeam: 'Mikä on yrityksesi tai tiimisi nimi?',
  nameOfCompanyOrTeamHelp:
    'Tästä tulee Teamspective-työtilasi nimi – valitse jotain jonka jäsenet tunnistavat.',
  reaction_agree: 'Samaa mieltä',
  reaction_thanks: 'Kiitos kommentista',
  reaction_team_discussion: 'Puhutaan tiimipalaverissa',
  reaction_personal_discussion: 'Puhutaan 1-1',
  reaction_important: 'Tämä on tärkeää',
  addReaction: 'Lisää reaktio',
  selectTime: 'Valitse aika',
  startingFrom: 'Alkaen',
  exportSuccess: 'Vienti onnistui',
  exportCanceled: 'Vienti peruttiin',
  exporting: 'Viedään...',
  exportingSlide: 'Viedään diaa',
  resultsOverTime: 'Tulokset aikajanalla',
  notes: 'Muistiinpanot',
  connectSsoDescription:
    'Yhdistä SSO-tarjoaja ja Teamspective-työtila käyttääksesi Single Sign-On -kirjautumista. Kun SSO on käytössä, muut kirjautumismenetelmät eivät ole käytössä työtilallesi.',
  ssoConnectedDescription: (ssoProvider: string) =>
    `Työtilassasi on käytössä ${ssoProvider} Single Sign-On (SSO) -kirjautuminen. Muut kirjautumismenetelmät eivät ole käytössä.`,
  activate: 'Aktivoi',
  instructions: 'Ohjeet',
  selectTopics: 'Valitse aiheet',
  membersOf: 'Ryhmän jäsenet:',
  publishToUser: 'Julkaise käyttäjälle',
  chooseEvaluators: 'Valitse henkilöt ja palautepohjat',
  addMultipleEvaluatorsTip:
    'Kerää palautetta vähintään kolmelta henkilöltä varmistaaksesi laadukkaat tulokset',
  checkEvaluatorEmail:
    'Tarkistathan että ulkopuolisen palautteen antajan sähköpostiosoite on kirjoitettu oikein',
  hrisIntegrationActive: 'HRIS-integraation aktivoitu',
  hrisIntegrationNotInUse:
    'Työtilassa ei ole käytössä HRIS-integraatiota. Integraation avulla käyttäjien ja ryhmien hallinta voidaan automatisoida. Aktivoi integraatio alta tai ota yhteyttä Teamspectiven asiakasvastaavaan tai tukeen saadaksesi lisätietoa integraatioista.',
  integrationLabel: 'Järjestelmä',
  sync: 'Synkronoi',
  integrationActivated: 'HRIS-integraatio on nyt aktiivinen',
  integrationImmediateSyncDescription:
    'Viimeinen askel on käyttäjien ja ryhmien synkronointi HR-järjestelmästä. Voit synkronoida välittömästi klikkaamalla alta. Ensimmäisessä synkronoinnissa voi kestää muutama minuutti.',
  integrationImmediateSyncWarning:
    'Huomio: mikäli työtilassasi on jo tuloksia esimerkiksi Pulse-työkalusta, paina ohita. Ota yhteys Teamspectiven asiakasvastaavaan (chat tai hello@teamspective.com) niin autamme saamaan integraation valmiiksi säilyttäen aiemmat tulokset.',
  integrationSyncUsers: 'Synkronoi käyttäjät',
  integrationSyncTeams: 'Synkronoi ryhmät',
  integrationSyncSuccessful: 'Integraation tiedot haettu',
  integrationSyncDelayed: 'Odotetaan dataa integraatiosta. Hakeminen voi kestää muutaman minuutin.',
  integrationLastRun: 'Viimeksi synkronoitu',
  allQuestions: 'Kaikki kysymykset',
  showOnlyUnpublished: 'Näytä vain julkaisemattomat',
  unpublished: 'Julkaisematon',
  unpublishedResults: 'Julkaisematon tulos',
  resultsForGroup: (groupName: string) => `Tulokset ryhmälle ${groupName}`,
  resultsForCohort: 'Tulokset vastaajille joilla on valittu arvo',
  resultsForUser: 'Tulokset henkilölle',
  evaluationOverviewRequiresThemes:
    'Jotta voit nähdä palautteen yleiskuvan, sinun on lisättävä kysymyksiin teemoja.',
  published: 'Julkaistu',
  notPublished: 'Ei julkaistu',
  publishNewResultsToUser: 'Julkaise uudet tulokset käyttäjälle',
  allResultsArePublishedToUser: 'Kaikki tulokset on tarkastettu ja julkaistu',
  evaluationPublishing_discouraged:
    'Palautteen julkaisemista ei suositella ennen kuin palautetta on saatu vähintään kolmelta palautteen antajalta. Laadukkaiden tulosten ja anonymiteetin varmistamiseksi pyydä lisää palautetta.',
  evaluationPublishing_moderate:
    'Voit piilottaa yksittäisiä kommenttejä vastaanottajalta. Keskiarvot näytetään vastaanottajalle.',
  iHaveModeratedCommentsAbove: 'Olen tarkastanut ylläolevat kommentit',
  commentRedacted: 'Kommenttia ei näytetä käyttäjälle',
  commentShown: 'Kommentti näytetään käyttäjälle',
  notToBeShownToUser: 'Ei näytetä käyttäjälle',
  toBeShownToUser: 'Näytetään käyttäjälle',
  shownToUser: 'Näytetään käyttäjälle',
  notShownToUser: 'Ei näytetä käyttäjälle',
  workspaceRole: 'Rooli työtilassa',
  productPromoTitle: 'Ota Teamspective käyttöön myös teillä!',
  productPromoDescription:
    'Annoit juuri palautetta, joka tukee yhteistyön kehittämistä partneri-organisaationne kanssa. Voit tehdä samaa kehitystä myös omassa yrityksessäsi.',
  customerResults: 'Teamspectiven asiakkaiden tuloksia',
  feedbackSatisfaction: 'Henkilöstön tyytyväisyys palautteeseen',
  increaseInProductivity: 'Tuottavuuden kasvu',
  annualRoi: 'Vuotuinen ROI',
  bookDemo: 'Varaa demo',
  allUsers: 'Kaikki',
  requestSelfEvaluation: 'Pyydä itsereflektiota',
  publishFewEvaluationsClause:
    'Ymmärrän, että tämän palautteen julkaisu voi heikentää palautteen antajien anonymiteettiä',
  evaluationTemplate: 'Palautepohja',
  evaluationTemplates: 'Palautepohjat',
  template: 'Pohja',
  templates: 'Pohjat',
  templateName: 'Palautepohjan nimi',
  templateInformation: 'Palautepohjan tiedot',
  otherTemplates: 'Muut pohjat',
  linear7: '7 pisteen asteikko',
  linear5: '5 pisteen asteikko',
  linear4: '4 pisteen asteikko',
  binary: 'Kyllä/ei',
  nps: 'Net Promoter Score',
  balance5: '5 pisteen asteikko, tasapaino',
  addNewQuestionToThisTemplate: 'Lisää uusi kysymys tähän pohjaan',
  editing: 'Muokataan',
  includeInEvaluationTemplates: 'Sisällytä palautepohjiin',
  selectTemplates: 'Valitse palautepohjat',
  deleteQuestionConfirmation:
    'Haluatko pysyvästi poistaa tämän kysymyksen? Jos jatkat, kysymys poistetaan kaikista palautepohjista.',
  defaultQuestion: 'Oletuskysymys',
  deprecatedQuestion:
    'Tästä kysymyksestä on saatavilla uudempi versio. Suosittelemme käyttämään uutta versiota.',
  unselectedDefaultQuestion: 'Suosittelemme valitsemaan kaikki oletuskysymykset.',
  copyToNewQuestion: 'Kopioi uudeksi kysymykseksi',
  createQuestionSuccess: 'Kysymys luotu onnistuneesti',
  requested: 'Pyydetty',
  viewingOthersEvaluations: 'Tarkastelet toisen käyttäjän palautteita hallinnoijana',
  youHaveNPendingFeedbackRequests: (n: number) =>
    n === 1
      ? 'Sinulla on yksi vastaamaton palautepyyntö'
      : `Sinulla on ${n} vastaamatonta palautepyyntöä`,
  latestScoreByPerson: 'Viimeisin tulos henkilöittäin',
  withinSixMonths: 'Kuuden kuukauden sisällä',
  existingWorkspaceJoinInstruction:
    'Onko organisaatiollasi jo tilaus? Varmista että olet saanut kutsun työtilaan ja ota tarvittaessa yhteys organisaation admin-käyttäjään.',
  whistleblowerIncluded:
    'Each product includes a Whistleblower function that complies with EU legislation',
  account: 'Tili',
  personalFeedback: 'Henkilökohtaiset palautteet',
  snoozeNotification: 'Torkuta viikoksi',
  muteNotification: 'Hiljennä tämä pyyntö',
  notificationSnoozed: 'Tämän pyynnön ilmoitukset torkutettu viikoksi.',
  notificationMuted: 'Tämän pyynnön ilmoitukset hiljennetty.',
  addedToWorkspaceTitle: (workspaceName: string) => `Sinut lisättiin työtilaan ${workspaceName}`,
  addedToWorkspaceBody: (workspaceName: string) => [
    `:white_check_mark: Sinut on lisätty jäseneksi Teamspectiven työtilaan ${workspaceName}.`,
    'Tämä viesti on vain tiedoksesi ja sinun ei tarvitse tehdä mitään uutta palvelussa.',
  ],
  feedbackRequestTitle: (requesterName: string) => `${requesterName} pyytää sinulta palautetta`,
  feedbackRequestBody: (requesterName: string, personalMessage: string | null) => [
    `*${requesterName} on lähettänyt sinulle palautepyynnön Teamspectivessä*.`,
    personalMessage
      ? `:speech_balloon: Liitteenä on henkilökohtainen viesti: _${personalMessage}_`
      : '',
    ':dart: Autamme sinua suunnittelemaan, kirjoittamaan ja jakamaan palautteesi',
  ],
  giveFeedback: 'Anna palautetta',
  youHaveFeedbackTitle: (providerName: string) =>
    `${providerName} lähetti sinulle uutta palautetta`,
  youHaveFeedbackBody: (providerName: string) =>
    `Hyviä uutisia! ${providerName} on lähettänyt sinulle uutta palautetta.`,
  youHaveAnonymousFeedbackTitle: 'Olet saanut palautetta',
  youHaveAnonymousFeedbackBody: 'Hyviä uutisia! Olet saanut uutta palautetta.',

  youHavePraiseTitle: (providerName: string) => `${providerName} lähetti sinulle kehuja!`,
  youHavePraiseBody: (providerName: string) =>
    `Hyviä uutisia! ${providerName} on lähettänyt sinulle kehuja.`,
  seePraise: 'Katso kehut',
  requestCommentReceivedTitle: (commentAuthor: string) =>
    `${commentAuthor} lisäsi kommentin palautekeskusteluunne`,
  seeDiscussionAndReply: 'Katso keskustelu & vastaa',
  anonymousChatMessageReceivedTitle: (workspaceName: string) =>
    `Työtilasi ${workspaceName} on saanut anonyymin viestin`,
  anonymousChatMessageReceivedBody: (workspaceName: string, providerName: string | null) =>
    `${workspaceName} on saanut uuden chat-viestin${
      providerName ? ` käyttäjältä ${providerName}` : ''
    }.`,
  anonymousChatReminderTitle: (workspaceName: string) =>
    `Muistutus: Työtilasi ${workspaceName} on saanut viestin`,
  anonymousChatReminderBody: (workspaceName: string) =>
    `Työtilasi ${workspaceName} on saanut viestin Teamspectivessä, mutta kukaan ei ole vielä vastannut siihen. Muista lukea palaute ja kuitata se vastaamalla.`,
  replyToFeedback: 'Vastaa palautteeseen',
  workspaceFeedbackCommentReceivedTitle: (workspaceName: string, isFeedbackProvider: boolean) =>
    `${
      isFeedbackProvider ? 'Palautettasi' : 'Palautetta'
    } työtilalle ${workspaceName} on kommentoitu.`,
  seeDiscussion: 'Katso keskustelu',
  feedbackReminderTitle: (requesterName: string) =>
    `Sinulla on avoin palautepyyntö, jonka vastaanottajana on ${requesterName}.`,
  feedbackReminderHint: () =>
    'Jos mietit vielä mitä kirjoittaa, kannattaa mainita vastaanottajalle, että vastaat pian ja ettet ole unohtanut. Teamspective tarjoaa myös tukea palautteen muotoiluun kirjoittaessasi – kannattaa kurkata ne inspiraatioksi!',
  pulseReminderTitle: 'Pulssimuistutus',
  pulseGreeting: 'Hei!',
  pulseReminderBody: (teamName: string, closingTime: string) => [
    `:heartbeat: Panostasi tarvitaan, jotta tiimisi (${teamName}) pulssitulokset saadaan valmiiksi.`,
    `:bell: Pulssi sulkeutuu ${closingTime}`,
    ':thinking_face: Otapa muutama hetki miettien tätä viikkoa ja kerro tiimillesi miten sinulla menee – jaa ilot ja huolet.',
  ],
  evaluationRequestWithPendingBody:
    'Huomaa että sinulla on vielä edellinen palautepyyntö johon et ole vastannut. Jos se ei ole enää ajankohtainen, voit ohittaa palautteen ja aloittaa seuraavan.',
  answer: 'Vastaa',
  evaluationExpires: (expires: string) => `Palaute on annettava ${expires} mennessä`,
  evaluationReminderTitle: 'Palautettasi tarvitaan',
  evaluationReminderBody: (pendingEvaluationsHeading: string, expires: string) =>
    `${pendingEvaluationsHeading}! Palaute on annettava ${expires} mennessä.`,
  evaluationFinalReminderTitle: 'Tunti aikaa jäljellä: Palautettasi tarvitaan',
  evaluationFinalReminderBody: (pendingEvaluationsHeading: string) =>
    `${pendingEvaluationsHeading}! Voit vastata vielä tunnin ajan.`,
  evaluationExtendedTitle: 'Vastausaikaa jatkettu',
  evaluationExtendedBody: (formattedDeadline: string, variant: 'plain' | 'emoji') =>
    `Myöhästyit ensimmäisestä määräajasta, mutta sait lisäaikaa. Vastaa ${formattedDeadline} mennessä.${variant === 'emoji' ? ' :ring_buoy:' : ''}`,
  evaluationExtendedLastChanceTitle: 'Palautettasi tarvitaan',
  evaluationExtendedLastChanceBody: (timeLeftHours: number, variant: 'plain' | 'emoji') =>
    `${variant === 'emoji' ? ' :bell: ' : ''}Vain ${timeLeftHours} tunti${timeLeftHours === 1 ? '' : 'a'} vastausaikaa jäljellä! Mielipidettäsi kaivataan. ${variant === 'emoji' ? ' :pray:' : ''}`,
  pulseFinalReminderTitle: 'Viimeinen mahdollisuus vastata – pulssi sulkeutuu tunnin kuluttua',
  pulseFinalReminderBody: (closingTime: string) =>
    `Sinulla on vielä tunti aikaa vastata tiimisi pulssikyselyyn. Pulssi sulkeutuu ${closingTime}.`,
  pulseResultsReadyTitle: 'Pulssitulokset ovat valmiita',
  pulseResultsReadyBody: (teamName: string, answerCount: number) =>
    `Pulssin tulokset ovat valmiina tiimillesi, ${teamName}. Tähän pulssiin tuli yhteensä ${answerCount} vastausta.`,
  pulseResultsReadyComments: (commentCount: number) =>
    `${commentCount === 1 ? 'Yksi uusi kommentti' : `${commentCount} uutta kommenttia`} on nähtävilläsi`,
  pulseResultsReadyHint:
    'Suosittelemme lämpimästi keskustelemaan tuloksista, kun tilanne on siihen sopiva.',
  networkSurveyReminderTitle: 'Verkostokyselymuistutus',
  hey: 'Hei',
  networkSurveyReminderBody: 'Muista vastata yrityksesi verkostokyselyyn.',
  networkSurveyReminderMentions: (mentionCount: number) =>
    `Kolleegasi ovat maininneet sinut jo ${mentionCount} kertaa, mutta yhteyksiäsi ei ole vielä lisätty.`,
  networkSurveyReminderNudge: 'Kysely sulkeutuu pian, mutta yhteyksiäsi ei ole vielä lisätty.',
  invitationAndTeamTitle: 'Tervetuloa Teamspectiveen',
  invitationAndTeamGreeting: 'Tervehdys, tässä Teamspective!',
  invitationAndTeamBody: (
    teamName: string,
    tosLink: (text: string) => string,
    privacyPolicyLink: (text: string) => string
  ) =>
    `Slack-tilisi on linkitetty työtilaan ${teamName}. Käyttämällä Teamspectivea, sitoudut palvelun ${tosLink(
      'käyttöehtoihin'
    )} ja ${privacyPolicyLink('tietosuojaselosteeseen')}.`,
  invitationAndTeamBenefits: 'Teamspective auttaa sinua kehittämään työpaikkaasi kolmella tavalla:',
  invitationAndTeamBenefitsPulse:
    'Pulssikysely: Kerro ideasi ja kokemuksesi turvallisesti ja helposti, jotta oikeisiin haasteisiin tiimityössä ja johtamisessa osataan tarttua',
  invitationAndTeamBenefitsFeedback:
    'Palaute: Käy merkityksellisiä keskusteluja tärkeimpien työkavereidesi kanssa, kerää palautetta ja saa enemmän ohjausta ja tunnustusta',
  invitationAndTeamBenefitsNetwork:
    'Verkostoanalyysi: Auta kehittämään yrityksesi toimintaa pohtimalla yhteistyötä ja vuorovaikutusta yrityksessäsi',
  invitationAndTeamEnding:
    'Voit käyttää Teamspectivea täällä Slackissa tai selaimessa. Mukavaa päivänjatkoa!',
  pulseCommentReactionTitle: 'Pulssikommenttiisi on reagoitu',
  pulseCommentReactionBody: (reactionCount: number, reactionsInMultipleComments: boolean) =>
    `${reactionsInMultipleComments ? 'Kommentteihisi' : 'Kommenttiisi'} on uusi${
      reactionCount > 1 ? 'a reaktioita' : ' reaktio'
    }`,
  seeCommentsAndReactions: 'Katso kommentit ja reaktiot',
  pulseCommentReplyTitle: 'Uusi vastaus kommenttiisi',
  pulseCommentReplyBody: 'Kommenttiisi on tullut uusi vastaus.',
  pulseCommentReplierWrote: (replierName: string | null) =>
    `${replierName ?? 'Kolleegasi'} kirjoitti:`,
  updatesInYourWorkspaceTitle: 'Päivityksiä työtilassasi',
  updatesInYourWorkspaceIntro: (workspaceName: string) =>
    `Hei! Kokosimme viimeisimmät automaattiset muutokset työtilanne käyttäjissä. Lähetimme viestin sinulle, koska olet työtilan ${workspaceName} admin-käyttäjä.`,
  updatesInYourWorkspaceAddedUsers: 'Uusia käyttäjiä Teamspectivessä ja työtilassa',
  updatesInYourWorkspaceAddedUsersNote:
    'Uudet käyttäjät eivät kuulu mihinkään työtilasi tiimiin. Voit lisätä heidät!',
  updatesInYourWorkspaceInvitedUsers: 'Käyttäjiä kutsuttu työtilaan',
  updatesInYourWorkspaceInvitedUsersNote:
    'Näille käyttäjille löytyi Teamspective-tunnukset, mutta he puuttuivat työtilastasi. Heidät kannattaa lisätä oikeisiin tiimeihin.',
  updatesInYourWorkspaceDeactivatedUsers: 'Käyttäjiä poistettu työtilasta',
  updatesInYourWorkspaceDeactivatedUsersNote:
    'Nämä käyttäjät eivät ole enää aktiivisia työntekijäjärjestelmässänne ja heidät poistettiin Teamspective-työtilastanne.',
  feedbackSharedTitle: 'Kollega on jakanut sinulle saamaansa palautetta',
  feedbackSharedBody: (sharerName: string) => `${sharerName} jakoi sinulle saamaansa palautetta.`,
  selfReflectionSharedTitle: 'Kollega on jakanut sinulle tekemäänsä itsereflektiota',
  selfReflectionSharedBody: (sharerName: string) =>
    `${sharerName} jakoi sinulle tekemäänsä itsereflektiota.`,
  seeSelfReflection: 'Katso itsereflektio',
  workspaceActivationTitle: 'Työtilan palautteen aktivointi suoritettu',
  workspaceActivationBody: (workspaceName: string) =>
    `Hei, lähetimme juuri seuraavat palautteen aktivoinnit työtilassasi ${workspaceName}:`,
  workspaceActivationListConnections: (activatedListConnections: number) =>
    `Listaa kontaktit: ${activatedListConnections} ihmistä`,
  workspaceActivationRequests: (activatedRequests: number) =>
    `Pyydä palautetta: ${activatedRequests} ihmistä`,
  workspaceActivationPraise: (activatedPraise: number) =>
    `Lähetä kehuja: ${activatedPraise} ihmistä`,
  evaluationsPublishedTitle: 'Sinulle on uusia palautteita',
  seeEvaluations: 'Katso palaute',
  evaluationsPublishedHint:
    'Ota hetki aikaa käydäksesi ne läpi ja koeta miettiä uusia kasvun mahdollisuuksia. Usein on myös hyödyllistä käydä näitä palautteita läpi yhdessä luotetun kollegan, esihenkilön tai valmentajan kanssa.',
  evaluationsReviewableTitle: 'Sinulla on palautteita tarkastettavana',
  evaluationsReviewableBody: (evalueeName: string) =>
    `Sinulla on palautteita tarkastettavana käyttäjälle ${evalueeName}`,
  review: 'Tarkasta',
  evaluationsReviewableBodyMore: (moreCount: number) => `...ja ${moreCount} muuta.`,
  plusMore: (moreCount: number) => `+ ${moreCount} muuta`,
  evaluationsReviewableHint:
    'Odota kaikkien palautteiden valmistumista ennen tulosten julkaisua käyttäjälle. Tämä varmistaa julkaistun palautteen anonymiteetin ja laadun.',
  selectYourProducts: 'Valitse tuotteesi',
  selectProductRecommendation: 'Suosittelemme aloittamaan Pulsesta, mutta sinä tiedät parhaiten.',
  costPerUser: 'Hinta per käyttäjä',
  annualBillingWithDiscount: 'Vuosilaskutus, -15%',
  monthlyBilling: 'Kuukausilaskutus',
  freeOptionDescription:
    '14 päivän ilmainen testijakso kaiken kokoisille yrityksille. Ilmainen alle 30 työntekijän yrityksille.',
  onboardingTabProfile: 'Profiili',
  onboardingTabProducts: 'Tuotteet',
  onboardingTabWorkspace: 'Työtila',
  openFeatureList: 'Avaa ominaisuuslista',
  closeFeatureList: 'Sulje ominaisuuslisa',
  securityAndCompliance: 'Turvallisuus ja yksityisyys',
  includedServices: 'Sisällytetyt palvelut',
  slack: 'Slack',
  googleCalendar: 'Google Kalenteri',
  msTeams: 'Microsoft Teams',
  email: 'Sähköposti',
  hrisIntegrationsAndCohorts:
    'HRIS-integraatiot ja käyttäjäkohortit (Personio, Hibob, Bamboo, Workday, ja monta muuta)',
  gdprCcpaCompliant: 'Täysin GDPR- ja CCPA-yhteensopiva',
  dedicatedAccountManager: 'Oma asiakkuuspäällikkö',
  leaderChatSupport: 'Chat-tuki kaikille johtajille',
  webinars: 'Webinaarit',
  workspaceReadyStartExploring:
    'Teamspective auttaa parantamaan organisaation terveyttä, tiimien tuottavuutta ja hyvinvointia tarjoamalla käytännöllistä henkilöstöanalytiikkaa.',
  featuredInSlackAppDirectory: 'Esitelty Slack App Directoryssa',
  importUsersAutomatically: 'Tuo käyttäjät automaattisesti',
  higherResponseRates: 'Korkeammat vastausprosentit',
  zeroTimeNotificationAction: 'Välittömästi ilmoituksesta toimintaan',
  continueWithoutConnecting: 'Jatka yhdistämättä',
  bookAProductKickOff: 'Varaa tuotteen kick-off-tapaaminen',
  productKickOffDescription:
    'Tarjoamme henkilökohtaisen aloituspalaverin asiantuntijoidemme kanssa, jotta pääset heti täyteen vauhtiin palautteen kanssa.',
  profileCreationDescription: 'Seuraa ohjeita luodaksesi profiili ja löytääksesi työtilasi..',
  selectYourWorkspace: 'Valitse työtila',
  otherCommercialOptions: 'Etsitkö jotain muuta? Ota yhteyttä.',
  whistleBlowerIncluded:
    'ilmoittajansuojelu- eli whistleblowing-kanava kuuluu kaikkiin tuotteisiimme.',
  free: 'Ilmainen',
  selectAtLeastOneProduct:
    'Valitse vähintään yksi tuote jatkaaksesi. Jos tarvitset lisää tietoa tuotteista, ota yhteyttä myyntiimme.',
  onlyFewUsersAdded: 'Vähän käyttäjiä lisätty',
  fewUsersDescription:
    'Vähintään kolme käyttäjää työtilassa mahdollistaa paremman anonymiteetin ja käyttökokemuksen.',
  companySize: 'Yhtiön koko',
  yourFeedbackRequestWasSentTo: (receiverName: string) =>
    `Palautepyyntösi lähetettiin käyttäjälle ${receiverName}!`,
  somethingWentWrongRequestNotSent: 'Jokin meni vikaan ja pyyntöäsi ei lähetetty :disappointed:',
  heresYourMessage: 'Tässä kirjoittamasi viesti jos tarvitset sitä:',
  requestFeedback: 'Pyydä palautetta',
  whoDoYouWantFeedbackFrom: 'Keneltä haluat palautetta?',
  selectPerson: 'Valitse henkilö',
  addCustomMessage: 'Lisää henkilökohtainen viesti',
  whoCanSeeThisFeedback: 'Kuka näkee tämän palautteen?',
  close: 'Sulje',
  whoDoYouWantFeedbackTo: 'Kenelle haluat antaa palautetta?',
  selectedUserNotInTeamspective: 'Valittua käyttäjää ei löytynyt Teamspectivesta.',
  selectedUserNotInWorkspace: 'Valittua käyttäjää ei löytynyt Teamspective-työtilastasi.',
  somethingWentWrongDraftNotCreated:
    'Jokin meni vikaan ja luonnosta palautteen kirjoittamiseen ei voitu luoda :(',
  timeToReplyTooSoon: 'Vastausaikaa pitää olla vähintään päivä.',
  yourEvaluationRequestWasSentTo: (receiverName: string) =>
    `Palautepyyntösi lähetettiin käyttäjälle ${receiverName}!`,
  whoDoYouWantEvaluationFrom: 'Keneltä haluat palautetta?',
  selectEvaluationType: 'Valitse palautten tyyppi',
  selectDateTimeToReply: 'Valitse vastausaika',
  askForEvaluation: 'Pyydä 360 palautetta',
  sendToPublicSlackChannelLabel: 'Lähetä kehut myös julkiselle Slack-kanavalle',
  writeYourPraise: 'Kirjoita kehusi',
  praiseHint:
    'Sano kiitos, näytä arvostuksesi ja anna tunnustusta hyvästä työstä. Kerro tilanteesta missä henkilö osoitti erinomaisuutensa. Mitä havaitsit? Miltä sinusta tuntui tilanteessa? Täyttyikö jokin tarve (tiimisi tai sinun) toiminnan seurauksena? Nyt on hieno hetki näyttää kiitollisuutesi.',
  feedbackSentRecipientsNotified: 'Palautteesi on lähetetty ja vastaanottajille ilmoitettu siitä.',
  sendAnonymousChat: 'Lähetä anonyymi viesti',
  oneTopicNeedsToBeAnswered: 'Sinun tulee vastata ainakin yhteen aiheeseen.',
  feedbackSentTo: (receiverName: string) => `Palautteesi käyttäjälle ${receiverName} on lähetetty!`,
  provideFeedback: 'Lähetä palaute',
  requestFeedbackFromHomeTab: 'Voit pyytää palautetta Home-välilehdeltä.',
  feedbackRequestSentAt: (requestCreated: string) => `Palautepyyntö lähetetty ${requestCreated}`,
  visibility: 'näkyvyys',
  visibleToYouAndRecipient: 'Näkyy sinulle ja vastaanottajalle.',
  allEvaluationsCompleted: 'Kaikki palautteet annettu',
  evaluationCompleteFor: (evalueeName: string) => `Palaute valmis käyttäjälle ${evalueeName}`,
  evaluationRequestExpiredOn: (expiredAt: string) => `Tämä palautepyyntö vanheni ${expiredAt}`,
  evaluationRequestExpiredFor: (evalueeName: string) =>
    `Palautepyyntö käyttäjälle ${evalueeName} on vanhentunut`,
  evaluationRequestSkipped: 'Palautepyyntö ohitettu',
  evaluating: (evalueeName: string) => `Annetaan palautetta henkilölle ${evalueeName}`,
  evaluatingStarted: (evalueeName: string) => `Henkilön ${evalueeName} palaute aloitettu`,
  selfEvaluationStarted: 'Itsereflektio aloitettu',
  evaluationSummary: 'Palautteen tiivistelmä',
  reviewAndSendEvaluation: 'Tarkasta ja lähetä palaute',
  sendEvaluation: 'Lähetä palaute',
  evaluationRequested: 'Palaute pyydetty',
  evaluationIn: (teamName: string) => `Palaute tiimissä ${teamName}.`,
  expiresAt: (expiresAt: string) => `Vanhenee ${expiresAt}.`,
  onlyPendingEvaluation: 'Tämä on ainoa keskeneräinen palautepyyntösi.',
  pendingEvaluationsCount: (pendingCount: number) =>
    `Sinulla on ${pendingCount} keskeneräistä palautepyyntöä.`,
  feedbackActivation_done:
    'Kiitos! Annamme sinulle parempia palaute-ehdotuksia näiden tietojen perusteella. :dart:',
  survey_done:
    ':white_check_mark: Vastauksesi on tallennettu. Kiitos kun otit osaa verkostokyselyyn!',
  feedbackActivation_prompt:
    ':handshake: Kerro ketkä ovat tärkeimpiä kollegojasi, jotta voimme jatkossa antaa sinulle ja heille osuvampia suosituksia.',
  survey_prompt:
    'Yrityksesi tutkii sosiaalista verkostoaan! Käytä hetki aikaa ja kerro yhteyksistäsi. Yhdistämällä vastauksia voimme kartoittaa yrityksesi tietovirtoja, siiloja ja pullonkauloja.\n\nPanoksesi on erittäin tärkeää analyysin laadun kasvattamiseen – vastaa niin aikaisin kuin se sinulle sopii!',
  mutedForMonth: 'Selvä. Emme kysy tästä kuukauteen. Hyvää päivänjatkoa!',
  selectPeople: 'Valitse henkilöt...',
  fillBasedOnRecentMonths: 'Ajattele viimeisimpiä kuukausia ja täytä nämä kentät.',
  muteForMonth: 'Hiljennä kuukaudeksi',
  praiseRewardMessage0: `:raised_hands: Kiitos että autoit kollegaasi saamaan tunnustusta työstään ja teoistaan. Kaikkien tulee tulla nähdyksi, jotta pysymme motivoituneina ja sitoutuneina.

Positiivinen vahvistaminen on voimia antava tapa tiimillesi, joten muista tehdä sitä pian uudestaan!`,
  praiseRewardMessage1:
    ':sunglasses: Olet huippu! Maailma tarvitsee ihmisiä kuten sinä, jotka eivät pelkää sanoa kiitos ja antaa toisille tunnustusta. Sanoimmeko jo että olet huippu? Koska olet.',
  praiseActivationMessage0: `:wave: Moi! Muistutan sinua ajoittain antamaan tunnustusta muille hyvästä työstä, jotta he tuntisivat itsensä arvostetuiksi.

Joten kuka on se erityinen ja mitä hän teki?`,
  praiseActivationMessage1: `Tykkäätkö tulla tykätyksi? Tottakai, kaikki tekevät niin!

Yritä sanoa kiitos jollekin joka auttoi sinua, tai osoittaaksesi että joku on tehnyt erinomaista työtä.`,
  praiseActivationMessage2:
    'Tässä on tilaisuus tehdä sinun ja jonkun muun päivästä hieman parempi.',
  praiseActivationMessage3: `Tiedätkö mikä on parasta kehuissa? Että se tuntuu hyvältä kaikille osapuolille.

Tässä on tilaisuus tehdä sinun ja jonkun muun päivästä hieman parempi.`,
  praiseActivationMessage4: `Hei! Onko joku auttanut sinua työssäsi viime aikoina?

Olisi hienoa jos he kuulisivat sinun sanovan sen, ja tässä on mahdollisuutesi!`,
  praiseActivationMessage5:
    ':wave: Moi! Onko joku tehnyt loistavaa työtä lähiaikoina ja ansaitsisi tunnustusta?',
  praiseActivationMessage6: `:wave: Moi! Onko joku tehnyt loistavaa työtä lähiaikoina ja ansaitsisi tunnustusta?

Olisi hienoa jos he kuulisivat sinun sanovan sen, ja tässä on mahdollisuutesi!`,
  praiseActivationMessage7:
    'Yritä sanoa kiitos jollekin joka auttoi sinua, tai osoittaaksesi että joku on tehnyt erinomaista työtä. :star-struck:',
  praiseActivationMessage8:
    ':bulb: Palautteen ja tunnustuksen saaminen tekee työstä miellyttävää ja motivoivaa. Muistutan, että myös sinä voit osallistua tähän työpaikallasi.',
  praiseActivationMessage9:
    ':sunny: Tässä on tilaisuus tehdä sinun ja jonkun muun päivästä hieman parempi.',
  quickSuggestions: 'Ehdotukset',
  praiseFor: (name: string) => `Kiitä käyttäjää ${name}`,
  startWriting: 'Aloita kirjoittaminen',
  askFeedbackFrom: (name: string) => `Pyydä käyttäjältä ${name}`,
  feedbackActivationMessage: (
    currentCount: number
  ) => `:speech_balloon: *Hei, on aika pyytää palautetta*

Laadukas palaute kollegoilta, asiakkailta tai esihenkilöltä johtaa parempaan suoriutumiseen, sujuvampaan yhteistyöhön ja toimivimpiin ihmissuhteisiin.

Kuinka paljon palautetta on tarpeeksi? Olemme havainneet, että palautteen saaminen kolmelta henkilöltä kolmen kuukauden välein on riittävästi toimivan palautekulttuurin ylläpitämiseksi.

Viimeisen kolmen kuukauden aikana ${
    currentCount === 0
      ? 'et ole pyytänyt palautetta'
      : currentCount === 1
        ? 'olet pyytänyt palautetta kerran'
        : currentCount === 2
          ? 'olet pyytänyt palautetta kaksi kertaa'
          : `olet pyytänyt palautetta ${currentCount} kertaa`
  }.`,
  wasSummaryUseful: 'Oliko tiivistelmä hyödyllinen?',
  wasGuidanceUseful: 'Oliko ohjeistus hyödyllinen?',
  pulseSummaryIntro: (messagingPlatformName: string) =>
    `:star: *UUTTA* :star: Olemme tehneet nopean tiivistelmän pulssituloksista tiimeillesi. Paina tiimin nimeä alla nähdäksesi sen ${messagingPlatformName}issa. Tiivistelmät ovat saatavilla tällä hetkellä vain englanniksi.`,
  createAccountBeforeLogin: 'Sinun täytyy luoda tili ennen kirjautumista',
  loginCommandHelp: 'Voit saada kirjautumislinkin Teamspectiveen tällä komennolla.',
  loginLinkText: (link: string) =>
    `Hei! Tässä kirjautumislinkkisi: *<${link}|:point_right: Kirjaudu Teamspectiveen>*. Se on voimassa tunnin ajan. Mukavaa päivää!`,
  loginLinkIntro:
    'Hei! Tässä kirjautumislinkkisi Teamspectiveen. Se on voimassa tunnin ajan. Mukavaa päivää!',
  loginLinkLabel: 'Kirjaudu Teamspectiveen',
  yourLoginLink: 'Kirjautumislinkkisi',
  createAccountBeforePraise: 'Sinun tulee luoda tili ennen kehumista',
  praiseHelp: `Tällä komennolla voit lähettää kollegallesi julkisen kehun.

Käytä komentoa näin:
\`/praise <vastaanottaja(t)> <viesti>\`

Esimerkiksi:
\`/praise @roni Olet huippu!\`

Huomaa että Slack-maininnat poistetaan viestistä, joten käytä pelkkiä nimiä viestissäsi.`,
  noPraiseChannelSet:
    'Tiimilläsi ei ole määritetty kanavaa kehuille! Valitse kanava tiimisi asetuksista.',
  noPraisedUsers: 'Et nimennyt yhtään käyttäjää.',
  cannotPraiseSelfWithOthers: 'Et voi kehua itseäsi muiden kanssa.',
  praiseInvalidUsers: (userNames: string, message: string) =>
    `Valitettavasti kehujasi ei voitu lähettää koska käyttäjällä ${userNames} ei ole Teamspective-tiliä tai ei kuulu työtilaasi. Tässä viestisi jos vielä tarvitset sitä: \n"${message}"`,
  thanksPraiseSent: 'Kiitos! Kehusi on lähetetty.',
  thanksPraiseSentToChannel: (channel: string) =>
    `Kiitos! Kehusi on lähetetty ja jaettu kanavalle #${channel}.`,
  selfPraiseMessages: [
    'Näytät hyvältä tänään!',
    'Hyvin tehty!',
    'Hyvää työtä!',
    'Uskon sinuun!',
    'Jatka samaan malliin!',
    'Ole ylpeä itsestäsi!',
    'Kehu itseäsi!',
  ],
  developmentFramework: 'Kehittämiskehys (englanniksi)',
  viewFramework: 'Katso kehys',
  editAnswers: 'Muokkaa vastauksia',
  pleaseReviewAndSendEvaluation: 'Tarkasta ja lähetä palautteesi',
  networkSurveyEnded: 'Tämä verkostokysely on päättynyt.',
  answerRequired: 'Tämä vastaus vaaditaan',
  selectPraiseRecipients: (justOne: boolean) =>
    `Valitse kehun vastaanottaja${justOne ? '' : '(t)'}`,
  selectAtLeastOneRecipient: 'Valitse ainakin yksi vastaanottaja',
  pleaseWriteSomething: 'Kirjoita jotain',
  sharePraiseInMsTeamsChannel: (channelName: string) =>
    `Jaa kehut myös MS Teams -kanavalla (${channelName})`,
  surveyHasClosedResults: (surveyName: string) =>
    `${surveyName} on sulkeutunut ja tulokset ovat nyt nähtävissä!`,
  seeTheResultsMarkdown: (link: string) => `Lähde tarkastelemaan [tiimisi tuloksia](${link}).`,
  here: 'täältä',
  selectDateToReply: 'Valitse vastauspäivä',
  selectTimeToReply: 'Valitse vastausaika',
  requiredAndInFuture: 'Pakollinen ja pitää olla tulevaisuudessa',
  required: 'Pakollinen',
  msTeamsIntro: (landingPageLink: (label: string) => string) =>
    `Hei! Olen ${landingPageLink(
      'Teamspective'
    )} botti. Kerron sinulle ilmoituksista Teamspectivesta ja annan sinun vastata pulssikyselyihin suoraan täältä Teamsista.`,
  msTeamsSupportedCommands: 'Tuetut komentoni ovat',
  quotes: [
    'Kuten mikä tahansa taito, palautteen antaminen on helpompaa mitä enemmän teet sitä.',
    'Milloin viimeksi kysyit tiimikaveriltasi miten hänellä menee?',
    'Kohota ihmisiä ja he nostavat sinuakin.',
  ],
  requestOrWriteFeedback: 'Pyydä tai kirjoita palautetta',
  msTeamsInvalidCommand: 'Anteeksi, en ymmärtänyt tuota. Tuetut komentoni ovat',
  msTeamsInvalidCommandHelp: (landingPageLink: (label: string) => string) =>
    `Saadaksesi lisää tietoa Teamspectivesta, käytä _help_ komentoa tai vieraile ${landingPageLink(
      'Teamspectiven sivuilla'
    )}.`,
  goodNews: 'Hyviä uutisia!',
  msTeamsAccountLinked: (email: string) =>
    `Teamspective tilisi (_${email}_) on nyt linkitetty Microsoft Teams -tiliisi. Kerron sinulle ilmoituksista Teamspectivesta ja annan sinun vastata pulssikyselyihin suoraan täällä Teamsissa. Kirjoita _help_ komento saadaksesi lisää tietoa minusta ja muista komennoistani.`,
  ownGroups: 'Omat ryhmät',
  otherGroups: 'Muut ryhmät',
  consecutiveAnswers: 'Peräkkäiset vastaukset',
  answeringTimeSec: 'Vastausaika (s)',
  resultsOut: 'Tulokset julkaistaan',
  reopenEvaluation: 'Avaa palaute uudelleen',
  reopenEvaluationInstruction:
    'Palautepyyntö lähetetään takaisin palautteen antajalle, joka voi tarkastella ja päivittää palautettaan.',
  userInstruction: 'Käyttäjän ohje',
  adminInstruction: 'Admin-käyttäjän ohje',
  addNewChannel: 'Lisää uusi kanava',
  manageChannels: 'Hallitse kanavia',
  anonymousChatRecipientsForChannel: (channelName: string) =>
    `Anonyymin chatin vastaanottajat kanavalle ${channelName}`,
  messageForUser: 'Viesti käyttäjälle',
  messageForRecipient: 'Viesti vastaanottajalle',
  thankYouForFeedback: 'Kiitos palautteestasi',
  anonymousChatChannels: 'Anonyymin chatin kanavat',
  inactive: 'Epäaktiivinen',
  channelCreated: 'Kanava luotu',
  channelUpdated: 'Kanava päivitetty',
  addAnonymousChatChannel: 'Lisää anonyymin chatin kanava',
  guidanceToUser: 'Ohjeistus käyttäjälle',
  guidanceToFeedbackRecipient: 'Ohjeistus palautteen vastaanottajalle',
  anonymousChatActivated: 'Anonyymi chat aktivoitu',
  activateAnonymousChat: 'Aktivoi anonyymi chat',
  activateAnonymousChatDescription:
    'Anonyymi chat mahdollistaa luottamuksellisen viestinnän työntekijöiltä yrityksesi johdolle. Aktivoi EU:n whistleblowing-direktiiviin mukainen whistleblower-kanava ja luo muita omia kanavia anonyymin chatin asetuksissa.',
  anonymousChatChannelManagementDescription:
    'Voit hallita anonyymin chatin kanavia tästä. Kanavat ilman vastaanottajaa ovat epäaktiivisia ',
  noActiveAnonymousChatChannels: 'Anonyymin chatin kanavia ei ole aktiivisena.',
  deleteEvaluationRequestsTitle: 'Poistetaanko palautepyynnöt?',
  deleteEvaluationRequestsDescription:
    'Käyttäjät eivät voi enää vastata pyyntöihin. Mahdolliset vastaukset kysymyksiin poistetaan.',
  evaluationRequestRescheduled: 'Palautepyyntö ajastettu uudelleen',
  evaluationRequestDeleted: 'Palautepyyntö poistettu',
  evaluationRequestNotAvailable: 'Palautepyyntö ei ole saatavilla',
  evaluationRequestNotAvailableDescription:
    'Palautepyyntö ei ole saatavilla. Se on mahdollisesti poistettu.',
  passiveDataSources: 'Passiiviset datalähteet',
  extendNetworkSurveyRound: 'Jatka verkostokyselyä',
  increaseAnsweringTime: 'Lisää vastausaikaa',
  team: 'Tiimi',
  admin: 'Admin',
  me: 'Minä',
  sortBy: 'Järjestä',
  userSettings: 'Käyttäjäasetukset',
  myActivity: 'Oma toiminta',
  takeActionYouHaveNPendingTasks: (n: number) =>
    n !== 1
      ? `Toimi heti! Sinulla on ${n} odottavaa tehtävää.`
      : 'Toimi heti! Sinulla on yksi odottava tehtävä.',
  activity: 'Toiminto',
  person: 'Henkilö',
  details: 'Tiedot',
  activityType_writeFeedback: 'Kirjoita palautetta',
  activityType_evaluate: 'Anna palautetta',
  activityType_receivedFeedback: 'Lukematon palaute',
  activityType_receivedEvaluation: 'Uusi palaute',
  activityType_receivedFeedbackComment: 'Uusi kommentti palautteeseen',
  activityType_waitingForFeedback: 'Odotetaan palautetta',
  activityType_waitingForEvaluation: 'Odotetaan palautetta',
  openList: 'Avaa lista',
  closeList: 'Sulje lista',
  evaluationChart: 'Kaavio',
  evaluationTable: 'Taulukko',
  myEvaluations: 'Palautteeni',
  feedback360: '360 Palaute',
  customizeSurveys: 'Muokkaa kyselyä',
  channelName: 'Kanavan nimi',
  slackChannelName: 'Slack-kanavan nimi',
  link: 'Linkki',
  customGuidance: 'Oma opastus',
  addCustomGuidance: 'Lisää oma ohje',
  pulseCustomGuidanceDescription:
    'Voit näyttää käyttäjille välittömiä ohjeita vastausten petusteella. Valitse kysymys ja vastaus, johon haluat liittää ohjetekstin. Teksti näkyy vastaajalle vastausten yhteenvedossa.',
  addNewGuidance: 'Lisää uusi ohjeistus',
  discoverYourNetwork: 'Löydä verkostosi',
  organizeBy: 'Järjestä',
  observe: 'Katsele',
  clusters: 'Klusterit',
  engagement: 'Aktiiviisuus',
  quartile_top: 'Ylin 25%',
  quartile_topMid: 'Ylempi keski 25%',
  quartile_bottomMid: 'Alempi keski 25%',
  quartile_bottom: 'Alin 25%',
  timeline: 'Aikajana',
  typeToSearchNames: 'Kirjoita ja etsi nimiä',
  remindersSent: 'Muistutukset lähetetty',
  networkSurveyReminderAction: 'Lähetä muistutus',
  networkSurveyReminderChannelSelect: 'Valitse viestikanava',
  networkSurveyReminderInfo:
    'Lähetämme muistutuksen ainoastaan niille, jotka eivät ole vastanneet.',
  impact: 'Vaikutus',
  answersSoFar: 'vastausta tähän mennessä',
  refer: 'Suosittele Teamspectivea',
  aiDelayNote:
    'Huomioi, että tekoälyanalyysin tulokset eivät välttämättä näy välittömästi. Datan käsittely tekoälyanalyysia otettaessa käyttöön vie aikaa.',
  activateAiSentimentAnalysis: 'Aktivoi tekoälysentimenttianalyysi',
  sentimentAnalysisDescription:
    'Tämä ominaisuus analysoi automaattisesti kaikki kommenttisi, jotta voit helposti huomata kehitysideat ja mitkä aiheet saavat eniten huomiota.',
  activateAiFeedbackAnalysis: 'Aktivoi palautteen tekoälyanalyysi',
  aiFeedbackAnalysisDescription:
    'Tämä ominaisuus analysoi henkilön kirjalliset palautteet ja esittelee niissä löydetyt vahvuudet ja kehittämiskohteet sekä listaa, mitä työssä pitäisi aloittaa, lopettaa ja jatkaa. Havainnot näytetään palautteitavalle ja työtilan ylläpitäjille.',
  allowDiscussionsInSlack: 'Salli keskustelut Slackissa',
  discussionsInSlackDescription:
    'Salli keskustelujen käyminen tiimisi Slack-työtilassa. Osallistujat voivat silloin käydä keskustelua joko omalla kanavalla Slackissä tai selaimessa.',
  openPulseSettings: 'Avaa pulssiasetukset',
  open360FeedbackSettings: 'Avaa 360 Palautteen asetukset',
  openWorkspaceIntegrationSettings: 'Avaa työtilan integraatioasetukset',
  discussions: 'Keskustelut',
  myDiscussions: 'Minun keskusteluni',
  addDiscussion: 'Luo keskustelu',
  editDiscussion: 'Muokkaa keskustelua',
  discussionStartedBy: 'Keskustelun aloitti',
  limitedVisibility: 'Rajoitettu näkyvyys',
  discussionsLimitedVisibilityDescription:
    'Näkyy kaikille tämän keskustelun osallistujille ja järjestäjille.\nOsallistujien esihenkilöt näkevät keskustelun.',
  addNote: 'Lisää muistiinpano',
  discussionName: 'Keskustelun nimi',
  writeName: 'Kirjoita nimi',
  host: 'Järjestäjä',
  discussionParticipantNote:
    'Luodessasi keskustelua muille sinulla täytyy olla oikeus hallinnoida jokaista henkilöä. Näet osallistujalistassa vain nämä henkilöt.',
  noteSyncedToSlackChannel:
    'Tämä muistiinpano päivitetään yhdistetylle Slack-kanavalle. Kommentit näkyvät molemmissa sijainneissa.',
  slackBotRemovedFromChannel:
    'Teamspective poistettu Slack-kanavalta. Ole hyvä ja lisää se takaisin synkronoidaksesi kanava.',
  add: 'Lisää',
  leaveAComment: 'Jätä kommentti…',
  selectDiscussionTemplate: 'Valitse pohja',
  discussionTemplates: 'Keskustelupohjat',
  addTemplate: 'Lisää keskustelupohja',
  editDiscussionTemplate: 'Muokkaa keskustelupohjaa',
  deleteDiscussionTemplate: 'Poista keskustelupohja',
  discussionsFilter_participating: 'Joissa osallistujana',
  discussionsFilter_hosting: 'Joissa järjestäjänä',
  discussionsFilter_all: 'Kaikki näkyvät',
  discussionsFilter_managedMissing: 'Ilman keskustelua',
  content: 'Sisältö',
  nTranslations: (n: number) => `${n} käännös${n === 1 ? '' : 'tä'}`,
  editEvaluationTemplate: 'Muokkaa palautepohjaa',
  editEvaluationTemplatesAndQuestionsAt: 'Muokkaa palautepohjia ja kysymyksiä sivulla',
  addNewTemplate: 'Lisää uusi palautepohja',
  startNewEvaluations: 'Luo uusia palautepyyntöjä',
  discardEvaluationDraft: 'Hylkää luonnos',
  discardEvaluationDraftConfirm: 'Haluatko varmasti poistaa luonnoksen?',
  selectEvaluees: 'Valitse palautteen saajat',
  preselectTemplates: 'Esivalitse palautepohjat',
  heading: 'Otsikko',
  bold: 'Lihavoi',
  italic: 'Kursivoi',
  underline: 'Alleviivaa',
  strikethrough: 'Yliviivaa',
  bulletedList: 'Lista',
  numberedList: 'Numeroitu lista',
  checkList: 'Tehtävälista',
  sensitivity: 'Herkkyys',
  dataSource: 'Datalähde',
  doubleClickToExpand: 'Kaksoisklikkaa laajentaaksesi',
  nExpandedGroups: (n: number) =>
    `${n} laajennettu${n === 1 ? '' : 'a'} ryhmä${n === 1 ? '' : 'ä'}`,
  'evaluationAnswerAnalysisFramework_start-stop-continue': 'Aloita Lopeta Jatka',
  'evaluationAnswerAnalysisFramework_strengths-development-areas': 'Vahvuudet ja kehittymiskohdat',
  evaluationAnswerAnalysisType_start: 'Aloita',
  evaluationAnswerAnalysisType_stop: 'Lopeta',
  evaluationAnswerAnalysisType_continue: 'Jatka',
  evaluationAnswerAnalysisType_strength: 'Vahvuudet',
  evaluationAnswerAnalysisType_developmentArea: 'Kehittymiskohteet',
  mentionedNTimes: (n: number) => (n === 1 ? 'Mainittu kerran' : `Mainittu ${n} kertaa`),
  plusNOtherAnswers: (n: number) => (n === 1 ? `+${n} muu vastaus` : `+${n} muuta vastausta`),
  groupIsFromIntegrationDescription: (integrationName: string | null) =>
    `Ryhmän lähde: ulkoinen järjestelmä${integrationName ? ` ${integrationName}` : ''}`,
  feedbackSettings: 'Henkilökohtaisen palautteen asetukset',
  evaluationSettings: '360 palautteen asetukset',
  multipleGroups: 'Useita ryhmiä',
  cohorts: 'Kohortit',
  commentsSummary: 'Kommenttien tiivistelmä',
  noSuggestions: 'Ei ehdotuksia',
  manageFeedbackDescription: (scope?: string) =>
    `Saa tarkastella julkaisemattomia tai julkaistuja palautteita ja ei-yksityisiä palautteita${scope ? ` ${scope}` : ''}. Saa julkaista julkaisemattomia palautteita, avata vanhentuneita palautteita tai poistaa keskeneräisiä palautteita. Saa luoda keskusteluja.`,
  shortSummaryAvailableOnlyForLatestResults:
    'Lyhyt tiivistelmä saatavilla vain uusimmille tuloksille.',
  addInsightsFrom360Feedback: 'Lisää havainnot 360 Palautteesta',
  collectingInsights: 'Kerätään havaintoja…',
  canTakeUpTo60Seconds: 'Voi kestää jopa 60 sekuntia',
  anonymous: 'Anonyymi',
  evaluationComments: 'Palautteen kommentit',
  workspaceAverage: 'Työtilan keskiarvo',
  answerChoiceIcons: 'Vastausvaihtoehtojen kuvakkeet',
  answerChoiceIconsUpdated: 'Vastausvaihtoehtojen kuvakkeet päivitetty',
  settingAffectsSlackAndMsTeamsOnly:
    'Asetus vaikuttaa vain Slack- ja Microsoft Teams -käyttöliittymiin.',
  editable: 'Muokattava',
  discussionNoteEditable: (days: number) =>
    `Tätä muistiinpanoa voi muokata ${days} päivää sen luomisen jälkeen.`,
  discussionCommentEditable: (days: number) =>
    `Tätä kommenttia voi muokata ${days} päivää sen luomisen jälkeen.`,
  discussionUpdated: (discussionName: string) => `Keskustelua ${discussionName} on päivitetty`,
  discussionUpdatedMore: (moreCount: number) => `...ja ${moreCount} muuta muutosta.`,
  discussionAction_creator_discussion_created: (creator: string, discussionName: string) =>
    `${creator} teki keskustelun ${discussionName}`,
  discussionAction_discussion_created: (discussionName: string) =>
    `Keskustelu ${discussionName} tehty`,
  discussionAction_note_created: 'Muistiinpano tehty keskusteluun',
  discussionAction_author_note_created: (author: string) =>
    `${author} teki muistiinpanon keskusteluun`,
  discussionAction_note_updated: 'Muistiinpanoa päivitetty keskustelussa',
  discussionAction_author_comment_created: (author: string) =>
    `${author} jätti kommentin keskusteluun`,
  discussionAction_comment_created: 'Kommentti jätetty keskusteluun',
  emailFallbackUrlDescription:
    'Jos yllä olevat napit eivät toimi, kopioi ja liitä jokin seuraavista linkeistä nettiselaimen osoitekenttään.',
  recalculateGraph: 'Asettele graafi',
  dynamic: 'Dynaaminen',
  always: 'Aina',
  showConnections: 'Näytä yhteydet',
  betweenSelected: 'Valittujen välillä',
  allConnections: 'Kaikki yhteydet',
  secondDegreeConnections: 'Toisen asteen yhteydet',
  selectGroupMembers: 'Valitse ryhmän henkilöt',
  addQuestions: 'Lisää kysymyksiä',
  startNew: 'Aloita uusi',
  connectToSlack: 'Yhdistä Slackiin',
  discussionsConnectToSlackDescription: 'Tuo keskustelut tiimisi Slackiin ja anna kanavalle nimi.',
  channelAlreadyExists: 'Samanniminen kanava on jo olemassa',
  slackChannelCreatedWithName: 'Slack-kanava luotu nimellä',
  discussionsSlackSync: 'Keskustelujen synkronointi Slackiin',
  defaultTemplates: 'Oletuspohjat',
  continue: 'Jatka',
  expires: 'Päättyy',
  expired: 'Päättyi',
  completed: 'Valmis',
  lastNotifiedAt: (date: string, channel: string) =>
    `Edellinen muistutus lähetetty: ${date}, ${channel}`,
  extendedNotifiedAt: (date: string, channel: string) =>
    `Vastausaikaa jatkettu. Edellinen muistutus lähetetty: ${date}, ${channel}`,
  notificationNotYetSent: 'Muistutusta ei ole vielä lähetetty',
  evaluationReminderDaysBefore: `Muistutukset lähetetään palautteen antajille ${listItemsInNaturalLanguage.fi(evaluationRemindersDaysBefore.map(String))} päivää ennen päättymistä.`,
  notificationChannel: {
    slack: 'Slack',
    'ms-teams': 'Microsoft Teams',
    email: 'Sähköposti',
    demo: 'Teamspective demo muistutus',
    error: 'Virhe',
    optout: 'Ei saatavilla',
  },
  errorInSendingReminder: 'Virhe lähetettäessä muistutuksia. Ylläpitoa tiedotettu.',
  userHasDisabledNotifications: 'Käyttäjä on poistanut ilmoitukset käytöstä.',
  automaticEvaluationRescheduling: 'Automaattinen palautteiden jatkoaika',
  extendDeadlineByHours: (hours: number) =>
    hours === 0 ? 'Ei käytössä' : `Jatka päättymisaikaa ${hours} tunnilla`,
  peopleSelected: (n: number) => `${n} henkilöä valittu`,
  evaluationRescheculeInfo:
    'Palautepyyntöjen päättymisaikaa jatketaan automaattisesti. Uudesta vastausajasta lähetetään ilmoitus sen alkaessa ja muistutus 6 tuntia ennen sen päättymistä, mutta vain toimistoaikoina (klo 8-16).',
  showEvaluatorNamesTo: 'Näytä palautteen antajien nimet',
  evaluatorNameVisibility_all: 'Kaikki (myös vastaanottaja)',
  evaluatorNameVisibility_admins: 'Työtilan ylläpitäjät',
  evaluatorNameVisibility_finegrained: 'Oikeuden haltijat',
  evaluatorNameVisibility_never: 'Älä näytä',
  evaluatorNameVisibilityTooltip: `Oletuksena palautteen saaja ei voi nähdä palautteen antajien nimiä.

Kaikki (myös vastaanottaja): Kaikki voivat nähdä palautteen antajien nimet, mukaan lukien vastaanottajat.

Työtilan ylläpitäjät: Työtilan ylläpitäjät ja tarkastelijat voivat nähdä palautteen antajien nimet mikäli eivät ole kyseisen palautteen vastaanottajia.

Oikeuden haltijat: Käyttäjät, joille on myönnetty palautteen hallintaoikeudet "Oikeudet" välilehdellä, voivat nähdä palautteen antajien nimet, mikäli eivät ole kyseisen palautteen saajia.

Älä näytä: Palautteen antajien nimet piilotetaan kaikilta, mukaan lukien "Palautepyynnöt" välilehdeltä ja raporteista.`,
  changeEvaluationNameVisibilityWarning: (from: string, to: string) =>
    `Olet muuttamassa palautteen antajien nimien näkyvyyttä kaikille työtilan jäsenille asetuksesta "${from}" asetukseen "${to}". Tämä vaikuttaa kaikkiin palautteisiin, mukaan lukien julkaistuihin palautteisiin. Asetuksen muutokset tallennetaan audit-lokiin.`,
  addNewTheme: 'Lisää uusi teema',
  editTheme: 'Muokkaa teemaa',
  themeInformation: 'Teeman tiedot',
  pinToResultsOverview: 'Näytä tulosten yhteenvedossa',
  themeName: 'Teeman nimi',
  pulseThemeUpdated: 'Teema päivitetty',
  pulseThemeCreated: 'Teema luotu',
  hours: 'tuntia',
  days: 'päivää',
  tasks: 'Tehtävät',
  newFeedback: 'Uutta palautetta',
  yourRequests: 'Omat palautepyynnöt',
  noPendingTasks: 'Ei tehtävää. Nauti päivästäsi!',
  noNewFeedback: 'Ei uutta palautetta',
  nothingHere: 'Ei mitään täällä',
  hideArchived: 'Piilota arkistoidut',
  archiveTemplate: 'Arkistoi palautepohja',
  archiveTemplateDescription:
    'Arkistoituja palautepohjia ei voi käyttää uusiin palautteisiin, mutta niitä käyttävät palautteet näkyvät yhä tuloksissa.',
  unarchiveTemplate: 'Palauta palautepohja arkistosta',
  unarchiveTemplateDescription: 'Voit sen jälkeen käyttää sitä uusissa palautteissa.',
  singleSignOn: 'Single Sign-On',
  chooseYourSsoProvider: 'Valitse SSO-palveluntarjoaja',
  usingOtherSsoProvider:
    'Käytätkö jotain muuta SSO-palveluntarjoajaa? Ota yhteyttä Teamspectiven tukeen.',
  noActivity: 'Ei toimintaa',
  readyToPublish: 'Valmis julkaisuun',
  waitingForAnswers: 'Odotetaan vastauksia',
  initialize360FeedbackCta:
    'Ennen kuin voit lähettää 360 palautepyyntöjä, tarvitset palautepohjat. Paina alta aloittaksesi.',
  hidden: 'Piilotettu',
  kpis: 'KPI:t',
  keyMetrics: 'Keskeiset mittarit',
  lastEdited: 'Viimeksi muokattu',
  lastEditedBy: 'Viimeksi muokannut',
  notifyOnPublishableFeedback: 'Ilmoita arvioijille jos uutta palautetta voidaan julkaista',
  pulseThemeDescription:
    'Teemat auttavat sinua seuraamaan toisiinsa liittyvien kysymysten muutoksia. Teema yhdistää useita kysymyksiä, ja sen tulos on näiden kysymysten keskiarvo. Voit luoda omia teemoja ja päättää, mitkä teemat eri ryhmät näkevät tulossivullaan.',
  managers: 'Esihenkilöt',
  managerAccessDescription: `Hallinnoiva käyttäjä voi:
 • Katsoa 360 palautetta (julkaistua tai julkaisematonta)
 • Hallinnoida 360 palautetta (moderoida, avata uudelleen, poistaa, julkaista)
 • Katsoa ei-yksityistä palautetta
 • Katsoa keskusteluja
 • Hallinnoida keskusteluja (luoda, muokata, tai lisätä AI-sisältöä)`,
  missingPermissions: 'Puuttuvia oikeuksia',
  discussionForOthersPermissionInfo:
    'Tarvitset luvan hallinnoidaksesi keskustelua muiden puolesta. Et voi hallinnoida seuraavia henkilöitä:',
  discussionForOthersHowToFix:
    'Muuttaaksesi hallinnointioikeuksiasi ota yhteyttä työtilan ylläpitäjään.',
  defaultVisibilityForPersonalFeedback: 'Oletusnäkyvyys henkilökohtaiselle palautteelle',
  noAnswer: 'Ei vastausta',
  feedbackProvideCta: 'Sinua pyydetään antamaan palautetta! Klikkaa "Vastaa" aloittaaksesi.',
  surveyInformation: 'Kyselyn tiedot',
  addNewSurvey: 'Lisää uusi kysely',
  survey: 'Kysely',
  passive: 'Passiivinen',
  startDate: 'Aloituspäivä',
  endDate: 'Päättymispäivä',
  addQuestionsToSurvey: 'Lisää kysymyksiä kyselyyn',
  createQuestionsTip: 'Voit luoda lisää kysymyksiä Muokkaa kyselyä -sivulla.',
  edited: 'Muokattu',
  numericAnswers: 'Numerovastaukset',
  writtenAnswers: 'Kirjoitetut vastaukset',
  remindSelectedEvaluators: 'Muistuta valittuja arvioijia',
  remindSelectedEvaluatorsDescription:
    'Lähetä muistutus valituille arvioijille. Huomioi, että käyttäjät, jotka ovat estäneet ilmoitukset, eivät saa muistutusta.',
  connectGoogleCalendar: 'Yhdistä Google Kalenteriin',
  toConnectGoogleCalendar:
    'Yhdistääksesi Google Kalenterin, sinun on lisättävä oikeudet palvelukäyttäjälle Google-työtilaasi.',
  requiredSteps: 'Askeleet:',
  domainWideDelegationInstructions: `1. Avaa Google Admin konsoli
2. Navigoi Security → API Controls → Domain-wide Delegation
3. Lisää uusi API-asiakas näillä tiedoilla:`,
  superAdminEmail: 'Google työtilan super-ylläpitäjän sähköposti',
  connect: 'Yhdistä',
  googleClientId: 'Client ID',
  copy: 'Kopioi',
  copied: 'Kopioitu',
  emailDoesNotMatchUser:
    'Turvallisuussyistä sähköpostin tulee täsmätä kirjautuneen käyttäjän sähköpostia',
  userNotSuperAdmin: 'Käyttäjällä ei ole super admin -roolia Google Workspacessa',
  thisEmailAlreadyAdded: 'Sähköposti on jo lisätty',
  questionnaireInfo: 'Kyselyn tiedot',
  deleteThisSurvey: 'Poista tämä kysely',
  kpiExplainer: 'KPI:n tulos on siihen kuuluvien teemojen tulosten keskiarvo.',
  themeExplainer:
    'Teeman tulos on siihen kuuluvien kysymysten viimeisimpien tulosten keskiarvo, huomioiden vain viimeiset 6 kuukautta.',
  selectSurvey: 'Valitse kysely',
  surveys: 'Kyselyt',
  selectFeedbackRecipients: 'Valitse palautteen saajat',
  resultsByQuestion: 'Tulokset kysymyksittäin',
  allFeedbackProviders: 'Kaikki palautteen antajat',
  connectionWeak: 'Heikko',
  connectionMedium: 'Keskiväli',
  connectionClose: 'Lähellä',
  personalConnections: 'Henkilökohtaiset yhteydet',
  personalConnectionsDescription:
    'Esihenkilön henkilökohtaisten yhteyksien määrä suoriin alaisiin, vertaisiin ja ulkoisiin sidosryhmiin.',
  totalPersonalConnections: 'Henkilökohtaisten yhteyksien kokonaismäärä',
  workspaceMedian: 'Työtilan mediaani',
  connectionStrength: 'Yhteyden vahvuus',
  seniorManagement: 'Ylempi johto',
  otherColleagues: 'Muut kollegat',
  directReports: 'Suorat alaiset',
  collaborationOfDirectReports: 'Suorien alaisten yhteistyö',
  collaborationOfDirectReportsDescription:
    'Mittaa kuinka hyvin tiimin jäsenet tekevät yhteistyötä suoraan toistensa kanssa, sen sijaan että tukeutuisivat esihenkilöön.',
  peerAverage: 'Vertaisten keskiarvo',
  leaderCentralityDescription:
    'Osoittaa kuinka usein esihenkilö toimii keskeisenä linkkinä tiimin jäsenten välillä.',
  leaderCentrality: 'Esihenkilön keskeisyys',
  connectionStrengthPerDirectReport: 'Yhteyden vahvuus per alainen',
  connectionStrengthPerDirectReportDescription:
    'Mittaa yhteyden vahvuutta esihenkilön ja hänen alaistensa välillä sekä sitä, miten huomio jakautuu.',
  leaderName: 'Esihenkilön nimi',
  crossTeamConnections: 'Tiimienväliset yhteydet',
  leaderShareOfConnections: 'Esihenkilön osuus tiimienvälisistä yhteyksistä',
  crossTeamConnectionsDescriptions:
    'Mittaa kuinka paljon tiimienväliset yhteydet ovat riippuvaisia esihenkilöstä.',
  surveySummary: 'Kyselyn yhteenveto',
  themesCovered: (n: number) => `${n} teema${n === 1 ? '' : 'a'} kattu${n === 1 ? '' : 'a'}`,
  notStarted: 'Ei aloitettu',
  none: 'Ei mitään',
  employeeInsights: 'Yksilömetriikat',
  showExternalPeople: 'Näytä työtilan ulkopuoliset henkilöt',
  featureComingSoon: 'Uusi ominaisuus tulossa pian',
  last4Months: 'Viimeiset 4 kuukautta',
  dataSources: 'Tietolähteet',
  globalConnectors: 'Työtilan yhdistäjät',
  globalConnectorsDescription: 'Suuressa tarpeessa, yhdistävät organisaation eri osia toisiinsa.',
  localConnectors: 'Paikalliset yhdistäjät',
  localConnectorsDescription:
    'Suuressa tarpeessa, ovat keskeisiä tietyssä paikassa organisaatiota.',
  connectorPotential: 'Yhdistäjäpotentiaali',
  connectorPotentialDescription:
    'Matalassa tai keskimääräisessä tarpessa olevat henkilöt, jotka ovat keskeisiä lähiorganisaatiossaan.',
  highlight: 'Korosta',
  centralityInCollaborationCluster: 'Keskeisyys lähiorganisaatiossa',
  howToReadDemandCentralityScatterplot_demand:
    'Osoittaa kuinka paljon henkilö kokee kuormitusta kokousten ja saapuvien viestien määrän perusteella. Korkeampi arvo viittaa suurempaan ajankäytön ja huomion tarpeeseen. Liiallinen kuormitus voi lisätä riskiä ylikuormittumiseen tai muodostua pullonkaulaksi toteutukselle.',
  howToReadDemandCentralityScatterplot_centrality:
    'Mittaa kuinka keskeinen henkilö on lähiorganisaatiossaan. Arvo perustuu kokousten ja saapuvien viestien määrään, painotettuna vuorovaikutuskumppanien keskeisyydellä. Korkeampi arvo tarkoittaa keskeisempää roolia ryhmän työssä. Matalampi arvo voi viitata vähemmän keskeiseen rooliin, tai rooliin useiden ryhmien yhdistäjänä.',
  externalUsers: 'Ulkopuoliset käyttäjät',
  others: 'Muut',
  insights: 'Raportit',
  networkPassiveCtaAISummary: 'AI-yhteenveto',
  networkPassiveCtaAISummaryBody:
    'Pian näet keskeiset havainnot ja suositukset perustuen yhteistyön analyysiin. Pysy kuulolla!',
  hiddenTeam: 'Piilotettu ryhmä',
  workspaceCommentVisibilityAdminsOnlyAllRedacted: (redactionLimit: number) =>
    `Kaikki ryhmät, joihin tämä kommentti on suunnattu, saivat ${redactionLimit} tai vähemmän vastausta. Vain työtilan ylläpitäjät voivat nähdä tämän kommentin.`,
  heatmapBeta: 'Heatmaps (beta)',
  numberOfResponses: 'Vastausmäärä',
  hierarchy: 'Ryhmähierarkia',
  allSurveys: 'Kaikki kyselyt',
  managerManageUserFeedback: 'Käytä esihenkilön hallintaoikeuksia palautteiden hallinnassa',
  managerManageUserFeedbackDescription:
    'Anna esihenkilöille käyttöoikeudet suorien alaisten palautteiden hallinnassa',
}

export const translations = {
  en,
  fi,
  sv: en,
  no: en,
  dk: en,
  es: en,
  de: en,
  it: en,
  fr: en,
  pl: en,
  cn: en,
  uk: en,
}

export const text = <TKey extends keyof typeof en, TLang extends Language>(
  key: TKey,
  lang: TLang
): (typeof translations)[TLang][TKey] => translations[lang][key] ?? translations.en[key]

export type Texts = (typeof translations)[Language]
