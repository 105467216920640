import { createRoot } from 'react-dom/client'
import { init as initSentry, withProfiler, browserTracingIntegration } from '@sentry/react'
import { SENTRY_FRONTEND_DSN, SENTRY_TUNNEL_PATHNAME, normalizePath } from '@teamspective/common'
import App from './App'
import sourceVersion from './sourceVersion'
import { StrictMode } from 'react'
import { logger } from './util'

const isDev = import.meta.env.DEV || window.location.hostname === 'localhost'

initSentry({
  release: sourceVersion,
  /* Sentry environment is production by default and is incorrect in
    staging/demo. It's a bit tricky to get it correct here, so make it 'unknown'
    for now. Environments can be distinguished by url anyway. */
  environment: 'unknown',
  dist: 'teamspective-web',
  dsn: isDev ? undefined : SENTRY_FRONTEND_DSN,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    /ResizeObserver loop limit exceeded/,
    /ResizeObserver loop completed with undelivered notifications/,
    /NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission./,
    /Failed to construct 'Notification': Illegal constructor/,
    /__wx._ready is not a function/,
    // On hot-reload some requests fail with 500 making Spotlight pop-up. Ignore these locally.
    // The 500 errors should be reported by Sentry on the server side.
    ...(window.location.hostname === 'localhost'
      ? [
          'AxiosError: Request aborted',
          'AxiosError: Request failed with status code 500',
          'Sigma: could not find a suitable program for node type "border"!',
        ]
      : []),
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
    // Firefox extensions
    /^resource:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  integrations: [
    browserTracingIntegration({
      beforeStartSpan: (context) => ({
        ...context,
        name: normalizePath(window.location.pathname),
      }),
    }),
  ],
  tracePropagationTargets: ['teamspective.com/api'],
  tracesSampleRate: isDev ? 1 : 0.1,
  tunnel: SENTRY_TUNNEL_PATHNAME,
  attachStacktrace: true,
})

// eslint-disable-next-line react-refresh/only-export-components
const WrappedApp = withProfiler(App)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)

logger.trace('rendering app', { container, root })
root.render(
  <StrictMode>
    <WrappedApp />
  </StrictMode>
)
