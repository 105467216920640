import type {
  DiscussionCommentId,
  DiscussionNoteId,
  DiscussionId,
  NonEmptyArray,
  TeamId,
  ManagementFeedbackChannelId,
  UserId,
  RequestId,
  EvaluationTemplateId,
  EvaluationId,
  PulseThemeId,
  PulseKpiId,
  ManagementFeedbackId,
  PulseAnswerCommentId,
  PulseSingleRoundId,
  Measurement,
  SurveyContext,
} from './types/index.ts'
import {
  isDemoTeamId,
  serializeExtendedMeasurement,
  serializeMeasurement,
  serializeSurveyContext,
  type DemoTeamId,
  type PulseQuestionTag,
  type Stage,
} from './types/index.ts'
import { BASE_URL } from './constants.ts'
import { encodeHashParams, filterObject } from './util.ts'
import type { CohortKey, CohortValue, Language } from './domain/index.ts'

type FrontPageParam = { slack: 'success' | 'failure' }

export const heatmapColumnTypes = ['latest', 'quarters', 'months', 'weeks', 'rounds'] as const
export type ColumnType = (typeof heatmapColumnTypes)[number]
export type CohortString = `${string}:${string}`

export type CustomizePulseSurveyTab = 'questions' | 'themes' | 'kpis' | 'guidance'

type PulseLinkParams =
  | {
      tab: 'customize'
      view: CustomizePulseSurveyTab
    }
  | ({
      tab: 'pulse'
      teamId?: TeamId | DemoTeamId
      measurement?: 'all' | 'activity' | Measurement
      surveyContext?: SurveyContext
    } & (
      | {
          cohortKey: CohortKey
          cohortValue: CohortValue
        }
      | {
          cohortKey?: undefined
          cohortValue?: undefined
        }
    ))
  | {
      tab: 'results'
      teamId?: TeamId | DemoTeamId
      columnType?: ColumnType
      measurement?: 'all' | Measurement
      surveyContext?: SurveyContext
      cohortKey?: CohortKey
    }
  | {
      tab: 'comments'
      teamId?: TeamId | DemoTeamId
      measurement?: 'all' | Measurement
      surveyContext?: SurveyContext
    }
  | { tab: 'automated' | 'single-round' | 'settings' }

// Putting state into query params has many benefits but react-router does not
// have first-class support, requiring this sort of manual work.
// Something to consider: Tanstack router would provide first-class typesafe
// query params with sane default serialization for complex objects. The goals
// of the project also align with our current SPA approach more as react-router
// is increasingly becoming a SSR framework.
const flattenPulseLinkParams = (
  params: PulseLinkParams
): Record<string, string | number | undefined> => {
  switch (params.tab) {
    case 'pulse':
      return {
        tab: 'pulse',
        teamId: params.teamId,
        measurement: params.measurement
          ? typeof params.measurement === 'string'
            ? params.measurement
            : serializeMeasurement(params.measurement)
          : undefined,
        surveyContext: params.surveyContext
          ? serializeSurveyContext(params.surveyContext)
          : undefined,
        cohortKey: params.cohortKey,
        cohortValue: params.cohortValue,
      } satisfies Record<keyof typeof params, string | number | undefined>
    case 'results':
      return {
        tab: 'results',
        teamId: params.teamId,
        cohortKey: params.cohortKey,
        measurement: params.measurement
          ? serializeExtendedMeasurement(params.measurement)
          : undefined,
        columnType: params.columnType,
        surveyContext: params.surveyContext
          ? serializeSurveyContext(params.surveyContext)
          : undefined,
      } satisfies Record<keyof typeof params, string | number | undefined>
    case 'comments':
      return {
        tab: 'comments',
        teamId: params.teamId,
        measurement: params.measurement
          ? serializeExtendedMeasurement(params.measurement)
          : undefined,
        surveyContext: params.surveyContext
          ? serializeSurveyContext(params.surveyContext)
          : undefined,
      } satisfies Record<keyof typeof params, string | number | undefined>
    case 'customize':
    case 'automated':
    case 'single-round':
    case 'settings':
      return params
  }
}

export const workspaceTabs = {
  workspace: 'workspace',
  subscription: 'subscription',
  managers: 'managers',
  members: 'members',
  groups: 'groups',
  integrations: 'integrations',
} as const

export const managementFeedbackTabs = {
  channels: 'channels',
  manage: 'manage',
} as const

export const engagementTabs = {
  pulse: 'pulse',
  results: 'results',
  comments: 'comments',
  automated: 'automated',
  singleRound: 'single-round',
  customize: 'customize',
  settings: 'settings',
  heatmapBeta: 'heatmap-beta',
} as const

export const discussionsTabs = {
  myDiscussions: 'my-discussions',
  discussionTemplates: 'discussion-templates',
} as const

export type PulseTab = (typeof engagementTabs)[keyof typeof engagementTabs]

type WorkspaceLinkParams = {
  tab?: (typeof workspaceTabs)[keyof typeof workspaceTabs]
  action?: 'addFromSlack'
  error?: 'slackWorkspaceAlreadyLinked'
}

type ManagementFeedbackLinkParams = {
  tab?: (typeof managementFeedbackTabs)[keyof typeof managementFeedbackTabs]
  managementFeedbackChannelId?: ManagementFeedbackChannelId
}

const workspaceFragment = (params?: WorkspaceLinkParams) => {
  if (!params || !params.tab) {
    return ''
  } else if (params.tab === 'members' && params.action === 'addFromSlack') {
    return `?action=${params.action}#${params.tab}`
  } else if (params.tab === 'integrations' && params.error === 'slackWorkspaceAlreadyLinked') {
    return `?error=${params.error}#${params.tab}`
  } else {
    return `#${params.tab}`
  }
}

export const feedbackTabs = {
  myFeedback: 'my-feedback',
  topics: 'topics',
  feedbackLink: 'feedback-link',
  managementFeedback: 'management-feedback',
  sharedFeedback: 'shared-feedback',
  feedbackActivation: 'feedback-activation',
  evaluations: 'evaluations',
  overview: 'feedback-overview',
  results: 'feedback-results',
  evaluationRequests: 'feedback-requests',
  questionnaire: 'feedback-questionnaire',
  settings: 'settings',
  access: 'access-permissions',
} as const

export type FeedbackProfileTab = 'feedback' | 'evaluations'
export type FeedbackResultTab = 'chart' | 'table' | 'personal'
export type CustomizeEvaluationSurveyTab = 'templates' | 'questions'

type FeedbackParams = {
  tab?: (typeof feedbackTabs)[keyof typeof feedbackTabs]
  teamId?: TeamId | DemoTeamId
  userId?: UserId
  profileParams?: {
    view: FeedbackProfileTab
  }
  resultParams?: {
    view: FeedbackResultTab
  }
  customizeParams?: {
    view: CustomizeEvaluationSurveyTab
  }
  takeAction?: boolean
}

const getQueryString = (params: Record<string, string | number | undefined>) => {
  // The official types do not allow types other than string but these do stringify nicely
  const queryString = new URLSearchParams(
    filterObject(params, Boolean) as Record<string, string>
  ).toString()
  return queryString.length ? `?${queryString}` : ''
}

const feedbackLinkFragment = (p?: FeedbackParams) => {
  if (!p) {
    return ''
  }
  const hashParams = encodeHashParams({
    ...p.profileParams,
    ...p.customizeParams,
    ...p.resultParams,
    takeAction: (p.takeAction ?? false) ? 'true' : undefined,
  })

  const queryString = getQueryString({ teamId: p.teamId, userId: p.userId })
  const hash = hashParams ? `#${p.tab ?? 'my-feedback'}${hashParams}` : p.tab ? '#' + p.tab : ''
  return queryString + hash
}

export const networkTabs = {
  passiveOnboarding: 'passive-onboarding',
  networkGraph: 'network-graph',

  // survey-based
  personalNetwork: 'personal-network',
  individualImpact: 'individual-impact',

  // shared
  employeeInsights: 'employee-insights',

  // Always visible
  passiveSources: 'passive-sources',
  survey: 'survey',

  leadershipStyle: 'leadership-style',
} as const

type NetworkParams = {
  tab?: (typeof networkTabs)[keyof typeof networkTabs]
  userId?: UserId
}

export const withoutUrlParam = (str: string) => str.replace(/:.*$/, '')

const provideEvaluationPageBackOptions = ['home', 'my-activity'] as const
export type ProvideEvaluationPageBackOption = (typeof provideEvaluationPageBackOptions)[number]
export const parseEvaluationPageOption = (value: string | null): ProvideEvaluationPageBackOption =>
  value && (provideEvaluationPageBackOptions as readonly string[]).includes(value)
    ? (value as ProvideEvaluationPageBackOption)
    : 'home'

const LOCAL_TESTING_REDIRECT = false
export const redirectViaLandingPage = (destinationPath: string, stage: Stage) =>
  stage === 'production'
    ? `https://teamspective.com/redirect?path=${encodeURIComponent(destinationPath)}`
    : LOCAL_TESTING_REDIRECT
      ? `http://localhost:8000/redirect?path=${encodeURIComponent(destinationPath)}`
      : `${BASE_URL[stage]}${destinationPath}`

const feedbackLink = (p?: FeedbackParams) => `/feedback${feedbackLinkFragment(p)}`

export const getDiscussionAnchorHash = ({
  discussionNoteId,
  discussionCommentId,
}: {
  discussionNoteId?: DiscussionNoteId
  discussionCommentId?: DiscussionCommentId
}) =>
  discussionNoteId
    ? `#note-${discussionNoteId}`
    : discussionCommentId
      ? `#comment-${discussionCommentId}`
      : ''

export const links = {
  frontPage: '/home',
  frontPageWithParam: ({ slack }: FrontPageParam) => `/?slack=${slack}`,
  settings: '/settings',
  feedback: feedbackLink,
  takeActionList: feedbackLink({ tab: 'my-feedback', takeAction: true }),
  network: (p?: NetworkParams) =>
    `/network${p?.userId ? `?userId=${p.userId}` : ''}${p?.tab ? `#${p.tab}` : ''}`,
  makeRequest: '/feedback/request',
  makeEvaluationRequest: '/evaluation/request',
  viewFeedback: (requestId?: RequestId) => `/feedback/request/${requestId ?? ':requestId'}`,
  provideFeedback: (requestId?: RequestId) => `/feedback/provide/${requestId ?? ':requestId'}`,
  provideDemoFeedback: (requestId?: RequestId) =>
    `/feedback/demo/provide/${requestId ?? ':requestId'}`,
  pulse: (p?: PulseLinkParams) => {
    return p
      ? `/pulse${'teamId' in p && p.teamId && isDemoTeamId(p.teamId) ? '/demo' : ''}${getQueryString(flattenPulseLinkParams(p))}${
          'tab' in p ? `#${p.tab}` : ''
        }`
      : '/pulse'
  },
  pulseComment: ({
    pulseCommentIds,
  }: {
    pulseCommentIds?: NonEmptyArray<PulseAnswerCommentId>
  } = {}) => `/pulse/comment/${pulseCommentIds?.join(',') ?? ':pulseCommentIds'}`,
  pulseDemo: () => '/pulse/demo',
  createPulse: '/pulse/new-pulse',
  createPulseSingleRound: '/pulse/new-single',
  editSingleRoundSurvey: (pulseSingleRoundId?: PulseSingleRoundId) =>
    `/pulse/single-round/${pulseSingleRoundId ?? ':pulseSingleRoundId'}`,
  admin: '/admin',
  integrationDebugPage: (teamId?: TeamId) => `/admin/integration-debug/${teamId ?? ':teamId'}`,
  register: '/register',
  login: '/login',
  codeLogin: (workspaceLoginCode?: string) =>
    `/login/code/${workspaceLoginCode ?? ':workspaceLoginCode'}`,
  loginWithToken: (token: string, redirectPath?: string) =>
    `/api/login/token?token=${encodeURIComponent(token)}${redirectPath ? `&redirect=${encodeURIComponent(redirectPath)}` : ''}`,
  sharedFeedback: (token?: string) => `/shared/feedback/${token ?? ':token'}`,
  adminWorkspaceMembershipPage: (teamId?: TeamId) => `/admin/membership/${teamId ?? ':teamId'}`,
  viewUserEvaluations: (userId?: UserId) => `/feedback/evaluation/by-user/${userId ?? ':userId'}`,
  provideEvaluation: (
    evaluationId?: EvaluationId,
    back: ProvideEvaluationPageBackOption = 'home',
    lang?: Language
  ) =>
    `/feedback/evaluation/provide${evaluationId ? getQueryString({ evaluationId, back, lang }) : ''}`,
  editEvaluationTemplate: (evaluationTemplateId?: EvaluationTemplateId) =>
    `/feedback/evaluation-template/${evaluationTemplateId ?? ':evaluationTemplateId'}`,
  newEvaluationTemplate: '/feedback/evaluation-template/new',
  newPulseTheme: '/pulse/theme/new',
  editPulseTheme: (pulseThemeId?: PulseThemeId) =>
    `/pulse/theme/${pulseThemeId ?? ':pulseThemeId'}`,
  editPulseKpi: (pulseKpiId?: PulseKpiId) => `/pulse/kpi/${pulseKpiId ?? ':pulseKpiId'}`,
  viewManagementFeedback: (managementFeedbackId?: ManagementFeedbackId) =>
    `/management-feedback/view/${managementFeedbackId ?? ':managementFeedbackId'}`,
  managementFeedback: (p?: ManagementFeedbackLinkParams) =>
    `/management-feedback${
      p?.managementFeedbackChannelId
        ? `?managementFeedbackChannelId=${p.managementFeedbackChannelId}`
        : ''
    }${p?.tab ? `#${p.tab}` : ''}`,
  provideManagementFeedback: (channelId?: ManagementFeedbackChannelId) =>
    `/management-feedback/provide${channelId ? `?managementFeedbackChannelId=${channelId}` : ''}`,
  multiWorkspace: '/all-workspaces',
  notFound: '/not-found',
  playbook: (tag: PulseQuestionTag) => `/api/pulse/playbook?theme=${tag}`,
  workspace: (p?: WorkspaceLinkParams) => '/workspace' + workspaceFragment(p),
  feedbackLink: (slug?: string) => `/feedback/${slug ?? ':slug'}`,
  logout: '/logout',
  discussions: '/discussions',
  discussion: ({
    discussionId,
    discussionNoteId,
    discussionCommentId,
  }: {
    discussionId?: DiscussionId
    discussionNoteId?: DiscussionNoteId
    discussionCommentId?: DiscussionCommentId
  } = {}) =>
    `/discussions/${discussionId ?? ':discussionId'}${getDiscussionAnchorHash({
      discussionNoteId,
      discussionCommentId,
    })}`,
} as const

export const normalizePath = (path: string): string => {
  return path.replace(/\d+/g, '<id>')
}

export const referralPageLink = 'https://hs.teamspective.com/referral-program'
export const knowledgeBaseLink = 'https://support.teamspective.com'
export const landingPageLink = 'https://teamspective.com'
export const dpaLink = 'https://teamspective.com/dpa'
export const privacyPolicyLink = 'https://teamspective.com/privacy-policy'
export const howToDesignASurveyQuestionLink =
  'https://support.teamspective.com/en/articles/6185859-how-to-design-a-survey-question'
export const oktaIntegrationIstructionsLink =
  'https://support.teamspective.com/en/articles/8075240-okta-saml-single-sign-on-sso-for-teamspective'
export const entraIdIngegrationInstructionsLink =
  'https://support.teamspective.com/en/articles/9744363-entra-id-saml-single-sign-on-sso-for-teamspective'
export const passiveDataPrivacyLink =
  'https://support.teamspective.com/en/articles/10741549-collaboration-data-processing-and-privacy'
