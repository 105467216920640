import { links, userLang } from '@teamspective/common'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { CheckCircleOutlined } from '@ant-design/icons'
import Result from '../../components/Result'
import { cyan } from '@teamspective/common'
import authStore from '../../stores/authStore'
import { translations } from '@teamspective/common'
import { lazy } from 'react'

const FireConfetti = lazy(() => import('../../components/confetti'))

const FeedbackProvideSuccessPage = ({
  isDemo,
  isSelfReflection,
}: {
  isDemo: boolean
  isSelfReflection: boolean
}) => {
  const { user } = authStore.useContainer()
  const lang = userLang(user)
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <FireConfetti />
      <Result
        extraTop={<CheckCircleOutlined style={{ fontSize: 64, marginBottom: 16, color: cyan }} />}
        title={
          isDemo
            ? translations[lang].allDone
            : isSelfReflection
              ? translations[lang].selfReflectSuccess
              : translations[lang].feedbackSuccess
        }
        body={isDemo ? undefined : translations[lang].youCanAskForFeedbackToo}
        lang={lang}
        showBackToFrontPage={false}
        extra={
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {isDemo ? (
              <div>
                <Link to={links.frontPage}>
                  <Button style={{ marginLeft: 16 }}>{translations[lang].backToFrontPage}</Button>
                </Link>
              </div>
            ) : user.status === 'complete' ? (
              <div>
                <Link to={links.makeRequest}>
                  <Button type="primary">{translations[lang].collectPersonalFeedback}</Button>
                </Link>
                <Link to={links.frontPage}>
                  <Button style={{ marginLeft: 16 }}>{translations[lang].backToFrontPage}</Button>
                </Link>
              </div>
            ) : (
              <div>
                <Link to={links.register}>
                  <Button type="primary">{translations[lang].createAccount}</Button>
                </Link>
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}

export default FeedbackProvideSuccessPage
