import type React from 'react'
import { Tooltip } from 'antd'
import { StarFilled as StarIcon } from '@ant-design/icons'
import type { Language, PlainTopic, RequestTopic } from '@teamspective/common'
import Info from '../Info'

import { lightBlack } from '@teamspective/common'
import { translations } from '@teamspective/common'

const CreatedBy = ({ name, lang }: { name: string; lang: Language }) => (
  <Tooltip title={translations[lang].userCreatedCustomTopic(name)}>
    <StarIcon style={{ color: lightBlack, marginBottom: 4 }} />
  </Tooltip>
)

export const FormattedDescription = ({ description }: { description: string }): JSX.Element => (
  <div style={{ whiteSpace: 'pre-wrap' }}>{description.replace(/\u2022/g, ' \u2022')}</div>
)

const TopicTitle = ({
  topic,
  lang,
  name,
  titleStyle,
}: {
  topic: PlainTopic | RequestTopic
  lang: Language
  name: string
  titleStyle?: React.CSSProperties
}): JSX.Element => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 style={{ marginBottom: 0, ...titleStyle }}>{topic.label}</h3>
        {topic.description ? (
          <div style={{ marginTop: 4 }}>
            <Info
              content={
                <div>
                  <b>{topic.label}</b>
                  <FormattedDescription description={topic.description} />
                </div>
              }
            />
          </div>
        ) : null}
      </div>
      {topic.type === 'plain' && topic.source === 'user' ? (
        <CreatedBy name={name} lang={lang} />
      ) : null}
    </div>
  )
}

export default TopicTitle
