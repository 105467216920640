import type React from 'react'
import { Avatar, Tooltip } from 'antd'
import type { Language } from '@teamspective/common'
import { UserIcon } from '../icons'
import { lightlightGrey, white } from '@teamspective/common'

import { translations } from '@teamspective/common'

const AnonymousAvatar: React.FC<{ lang: Language }> = ({ lang }) => {
  return (
    <Tooltip title={translations[lang].anonymousFeedback}>
      <Avatar style={{ backgroundColor: lightlightGrey, color: white }} icon={<UserIcon />} />
    </Tooltip>
  )
}

export default AnonymousAvatar
