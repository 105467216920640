import { createHeadlessEditor } from '@lexical/headless'
import type { SerializedEditorState } from 'lexical'
import type { Branded } from './types/index.ts'
import {
  ParagraphNode,
  TextNode,
  LineBreakNode,
  TabNode,
  $getRoot,
  $isTextNode,
  $createTextNode,
} from 'lexical'
import { $createLinkNode, $isLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import type { HeadingTagType } from '@lexical/rich-text'
import { $createHeadingNode, $isHeadingNode, HeadingNode } from '@lexical/rich-text'
import type { Transformer } from '@lexical/markdown'
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  INLINE_CODE,
  ITALIC_UNDERSCORE,
  ORDERED_LIST,
  QUOTE,
  UNORDERED_LIST,
} from '@lexical/markdown'

export const DEFAULT_LEAF_NODES = [TextNode, LineBreakNode, TabNode]

export const ALLOWED_CUSTOM_NODES = [HeadingNode, ListNode, ListItemNode, LinkNode]

export const RICH_TEXT_ELEMENT_NODES = [ParagraphNode, ...ALLOWED_CUSTOM_NODES]

type SerializedRichTextLeafNode = Parameters<(typeof DEFAULT_LEAF_NODES)[number]['importJSON']>[0]

type SerializedRichTextElementNode = Parameters<
  (typeof RICH_TEXT_ELEMENT_NODES)[number]['importJSON']
>[0] & { children: SerializedRichTextNode[] }

export type SerializedRichTextNode = SerializedRichTextElementNode | SerializedRichTextLeafNode

export type RichText = Branded<SerializedEditorState<SerializedRichTextNode>, 'ValidatedRichText'>

export const EMPTY_RICH_TEXT: RichText = {
  root: {
    type: 'root',
    format: '',
    indent: 0,
    children: [
      {
        type: 'paragraph',
        format: '',
        indent: 0,
        version: 1,
        children: [] as SerializedRichTextNode[],
        direction: 'ltr',
      },
    ],
    version: 1,
    direction: 'ltr',
  },
} as RichText

const ASSERT_EDITOR = createHeadlessEditor({
  nodes: ALLOWED_CUSTOM_NODES,
  onError: (e) => {
    throw e
  },
})

export const assertRichText = (obj: unknown): RichText => {
  if (typeof obj !== 'object' || obj === null) {
    throw new Error('Invalid rich text object')
  }

  const tested = obj as RichText

  ASSERT_EDITOR.parseEditorState(tested)

  return tested
}

// To convert RichText to other formats, we need to use headless Lexical editor
// for that. See: https://lexical.dev/docs/packages/lexical-headless
export const richTextToPlainText = (richText: RichText): string => {
  const editor = createHeadlessEditor({
    nodes: ALLOWED_CUSTOM_NODES,
    onError: (e) => {
      throw e
    },
  })

  editor.setEditorState(editor.parseEditorState(richText))

  let text = ''

  editor.update(
    () => {
      text = $getRoot().getTextContent()
    },
    { discrete: true }
  )

  return text
}

// Slack has its own markdown syntax
// https://api.slack.com/reference/surfaces/formatting
const SLACK_MARKDOWN_TRANSFORMERS_FROM_SLACK: Transformer[] = [
  QUOTE,
  UNORDERED_LIST,
  ORDERED_LIST,
  INLINE_CODE,
  {
    format: ['bold'],
    tag: '*',
    type: 'text-format',
  },
  ITALIC_UNDERSCORE,
  {
    format: ['strikethrough'],
    tag: '~',
    type: 'text-format',
  },

  // Adapted from:
  // https://github.com/facebook/lexical/blob/main/packages/lexical-markdown/src/MarkdownTransformers.ts#L357
  {
    dependencies: [LinkNode],
    export: (node, _exportChildren, exportFormat) => {
      if (!$isLinkNode(node)) {
        return null
      }
      const linkContent = `<${node.getURL()}|${node.getTextContent()}>`
      const firstChild = node.getFirstChild()
      if (node.getChildrenSize() === 1 && $isTextNode(firstChild)) {
        return exportFormat(firstChild, linkContent)
      } else {
        return linkContent
      }
    },
    importRegExp: /<([^|>]+)(?:\|([^>]+))?>/,
    regExp: /<([^|>]+)(?:\|([^>]+))?>$/,
    replace: (textNode, match) => {
      const [, linkUrl, linkText] = match
      const linkNode = $createLinkNode(linkUrl)
      const linkTextNode = $createTextNode(linkText || linkUrl)
      linkTextNode.setFormat(textNode.getFormat())
      linkNode.append(linkTextNode)
      textNode.replace(linkNode)
    },
    trigger: '>',
    type: 'text-match',
  },
]

const SLACK_MARKDOWN_TRANSFORMERS_TO_SLACK: Transformer[] = [
  // Slack doesn't have heading tags so just convert headings to bold
  // Adapted from:
  // https://github.com/facebook/lexical/blob/main/packages/lexical-markdown/src/MarkdownTransformers.ts#L190
  {
    dependencies: [HeadingNode],
    export: (node, exportChildren2) => {
      if (!$isHeadingNode(node)) {
        return null
      }
      return '*' + exportChildren2(node) + '*'
    },
    regExp: /^(#{1,6})\s/,

    // This is never called, but can be useful later if markdown headers are
    // supported somewhere
    replace: (parentNode, children, match) => {
      const node = $createHeadingNode(('h' + match[1].length) as HeadingTagType)
      node.append(...children)
      parentNode.replace(node)
      node.select(0, 0)
    },
    type: 'element',
  },
  ...SLACK_MARKDOWN_TRANSFORMERS_FROM_SLACK,
]

export const slackMarkdownToRichText = (markdown: string): RichText => {
  const editor = createHeadlessEditor({
    nodes: ALLOWED_CUSTOM_NODES,
    onError: (e) => {
      throw e
    },
  })

  editor.update(
    () => {
      $convertFromMarkdownString(markdown, SLACK_MARKDOWN_TRANSFORMERS_FROM_SLACK)
    },
    { discrete: true }
  )

  return assertRichText(editor.getEditorState().toJSON())
}

export const richTextToSlackMarkdown = (richText: RichText): string => {
  const editor = createHeadlessEditor({
    nodes: ALLOWED_CUSTOM_NODES,
    onError: (e) => {
      throw e
    },
  })

  editor.setEditorState(editor.parseEditorState(richText))

  let text = ''

  editor.update(
    () => {
      text = $convertToMarkdownString(SLACK_MARKDOWN_TRANSFORMERS_TO_SLACK)
    },
    { discrete: true }
  )

  return text
}
