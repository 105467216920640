import type React from 'react'
import { Comment } from '@ant-design/compatible'
import type {
  RequestComment as RequestCommentType,
  Language,
  AnyRequest,
  User,
} from '@teamspective/common'
import { userName, formatUntilNow } from '@teamspective/common'

import { captureEvent } from '@sentry/react'
import UserAvatar from '../user/UserAvatar'
import AnonymousAvatar from './AnonymousAvatar'

const RequestComment = ({
  request,
  comment,
  extra,
  lang,
}: {
  request: AnyRequest
  comment: Pick<RequestCommentType, 'userId' | 'content' | 'createdAt' | 'madeBy' | 'authorRole'>
  extra?: React.ReactNode
  lang: Language
}) => {
  const author = [
    request.provider,
    request.requester,
    ...(request.type === 'provided' ? request.additionalRecipients : []),
  ]
    .filter((user): user is User => user !== 'anonymous')
    .find((user) => user.userId === comment.userId)
  if (!author) {
    captureEvent({
      message: 'Unknown author for request comment',
      extra: { userId: comment.userId },
    })
    return null
  }
  return (
    <Comment
      content={comment.content}
      style={{ marginBottom: 16, maxWidth: 480, whiteSpace: 'pre-wrap' }}
      datetime={
        <span>
          {formatUntilNow(comment.createdAt, lang)}
          {extra ? extra : null}
        </span>
      }
      className={
        comment.madeBy === 'me' ? 'written-by-me speech-bubble-comment' : 'speech-bubble-comment'
      }
      author={userName(author)}
      avatar={<UserAvatar user={author} />}
    />
  )
}

export default RequestComment

export const AnonymousRequestComment = ({
  comment,
  extra,
  lang,
}: {
  request: AnyRequest
  comment: Pick<RequestCommentType, 'content' | 'createdAt' | 'madeBy' | 'authorRole'>
  extra?: React.ReactNode
  lang: Language
}) => (
  <Comment
    content={comment.content}
    style={{ marginBottom: 16, maxWidth: 480, whiteSpace: 'pre-wrap' }}
    datetime={
      <span>
        {formatUntilNow(comment.createdAt, lang)}
        {extra ? extra : null}
      </span>
    }
    className={
      comment.madeBy === 'me' ? 'written-by-me speech-bubble-comment' : 'speech-bubble-comment'
    }
    avatar={<AnonymousAvatar lang={lang} />}
  />
)
