import type React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Comment } from '@ant-design/compatible'
import { Button, Modal, Input, Divider, App } from 'antd'
import type {
  DraftedRequest,
  NewRequest,
  ProvidedRequest,
  Feedback,
  RequestTopic,
  UserId,
  FeedbackId,
  RequestId,
} from '@teamspective/common'
import { userLang, userName, links, dateToString, pick } from '@teamspective/common'
import { PlusOutlined as PlusIcon } from '@ant-design/icons'
import { api } from '../../api'

import { cyan, darkBlue, textColor } from '@teamspective/common'
import Loader from '../../components/Loader'
import Info from '../../components/Info'
import { translations } from '@teamspective/common'
import BaseContainer from '../../components/BaseContainer'
import useApiCall from '../../components/hooks/useApiCall'
import { SavedIcon } from '../../components/icons'
import Redirect from '../../components/Redirect'
import topicStore from '../../stores/topicStore'
import { RequestView } from '../ViewFeedback/RequestView'
import Footer from '../../Footer'
import authStore from '../../stores/authStore'
import CreateFeedback from './CreateFeedback'
import FeedbackProvideSuccessPage from './FeedbackProvideSuccessPage'
import { FeedbackVisibilityInfo } from '../../components/feedback/FeedbackVisibilityInfo'
import BaseHeader from '../../components/BaseHeader'
import { useGenericErrorNotification, useNotifyOnResponse } from '../../apiActionNotification'
import SubmitButton from '../../components/SubmitButton'

const toPreview = ({ request }: { request: NewRequest | DraftedRequest }): ProvidedRequest => ({
  ...request,
  feedback: request.feedback.filter(({ content }) => content.trim().length),
  type: 'provided',
  commentCount: 0,
  lastCommentAt: null,
  unseenComments: false,
  providerMessage: request.type === 'draft' ? request.providerMessage : '',
  feedbackIsAbout: 'me',
  providedAt: dateToString(new Date()),
  feedbackShares: [],
  additionalRecipients: [],
})

const ProvideAnonymousFeedback: React.FC = () => {
  const notifyOnResponse = useNotifyOnResponse()
  const genericErrorNotification = useGenericErrorNotification()
  const { modal } = App.useApp()
  const { user } = authStore.useContainer()
  const lang = userLang(user)
  const { slug } = useParams()
  if (!slug) {
    throw new Error('Malformed feedback slug')
  }

  const feedbackLink = useApiCall(`getFeedbackLink-${slug}`, () =>
    api.getFeedbackLink({ feedbackLinkSlug: slug })
  )

  const { topics } = topicStore.useContainer()

  const [feedback, setFeedback] = useState<Pick<Feedback, 'topicId' | 'content' | 'category'>[]>([])
  const [requestTopics, setRequestTopics] = useState<RequestTopic[] | null>(null)
  const [providerMessage, setProviderMessage] = useState('')

  const [success, setSuccess] = useState(false)
  const [seePreview, setSeePreview] = useState<boolean>(false)
  const navigate = useNavigate()

  const [providerMessageInputOpen, setProviderMessageInputOpen] = useState(false)

  useEffect(() => {
    if (feedbackLink.type === 'ok' && !requestTopics) {
      setRequestTopics(
        feedbackLink.data.topics.map((topic) => ({
          ...topic,
          createdByUserId: feedbackLink.data.user.userId,
          type: 'request',
        }))
      )
    }
  }, [feedbackLink, requestTopics])

  useEffect(() => {
    if (user.status === 'complete' && feedbackLink.type === 'ok' && feedbackLink.data.enabled) {
      if (user.userId === feedbackLink.data.user.userId) {
        navigate(links.feedback({ tab: 'feedback-link' }))
      } else {
        void api
          .createUnsolicitedFeedbackRequest({
            recipientUserId: feedbackLink.data.user.userId,
            visibility: 'private',
            feedbackLinkSlug: slug,
          })
          .then((response) => {
            if (response.status === 'ok') {
              navigate(links.provideFeedback(response.data))
            } else {
              genericErrorNotification(lang)
            }
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, feedbackLink.type, navigate])

  const anonymousFeedbackApi: typeof api = {
    ...api,
    createDraftFeedback: (fb) => {
      const feedback = {
        ...fb,
        content: '',
        // TODO: Allow setting null feedbackIds instead of placeholder values like here?
        feedbackId: 0 as FeedbackId,
        updatedAt: dateToString(new Date()),
        isSeen: false,
      }
      setFeedback((fbs) => fbs.concat(pick(feedback, ['category', 'topicId', 'content'])))
      return Promise.resolve({ status: 'ok', data: feedback })
    },
    updateDraftFeedback: ({ feedback }) => {
      setFeedback((fbs) =>
        fbs.map((fb) =>
          pick(
            fb.category === feedback.category && fb.topicId === feedback.topicId ? feedback : fb,
            ['category', 'topicId', 'content']
          )
        )
      )
      return Promise.resolve({ status: 'ok', data: null })
    },
    addRequestTopic: ({ topicId }) => {
      const topic = topics !== 'loading' ? topics.find((t) => t.topicId === topicId) : undefined
      if (topic) {
        setRequestTopics((rts) =>
          (rts ?? []).concat({ ...topic, createdByUserId: 0 as UserId, type: 'request' })
        )
      }

      return Promise.resolve({ status: 'ok', data: null })
    },
    publishRequestFeedback: () => {
      return api.sendAnonymousFeedback({ feedbackLinkSlug: slug, feedback, providerMessage })
    },
  }

  if (feedbackLink.type === 'loading' || topics === 'loading') {
    return <Loader />
  }

  if (feedbackLink.type === 'error') {
    return <Redirect to={links.login} />
  }

  if (success) {
    return <FeedbackProvideSuccessPage isDemo={false} isSelfReflection={false} />
  }

  const contentfulFeedback = feedback.filter((f) => Boolean(f.content.trim()))

  const SendButton = (
    <SubmitButton
      style={{ marginLeft: 16 }}
      type="primary"
      disabled={contentfulFeedback.length === 0}
      onClick={async () => {
        const onOk = async () => {
          await api
            .sendAnonymousFeedback({ feedbackLinkSlug: slug, feedback, providerMessage })
            .then(notifyOnResponse({ lang }))

          setSuccess(true)
        }

        const suspiciouslyShortFeedback =
          contentfulFeedback.map(({ content }) => content).join().length < 10

        if (suspiciouslyShortFeedback) {
          await modal.confirm({
            onOk,
            content: translations[lang].suspiciouslyShort,
            okText: translations[lang].publishFeedback,
            cancelText: translations[lang].back,
          })
        } else {
          await onOk()
        }
      }}
    >
      {translations[lang].publishFeedback}
    </SubmitButton>
  )

  return (
    <div>
      <h1>{translations[lang].feedbackFor(userName(feedbackLink.data.user))}</h1>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <div />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FeedbackVisibilityInfo
            visibility="private"
            feedbackShares={[]}
            lang={lang}
            provider="anonymous"
            recipient={feedbackLink.data.user}
          />
          <Info
            title={translations[lang].followTheseGuidelines}
            content={
              <div style={{ maxWidth: 320 }}>
                <ul style={{ paddingLeft: 16 }}>
                  {translations[lang].guideLineRows.split('\n').map((row, i) => (
                    <li style={{ marginBottom: 8 }} key={i}>
                      {row}
                    </li>
                  ))}
                </ul>
                {translations[lang].thanksInAdvance}
              </div>
            }
            style={{ color: textColor }}
          />
          {!providerMessageInputOpen ? (
            <Button
              style={{ color: darkBlue }}
              type="text"
              onClick={() => setProviderMessageInputOpen(true)}
            >
              <PlusIcon />
              {translations[lang].addPersonalMessage}
            </Button>
          ) : null}
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        {providerMessageInputOpen ? (
          <Comment
            content={
              <>
                <Input.TextArea
                  rows={2}
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  maxLength={200}
                  value={providerMessage}
                  onChange={(e) => {
                    setProviderMessage(e.target.value)
                  }}
                  placeholder={translations[lang].typeSomething}
                  autoFocus
                />{' '}
                <div style={{ textAlign: 'right', marginBottom: -8 }}>
                  {providerMessage === '' ? (
                    ' '
                  ) : (
                    <>
                      {translations[lang].saved}
                      <SavedIcon style={{ color: cyan, marginLeft: 8 }} />
                    </>
                  )}
                </div>
              </>
            }
            style={{ width: 480, marginTop: 8, whiteSpace: 'pre-wrap' }}
            className="written-by-me speech-bubble-comment"
          />
        ) : null}
      </div>

      <Divider />

      <CreateFeedback
        lang={lang}
        refresh={() => null}
        api={anonymousFeedbackApi}
        request={toPreview({
          request: {
            type: 'draft',
            requestId: 0 as RequestId,
            personalMessage: '',
            providerMessage,
            requestCreated: dateToString(new Date()),
            feedbackIsAbout: 'colleague',
            origin: 'unsolicited',
            isSeen: false,
            requester: feedbackLink.data.user,
            provider: feedbackLink.data.user,
            feedback: feedback.map((fb, i) => ({
              ...fb,
              isSeen: false,
              // TODO: Is using index of the feedback as feedbackId ok? This value seems a bit meaningless so maybe make
              // the field optional?
              feedbackId: i as FeedbackId,
              updatedAt: dateToString(new Date()),
            })),
            visibility: 'private',
          },
        })}
        requestTopics={requestTopics ?? []}
        favouriteTopicIds={feedbackLink.data.topics.map((t) => t.topicId)}
      />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={contentfulFeedback.length === 0}
          style={{ marginLeft: 16 }}
          onClick={() => {
            setSeePreview(true)
          }}
        >
          {translations[lang].preview}
        </Button>
        {SendButton}
      </div>

      <Modal
        onCancel={() => setSeePreview(false)}
        centered
        title={translations[lang].seePreviewAndSend}
        styles={{ body: { maxHeight: 480, overflowY: 'scroll' } }}
        open={seePreview}
        width={720}
        footer={
          <>
            <Button onClick={() => setSeePreview(false)}>{translations[lang].back}</Button>
            {SendButton}
          </>
        }
      >
        <RequestView
          request={{
            ...toPreview({
              request: {
                type: 'draft',
                feedback: feedback as Feedback[],
                requestId: 0 as RequestId,
                personalMessage: null,
                requester: feedbackLink.data.user,
                provider: feedbackLink.data.user,
                providerMessage,
                requestCreated: dateToString(new Date()),
                feedbackIsAbout: 'colleague',
                isSeen: false,
                origin: 'unsolicited',
                visibility: 'private',
              },
            }),
            provider: 'anonymous',
          }}
          requestTopics={requestTopics ?? []}
          type="preview"
        />
      </Modal>
    </div>
  )
}

const ProvideAnonymousFeedbackPage: React.FC = (): JSX.Element | null => (
  <div>
    <BaseHeader />
    <BaseContainer>
      <ProvideAnonymousFeedback />
    </BaseContainer>
    <Footer />
  </div>
)

export default ProvideAnonymousFeedbackPage
