import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

type Action = 'flush' | 'reset' | 'clear'

function useStateWithDebouncedSideEffect<T>(
  initialValue: T,
  delay: number,
  callback: (value: T) => Promise<unknown>
): [T, Dispatch<SetStateAction<T>>, (t: Action) => Promise<unknown>] {
  const [value, setValue] = useState<T>(initialValue)
  const debouncingOnValue = useRef(initialValue)
  const timer = useRef<number | null>(null)

  const action = useCallback(
    async (type: Action) => {
      if (timer.current) {
        window.clearTimeout(timer.current)
      }
      switch (type) {
        case 'clear':
          return
        case 'flush':
          return await callback(value)
        case 'reset':
          return setValue(initialValue)
      }
    },
    [timer, initialValue, value, setValue, callback]
  )

  useEffect(() => {
    // If the changed value is alredy being debounced, keep going
    if (debouncingOnValue.current === value) {
      return
    }

    // A different debounce action will happen so the old timer is no longer valid
    if (timer.current) {
      window.clearTimeout(timer.current)
    }

    // Special case: falsy values not debounced
    if (!value) {
      void callback(value)
      return
    }

    // Schedule the new debounce
    debouncingOnValue.current = value
    timer.current = window.setTimeout(() => {
      void callback(value)
    }, delay)

    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current)
      }
    }
  }, [value, action, initialValue, callback, delay])

  return [value, setValue, action]
}

export default useStateWithDebouncedSideEffect
