import Logo from './Logo'
import { Dropdown, Button } from 'antd'
import { api } from '../api'
import { white, lightlightGrey } from '@teamspective/common'
import { translations } from '@teamspective/common'
import BaseContainer from './BaseContainer'
import { SettingsIcon, DownIcon } from './icons'
import LanguageSelector from './core/LanguageSelector'
import authStore from '../stores/authStore'

const BaseHeader = ({
  showLogout,
  showLanguageSelector,
}: {
  showLogout?: boolean
  showLanguageSelector?: boolean
}) => {
  const { lang, setLanguage } = authStore.useContainer()

  return (
    <header>
      <BaseContainer
        outerStyle={{ backgroundColor: white, borderBottom: `1px solid ${lightlightGrey}` }}
        innerStyle={{
          flexFlow: undefined,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Logo style={{ alignSelf: 'left' }} size="regular" />
        {showLogout ? (
          <Dropdown
            menu={{
              onClick: ({ key }) => {
                if (key === 'logout') {
                  void api.logout().then(() => window.location.reload())
                }
              },
              items: [{ key: 'logout', label: translations.en.logout }],
            }}
          >
            <Button type="link">
              <SettingsIcon />
              <DownIcon />
            </Button>
          </Dropdown>
        ) : (
          <div />
        )}
        {showLanguageSelector ? (
          <LanguageSelector lang={lang} onChange={setLanguage} variant="borderless" />
        ) : (
          <div />
        )}
      </BaseContainer>
    </header>
  )
}

export default BaseHeader
