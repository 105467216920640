import type React from 'react'
import { Button, Card } from 'antd'
import { Link } from 'react-router-dom'

import type { Language } from '@teamspective/common'
import { links } from '@teamspective/common'
import { translations } from '@teamspective/common'

const Result = ({
  title,
  body,
  lang,
  extraTop,
  extra,
  showBackToFrontPage,
  style,
  maxWidth = 640,
}: {
  title: string
  body?: string
  lang: Language
  extraTop?: React.ReactNode
  extra?: React.ReactNode
  showBackToFrontPage?: boolean
  style?: React.CSSProperties
  maxWidth?: number | 'none'
}): JSX.Element => {
  return (
    <>
      <Card
        style={{ width: '100%', maxWidth }}
        styles={{
          body: { display: 'flex', alignItems: 'center', flexDirection: 'column', ...style },
        }}
      >
        {extraTop ? extraTop : null}
        <h2>{title}</h2>
        {body ? <p style={{ color: '' }}>{body}</p> : null}
        {extra ? extra : null}
      </Card>
      {showBackToFrontPage ? (
        <Link to={links.frontPage}>
          <Button style={{ marginTop: 32 }}>{translations[lang].backToFrontPage}</Button>
        </Link>
      ) : null}
    </>
  )
}
export default Result
