// main colors used are grey, blue, yellow, cyan, volcano
import { type NetworkRecognitionArea } from '../../../index.ts'
import { linearScaleColorArray } from './questionChoiceColors.ts'

export const greys = [
  '#ffffff', // 0
  '#fcfaf8', // 1
  '#f5efec', // 2
  '#ebe4e1', // 3
  '#d8cfca', // 4
  '#c5bab5', // 5
  '#b2a6a0', // 6
  '#9f928b', // 7
  '#8c7f78', // 8
  '#796c65', // 9
  '#665953', // 10
  '#534842', // 11
  '#403632', // 12
  '#2d2522', // 13
  '#1a1513', // 14
  '#000000', // 15
] as const

export const white = greys[0]
export const khaki = greys[1]
export const pageBackgroundColor = greys[1]
export const khakiDarker = greys[2]
export const lightlightGrey = greys[4]
export const lightGrey = greys[6]
export const lightBrown = greys[8]
export const textColor = greys[11]
export const textColor2 = 'rgba(0, 0, 0, 0.88)'
export const secondaryTextColor = 'rgba(0, 0, 0, 0.65)'
export const tertiaryTextColor = 'rgba(0, 0, 0, 0.45)'
export const iconColor = 'rgba(0, 0, 0, 0.45)'
export const lightIconColor = 'rgba(0, 0, 0, 0.30)'
export const progressIndicatorColor = lightIconColor
export const progressTrailColor = 'rgba(0, 0, 0, 0.06)'
export const borderColor = 'rgba(0, 0, 0, 0.15)'
export const tagColor = 'rgba(0, 0, 0, 0.04)'
export const hollowBackground = 'rgba(0, 0, 0, 0.08)'
export const buttonColor = 'rgba(0, 0, 0, 0.06)'
export const dividerColor = 'rgba(0, 0, 0, 0.06)'
export const selectorCardColor = 'rgba(0, 0, 0, 0.04)'
export const segmentedBgColor = 'rgba(0, 0, 0, 0.04)'
export const colorFillAlter = 'rgba(0, 0, 0, 0.02)'
export const collapseColor = '#FAFAFA'
/** @alias lightlightGrey */
export const tagBorderColor = lightlightGrey
export const aiColor = 'rgb(234, 244, 254)'
export const placeholderColor = greys[9]
export const textInputFilledBackgroundHover = 'rgba(0, 0, 0, 0.06)'
export const textInputFilledBackground = 'rgba(0, 0, 0, 0.02)'

export const benchmarkColor = '#A9ECE8'
export const headingBlue = '#2d2f57'
export const midnightBlue = '#002760'
export const midnightBlueOpacity = '#00276066'
export const darkBlue = '#2a3fa2'
export const blue = '#4961d0'
export const blueBackground = '#E6F4FF'
export const blueHover = '#7189de'
export const yellowBackground = '#FFE58F'
/** @alias blue */
export const buttonSelectedColor = blue
/** @alias blueBackground */
export const buttonSelectedBackground = blueBackground
export const skyBlue = '#9DCBE1'
export const sienna = '#ff7a45'
export const red = '#d4380d'
export const notificationRed = '#d7444e'
export const lightBlack = greys[11]
export const yellow = '#fadb14'
export const cyan = '#13c2c2'
export const green = '#52c41a'
export const lightGreen = '#D9F7BE'
export const brightOrange = '#F46A36'
export const gold = '#FAAD14'
const aquamarine = '#6EDBE2'
const paleGreyBlue = '#AEB9D1'
export const seaBlue = '#4096FF'

export const orange = 'rgba(255, 233, 217, 1)'
export const backgroundOrange = 'rgba(255, 233, 217, 0.5)'
export const backgroundGreen = 'rgba(247, 249, 217, 0.5)'
export const backgroundYellow = '#fffbe6'

export const brandColor = '#E68359'

export const lightText = greys[9]
export const border = greys[4]
export const background = greys[1]
export const peachy = '#f2ece8'

export const demoYellow = '#F8DE81'

export const selfReflectionYellow = '#f5e7a3'

export const colorSuccess = linearScaleColorArray[4]
export const colorWarningLight = '#FFF1B8'
export const colorWarning = linearScaleColorArray[2]
/** @alias gold */
export const colorWarningDark = gold
export const colorError = linearScaleColorArray[0]

/** @alias blueBackground */
export const colorRunning = blueBackground

export const colorNegativeSentiment = '#FFA39E'
export const colorNeutralSentiment = '#C7CBCA'
export const colorPositiveSentiment = '#87E8DE'
/** @alias blue */
export const colorActionFactor = blue

export const neutralGraphColor = '#ABBAD1'

export const completedEvaluationsColor = aquamarine
export const pendingEvaluationsColor = seaBlue
export const expiredEvaluationsColor = '#FFA39E'
export const notEvaluatedColor = '#CDC5C0'

/** @alias blueBackground */
export const takeActionBgColor = blueBackground
/** @alias gold */
export const draftColor = gold
export const expiringColor = '#FF4D4F'

export const evaluationSummaryStartColor = '#2CD766'
export const evaluationSummaryStopColor = '#FF4D4F'
export const evaluationSummaryContinueColor = '#FAAD14'
export const evaluationSummaryColor = '#0D4D90'

export const unpublishedEvaluationColor = '#FFD666'
export const unpublishedEvaluationDiscouragedColor = '#FFB28B'

export const evaluationTableReadyToPublishColor = '#FFF1B8'
export const evaluationTableReadyToPublishIconColor = '#FAAD14'

/** @alias */
export const evaluationTablePublishedColor = lightGreen
export const evaluationTablePublishedIconColor = '#52C41A'

export const evaluationTablePendingColor = 'rgba(0, 0, 0, 0.04)'
export const evaluationTablePendingIconColor = 'rgba(0, 0, 0, 0.45)'

export const discussionTimelineColor = '#1677FF'

const customSequentialMap = {
  2: ['#2c7fb8', '#edf8b1'],
  3: ['#2c7fb8', '#7fcdbb', '#edf8b1'],
  4: ['#225ea8', '#41b6c4', '#a1dab4', '#ffffcc'],
  5: ['#253494', '#2c7fb8', '#41b6c4', '#a1dab4', '#ffffcc'],
  6: ['#253494', '#2c7fb8', '#41b6c4', '#7fcdbb', '#c7e9b4', '#ffffcc'],
  7: ['#0c2c84', '#225ea8', '#1d91c0', '#41b6c4', '#7fcdbb', '#c7e9b4', '#ffffcc'],
  8: ['#0c2c84', '#225ea8', '#1d91c0', '#41b6c4', '#7fcdbb', '#c7e9b4', '#edf8b1', '#ffffd9'],
} as const

export const customChoiceColor = (value: number, choiceCount: number): string => {
  const mapping = customSequentialMap[choiceCount as keyof typeof customSequentialMap]
  if (!mapping) {
    return 'yellow'
  }
  const color = mapping[value - 1]
  if (!color) {
    return 'red'
  }
  return color
}

export const networkAreaColors: Record<NetworkRecognitionArea | 'connector', string> = {
  culture: '#cabc6a',
  execution: '#78c5db',
  leadership: '#82ca9d',
  connector: '#b8b9cb',
}

// https://sashamaps.net/docs/resources/20-colors/
export const categoricalColors = [
  '#4363d8',
  '#ffe119',
  '#469990',
  '#f58231',
  '#dcbeff',
  '#bfef45',
  '#fffac8',
  '#808000',
  '#42d4f4',
  '#800000',
  '#aaffc3',
  '#000075',
  '#fabed4',
  '#a9a9a9',
  '#ffd8b1',
  '#9A6324',
  '#e6194B',
  '#3cb44b',
  '#911eb4',
  '#f032e6',
]

export const egonetRelationColors = {
  managerOfLeader: aquamarine,
  other: seaBlue,
  report: paleGreyBlue,
}

export const evaluationScoreColors = [aquamarine, seaBlue, '#FFCA40', '#A86EE2']
