import type {
  HrisIntegrationClientInfo,
  SSOProvider,
  TeamId,
  UserId,
  WorkspaceFeedbackSettings,
} from '../types/index.ts'
import type { DateString } from './date.ts'
import type { SubscriptionPlan, User } from './user.ts'
import type {
  UserTeam,
  TeamRole,
  GroupScopedPermissions,
  WorkspacePermissions,
  UserPermissions,
} from './userTeam.ts'
import type { Pulse, DemoPulse } from './pulse.ts'
import type { TeamSlackWorkspace } from './slack.ts'
import type { MsTeamsConnectionLimited } from './ms-teams.ts'
import { fromEntries } from '../util.ts'

export type TeamDescriptor = {
  teamId: TeamId
  teamName: string
}
export type DemoTeamDescriptor = { teamId: DemoTeamId; teamName: string }
export type AnyTeamDescriptor = TeamDescriptor | DemoTeamDescriptor
export type WithChildren<T> = T & { children: WithChildren<T>[] }
export type WithLevel<T extends AnyTeamDescriptor> = T & {
  level: number
  parentId: T['teamId'] | null
}
export type WithRole<T> = T & { role: TeamRole | 'none'; permissions: GroupScopedPermissions }
export type TeamDescriptorWithChildren = WithChildren<TeamDescriptor>
export type DemoTeamDescriptorWithChildren = WithChildren<DemoTeamDescriptor>
export type AnyTeamDescriptorWithChildren =
  | TeamDescriptorWithChildren
  | DemoTeamDescriptorWithChildren

export type TeamDescriptorWithLevel = WithLevel<TeamDescriptor>
export type DemoTeamDescriptorWithLevel = WithLevel<DemoTeamDescriptor>

type BaseTeam<T extends TeamDescriptor | DemoTeamDescriptor = TeamDescriptor> = {
  teamId: T['teamId']
  teamName: string
  workspaceId: T['teamId']
  createdAt: DateString
  userCount: number
  pulseParticipantUserCount: number
}

export type Group<T extends TeamDescriptor | DemoTeamDescriptor = TeamDescriptor> = BaseTeam<T> & {
  type: 'group'
  publicId: GroupClientId
  teamExternalId: string | null
}

export type Workspace<T extends TeamDescriptor | DemoTeamDescriptor = TeamDescriptor> =
  BaseTeam<T> & {
    type: 'workspace'
    publicId: WorkspaceClientId
    archivedAt: DateString | null
  }

export type Team<T extends TeamDescriptor | DemoTeamDescriptor = TeamDescriptor> =
  | Workspace<T>
  | Group<T>

export type UserWorkspace = {
  teamId: TeamId
  teamName: string
  role: TeamRole
  pulseParticipant: boolean
}

export type DetailedWorkspace<T extends TeamDescriptor | DemoTeamDescriptor = TeamDescriptor> =
  T & {
    createdAt: DateString
    userTeams: Array<UserTeam<T['teamId']>>
    groups: Group<T>[]
    users: User[]
    role: TeamRole | 'none'
    permissions: GroupScopedPermissions
    workspacePermissions: WorkspacePermissions
    userPermissions: UserPermissions
    children: WithChildren<WithRole<T>>[]
    subscription: SubscriptionDetails
    allowedDomains: string[]
    features: TeamFeatures
    slackWorkspace: TeamSlackWorkspace | { type: 'none' }
    msTeamsConnection: { type: 'none' } | (MsTeamsConnectionLimited & { type: 'installed' })
    sso: { type: SSOProvider | 'none' }
    hrisIntegration: { type: 'none' } | (HrisIntegrationClientInfo & { type: 'active' })
    archivedAt: DateString | null
    publicId: WorkspaceClientId
    feedbackSettings: WorkspaceFeedbackSettings
  }

export type AnyDetailedWorkspace = DetailedWorkspace<TeamDescriptor | DemoTeamDescriptor>

export type DemoTeam = Team<DemoTeamDescriptor>
export type DemoDetailedWorkspace = DetailedWorkspace<DemoTeamDescriptor>

export const subscriptionIntervals = ['year', 'month'] as const
export type SubscriptionInterval = (typeof subscriptionIntervals)[number]

export type SubscriptionTerms = {
  subscriptionInterval: SubscriptionInterval
  subscriptionPlan: SubscriptionPlan
  teamId: TeamId
  fixedPrice: boolean
}

export type StripeSubscriptionDetails = {
  status: 'subscribed'
  cancelAtPeriodEnd: boolean
  currentPeriodEnd: DateString
  terms: SubscriptionTerms
}

export type GrantedSubscriptionDetails = {
  status: 'granted' | 'free-trial'
  end: DateString
  subscriptionPlan: SubscriptionPlan
}

export type FreeTierDetails = {
  status: 'free-under-30'
}

export type SubscriptionDetails =
  | { status: 'none' }
  | FreeTierDetails
  | GrantedSubscriptionDetails
  | StripeSubscriptionDetails

export const allFeatureIds = [
  // core features
  'pulse',
  'feedback',
  'network',
  'discussions',
  'management-feedback',
  'network',
  // pulse addons
  'cohorts',
  'playbooks',
  'smart-pulse-summary',
  'survey-targeting',
  'pulse-comment-sentiment-analysis',
  'evaluation-ai-summary',
  // feedback addons
  'personal-feedback',
  'performance-management',
  'unsolicited-feedback',
  'self-reflection',
  'request-own-evaluation',
  'evaluation-own-numeric-scores',
  'evaluation-own-numeric-scores-by-question',
  // network addons
  'network-survey',
  // beta features
  'passive-influence-demand',
  'engagement-beta',
  // other
  'okta-sso',
] as const

export type FeatureId = (typeof allFeatureIds)[number]

export const allCoreFeatureIds = [
  'pulse',
  'feedback',
  'network',
] as const satisfies readonly FeatureId[]

export type CoreFeatureId = (typeof allCoreFeatureIds)[number]

export const teamAdminModifiableFeatureIds = [
  'unsolicited-feedback',
  'request-own-evaluation',
  'evaluation-own-numeric-scores',
  'evaluation-own-numeric-scores-by-question',
  'pulse-comment-sentiment-analysis',
  'evaluation-ai-summary',
] as const satisfies readonly FeatureId[]

export type TeamAdminModifiableFeatureId = (typeof teamAdminModifiableFeatureIds)[number]

export const experimentalFeatureIds: FeatureId[] = ['discussions', 'engagement-beta']

export type TeamFeatures = Record<FeatureId, boolean>

export const EmptyTeamFeatures: TeamFeatures = fromEntries(allFeatureIds.map((id) => [id, false]))

export const DemoTeamIds = [
  'demo',

  'demo-rd',
  'demo-mkt',
  'demo-cs',
  'demo-fin',
  'demo-hr',

  'demo-eu',
  'demo-us',
  'demo-as',
] as const

export type DemoTeamId = (typeof DemoTeamIds)[number]

export const isDemoTeamId = (param: number | string | null): param is DemoTeamId => {
  if (typeof param === 'number') {
    return false
  }
  return DemoTeamIds.some((id) => id === param)
}

export const isDemoTeam = (param: Team | DemoTeam): param is DemoTeam => isDemoTeamId(param.teamId)
export const isDemoTeamDescriptor = (
  param: TeamDescriptor | DemoTeamDescriptor
): param is DemoTeamDescriptor => isDemoTeamId(param.teamId)

export const isDemoDetailedWorkspace = (
  param: AnyDetailedWorkspace
): param is DemoDetailedWorkspace => isDemoTeamId(param.teamId)

export const isDetailedWorkspace = (param: AnyDetailedWorkspace): param is DetailedWorkspace =>
  !isDemoDetailedWorkspace(param)

export const isDemoPulse = (pulse: DemoPulse | Pulse): pulse is DemoPulse =>
  isDemoTeamId(pulse.teamId)

export type WorkspaceSummary = {
  teamId: TeamId
  workspaceName: string
  createdAt: DateString
  archivedAt: DateString | null
  subscriptionPlan: SubscriptionPlan | null
  grantedPlan: SubscriptionPlan | null
  userCount: number
  testUserCount: number
  slackWorkspace: { slackWorkspaceName: string } | null
  msTeamsConnection: { msTeamsTeamName: string } | null
  isActive: boolean
  hrisIntegration: HrisIntegrationClientInfo | null
  loginCode: string | null
}

export const workspaceOnboardingSteps = ['connect-slack', 'invite-users', 'start-pulse'] as const

export type WorkspaceOnboardingStep = (typeof workspaceOnboardingSteps)[number]

export type WorkspaceOnboarding = {
  teamId: TeamId
  userId: UserId
  completed: boolean
  completedSteps: WorkspaceOnboardingStep[]
}

export type ClientIdPrefix = 'ws' | 'group' | 'apikey'

export type WorkspaceClientId = `ws_${string}`
export type GroupClientId = `group_${string}`
export type ApiKeyClientId = `apikey_${string}`
export type ClientId = WorkspaceClientId | GroupClientId | ApiKeyClientId
