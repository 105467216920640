import { Alert, Button } from 'antd'

import { khaki, lightlightGrey, white } from '@teamspective/common'
import { translations } from '@teamspective/common'
import BaseContainer from './BaseContainer'
import Logo from './Logo'

const ErrorPage = ({
  error,
  resetError,
  showHeader = true,
}: {
  error: unknown
  resetError: () => unknown
  showHeader?: boolean
}): JSX.Element => {
  return (
    <div>
      {showHeader && (
        <header>
          <BaseContainer
            outerStyle={{ backgroundColor: white, borderBottom: `1px solid ${lightlightGrey}` }}
            innerStyle={{
              flexFlow: undefined,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Logo style={{ alignSelf: 'left' }} size="small" />
          </BaseContainer>
        </header>
      )}

      <BaseContainer outerStyle={{ backgroundColor: khaki }} innerStyle={{ padding: 32 }}>
        <h2>{translations.en.genericErrorMessageTitle}</h2>
        <h3>{translations.en.genericErrorMessageDescription}</h3>
        <Alert message={String(error)} style={{ margin: 16, maxWidth: 640 }} />
        <Button
          onClick={() => {
            localStorage.removeItem('teamId')
            window.location.pathname = '/'
            resetError()
          }}
        >
          {translations.en.backToFrontPage}
        </Button>
      </BaseContainer>
    </div>
  )
}

export default ErrorPage
