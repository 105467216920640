import { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined as LoadingIcon } from '@ant-design/icons'

const Loader: React.FC<{ fontSize?: number; delay?: number }> = ({ fontSize, delay }) => {
  const [loaderVisible, setIsLoaderVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setIsLoaderVisible(true), delay ?? 500)
    return () => clearTimeout(timer)
  }, [delay])

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loaderVisible ? <PlainLoader fontSize={fontSize ?? 64} /> : null}
    </div>
  )
}

export const PlainLoader: React.FC<{ fontSize?: number }> = ({ fontSize }) => (
  <Spin indicator={<LoadingIcon style={{ fontSize: fontSize ?? 64 }} spin />} />
)

export default Loader
