import type { Language } from './domain/user.ts'

export const getQuotedString = (string: string, lang: Language): string => {
  switch (lang) {
    case 'en':
    case 'sv':
    case 'no':
    case 'dk':
    case 'es':
    case 'de':
    case 'it':
    case 'fr':
    case 'pl':
    case 'cn':
    case 'uk':
      return `“${string}”`
    case 'fi':
      return `”${string}”`
  }
}
