import { useEffect, useState, useMemo, useCallback } from 'react'
import type { DetailedWorkspace, Ok, TeamId, UserWorkspace } from '@teamspective/common'
import { parseIntOrNull } from '@teamspective/common'
import { createContainer } from 'unstated-next'
import { useSearchParams } from 'react-router-dom'
import { api } from '../api'
import type { OptimisticMutator } from '../components/hooks/useApiCall'
import useApiCall from '../components/hooks/useApiCall'

type UseWorkspaceSelectorStoreReturn = {
  workspace: DetailedWorkspace | 'loading' | 'none'
  refreshWorkspace: () => void
  refreshWorkspaceOptimistic?: OptimisticMutator<DetailedWorkspace>
  refreshTeams: () => void
  setSelectedWorkspaceId: (id: TeamId) => void
  allWorkspaces: UserWorkspace[] | 'loading'
}

const useWorkspaceSelectorStore = (): UseWorkspaceSelectorStoreReturn => {
  const userWorkspaceRequest = useApiCall('getUserWorkspaces', () => api.getUserWorkspaces())

  const [, setSearch] = useSearchParams()

  const localStorageStoredValue = localStorage.getItem('teamId')
  const localStorageTeamId = localStorageStoredValue
    ? (parseIntOrNull(localStorageStoredValue) as TeamId)
    : null

  const [selectedWorkspaceId, innerSetSelectedWorkspaceId] = useState(
    localStorageTeamId ?? 'loading'
  )

  const setSelectedWorkspaceId = useCallback(
    (teamId: TeamId | 'none') => {
      if (teamId !== 'none') {
        localStorage.setItem('teamId', String(teamId))
      }
      // TODO: setSearch loses the fragment eg. /pulse?teamId=1#results -> /pulse
      setSearch((prev) => {
        prev.delete('teamId')
        return prev
      })
      innerSetSelectedWorkspaceId(teamId)
    },
    [setSearch]
  )

  const allUserWorkspaces = useMemo(
    () => (userWorkspaceRequest.type !== 'ok' ? 'loading' : userWorkspaceRequest.data),
    [userWorkspaceRequest]
  )

  // Initialize selectedWorkspace if no workspace was found in localStorage
  useEffect(() => {
    if (
      (selectedWorkspaceId !== 'none' && selectedWorkspaceId !== 'loading') ||
      allUserWorkspaces === 'loading'
    ) {
      return
    }
    setSelectedWorkspaceId(allUserWorkspaces.length > 0 ? allUserWorkspaces[0]?.teamId : 'none')
  }, [selectedWorkspaceId, setSelectedWorkspaceId, allUserWorkspaces])

  const workspaceRequest = useApiCall(
    typeof selectedWorkspaceId === 'number' ? `getTeam-${selectedWorkspaceId}` : null,
    () =>
      api
        .getTeam({
          // If the first argument to `useApiCall` is null, this callback will never be called.
          // Thus this cast is safe
          // TODO: Make the `useApiCall` 1st argument a function that returns the key and some
          // data K that gets passed to this callback. This allows narrowing the
          teamId: selectedWorkspaceId as TeamId,
        })
        .then((teamResponse) => {
          if (teamResponse.status === 'ok') {
            return teamResponse as Ok<DetailedWorkspace>
          } else {
            if (localStorageStoredValue) {
              localStorage.removeItem('teamId')
              window.location.reload()
            }
            throw Error('Invalid team response')
          }
        })
  )

  const workspace =
    workspaceRequest?.type === 'ok'
      ? workspaceRequest.data
      : workspaceRequest?.type === 'loading' || selectedWorkspaceId === 'loading'
        ? 'loading'
        : 'none'

  return {
    workspace,
    setSelectedWorkspaceId,
    allWorkspaces: allUserWorkspaces,
    refreshWorkspace: workspaceRequest?.refresh ?? userWorkspaceRequest.refresh,
    refreshWorkspaceOptimistic: workspaceRequest?.refreshOptimistic,
    refreshTeams: userWorkspaceRequest.refresh,
  }
}

export default createContainer(useWorkspaceSelectorStore)
