import type React from 'react'
import { useState } from 'react'
import { Button } from 'antd'
import type { Language } from '@teamspective/common'
import { darkBlue } from '@teamspective/common'
import { translations } from '@teamspective/common'

const ExpanderInfo = ({
  content,
  title,
  lang,
}: {
  content?: React.ReactNode
  title: React.ReactNode
  lang: Language
}): JSX.Element => {
  const [expand, setExpand] = useState<boolean>(false)

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {title}
        {content ? (
          <Button
            size="small"
            onClick={() => setExpand(!expand)}
            type="link"
            style={{ fontSize: 12, color: darkBlue, marginLeft: 8, marginTop: 2 }}
          >
            {`${expand ? translations[lang].hide : translations[lang].expand}...`}
          </Button>
        ) : null}
      </div>
      {content && expand ? content : null}
    </div>
  )
}

export default ExpanderInfo
