import { useEffect, useState } from 'react'

const useTypewriter = (text: string, speedMs = 20): string => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    setCurrentIndex(0)

    const interval = setInterval(() => {
      setCurrentIndex((idx) => (idx < text.length ? idx + 1 : idx))
    }, speedMs)

    return () => window.clearInterval(interval)
  }, [text, speedMs])

  return text.slice(0, currentIndex)
}

export default useTypewriter
