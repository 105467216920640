import type { AnyRequest, DateString, SharedRequest } from '@teamspective/common'

export const leastDateString = (arr: DateString[]): DateString | null => {
  const first = arr[0]
  if (first === undefined) {
    return null
  }

  return arr.reduce(
    (nextDate, currentMin) => (nextDate < currentMin ? nextDate : currentMin),
    first
  )
}

export const greatestDateString = (arr: DateString[]): DateString | null => {
  const first = arr[0]
  if (first === undefined) {
    return null
  }

  return arr.reduce(
    (nextDate, currentMax) => (nextDate > currentMax ? nextDate : currentMax),
    first
  )
}

export const requestTimestamp = (request: AnyRequest | SharedRequest): DateString => {
  switch (request.type) {
    case 'requested':
      return request.requestCreated
    case 'draft':
      return greatestDateString(request.feedback.map((f) => f.updatedAt)) || request.requestCreated
    case 'shared':
      return (
        greatestDateString([...request.feedback.map((f) => f.updatedAt)]) || request.requestCreated
      )
    case 'provided':
      return (
        greatestDateString([
          ...request.feedback.map((f) => f.updatedAt),
          ...(request.lastCommentAt ? [request.lastCommentAt] : []),
        ]) || request.requestCreated
      )
  }
}

export const isSelfReflection = (request: AnyRequest | SharedRequest): boolean =>
  request.provider !== 'anonymous' && request.requester.userId === request.provider.userId
