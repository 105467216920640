import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'

import type { PlainTopic, ToBeCreatedTopic, TopicId } from '@teamspective/common'

import { captureEvent } from '@sentry/react'
import { api } from '../api'
import authStore from './authStore'

const getTopics = async (): Promise<PlainTopic[]> => {
  const result = await api.getTopics({})

  if (result.status === 'forbidden') {
    throw new Error('Authentication error')
  }

  return result.data
}

const useTopicStore = () => {
  const { user } = authStore.useContainer()
  const lang = user.status === 'complete' ? user.language : 'en'
  const [topics, setTopics] = useState<'loading' | Array<PlainTopic>>('loading')
  const refresh = () => void getTopics().then(setTopics)

  useEffect(() => refresh(), [lang])

  return {
    topics,
    refresh,

    getTopic: (topicId: TopicId): PlainTopic | ToBeCreatedTopic | 'loading' => {
      if (topics === 'loading') {
        return 'loading'
      }
      const topic = topics.find((t) => 'topicId' in t && t.topicId === topicId)
      if (!topic) {
        captureEvent({ message: 'Topic not found', extra: { topicId, topics } })
        return 'loading'
      }
      return topic
    },
    openTopicId:
      topics === 'loading'
        ? ('loading' as const)
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          topics.find((t) => t.type === 'plain' && t.openTopic)!.topicId,
    userHasFavouriteTopics:
      topics === 'loading' ? 'loading' : topics.some((t) => t.type === 'plain' && t.favourite),
  }
}

export default createContainer(useTopicStore)
