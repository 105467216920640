import { emailRegex, links, matchSingleCaptureGroup } from '@teamspective/common'
import GoogleSignIn from '../../components/integrations/GoogleSignIn'
import SignInWithSlack from '../../components/integrations/SignInWithSlack'
import EmailForm from '../../components/user/EmailForm'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Alert, Button, Divider } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { api } from '../../api'
import BaseHeader from '../../components/BaseHeader'
import { animated, useSpring } from 'react-spring'
import { getIllustrationUrl } from '../../illustration'
import {
  Checkmark,
  DesktopBackgroundBlock1,
  DesktopBackgroundBlock2,
  DesktopImageWrapper,
  Heading,
  MainColumn,
  MobileBackgroundBlock1,
  MobileBackgroundBlock2,
  MobileImageWrapper,
} from './loginSignupSharedComponents'
import { useResizeObserver } from 'usehooks-ts'
import { blueBackground } from '@teamspective/common'
import { Trans } from '@lingui/react/macro'

const lang = 'en'

type PageState =
  | {
      state: 'init'
      email?: string
      encounteredFreeDomainSource: 'google' | 'slack' | 'email' | null
    }
  | { state: 'sent-link'; email: string }

const SignupPage = () => {
  const location = useLocation()
  const [pageState, setPageState] = useState<PageState>({
    state: 'init',
    encounteredFreeDomainSource: location.search.includes('google-domain-blocked')
      ? 'google'
      : location.search.includes('slack-domain-blocked')
        ? 'slack'
        : null,
  })

  const imageAnimation = useSpring({
    from: { x: -100 },
    to: { x: 0 },
  })

  const loginRef = useRef(null)
  const { width } = useResizeObserver({ ref: loginRef })

  const register = useCallback(async (email: string) => {
    localStorage.removeItem('teamId')
    const res = await api.registerUser({ email })
    switch (res.status) {
      case 'conflict':
        setPageState({
          state: 'init',
          email,
          encounteredFreeDomainSource: 'email',
        })
        break

      case 'ok':
        setPageState({
          state: 'sent-link',
          email,
        })
        break
    }
  }, [])

  const navigate = useNavigate()

  useEffect(() => {
    if (!location || pageState.state === 'sent-link') {
      return
    }
    const matchedUndecodedEmail = matchSingleCaptureGroup(location.search, /\?email=(.*)/)
    if (!matchedUndecodedEmail) {
      return
    }
    const matchedEmail = decodeURIComponent(matchedUndecodedEmail)
    if (!matchedEmail) {
      return
    }
    if (emailRegex.exec(matchedEmail)) {
      void register(matchedEmail).then(() => {
        void navigate(links.register, { replace: true })
      })
    }
  }, [pageState.state, navigate, register, location])

  return (
    <div id="page-register">
      <BaseHeader />
      <MobileImageWrapper>
        <MobileBackgroundBlock1 />
        <MobileBackgroundBlock2 />
        <animated.img
          src={getIllustrationUrl('sign-up-mobile')}
          style={{
            height: 240,
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            top: -20,
            left: 0,
            right: 0,
            textAlign: 'center',
            ...imageAnimation,
          }}
        />
      </MobileImageWrapper>
      <div style={{ display: 'flex' }}>
        <DesktopImageWrapper>
          <DesktopBackgroundBlock2>
            <DesktopBackgroundBlock1>
              <animated.img
                src={getIllustrationUrl('sign-up')}
                style={{
                  position: 'absolute',
                  right: -75,
                  bottom: -20,
                  zIndex: 1000,
                  ...imageAnimation,
                }}
              />
            </DesktopBackgroundBlock1>
          </DesktopBackgroundBlock2>
        </DesktopImageWrapper>
        <div
          style={{
            flex: '1 0 53%',
          }}
        >
          <MainColumn $side="right">
            {pageState.state === 'sent-link' ? (
              <>
                <Heading>
                  <Trans>Sign up to Teamspective with your company account!</Trans>
                </Heading>
                <div style={{ textAlign: 'center' }}>
                  <Checkmark />
                  <h2>
                    <Trans>Check your email</Trans>
                  </h2>
                  <b>{pageState.email}</b>
                  <Divider />
                  <p>
                    <Trans>
                      We sent you an email at {pageState.email}. It has a link that will log you in.
                    </Trans>
                  </p>
                </div>
              </>
            ) : (
              <>
                <Heading>
                  <Trans>Sign up to Teamspective with your company account!</Trans>
                </Heading>
                <Alert
                  style={{ border: 0, background: blueBackground }}
                  message="Sign up now to get FREE trial"
                />
                <SignInWithSlack
                  lang={lang}
                  type="signUp"
                  redirectPath={location.pathname}
                  showFreeDomainError={pageState.encounteredFreeDomainSource === 'slack'}
                />
                {width && width > 0 && (
                  <GoogleSignIn
                    lang={lang}
                    type="signUp"
                    width={width}
                    showFreeDomainError={pageState.encounteredFreeDomainSource === 'google'}
                  />
                )}
                <EmailForm
                  showRememberMe={true}
                  onSubmit={(params) => register(params.email)}
                  cta="signUp"
                  freeDomainEmail={
                    pageState.encounteredFreeDomainSource === 'email' ? pageState.email : undefined
                  }
                />
                <div>
                  <h4>{'Already have an account?'}</h4>
                  <Link to={links.login} id="link-login">
                    <Button type="link">
                      <Trans>Log in</Trans>
                    </Button>
                  </Link>
                </div>
                <div ref={loginRef} />
              </>
            )}
          </MainColumn>
        </div>
      </div>
    </div>
  )
}

export default SignupPage
