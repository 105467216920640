import type React from 'react'
import type { FeedbackId, FeedbackVisibility, ToBeInvitedUser } from '@teamspective/common'
import type { Language, User } from '@teamspective/common'
import { textColor } from '@teamspective/common'
import Info from '../Info'
import { translations } from '@teamspective/common'
import { LimitedVisibilityIcon, PublicVisibilityIcon, PrivateVisibilityIcon } from '../icons'
import { getFeedbackVisibilityDescription } from './getFeedbackVisibilityDescription'

export const FeedbackVisibilityIcon = ({ visibility }: { visibility: FeedbackVisibility }) => {
  switch (visibility) {
    case 'private':
      return <PrivateVisibilityIcon />
    case 'limited':
      return <LimitedVisibilityIcon />
    case 'public':
      return <PublicVisibilityIcon />
  }
}

export const FeedbackVisibilityInfo: React.FC<{
  visibility: FeedbackVisibility
  recipient: User
  provider: User | ToBeInvitedUser | 'anonymous'
  feedbackShares: { user: User; feedbackId: FeedbackId }[]
  lang: Language
  compact?: boolean
}> = ({ visibility, lang, compact, feedbackShares, recipient, provider }) => {
  const textsItems = getFeedbackVisibilityDescription({
    visibility,
    lang,
    feedbackShares,
    recipient,
    provider,
  })

  return (
    <Info
      icon={<FeedbackVisibilityIcon visibility={visibility} />}
      title={translations[lang][visibility]}
      content={
        <ul>
          {textsItems.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      }
      compacted={compact}
      style={{ color: textColor }}
    />
  )
}
