import type {
  BillingCompanyInfo,
  CohortOptions,
  CouponState,
  PulseCommentReactionType,
  SubscriptionAddons,
  TaxId,
  Language,
  EvaluationResultTimeRangeWithSummaryText,
  CohortKey,
} from './domain/index.ts'
import type {
  IntegrationTool,
  CsvImportResult,
  PulseResults,
  AnyRequest,
  ClientPayloadUser,
  ClientRequestCommentPayload,
  CreateRequestPayload,
  UpdatePulseAnswerCommentPayload,
  DetailedWorkspace,
  Feedback,
  FeedbackCategory,
  LoggedOut,
  PlainTopic,
  PulseAnswerPayload,
  RequestComment,
  Version,
  SubscriptionTerms,
  Team,
  TeamDescriptor,
  PulseStatistics,
  ReschedulePulsePayload,
  User,
  FeatureId,
  UserNotificationPreference,
  UserNotificationPreferences,
  TeamSlackChannel,
  SlackChannel,
  PrivacyPolicyConsent,
  FeedbackStats,
  ProvidedRequest,
  PersonalDetails,
  DemoTeamId,
  DemoDetailedWorkspace,
  SlackWorkspaceUser,
  PulseComment,
  Question,
  DateString,
  PulseAnswerConflictReason,
  NonApiEndpointEvent,
  WorkspaceSummary,
  ManagementFeedback,
  ManagementFeedbackWithComments,
  ManagementFeedbackCommentUserRole,
  FeedbackSharePayload,
  SharedRequest,
  TeamFeatures,
  UserTimestampEvent,
  ToBeCreatedTopic,
  RequestTopic,
  QuestionContent,
  WorkspaceActivationSettings,
  UpdatePulseSettingsPayload,
  PulseResultsByQuestionWithSubteamsTimescale,
  PulseResultsByKpiWithSubteamsTimescale,
  PulseResultsByLatestWithSubteams,
  EngagementBenchmarks,
  TeamUserKpiGroups,
  NetworkLayerName,
  NetworkQuestionnaire,
  FeedbackLink,
  UserAvailableFeatures,
  NetworkSurveyRound,
  EditableNotificationPreferenceId,
  WorkspaceOnboarding,
  WorkspaceOnboardingStep,
  CreatePulseOptions,
  GrantedSubscriptionDetails,
  MsTeamsChannel,
  MsTeamsConnectStatus,
  NetworkMetrics,
  QuestionScale,
  MultiWorkspaceLatestPulseResults,
  UserNetworkMetrics,
  RequestLoginTokenPayload,
  EvaluationTheme,
  TranslationString,
  TeamRole,
  EvaluationSummary,
  SelectableFeedbackVisibility,
  PendingEvaluationDescriptor,
  SlackChannelId,
  SlackUserId,
  MsTeamsUser,
  MsTeamsTeamId,
  MsTeamsTeam,
  CoreFeatureId,
  ManagementFeedbackChannel,
  NewManagementFeedbackChannel,
  ManagementFeedbackChannelUpdatePayload,
  PulseSingleRound,
  PulseCustomGuidance,
  NotificationMedium,
  SurveyCustomGuidance,
  PulseCustomGuidanceWithUserStats,
  NonEmptyArray,
  CustomerActivity,
  CreateDiscussionCommentPayload,
  CreateDiscussionPayload,
  UpdateDiscussionPayload,
  DeleteDiscussionParams,
  EditDiscussionCommentPayload,
  EditDiscussionNotePayload,
  DeleteDiscussionNoteParams,
  CreateDiscussionNotePayload,
  DeleteDiscussionCommentParams,
  GetDiscussionParams,
  GetMyDiscussionsParams,
  DiscussionDescriptor,
  Discussion,
  DiscussionCommentId,
  DiscussionId,
  DeleteDiscussionTemplateParams,
  EditDiscussionTemplatePayload,
  CreateDiscussionTemplatePayload,
  GetDiscussionTemplateParams,
  DiscussionTemplate,
  EvaluationTemplate,
  EvaluationType,
  UserCohorts,
  DiscussionInsight,
  ProvidedEvaluationView,
  QuestionScaleStyle,
  UserWorkspace,
  TeamId,
  UserId,
  WorkspaceFeedbackSettings,
  SingleRequestedEvaluationPayload,
  PulseTheme,
  PulseKpi,
  PulseRoundResult,
  PulseResultsByThemeWithSubteamsTimescale,
  ApiKeyClientId,
  PendingEvaluation,
  Evaluation,
  EvaluatorNameVisibility,
  SamlConfig,
  PulseThemeWithVisibility,
  PulseKpiWithVisibility,
  DiscussionNoteId,
  UpdatePulseSingleRoundParams,
  CreatePulseSingleRoundParams,
  InsertedEvaluationDescriptor,
  AvailableEvaluees,
  EvaluationRequest,
  RequestId,
  RequestCommentId,
  QuestionId,
  PulseRoundId,
  PulseCustomGuidanceId,
  TopicId,
  FeedbackId,
  EvaluationId,
  PulseId,
  ManagementFeedbackId,
  PulseKpiId,
  PulseThemeId,
  NetworkSurveyRoundId,
  EvaluationTemplateId,
  QuestionScaleStyleId,
  ManagementFeedbackChannelId,
  PulseAnswerCommentId,
  PulseAnswerCommentReplyId,
  EvaluationThemeId,
  Pulse,
  DemoPulse,
  WorkspaceSuperAdminAccess,
  PulseSingleRoundId,
  PassiveDataSource,
  NewPassiveSource,
  NetworkSnapshotType,
  UserConnection,
  SurveyContext,
  PassiveSourceConfiguration,
  SerializedNetworkSnapshot,
  UserLeadershipStyle,
  DateRangeString,
  ClientWorkspaceLeadershipStyles,
  PulseRoundResponseRates,
  HeatmapDataRow,
  HeatmapRange,
  SelectedMeasurements,
  CurrentUser,
} from './types/index.ts'
import type { IntegrationUserData } from './types/integration.ts'
import type { Survey } from './types/survey.ts'

export type Ok<T = null> = {
  status: 'ok'
  data: T
}

export function ok<T>(data: T): Ok<T> {
  return { status: 'ok', data }
}

export type PaymentRequired = {
  status: 'payment-required'
}

export function paymentRequired(): PaymentRequired {
  return { status: 'payment-required' }
}

export type NotFound = {
  status: 'not-found'
}

export function notFound(): NotFound {
  return { status: 'not-found' }
}

export type Forbidden = {
  status: 'forbidden'
  reason?: string
}

export function forbidden(extra?: Record<string, unknown>): Forbidden {
  return { ...extra, status: 'forbidden' }
}

export type BadRequest = {
  status: 'bad-request'
}

export function badRequest(extra?: Record<string, unknown>): BadRequest {
  return { ...extra, status: 'bad-request' }
}

export type Conflict<T extends string> = {
  status: 'conflict'
  reason: T
}

export function conflict<T extends string>(reason: T): Conflict<T> {
  return { status: 'conflict', reason }
}

export type Redirect = {
  status: 'redirect'
  url: string
}

export function redirect(url: string): Redirect {
  return { status: 'redirect', url }
}

export type UnprocessableEntity<T extends string = string> = {
  status: 'unprocessable-entity'
  reason: T
}

export function unprocessableEntity<T extends string>(reason: T): UnprocessableEntity<T> {
  return { status: 'unprocessable-entity', reason }
}

export type ApiResponse<T = null> =
  | Ok<T>
  | BadRequest
  | NotFound
  | Forbidden
  | PaymentRequired
  | UnprocessableEntity<string>
  | Conflict<string>

type SupportedHttpMethod = 'get' | 'post' | 'put' | 'delete'

export type ApiDefinition = {
  version: () => Promise<Ok<Version>>
  registerUser: (p: { email: string }) => Promise<Ok | Conflict<'free-domain'>>
  tokenLogin: (p: { token: string }) => Promise<Ok<User> | Forbidden>
  codeLogin: (p: {
    loginCode: string
    workspaceLoginCode: string
  }) => Promise<Ok<{ status: 'invalid-token' } | { status: 'valid-token'; userId: UserId }>>
  requestLoginToken: (p: RequestLoginTokenPayload) => Promise<Ok | Redirect | NotFound>
  logout: () => Promise<Ok | Forbidden>
  refreshSession: (p: { rememberMe?: boolean }) => Promise<Ok | Forbidden>
  freeDomainCheck: (p: { domain: string }) => Promise<Ok<{ freeDomain: boolean }> | Forbidden>

  getUser: () => Promise<Ok<CurrentUser | LoggedOut> | Forbidden>
  updateUser: (p: PersonalDetails) => Promise<Ok<User> | Forbidden>

  getMyContacts: (p: { excludeTeamId?: TeamId }) => Promise<Ok<User[]> | Forbidden>

  getUserWorkspaces: () => Promise<Ok<UserWorkspace[]> | Forbidden>
  joinWorkspace: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>
  getJoinableWorkspaces: () => Promise<Ok<Team[]> | Forbidden>

  getPrivacyPolicyConsent: () => Promise<Ok<PrivacyPolicyConsent> | Forbidden>
  setPrivacyPolicyConsent: () => Promise<Ok | Forbidden>

  createTeam: (p: {
    name: string
    teamId: TeamId
    parentTeamId: TeamId
    joinTeam: boolean
  }) => Promise<Ok<Team> | Forbidden>
  removeTeam: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>
  createWorkspace: (p: {
    name: string
    enabledProducts: CoreFeatureId[]
    additionalInfo?: Record<string, string>
  }) => Promise<Ok<Team> | Forbidden>
  moveTeam: (p: {
    teamId: TeamId
    targetTeamId: TeamId
    parentTeamId: TeamId
  }) => Promise<Ok | Forbidden>

  getTeam: (p: {
    teamId: TeamId | DemoTeamId
  }) => Promise<Ok<DetailedWorkspace | DemoDetailedWorkspace> | Forbidden>
  updateTeam: (p: TeamDescriptor) => Promise<Ok<Team> | Forbidden>
  setTeamFeature: (p: {
    teamId: TeamId
    featureId: FeatureId
    enabled: boolean
  }) => Promise<Ok | Forbidden>
  updateTeamDomains: (p: { teamId: TeamId; domains: string[] }) => Promise<Ok | Forbidden>

  addUsersToWorkspace: (p: {
    teamId: TeamId
    users: ClientPayloadUser[]
    notify: boolean
  }) => Promise<Ok<{ userId: UserId; email: string | null }[]> | Forbidden>
  addUsersToTeam: (p: { userIds: UserId[]; teamId: TeamId }) => Promise<Ok | Forbidden>

  setUserTeamRole: (p: {
    userIdToSet: UserId
    userTeamRole: TeamRole
    teamId: TeamId
  }) => Promise<Ok | Forbidden>
  removeUserFromWorkspace: (p: {
    userIdToRemove: UserId
    teamId: TeamId
  }) => Promise<Ok | Forbidden>
  removeUserFromGroup: (p: { userIdToRemove: UserId; teamId: TeamId }) => Promise<Ok | Forbidden>

  getTopics: (p: { lang?: Language }) => Promise<Ok<PlainTopic[]> | Forbidden>
  getRequestTopics: (p: { requestId: RequestId }) => Promise<Ok<RequestTopic[]> | Forbidden>
  removeTopic: (p: { topicId: TopicId }) => Promise<Ok | Forbidden>
  setFavouriteTopic: (p: { topicId: TopicId; favourite: boolean }) => Promise<Ok>
  setFeedbackLinkTopic: (p: { topicId: TopicId; favourite: boolean }) => Promise<Ok>
  getUserFavouriteTopicIds: (p: {
    targetUserId: UserId
  }) => Promise<Ok<{ topicIds: TopicId[] }> | Forbidden>
  removeRequestTopic: (p: { requestId: RequestId; topicId: TopicId }) => Promise<Ok | Forbidden>
  archiveRequest: (p: { requestId: RequestId }) => Promise<Ok | Forbidden>
  setRequestPrivacy: (p: { requestId: RequestId; isPrivate: boolean }) => Promise<Ok | Forbidden>

  addUserTopic: (p: { topic: ToBeCreatedTopic; lang?: Language }) => Promise<Ok | Forbidden>
  addWorkspaceTopic: (p: { topic: ToBeCreatedTopic; teamId: TeamId }) => Promise<Ok | Forbidden>

  getRequest: (p: { requestId: RequestId }) => Promise<Ok<AnyRequest> | Forbidden>
  getRequests: () => Promise<Ok<AnyRequest[]> | Forbidden>
  viewFeedbackWithAuthToken: (p: {
    token: string
  }) => Promise<Ok<{ request: ProvidedRequest; topics: RequestTopic[] }> | Forbidden>
  createViewFeedbackAuthToken: (p: {
    requestId: RequestId
  }) => Promise<Ok<{ token: string }> | Forbidden>

  requestFeedback: (p: { requests: CreateRequestPayload[] }) => Promise<Ok | Forbidden>
  markRequestAsSeen: (p: { requestId: RequestId }) => Promise<Ok | Forbidden>
  addRequestProviderMessage: (p: {
    requestId: RequestId
    providerMessage: string
  }) => Promise<Ok | Forbidden>

  getTeamFeedbackStats: (p: {
    teamId: TeamId | DemoTeamId
    dayCount: number
  }) => Promise<Ok<FeedbackStats> | Forbidden>

  getManagedUserFeedbackStats: (p: {
    teamId: TeamId | DemoTeamId
    dayCount: number
  }) => Promise<Ok<FeedbackStats> | Forbidden>

  createDraftFeedback: (p: {
    requestId: RequestId
    topicId: TopicId
    category: FeedbackCategory
  }) => Promise<Ok<Feedback> | Forbidden>
  updateDraftFeedback: (p: { feedback: Feedback }) => Promise<Ok | Forbidden>
  deleteDraftFeedback: (p: { feedbackId: FeedbackId }) => Promise<Ok | Forbidden>

  addRequestTopic: (p: { requestId: RequestId; topicId: TopicId }) => Promise<Ok | Forbidden>

  publishRequestFeedback: (p: { requestId: RequestId }) => Promise<Ok | Forbidden>
  markFeedbackAsSeen: (p: { requestId: RequestId }) => Promise<Ok | Forbidden>
  createUnsolicitedFeedbackRequest: (p: {
    recipientUserId: UserId
    visibility: SelectableFeedbackVisibility
    feedbackLinkSlug?: string
  }) => Promise<Ok<RequestId> | Forbidden>
  createSelfReflection: () => Promise<Ok<RequestId> | Forbidden>

  sendUnsolicitedFeedback: (p: {
    type: FeedbackCategory
    recipientUserIds: UserId[]
    feedbackItems: {
      content: string
      topicId: TopicId
    }[]
    announcePraisePublicly: boolean
  }) => Promise<Ok | Forbidden>

  getWorkspaceEvaluationProgress: (p: { teamId: TeamId }) => Promise<Ok<EvaluationRequest[]>>

  updateEvaluation: (p: {
    value: number | string
    questionId: QuestionId
    evaluationId: EvaluationId
    isDraft?: boolean
  }) => Promise<Ok | NotFound | Forbidden>
  provideEvaluation: (p: { evaluationId: EvaluationId }) => Promise<Ok | NotFound | Forbidden>
  getWorkspaceEvaluationResults: (p: {
    teamId: TeamId
  }) => Promise<Ok<{ user: User; evaluations: ProvidedEvaluationView[] }[]>>
  requestEvaluations: (p: {
    teamId: TeamId
    evaluations: SingleRequestedEvaluationPayload[]
    expiresAt: DateString
  }) => Promise<Ok<InsertedEvaluationDescriptor[]> | Forbidden>
  getRequestedExternalEvaluations: () => Promise<
    Ok<{
      evaluationRequests: Evaluation[]
      evaluationTemplates: EvaluationTemplate[]
      questions: Question[]
      evaluationThemes: EvaluationTheme[]
      evaluatorNameVisibility: EvaluatorNameVisibility
    }>
  >
  getEvaluationTemplates: (p: { teamId: TeamId }) => Promise<Ok<EvaluationTemplate[]>>

  getPendingEvaluationsForMe: (p: { teamId: TeamId }) => Promise<Ok<PendingEvaluationDescriptor[]>>
  getEvaluationSummary: (p: {
    teamId: TeamId
    subjectUserId: UserId
    timeRange: EvaluationResultTimeRangeWithSummaryText
    lang?: Language
  }) => Promise<Ok<EvaluationSummary>>

  getRequestableEvaluees: (p: { teamId: TeamId }) => Promise<Ok<AvailableEvaluees> | Forbidden>
  getEvaluationsByEvaluee: (p: {
    teamId: TeamId
    subjectUserId: UserId
  }) => Promise<Ok<ProvidedEvaluationView[]> | Forbidden>

  publishEvaluationsForEvaluee: (p: {
    teamId: TeamId
    subjectUserId: UserId
  }) => Promise<Ok | Forbidden>
  setWrittenEvaluationAnswerRedaction: (p: {
    teamId: TeamId
    subjectUserId: UserId
    evaluationId: EvaluationId
    questionId: QuestionId
    redacted: boolean
  }) => Promise<Ok | Forbidden>

  deletePendingEvaluations: (p: {
    teamId: TeamId
    subjectUserIds: NonEmptyArray<UserId>
    evaluationIds: NonEmptyArray<EvaluationId>
  }) => Promise<Ok>

  writeRequestComment: (p: ClientRequestCommentPayload) => Promise<Ok | Forbidden>
  getRequestComments: (p: { requestId: RequestId }) => Promise<Ok<RequestComment[]> | Forbidden>
  deleteRequestComment: (p: { requestCommentId: RequestCommentId }) => Promise<Ok | Forbidden>
  markRequestCommentAsSeen: (p: { requestCommentId: RequestCommentId }) => Promise<Ok | Forbidden>

  getQuestions: (p: { teamId: TeamId }) => Promise<Ok<Question[]>>

  createWorkspaceQuestion: ({
    teamId,
    content,
    scale,
    type,
  }: {
    teamId: TeamId
    content: QuestionContent
    scale: QuestionScale
    type: 'pulse' | 'evaluation'
  }) => Promise<Ok<{ questionId: QuestionId }> | Forbidden>
  updateWorkspaceQuestion: (p: {
    questionId: QuestionId
    teamId: TeamId
    content: QuestionContent
  }) => Promise<Ok | Forbidden>
  deleteWorkspaceQuestion: (p: {
    questionId: QuestionId
    teamId: TeamId
  }) => Promise<Ok | Forbidden>

  createPulse: (p: {
    teamId: TeamId
    pulseSettings: Partial<CreatePulseOptions>
    startImmediately: boolean
  }) => Promise<Ok<{ pulseId: PulseId }> | Forbidden | PaymentRequired>
  reschedulePulse: (p: ReschedulePulsePayload) => Promise<Ok | Forbidden>
  updatePulseSettings: (p: UpdatePulseSettingsPayload) => Promise<Ok | Forbidden>
  getPulses: (p: { teamId: TeamId | DemoTeamId }) => Promise<Ok<Pulse[] | DemoPulse[]> | Forbidden>
  stopPulse: (p: { teamId: TeamId; pulseId: PulseId }) => Promise<Ok | Forbidden>
  resumePulse: (p: {
    teamId: TeamId
    pulseId: PulseId
    nextRound: 'immediate' | 'scheduled'
  }) => Promise<Ok | Forbidden | PaymentRequired>

  createPulseSingleRound: (
    p: CreatePulseSingleRoundParams
  ) => Promise<Ok<{ pulseSingleRoundId: PulseSingleRoundId }> | Forbidden | PaymentRequired>
  getPulseSingleRounds: (p: {
    teamId: TeamId | DemoTeamId
  }) => Promise<Ok<PulseSingleRound[]> | Forbidden>
  updatePulseSingleRound: (p: UpdatePulseSingleRoundParams) => Promise<Ok | NotFound | Forbidden>
  publishPulseSingleRound: (p: {
    teamId: TeamId
    pulseSingleRoundId: PulseSingleRoundId
  }) => Promise<Ok | Forbidden>
  deletePulseSingleRound: (
    p: { teamId: TeamId } & Pick<PulseSingleRound, 'pulseSingleRoundId'>
  ) => Promise<Ok | Forbidden>
  getPulseRoundResponseRateEstimate: (p: {
    teamId: TeamId
    pulseRoundId: PulseRoundId
  }) => Promise<Ok<PulseRoundResponseRates>>

  getCustomGuidances: (p: { teamId: TeamId }) => Promise<Ok<PulseCustomGuidanceWithUserStats[]>>
  updateCustomGuidance: (p: Omit<PulseCustomGuidance, 'pulseCustomGuidanceId'>) => Promise<Ok>
  deleteCustomGuidance: (p: Pick<PulseCustomGuidance, 'questionId' | 'teamId'>) => Promise<Ok>
  getUserSurveyGuidance: (p: {
    pulseRoundId: PulseRoundId
    teamId: TeamId
  }) => Promise<Ok<SurveyCustomGuidance[]>>
  updateSurveyCustomGuidanceFeedback: (p: {
    teamId: TeamId
    pulseCustomGuidanceId: PulseCustomGuidanceId
    pulseRoundId: PulseRoundId
    helpful: boolean
  }) => Promise<Ok>

  getUserSurveys: (p: { teamId: TeamId }) => Promise<Ok<Survey[]> | NotFound | Forbidden>
  answerPulseQuestion: (
    p: PulseAnswerPayload
  ) => Promise<Ok | Forbidden | Conflict<PulseAnswerConflictReason>>
  updatePulseAnswerComment: (p: UpdatePulseAnswerCommentPayload) => Promise<Ok | Forbidden>
  getPulseComments: (p: {
    teamId: TeamId | DemoTeamId
    surveyContext: SurveyContext
    questionId?: QuestionId
    pulseCommentIds?: NonEmptyArray<PulseAnswerCommentId>
    lang?: Language
  }) => Promise<Ok<PulseComment[]> | Forbidden | NotFound>
  replyToPulseComment: (p: {
    teamId: TeamId
    pulseCommentId: PulseAnswerCommentId
    content: string
    anonymousReplyAsOp: boolean
  }) => Promise<Ok | Forbidden | NotFound>
  deletePulseCommentReply: (p: {
    pulseCommentReplyId: PulseAnswerCommentReplyId
  }) => Promise<Ok | Forbidden>
  updatePulseCommentReaction: (p: {
    pulseCommentReactionType: PulseCommentReactionType
    pulseCommentId: PulseAnswerCommentId
    pulseCommentReplyId: PulseAnswerCommentReplyId | null
    teamId: TeamId
    operation: 'add' | 'delete'
  }) => Promise<Ok | Forbidden>

  getPulseResultsByCohort: (p: {
    teamId: TeamId | DemoTeamId
    cohortKey: string
    surveyContext: SurveyContext
  }) => Promise<Ok<Record<string, PulseResults>> | NotFound | Forbidden>
  getMultiWorkspaceLatestResults: (p: {
    workspaceIds: NonEmptyArray<TeamId>
  }) => Promise<Ok<MultiWorkspaceLatestPulseResults> | NotFound | Forbidden>
  getMultiWorkspaceAllResults: (p: {
    workspaceIds: NonEmptyArray<TeamId>
  }) => Promise<Ok<PulseResults> | NotFound>
  updateUserTimestamp: (p: { event: UserTimestampEvent }) => Promise<Ok | Forbidden>
  getCohortsByWorkspace: (p: {
    teamId: TeamId | DemoTeamId
  }) => Promise<Ok<CohortOptions[]> | Forbidden>

  generateTestPulseData: (p: {
    teamId: TeamId
    pulseId: PulseId
    weeks: number
  }) => Promise<Ok | Forbidden>
  generateTestEvaluationData: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>
  deleteEvaluationData: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>
  deletePulseData: (p: {
    teamId: TeamId
    pulseId: PulseId
  }) => Promise<Ok | Forbidden | Conflict<'cannot-clear-active-pulse'>>
  generateUserNetworkData: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>
  generateUsersAndGroups: (p: { teamId: TeamId; userCount: number }) => Promise<Ok | Forbidden>
  generateCurrentSurveyAnswers: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>

  getUserNotificationSettings: () => Promise<
    | Ok<{
        preferences: UserNotificationPreferences
        slackEnabled: boolean
        msTeamsEnabled: boolean
      }>
    | Forbidden
  >
  setUserNotificationPreference: (p: {
    notificationPreferenceId: EditableNotificationPreferenceId
    userNotificationPreference: UserNotificationPreference
  }) => Promise<Ok | Forbidden>

  getSharedTeamPraiseChannel: (p: {
    otherUserId: UserId
  }) => Promise<
    Ok<{ slack: TeamSlackChannel | 'none'; msTeams: MsTeamsChannel | 'none' }> | Forbidden
  >

  getSlackWorkspaceUsers: (p: {
    teamId: TeamId
  }) => Promise<Ok<SlackWorkspaceUser[]> | NotFound | Forbidden>
  getSlackWorkspaceChannels: (p: {
    teamId: TeamId
    excludeChannelsWithPostingRestrictions?: boolean
  }) => Promise<Ok<(SlackChannel & { memberCount: number })[]> | NotFound | Forbidden>
  getSlackWorkspaceChannelMembers: (p: {
    teamId: TeamId
    channelId: SlackChannelId
  }) => Promise<Ok<SlackUserId[]> | NotFound | Forbidden>

  updateSlackAllowJoinWorkspace: (p: { teamId: TeamId; allowJoin: boolean }) => Promise<Ok<null>>

  setTeamSlackPraiseChannel: (p: {
    teamId: TeamId
    slackChannelId: string
    slackChannelName: string
  }) => Promise<Ok | Forbidden>
  clearTeamSlackPraiseChannel: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>

  setUserTeamPulseParticipation: (p: {
    teamId: TeamId
    pulseParticipant: boolean
    participatorUserId: UserId
  }) => Promise<Ok | Forbidden>

  getPulseStatistics: (p: {
    teamId: TeamId | DemoTeamId
    dayCount: number
  }) => Promise<Ok<PulseStatistics> | Forbidden | NotFound>

  getNetworkResults: (p: { teamId: TeamId }) => Promise<Ok<NetworkMetrics | null> | Forbidden>

  getNetworkSnapshot: (p: {
    type: NetworkSnapshotType
    teamId: TeamId
    sources?: PassiveDataSource[]
    range: DateRangeString | 'cached-past-four-months'
  }) => Promise<Ok<SerializedNetworkSnapshot> | Forbidden | NotFound | BadRequest>

  getUserLeadershipStyle: (p: {
    teamId: TeamId
    subjectUserId: UserId
    type?: NetworkSnapshotType
  }) => Promise<Ok<UserLeadershipStyle> | Forbidden | NotFound>
  getLeadershipStyles: (p: {
    teamId: TeamId
    type?: NetworkSnapshotType
  }) => Promise<Ok<ClientWorkspaceLeadershipStyles> | NotFound>

  getPersonalNetworkMetrics: (p: {
    teamId: TeamId
  }) => Promise<Ok<UserNetworkMetrics> | Forbidden | NotFound>

  getPersonalNetworkAnswers: (p: {
    teamId: TeamId
  }) => Promise<Ok<Record<UserId, NetworkLayerName[]>> | Forbidden | NotFound>

  createSubscription: (p: {
    teamId: TeamId
    terms: SubscriptionTerms
    billingCompanyInfo: BillingCompanyInfo
    paymentMethod:
      | {
          type: 'card'
          stripePaymentMethodId: string
        }
      | {
          type: 'invoice'
        }
    retryInvoiceId: string | null
    chargeVat: boolean
    taxId: TaxId | null
    couponId: string | null
    addons: SubscriptionAddons
  }) => Promise<
    | Ok<
        | { type: 'done' }
        | {
            type: 'intent'
            status:
              | 'canceled'
              | 'processing'
              | 'requires_action'
              | 'requires_capture'
              | 'requires_confirmation'
              | 'requires_payment_method'
              | 'succeeded'
            clientSecret: string
            stripeInvoiceId: string
          }
      >
    | Forbidden
  >

  checkPromotionCode: (p: { code: string; teamId: TeamId }) => Promise<Ok<CouponState> | Forbidden>

  stripePortalSession: (p: { teamId: TeamId }) => Promise<Ok<{ url: string }> | Forbidden>

  logEvent: (p: { event: NonApiEndpointEvent }) => Promise<Ok>

  dryRunPulse: (p: {
    pulseId: PulseId
    begin?: DateString
  }) => Promise<Ok<{ questionIds: QuestionId[]; begin: DateString; end: DateString }> | Forbidden>

  importWorkspaceUsersCsv: (p: {
    teamId: TeamId
    csv: string
    dryRun: boolean
    syncUsers: boolean
    syncTeams: boolean
    syncHierarchy: boolean
  }) => Promise<Ok<CsvImportResult>>
  exportWorkspaceUsersCsv: (p: { teamId: TeamId }) => Promise<Ok<string>>

  syncIntegrationData: (p: { teamId: TeamId; dryRun?: boolean }) => Promise<Ok | Forbidden>

  debugIntegrationData: (p: { teamId: TeamId }) => Promise<Ok<IntegrationUserData[]> | Forbidden>

  getSuperAdminWorkspaces: () => Promise<Ok<WorkspaceSummary[]> | Forbidden>
  getWorkspaceSlackMemberships: (p: {
    teamId: TeamId
  }) => Promise<
    Ok<{ slackAccount: SlackWorkspaceUser; user: User | null }[]> | Forbidden | NotFound
  >
  getWorkspaceMsTeamsMemberships: (p: {
    teamId: TeamId
  }) => Promise<Ok<{ msTeamsUser: MsTeamsUser; user: User | null }[]> | Forbidden | NotFound>

  getWorkspaceSuperAdminAccess: () => Promise<Ok<WorkspaceSuperAdminAccess[]> | Forbidden>
  grantWorkspaceSuperAdminAccess: (p: {
    accessingUserId: UserId
    accessingTeamId: TeamId
    minutes: number
  }) => Promise<Ok | Forbidden>
  revokeWorkspaceSuperAdminAccess: (p: {
    accessingUserId: UserId
    accessingTeamId: TeamId
  }) => Promise<Ok | Forbidden>

  getPulsePlaybook: (p: { theme: string }) => void
  archiveWorkspace: (p: { teamId: TeamId }) => Promise<Ok | Forbidden | BadRequest>
  unarchiveWorkspace: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>

  sendManagementFeedback: (
    p: Pick<
      ManagementFeedback,
      'content' | 'teamId' | 'isAnonymous' | 'managementFeedbackChannelId'
    >
  ) => Promise<Ok>
  getManagementFeedbackById: (p: {
    managementFeedbackId: ManagementFeedbackId
  }) => Promise<Ok<ManagementFeedbackWithComments> | NotFound>
  getManagementFeedback: (p: { teamId: TeamId }) => Promise<Ok<ManagementFeedback[]>>
  markManagementFeedbackAsSeen: (p: {
    managementFeedbackId: ManagementFeedbackId
  }) => Promise<Ok | Forbidden>
  sendManagementFeedbackComment: (p: {
    managementFeedbackId: ManagementFeedbackId
    content: string
    userRole: ManagementFeedbackCommentUserRole
  }) => Promise<Ok | Forbidden>
  updateManagementFeedbackRecipients: (p: {
    teamId: TeamId
    managementFeedbackChannelId: ManagementFeedbackChannelId
    recipientUserIds: UserId[]
  }) => Promise<Ok | Forbidden>
  getManagementFeedbackChannels: (p: { teamId: TeamId }) => Promise<Ok<ManagementFeedbackChannel[]>>
  createManagementFeedbackChannel: (p: NewManagementFeedbackChannel) => Promise<Ok>
  updateManagementFeedbackChannel: (p: ManagementFeedbackChannelUpdatePayload) => Promise<Ok>
  activateManagementFeedback: (p: { teamId: TeamId }) => Promise<Ok>

  shareFeedback: (p: Omit<FeedbackSharePayload, 'userId'>) => Promise<Ok | Forbidden>
  getSharedFeedback: () => Promise<Ok<SharedRequest[]>>

  getFeedbackForSubject: (p: {
    teamId: TeamId
    subjectUserId: UserId
  }) => Promise<Ok<SharedRequest[]> | Forbidden>

  getDefaultFeatures: () => Promise<Ok<TeamFeatures> | Forbidden>

  getWorkspaceActivationSettings: (p: {
    teamId: TeamId
  }) => Promise<Ok<WorkspaceActivationSettings> | Forbidden>

  setWorkspaceActivationSettings: (
    p: { teamId: TeamId } & WorkspaceActivationSettings
  ) => Promise<Ok | Forbidden>
  setWorkspaceFeedbackSettings: (
    p: Partial<WorkspaceFeedbackSettings> & { teamId: TeamId }
  ) => Promise<Ok | Forbidden>

  getAllTeamAllResultsByQuestion: (p: {
    teamId: TeamId | DemoTeamId
    questionId: QuestionId
    surveyContext: SurveyContext
  }) => Promise<Ok<PulseResultsByQuestionWithSubteamsTimescale> | NotFound>
  getAllTeamAllResultsByKpi: (p: {
    teamId: TeamId | DemoTeamId
    pulseKpiId: PulseKpiId
    surveyContext: SurveyContext
  }) => Promise<Ok<PulseResultsByKpiWithSubteamsTimescale> | NotFound>
  getAllTeamAllResultsByTheme: (p: {
    teamId: TeamId | DemoTeamId
    pulseThemeId: PulseThemeId
    surveyContext: SurveyContext
  }) => Promise<Ok<PulseResultsByThemeWithSubteamsTimescale> | NotFound>
  getMultiWorkspaceResultsByKpi: (p: {
    workspaceIds: NonEmptyArray<TeamId>
    pulseKpiId: PulseKpiId
  }) => Promise<Ok<PulseResultsByKpiWithSubteamsTimescale> | NotFound>
  getMultiWorkspaceResultsByTheme: (p: {
    workspaceIds: NonEmptyArray<TeamId>
    pulseThemeId: PulseThemeId
  }) => Promise<Ok<PulseResultsByThemeWithSubteamsTimescale> | NotFound>
  getMultiWorkspaceResultsByQuestion: (p: {
    questionId: QuestionId
    workspaceIds: NonEmptyArray<TeamId>
  }) => Promise<Ok<PulseResultsByQuestionWithSubteamsTimescale> | NotFound>
  getMultiworkspacePulseThemesAndKpis: (p: {
    workspaceIds: NonEmptyArray<TeamId>
  }) => Promise<Ok<{ themes: PulseTheme[]; kpis: PulseKpi[] }>>
  getPulseResults: (p: {
    teamId: TeamId | DemoTeamId
    surveyContext: SurveyContext
  }) => Promise<Ok<PulseResults | null> | Forbidden>
  getAllTeamLatestResults: (p: {
    teamId: TeamId | DemoTeamId
    surveyContext: SurveyContext
  }) => Promise<Ok<PulseResultsByLatestWithSubteams> | NotFound>
  getEngagementBenchmarks: (p: { teamId: TeamId | DemoTeamId }) => Promise<Ok<EngagementBenchmarks>>
  getKpiResultsForUser: (p: { teamId: TeamId; surveyContext: SurveyContext }) => Promise<
    | Ok<{
        kpis: { kpi: PulseKpi; results: PulseRoundResult[] }[]
        themes: { theme: PulseTheme; results: PulseRoundResult[] }[]
      }>
    | NotFound
  >
  getTeamUserKpis: (p: {
    teamId: TeamId | DemoTeamId
    surveyContext: SurveyContext
  }) => Promise<Ok<TeamUserKpiGroups> | Forbidden | NotFound>

  answerNetworkQuestionnaire: (p: {
    layers: Partial<Record<NetworkLayerName, UserId[]>>
    teamId: TeamId
    networkSurveyRoundId: NetworkSurveyRoundId | null
  }) => Promise<Ok>
  getUserNetworkQuestionnaire: (p: { teamId: TeamId }) => Promise<Ok<NetworkQuestionnaire | null>>

  sendAnonymousFeedback: (p: {
    feedbackLinkSlug: string
    feedback: Pick<Feedback, 'topicId' | 'content' | 'category'>[]
    providerMessage?: string
  }) => Promise<Ok | Forbidden>
  getFeedbackLink: (p: { feedbackLinkSlug: string }) => Promise<Ok<FeedbackLink> | NotFound>
  getMyFeedbackLink: () => Promise<Ok<FeedbackLink> | NotFound>
  createFeedbackLink: () => Promise<Ok<{ feedbackLinkSlug: string }>>
  updateFeedbackLink: (p: { enabled: boolean }) => Promise<Ok>

  getWorkspaceSlackAndTeamsLinks: (p: {
    teamId: TeamId
  }) => Promise<Ok<{ userId: UserId; slack: boolean; msTeams: boolean }[]>>

  addUserFeedbackAccess: (p: {
    subjectUserIds: UserId[]
    teamId: TeamId
    accessorUserId: UserId
  }) => Promise<Ok | Forbidden>
  removeUserFeedbackAccess: (p: {
    subjectUserIds: UserId[]
    teamId: TeamId
    accessorUserId: UserId
  }) => Promise<Ok | Forbidden>
  getWorkspaceUserFeedbackPermissions: (p: { teamId: TeamId }) => Promise<
    Ok<{
      directUserFeedbackAccessPermissions: Partial<Record<UserId, UserId[]>>
      managerPermissions: Partial<Record<UserId, UserId[]>>
    }>
  >
  activateEvaluationFeature: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>
  getWorkspaceUserPendingEvaluationRequests: (p: {
    teamId: TeamId
  }) => Promise<Ok<PendingEvaluation[]>>
  setEvaluationTemplateQuestions: (p: {
    teamId: TeamId
    questions: { questionId: QuestionId; required: boolean }[]
    evaluationTemplateId: EvaluationTemplateId
  }) => Promise<Ok>
  skipEvaluation: (p: { evaluationId: EvaluationId }) => Promise<Ok>
  getEvaluationThemes: (p: { teamId: TeamId }) => Promise<Ok<EvaluationTheme[]>>
  setEvaluationQuestionThemes: (p: {
    questionId: QuestionId
    evaluationThemeIds: EvaluationThemeId[]
    teamId: TeamId
  }) => Promise<Ok>
  createEvaluationTheme: (p: {
    teamId: TeamId
    name: TranslationString
  }) => Promise<Ok<EvaluationThemeId>>
  createEvaluationTemplate: (p: {
    teamId: TeamId
    name: TranslationString
    evaluationType: EvaluationType
  }) => Promise<Ok<{ evaluationTemplateId: EvaluationTemplateId }>>
  updateEvaluationTemplate: (p: {
    teamId: TeamId
    evaluationTemplateId: EvaluationTemplateId
    evaluationType: EvaluationType
    name: TranslationString
    message?: TranslationString | undefined
  }) => Promise<Ok>
  setEvaluationTemplateArchived: (p: {
    teamId: TeamId
    evaluationTemplateId: EvaluationTemplateId
    archived: boolean
  }) => Promise<Ok>
  reopenEvaluation: (p: {
    subjectUserId: UserId
    evaluationId: EvaluationId
    teamId: TeamId
  }) => Promise<Ok>
  reactivateEvaluations: (p: {
    subjectUserIds: NonEmptyArray<UserId>
    evaluationIds: NonEmptyArray<EvaluationId>
    expiresAt: DateString
    teamId: TeamId
  }) => Promise<Ok>
  sendEvaluationReminders: (p: { subjectUserIds: UserId[]; teamId: TeamId }) => Promise<Ok>

  getUserAvailableFeatures: (p: { teamId: TeamId }) => Promise<Ok<UserAvailableFeatures>>

  getLatestNetworkSurveyRound: (p: { teamId: TeamId }) => Promise<
    Ok<null | {
      type: 'ongoing' | 'published'
      networkSurveyRound: NetworkSurveyRound
      publishedRoundExists: boolean
      participation: { userId: UserId; answered: boolean }[]
    }>
  >
  createNetworkSurveyRound: (p: {
    teamId: TeamId
    begin: DateString
    end: DateString
  }) => Promise<Ok | Forbidden>

  extendNetworkSurveyRound: (p: {
    teamId: TeamId
    networkSurveyRoundId: NetworkSurveyRoundId
    roundEnd: DateString
  }) => Promise<Ok>

  sendNetworkSurveyRoundReminders: (p: {
    teamId: TeamId
    networkSurveyRoundId: NetworkSurveyRoundId
    medium: NotificationMedium
  }) => Promise<Ok>

  getWorkspaceOnboarding: (p: { teamId: TeamId }) => Promise<Ok<WorkspaceOnboarding | null>>
  completeOnboardingStep: (p: {
    teamId: TeamId
    step: WorkspaceOnboardingStep
    skipped: boolean
  }) => Promise<Ok>
  revertOnboardingStep: (p: { teamId: TeamId; step: WorkspaceOnboardingStep }) => Promise<Ok>
  markOnboardingDone: (p: { teamId: TeamId }) => Promise<Ok>

  sendPulseQuestionnaireToSlackChannel: (p: {
    teamId: TeamId
    channel: SlackChannelId
  }) => Promise<Ok | Forbidden>

  getGrantedPlans: () => Promise<
    Ok<{ subscription: GrantedSubscriptionDetails; team: TeamDescriptor }[]>
  >
  addGrantedPlan: (
    p: Omit<GrantedSubscriptionDetails, 'status'> & { teamId: TeamId }
  ) => Promise<Ok>

  addSsoIntegration: (
    p: {
      teamId: TeamId
    } & SamlConfig
  ) => Promise<Ok | Conflict<string>>

  connectMsTeams: (p: {
    tenantId: string
    teamId: TeamId
    msTeamsTeamId?: string
  }) => Promise<Ok<MsTeamsConnectStatus> | Forbidden>
  connectMsTeamsForOna: (p: { tenantId: string; teamId: TeamId }) => Promise<Ok>
  getMsTeamsChannels: (p: { teamId: TeamId }) => Promise<Ok<MsTeamsChannel[]> | Forbidden>
  getMsTeamsTeams: (p: { teamId: TeamId }) => Promise<Ok<MsTeamsTeam[]> | Forbidden>
  getMsTeamsUsers: (p: {
    teamId: TeamId
    msTeamsTeamId?: MsTeamsTeamId
  }) => Promise<Ok<MsTeamsUser[]> | Forbidden>
  setMsTeamsPraiseChannel: (p: MsTeamsChannel & { teamId: TeamId }) => Promise<Ok | Forbidden>
  clearMsTeamsPraiseChannel: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>
  synchronizeMsTeams: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>

  syncWorkspaceSlackAccounts: (p: { teamId: TeamId }) => Promise<Ok>
  addPassiveSource: (p: {
    teamId: TeamId
    passiveSource: NewPassiveSource
  }) => Promise<
    | Ok
    | Forbidden
    | UnprocessableEntity<'emailDoesNotMatch'>
    | UnprocessableEntity<'userNotGoogleSuperAdmin'>
    | UnprocessableEntity<'unauthorizedClient'>
    | UnprocessableEntity<'missingDirectoryPermissions'>
    | UnprocessableEntity<'unknown'>
    | Conflict<'alreadyExists'>
  >
  getNetworkPassiveSources: (p: { teamId: TeamId }) => Promise<Ok<PassiveSourceConfiguration>>

  getKomboConnectLink: (p: { teamId: TeamId; toolId: string }) => Promise<Ok<{ url: string }>>
  createKomboIntegration: (p: {
    teamId: TeamId
    token: string
    syncUsers: boolean
    syncTeams: boolean
  }) => Promise<Ok>
  updateIntegration: (p: { teamId: TeamId; syncUsers: boolean; syncTeams: boolean }) => Promise<Ok>
  getAvailableKomboTools: (p: { teamId: TeamId }) => Promise<Ok<IntegrationTool[]>>
  customerActivity: (p: CustomerActivity) => Promise<Ok>

  getMyDiscussions: (p: GetMyDiscussionsParams) => Promise<Ok<DiscussionDescriptor[]> | Forbidden>
  getDiscussion: (p: GetDiscussionParams) => Promise<Ok<Discussion> | Forbidden>

  createDiscussion: (
    p: CreateDiscussionPayload
  ) => Promise<Ok<{ discussionId: DiscussionId }> | Forbidden | Conflict<'nameTaken'>>
  updateDiscussion: (p: UpdateDiscussionPayload) => Promise<Ok | Forbidden>
  deleteDiscussion: (p: DeleteDiscussionParams) => Promise<Ok | Forbidden>

  createDiscussionComment: (
    p: CreateDiscussionCommentPayload
  ) => Promise<Ok<{ discussionCommentId: DiscussionCommentId }> | Forbidden>
  editDiscussionComment: (p: EditDiscussionCommentPayload) => Promise<Ok | Forbidden>
  deleteDiscussionComment: (p: DeleteDiscussionCommentParams) => Promise<Ok | Forbidden>

  createDiscussionNote: (
    p: CreateDiscussionNotePayload
  ) => Promise<Ok<{ discussionNoteId: DiscussionNoteId }> | Forbidden>
  editDiscussionNote: (p: EditDiscussionNotePayload) => Promise<Ok | Forbidden>
  deleteDiscussionNote: (p: DeleteDiscussionNoteParams) => Promise<Ok | Forbidden>

  createDiscussionTemplate: (p: CreateDiscussionTemplatePayload) => Promise<Ok | Forbidden>
  getDiscussionTemplate: (
    p: GetDiscussionTemplateParams
  ) => Promise<Ok<DiscussionTemplate[]> | Forbidden>
  editDiscussionTemplate: (p: EditDiscussionTemplatePayload) => Promise<Ok | Forbidden>
  deleteDiscussionTemplate: (p: DeleteDiscussionTemplateParams) => Promise<Ok | Forbidden>

  /**
   * Get insights about the subject user's received answers to evaluations.
   *
   * Returns:
   *   200: Ok<DiscussionInsight[]> - Success
   *   200: Ok<[]> - Success if the subject user has not received any answers to evaluations
   *   422: UnprocessableEntity - LLM failed to generate insights in maximum number of attempts
   *   403: Forbidden - Caller doesn't have permission to see the insights for the subject user
   */
  getDiscussionInsights: (p: {
    teamId: TeamId
    subjectUserId: UserId
  }) => Promise<
    Ok<DiscussionInsight[]> | UnprocessableEntity<'llm-max-attempts-exceeded'> | Forbidden
  >

  connectDiscussionToSlack: (p: {
    teamId: TeamId
    discussionId: DiscussionId
    channelName: string
  }) => Promise<Ok | Forbidden | Conflict<'nameTaken'>>

  getWorkspaceUserCohorts: (p: { teamId: TeamId }) => Promise<Ok<UserCohorts[]> | Forbidden>

  getWorkspaceQuestionScaleStyles: (p: { teamId: TeamId }) => Promise<Ok<QuestionScaleStyle[]>>
  setWorkspaceQuestionScaleStyle: (p: {
    teamId: TeamId
    questionScaleStyleId: QuestionScaleStyleId
  }) => Promise<Ok>
  getQuestionScaleStyles: () => Promise<Ok<QuestionScaleStyle[]>>

  getPulseThemesAndKpis: (p: {
    teamId: TeamId | DemoTeamId
  }) => Promise<Ok<{ themes: PulseThemeWithVisibility[]; kpis: PulseKpiWithVisibility[] }>>
  createPulseTheme: (p: {
    teamId: TeamId
    name: TranslationString
    questionIds: QuestionId[]
    visibleTeamIds: TeamId[] | 'all'
    pinned: boolean
  }) => Promise<Ok<{ pulseThemeId: PulseThemeId }>>
  updatePulseTheme: (p: {
    teamId: TeamId
    pulseThemeId: PulseThemeId
    name: TranslationString
    questionIds: QuestionId[]
    visibleTeamIds: TeamId[] | 'all'
    pinned: boolean
  }) => Promise<Ok>
  setPulseThemeVisibility: (p: {
    teamId: TeamId
    pulseThemeId: PulseThemeId
    visibleTeamIds: TeamId[] | 'all'
    pinned: boolean
  }) => Promise<Ok>
  setPulseKpiVisibility: (p: {
    teamId: TeamId
    pulseKpiId: PulseKpiId
    visibleTeamIds: TeamId[] | 'all'
    pinned: boolean
  }) => Promise<Ok>

  createWorkspaceApiKey: (p: {
    teamId: TeamId
    name: string
  }) => Promise<Ok<{ apiKey: ApiKeyClientId }> | Conflict<'conflict'> | Forbidden>
  revokeWorkspaceApiKey: (p: { teamId: TeamId }) => Promise<Ok | Forbidden>

  getFeedbackNetworkRequestRecommendations: (p: {
    teamId: TeamId
    subjectUserId: UserId
  }) => Promise<Ok<UserConnection[]>>

  mergeWorkspaces: (p: {
    workspaceIds: TeamId[]
    workspaceName: string
  }) => Promise<Ok<TeamDescriptor>>
  deleteMergedWorkspace: (p: { workspaceId: TeamId }) => Promise<Ok>
  backfillWorkspaceCohort: (p: { teamId: TeamId }) => Promise<Ok>

  getHeatmapResults: (p: {
    teamId: TeamId
    range: HeatmapRange
    measurement: SelectedMeasurements
    surveyContext: SurveyContext
    filterByTeamId: TeamId
    organizeByCohort: CohortKey | null
  }) => Promise<Ok<HeatmapDataRow[]>>
}

export type ApiEndpointName = keyof ApiDefinition

export type ApiEndpoint = {
  method: SupportedHttpMethod
  url: string
}

export const apiEndpoints = {
  version: { method: 'get', url: '/version' },

  registerUser: { method: 'post', url: '/user/register' },
  tokenLogin: { method: 'get', url: '/login/token' },
  codeLogin: { method: 'post', url: '/login/code' },
  requestLoginToken: { method: 'post', url: '/token' },
  logout: { method: 'post', url: '/logout' },
  refreshSession: { method: 'post', url: '/refresh' },
  freeDomainCheck: { method: 'get', url: '/domain/free' },
  updateUserTimestamp: { method: 'put', url: '/user/timestamp' },

  getPulsePlaybook: { method: 'get', url: '/pulse/playbook' },

  getUser: { method: 'get', url: '/user' },
  updateUser: { method: 'put', url: '/user' },
  getUserNotificationSettings: { method: 'get', url: '/user/notifications/settings' },
  setUserNotificationPreference: { method: 'post', url: '/user/notifications/preference' },
  getPrivacyPolicyConsent: { method: 'get', url: '/user/privacypolicy/consent' },
  setPrivacyPolicyConsent: { method: 'post', url: '/user/privacypolicy/consent' },

  getMyContacts: { method: 'get', url: '/user/contacts' },

  getUserWorkspaces: { method: 'get', url: '/user/workspace' },
  getJoinableWorkspaces: { method: 'get', url: '/user/teams/joinable' },
  joinWorkspace: { method: 'post', url: '/user/team' },

  addUsersToTeam: { method: 'post', url: '/workspace/team/invite/users' },
  addUsersToWorkspace: { method: 'post', url: '/workspace/invite/users' },

  setUserTeamRole: { method: 'post', url: '/user/team/role' },
  removeUserFromWorkspace: { method: 'post', url: '/user/workspace/remove' },
  removeUserFromGroup: { method: 'post', url: '/user/group/remove' },
  createWorkspace: { method: 'post', url: '/workspace' },

  getTeam: { method: 'get', url: '/team' },
  createTeam: { method: 'post', url: '/team' },
  updateTeam: { method: 'put', url: '/team' },
  moveTeam: { method: 'put', url: '/team/move' },
  setTeamFeature: { method: 'put', url: '/team/feature' },
  updateTeamDomains: { method: 'put', url: '/team/domains' },
  removeTeam: { method: 'delete', url: '/team' },

  getSharedTeamPraiseChannel: { method: 'get', url: '/slack/shared-praise' },
  getSlackWorkspaceUsers: { method: 'get', url: '/slack/users' },
  getSlackWorkspaceChannels: { method: 'get', url: '/slack/channels' },
  getSlackWorkspaceChannelMembers: { method: 'get', url: '/slack/channel-members' },
  updateSlackAllowJoinWorkspace: { method: 'post', url: '/slack/allow-join-workspace' },

  setTeamSlackPraiseChannel: { method: 'post', url: '/team/slack/praise-channel' },
  clearTeamSlackPraiseChannel: { method: 'delete', url: '/team/slack/praise-channel' },

  getTopics: { method: 'get', url: '/topics' },
  getRequestTopics: { method: 'get', url: '/topics/request' },
  removeTopic: { method: 'delete', url: '/topic' },
  setFavouriteTopic: { method: 'post', url: '/topics/favourite' },
  setFeedbackLinkTopic: { method: 'post', url: '/topics/feedback-link' },
  getUserFavouriteTopicIds: { method: 'get', url: '/topics/favourite' },
  removeRequestTopic: { method: 'delete', url: '/topics/request' },

  addUserTopic: { method: 'post', url: '/topics/user' },
  addWorkspaceTopic: { method: 'post', url: '/topics/workspace' },

  getRequest: { method: 'get', url: '/request' },
  getRequests: { method: 'get', url: '/requests' },
  viewFeedbackWithAuthToken: { method: 'get', url: '/request/token' },
  createViewFeedbackAuthToken: { method: 'post', url: '/request/token' },

  requestFeedback: { method: 'post', url: '/request' },
  markRequestAsSeen: { method: 'post', url: '/request/seen' },

  createDraftFeedback: { method: 'post', url: '/feedback' },
  updateDraftFeedback: { method: 'put', url: '/feedback' },
  deleteDraftFeedback: { method: 'delete', url: '/feedback' },
  addRequestTopic: { method: 'post', url: '/request/topic' },
  archiveRequest: { method: 'post', url: '/request/archive' },
  setRequestPrivacy: { method: 'post', url: '/request/privacy' },
  publishRequestFeedback: { method: 'post', url: '/feedback/publish' },
  markFeedbackAsSeen: { method: 'post', url: '/feedback/seen' },
  createUnsolicitedFeedbackRequest: { method: 'post', url: '/feedback/unsolicited/create' },
  createSelfReflection: { method: 'post', url: '/feedback/self/create' },
  sendUnsolicitedFeedback: { method: 'post', url: '/feedback/unsolicited' },

  getTeamFeedbackStats: { method: 'get', url: '/feedback/stats/team' },
  getManagedUserFeedbackStats: { method: 'get', url: '/feedback/stats/managed-user' },

  getWorkspaceEvaluationProgress: { method: 'get', url: '/evaluation/workspace/progress' },
  updateEvaluation: { method: 'put', url: '/evaluation' },
  provideEvaluation: { method: 'post', url: '/evaluation/provide' },
  activateEvaluationFeature: { method: 'post', url: '/evaluation/activate' },
  getWorkspaceEvaluationResults: { method: 'get', url: '/evaluation/results' },
  requestEvaluations: { method: 'put', url: '/evaluation/request' },
  getRequestableEvaluees: { method: 'get', url: '/evaluation/available' },
  getEvaluationsByEvaluee: { method: 'get', url: '/evaluation/user/requests' },

  getEvaluationSummary: { method: 'get', url: '/evaluation/summary' },

  getWorkspaceUserPendingEvaluationRequests: {
    method: 'get',
    url: '/evaluation/workspace-requests',
  },
  setEvaluationTemplateQuestions: {
    method: 'post',
    url: '/evaluation/questions',
  },
  skipEvaluation: { method: 'post', url: '/evaluation/skip' },
  getEvaluationThemes: { method: 'get', url: '/evaluation/themes' },
  setEvaluationQuestionThemes: { method: 'put', url: '/evaluation/question/themes' },
  createEvaluationTheme: { method: 'post', url: '/evaluation/themes' },
  createEvaluationTemplate: { method: 'post', url: '/evaluation/template' },
  updateEvaluationTemplate: { method: 'put', url: '/evaluation/template' },
  setEvaluationTemplateArchived: { method: 'delete', url: '/evaluation/template' },
  reopenEvaluation: { method: 'put', url: '/evaluation/reopen' },
  reactivateEvaluations: { method: 'put', url: '/evaluation/reactivate' },
  sendEvaluationReminders: { method: 'post', url: '/evaluation/reminder' },
  getPendingEvaluationsForMe: { method: 'get', url: '/evaluations/pending-for-me' },
  getRequestedExternalEvaluations: { method: 'get', url: '/evaluations/external' },
  getEvaluationTemplates: { method: 'get', url: '/evaluations/templates' },
  getWorkspaceUserFeedbackPermissions: { method: 'get', url: '/evaluation/access/list' },
  publishEvaluationsForEvaluee: { method: 'put', url: '/evaluation/evaluee/visibility' },
  setWrittenEvaluationAnswerRedaction: { method: 'put', url: '/evaluation/answer/redaction' },
  deletePendingEvaluations: { method: 'delete', url: '/evaluation/requests' },

  addRequestProviderMessage: { method: 'post', url: '/request/provider-message' },
  writeRequestComment: { method: 'post', url: '/request/comment' },
  getRequestComments: { method: 'get', url: '/request/comment' },
  deleteRequestComment: { method: 'delete', url: '/request/comment' },
  markRequestCommentAsSeen: { method: 'post', url: '/request/comment/seen' },

  getUserSurveys: { method: 'get', url: '/survey/me' },

  createPulse: { method: 'put', url: '/pulse' },
  reschedulePulse: { method: 'post', url: '/pulse/schedule' },
  updatePulseSettings: { method: 'put', url: '/pulse/settings' },
  getPulses: { method: 'get', url: '/pulse' },
  resumePulse: { method: 'post', url: '/pulse/resume' },
  stopPulse: { method: 'post', url: '/pulse/pause' },

  createPulseSingleRound: { method: 'post', url: '/pulse/single-round' },
  getPulseSingleRounds: { method: 'get', url: '/pulse/single-round' },
  updatePulseSingleRound: { method: 'put', url: '/pulse/single-round' },
  deletePulseSingleRound: { method: 'delete', url: '/pulse/single-round' },
  publishPulseSingleRound: { method: 'post', url: '/pulse/single-round/publish' },
  getPulseRoundResponseRateEstimate: {
    method: 'get',
    url: '/pulse/round/response-rates',
  },

  getCustomGuidances: { method: 'get', url: '/pulse/custom-guidance/all' },
  updateCustomGuidance: { method: 'put', url: '/pulse/custom-guidance' },
  deleteCustomGuidance: { method: 'delete', url: '/pulse/custom-guidance' },
  getUserSurveyGuidance: { method: 'get', url: '/pulse/custom-guidance/user' },
  updateSurveyCustomGuidanceFeedback: {
    method: 'post',
    url: '/pulse/custom-guidance/user-feedback',
  },

  setUserTeamPulseParticipation: { method: 'put', url: '/pulse/user/participation' },

  answerPulseQuestion: { method: 'post', url: '/pulse/answer' },
  updatePulseAnswerComment: { method: 'put', url: '/pulse/answer/comment' },

  getPulseComments: { method: 'get', url: '/pulse/comments' },
  replyToPulseComment: { method: 'post', url: '/pulse/comment/reply' },
  deletePulseCommentReply: { method: 'delete', url: '/pulse/comment/reply' },
  updatePulseCommentReaction: { method: 'put', url: '/pulse/comment/reaction' },

  getPulseResultsByCohort: { method: 'get', url: '/pulse/results/by-cohort' },
  getPulseStatistics: { method: 'get', url: '/pulse/stats' },

  getNetworkResults: { method: 'get', url: '/network/results' },
  getNetworkSnapshot: { method: 'get', url: '/network/snapshot' },
  getLeadershipStyles: { method: 'get', url: '/network/leadership-style' },
  getUserLeadershipStyle: { method: 'get', url: '/network/leadership-style/user' },
  getPersonalNetworkMetrics: { method: 'get', url: '/network/results/personal' },
  getPersonalNetworkAnswers: { method: 'get', url: '/network/answers/personal' },

  getCohortsByWorkspace: { method: 'get', url: '/pulse/cohorts' },

  createSubscription: { method: 'post', url: '/subscription' },
  checkPromotionCode: { method: 'post', url: '/subscription/promo-code' },
  stripePortalSession: { method: 'post', url: '/subscription/portal' },

  getQuestions: { method: 'get', url: '/questions' },

  createWorkspaceQuestion: { method: 'post', url: '/workspace/question' },
  updateWorkspaceQuestion: { method: 'put', url: '/workspace/question' },
  deleteWorkspaceQuestion: { method: 'delete', url: '/workspace/question' },

  logEvent: { method: 'post', url: '/log-action' },

  importWorkspaceUsersCsv: { method: 'post', url: '/workspace/csv-import' },
  exportWorkspaceUsersCsv: { method: 'get', url: '/workspace/csv-export' },
  syncIntegrationData: { method: 'post', url: '/workspace/sync-integration' },
  debugIntegrationData: { method: 'post', url: '/workspace/debug-integration' },

  sendManagementFeedback: { method: 'post', url: '/feedback/management' },
  getManagementFeedbackById: { method: 'get', url: '/feedback/management/' },
  getManagementFeedback: { method: 'get', url: '/feedback/management/all' },
  markManagementFeedbackAsSeen: { method: 'post', url: '/feedback/management/seen' },
  sendManagementFeedbackComment: { method: 'post', url: '/feedback/management/comment' },
  updateManagementFeedbackRecipients: { method: 'post', url: '/feedback/management/recipients' },
  getManagementFeedbackChannels: { method: 'get', url: '/feedback/management/channels' },
  createManagementFeedbackChannel: { method: 'post', url: '/feedback/management/channels' },
  updateManagementFeedbackChannel: { method: 'put', url: '/feedback/management/channels' },
  activateManagementFeedback: { method: 'post', url: '/feedback/management/activate' },

  getWorkspaceActivationSettings: { method: 'get', url: '/feedback/activation-settings' },
  setWorkspaceActivationSettings: { method: 'post', url: '/feedback/activation-settings' },
  setWorkspaceFeedbackSettings: { method: 'post', url: '/feedback/settings' },

  shareFeedback: { method: 'post', url: '/feedback/share' },
  getSharedFeedback: { method: 'get', url: '/feedback/shared' },

  dryRunPulse: { method: 'get', url: '/admin/dry-run-pulse' },
  generateTestEvaluationData: { method: 'post', url: '/admin/generate-test-evaluation-data' },
  deleteEvaluationData: { method: 'post', url: '/admin/delete-all-evaluation-data' },
  generateTestPulseData: { method: 'post', url: '/admin/generate-test-pulse-data' },
  deletePulseData: { method: 'post', url: '/admin/delete-all-pulse-data' },
  generateUserNetworkData: { method: 'post', url: '/admin/generate-network-data' },
  generateUsersAndGroups: { method: 'post', url: '/admin/generate-users-and-groups' },
  generateCurrentSurveyAnswers: { method: 'post', url: '/admin/generate-pulse-round' },

  getWorkspaceSlackMemberships: { method: 'get', url: '/admin/workspaces/membership/slack' },
  getWorkspaceMsTeamsMemberships: { method: 'get', url: '/admin/workspaces/membership/ms-teams' },
  getWorkspaceSuperAdminAccess: { method: 'get', url: '/admin/access' },
  grantWorkspaceSuperAdminAccess: { method: 'post', url: '/admin/access' },
  revokeWorkspaceSuperAdminAccess: { method: 'delete', url: '/admin/access' },

  getSuperAdminWorkspaces: { method: 'get', url: '/admin/workspaces' },
  archiveWorkspace: { method: 'post', url: '/admin/archive-workspace' },
  unarchiveWorkspace: { method: 'post', url: '/admin/unarchive-workspace' },
  getDefaultFeatures: { method: 'get', url: '/admin/default-features' },

  getAllTeamAllResultsByQuestion: {
    method: 'get',
    url: '/pulse/all-teams-all-results-by-question',
  },

  getMultiWorkspaceAllResults: { method: 'get', url: '/pulse/results/multi-workspace/all' },
  getMultiWorkspaceResultsByQuestion: {
    method: 'get',
    url: '/pulse/results/multi-workspace/question',
  },
  getMultiWorkspaceResultsByKpi: { method: 'get', url: '/pulse/results/multi-workspace/kpi' },
  getMultiWorkspaceResultsByTheme: { method: 'get', url: '/pulse/results/multi-workspace/theme' },
  getMultiWorkspaceLatestResults: {
    method: 'get',
    url: '/pulse/results/multi-workspace/latest',
  },
  getMultiworkspacePulseThemesAndKpis: {
    method: 'get',
    url: '/pulse/results/multi-workspace/themes-kpis',
  },

  getAllTeamAllResultsByKpi: { method: 'get', url: '/pulse/all-teams-all-results-by-kpi' },
  getAllTeamAllResultsByTheme: { method: 'get', url: '/pulse/all-teams-all-results-by-theme' },
  getPulseResults: { method: 'get', url: '/pulse/team-all-results' },
  getAllTeamLatestResults: { method: 'get', url: '/pulse/all-teams-latest-results' },
  getEngagementBenchmarks: { method: 'get', url: '/pulse/benchmarks' },
  getKpiResultsForUser: { method: 'get', url: '/pulse/personal-kpi' },
  getTeamUserKpis: { method: 'get', url: '/pulse/team-user-kpi' },

  answerNetworkQuestionnaire: { method: 'post', url: '/network/answer' },
  getUserNetworkQuestionnaire: { method: 'get', url: '/network/questionnaire' },

  sendAnonymousFeedback: { method: 'post', url: '/feedback/anonymous' },
  getFeedbackLink: { method: 'get', url: '/feedback/link' },
  getMyFeedbackLink: { method: 'get', url: '/feedback/link/me' },
  createFeedbackLink: { method: 'post', url: '/feedback/link' },
  updateFeedbackLink: { method: 'put', url: '/feedback/link' },

  getWorkspaceSlackAndTeamsLinks: { method: 'get', url: '/workspace/messaging-channels' },

  getUserAvailableFeatures: { method: 'get', url: '/user/features' },

  getLatestNetworkSurveyRound: { method: 'get', url: '/network/survey-round' },
  createNetworkSurveyRound: { method: 'post', url: '/network/survey-round' },
  extendNetworkSurveyRound: { method: 'put', url: '/network/survey-round/extend' },
  sendNetworkSurveyRoundReminders: { method: 'post', url: '/network/survey-round/remind' },

  getWorkspaceOnboarding: { method: 'get', url: '/workspace/onboarding' },
  completeOnboardingStep: { method: 'put', url: '/workspace/onboarding/skip' },
  revertOnboardingStep: { method: 'put', url: '/workspace/onboarding/revert' },
  markOnboardingDone: { method: 'put', url: '/workspace/onboarding/done' },

  sendPulseQuestionnaireToSlackChannel: { method: 'post', url: '/pulse/send-to-slack-channel' },

  getGrantedPlans: { method: 'get', url: '/admin/granted-plans' },
  addGrantedPlan: { method: 'post', url: '/admin/granted-plans' },
  syncWorkspaceSlackAccounts: { method: 'post', url: '/admin/sync-slack' },

  addSsoIntegration: { method: 'post', url: '/workspace/sso' },

  connectMsTeams: { method: 'post', url: '/ms-teams/connect' },
  connectMsTeamsForOna: { method: 'post', url: '/ms-teams/connect-ona' },
  getMsTeamsChannels: { method: 'get', url: '/ms-teams/channels' },
  getMsTeamsTeams: { method: 'get', url: '/ms-teams/teams' },
  getMsTeamsUsers: { method: 'get', url: '/ms-teams/users' },
  setMsTeamsPraiseChannel: { method: 'post', url: '/ms-teams/praise-channel' },
  clearMsTeamsPraiseChannel: { method: 'delete', url: '/ms-teams/praise-channel' },
  synchronizeMsTeams: { method: 'post', url: '/ms-teams/synchronize' },

  addUserFeedbackAccess: { method: 'post', url: '/feedback/user/access' },
  removeUserFeedbackAccess: { method: 'post', url: '/feedback/user/access/remove' },
  getFeedbackForSubject: { method: 'get', url: '/feedback/user' },

  addPassiveSource: { method: 'post', url: '/network/passive-sources' },
  getNetworkPassiveSources: { method: 'get', url: '/network/passive-sources' },

  getKomboConnectLink: { method: 'get', url: '/integration/kombo/connect-link' },
  createKomboIntegration: { method: 'post', url: '/integration/kombo/activate' },
  updateIntegration: { method: 'post', url: '/integration/active' },
  getAvailableKomboTools: { method: 'get', url: '/integration/kombo/tools' },

  customerActivity: { method: 'post', url: '/customer-activity' },

  getMyDiscussions: { method: 'get', url: '/discussions/me' },
  getDiscussion: { method: 'get', url: '/discussion' },

  createDiscussion: { method: 'post', url: '/discussion' },
  updateDiscussion: { method: 'put', url: '/discussion' },
  deleteDiscussion: { method: 'delete', url: '/discussion' },

  createDiscussionComment: { method: 'post', url: '/discussion/comment' },
  editDiscussionComment: { method: 'put', url: '/discussion/comment' },
  deleteDiscussionComment: { method: 'delete', url: '/discussion/comment' },

  createDiscussionNote: { method: 'post', url: '/discussions/note' },
  editDiscussionNote: { method: 'put', url: '/discussions/note' },
  deleteDiscussionNote: { method: 'delete', url: '/discussions/note' },

  createDiscussionTemplate: { method: 'post', url: '/discussions/template' },
  getDiscussionTemplate: { method: 'get', url: '/discussions/template' },
  editDiscussionTemplate: { method: 'put', url: '/discussions/template' },
  deleteDiscussionTemplate: { method: 'delete', url: '/discussions/template' },

  getDiscussionInsights: { method: 'get', url: '/discussion/insights' },

  connectDiscussionToSlack: { method: 'post', url: '/discussion/connect/slack' },

  getWorkspaceUserCohorts: { method: 'get', url: '/workspace/users/attributes' },

  getWorkspaceQuestionScaleStyles: { method: 'get', url: '/question-scale-styles/workspace' },
  setWorkspaceQuestionScaleStyle: { method: 'post', url: '/question-scale-styles/workspace' },
  getQuestionScaleStyles: { method: 'get', url: '/question-scale-styles' },
  getPulseThemesAndKpis: { method: 'get', url: '/pulse/themes' },
  createPulseTheme: { method: 'post', url: '/pulse/theme' },
  updatePulseTheme: { method: 'put', url: '/pulse/theme' },
  setPulseThemeVisibility: { method: 'post', url: '/pulse/theme/visibility' },
  setPulseKpiVisibility: { method: 'post', url: '/pulse/kpi/visibility' },

  createWorkspaceApiKey: { method: 'post', url: '/workspace/api-key' },
  revokeWorkspaceApiKey: { method: 'delete', url: '/workspace/api-key' },
  getFeedbackNetworkRequestRecommendations: {
    method: 'get',
    url: '/network/recommendations/request',
  },
  mergeWorkspaces: { method: 'post', url: '/admin/merge-workspaces' },
  deleteMergedWorkspace: { method: 'delete', url: '/admin/merge-workspaces' },
  backfillWorkspaceCohort: { method: 'post', url: '/admin/backfill-workspace-cohort' },

  getHeatmapResults: { method: 'post', url: '/engagement/results/heatmap' },
} as const satisfies {
  [Key in ApiEndpointName]: { method: SupportedHttpMethod; url: string }
}

export const apiDataOrNull = <T>(payload: ApiResponse<T>): T | null => {
  if (payload.status !== 'ok') {
    return null
  }
  return payload.data
}

export const apiDataOrThrow = <T>(payload: ApiResponse<T>): T => {
  if (payload.status !== 'ok') {
    throw new Error(`api status was ${payload.status}`)
  }
  return payload.data
}
