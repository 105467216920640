// https://docs.render.com/logging#tracing-with-requestid-and-rndr-id
const RENDER_REQUEST_ID_HEADER = 'rndr-id'
// https://developers.cloudflare.com/fundamentals/reference/http-request-headers/
const CLOUDFLARE_RAY_ID_HEADER = 'cf-ray'
/** Teamspective request id header. Used only when render or cloudflare request ids were not available. */
export const TEAMSPECTIVE_REQUEST_ID_HEADER = 'x-teamspective-request-id'

type HasHeaders =
  // Axios
  | {
      headers: {
        [key in string]?: unknown
      }
    }
  // Node http
  | {
      headersDistinct: Record<string, string[] | undefined>
    }

/**
 * Gets first occurence of a given header
 */
const getHeader = (req: HasHeaders, name: string): string | undefined => {
  if ('headers' in req && typeof req.headers === 'object') {
    const value = req.headers[name]
    if (typeof value === 'string') {
      return value
    }
    if (Array.isArray(value)) {
      if (typeof value[0] === 'string') {
        return value[0]
      }
    }
    return undefined
  } else if ('headersDistinct' in req && name in req.headersDistinct) {
    // Fetch headers
    return req.headersDistinct[name]?.[0] ?? undefined
  }
  return undefined
}

export const extractRequestId = (reqOrRes: HasHeaders): string | undefined => {
  return (
    getHeader(reqOrRes, RENDER_REQUEST_ID_HEADER) ??
    getHeader(reqOrRes, CLOUDFLARE_RAY_ID_HEADER) ??
    getHeader(reqOrRes, TEAMSPECTIVE_REQUEST_ID_HEADER)
  )
}
