import type { QuestionId, QuestionTag, ReadonlyNonEmptyArray } from '../types/index.ts'
import {
  type Kpi,
  type PulseQuestionTag,
  isKpi,
  pulseQuestionsInSortOrder,
  isTheme,
} from '../types/index.ts'
import { assertNonEmpty, flatten, mapObject, unique } from '../util.ts'

// Spec at https://docs.google.com/spreadsheets/d/1RpQ0ch59TwJul6xV6Hx9QGUC4yZrajWMda1xhzh98y0/edit#gid=449509269

export const defaultTags: PulseQuestionTag[] = [
  'acceptance',
  'accepting_differences',
  'accomplishment',
  'autonomy_statement',
  'clarity_statement',
  'communication_in_team_statement',
  'competence_statement',
  'coordination',
  'dependability_statement',
  'effectiveness',
  'enps',
  'fairness_statement',
  'feedback_to_leaders',
  'giving_feedback',
  'goal_setting',
  'impact',
  'meaningful_mission',
  'mistake_tolerance',
  'non_discrimination',
  'pace_of_learning',
  'performance_issues',
  'reasonable_workload',
  'recognition_frequency',
  'recovery',
  'redirection',
  'relationships',
  'role_expectations',
  'safety',
  'satisfaction_with_feedback_statement',
  'sense_of_control',
  'sick_leave',
  'strengths_utilization',
  'stress',
  'support',
  'team_effectiveness',
  'voicing_concerns',
  'wellbeing_statement',
]

export const deprecatedTags: PulseQuestionTag[] = [
  'appreciation',
  'autonomy',
  'career_opportunities',
  'clarity',
  'communication_in_team',
  'competence',
  'dependability',
  'fairness',
  'inclusion',
  'psychological_safety',
  'satisfaction_with_feedback',
  'wellbeing',
]

export const engagementThemes = [
  'theme_motivation',
  'theme_teamwork',
  'theme_company_culture_and_practices',
  'theme_personal_growth',
  'theme_feedback',
  'theme_enps',
  'theme_psychological_safety',
  'theme_leadership',
] as const

export const wellbeingThemes = ['theme_mental_wellbeing', 'theme_physical_health_safety'] as const

export const deibThemes = ['theme_perceived_equality', 'theme_equality_practices'] as const

export const enpsThemes = ['theme_enps'] as const

type EngagementTheme = (typeof engagementThemes)[number]
type WellbeingTheme = (typeof wellbeingThemes)[number]
type DeibTheme = (typeof deibThemes)[number]
type EnpsTheme = (typeof enpsThemes)[number]

export type Theme = EngagementTheme | WellbeingTheme | DeibTheme | EnpsTheme

export const KpiThemeQuestionStructure: {
  kpi_engagement: { [key in EngagementTheme]: PulseQuestionTag[] }
  kpi_wellbeing: { [key in WellbeingTheme]: PulseQuestionTag[] }
  kpi_deib: { [key in DeibTheme]: PulseQuestionTag[] }
  kpi_enps: { [key in EnpsTheme]: PulseQuestionTag[] }
} = {
  kpi_engagement: {
    theme_teamwork: [
      'team_effectiveness',
      'dependability',
      'dependability_statement',
      'communication_in_team',
      'communication_in_team_statement',
      'support',
      'strengths_utilization',
      'clarity',
      'clarity_statement',
      'coordination',
    ],
    theme_motivation: [
      'autonomy',
      'autonomy_statement',
      'competence',
      'competence_statement',
      'impact',
      'clarity',
      'clarity_statement',
      'pace_of_learning',
      'personal_growth',
      'effectiveness',
      'accomplishment',
      'satisfaction_with_feedback',
      'satisfaction_with_feedback_statement',
      'relationships',
      'inclusion',
      'inclusion_statement',
      'acceptance',
      'belonging',
    ],
    theme_leadership: [
      'performance_issues',
      'tools_and_resources',
      'dealing_with_problems',
      'support',
      'clarity',
      'clarity_statement',
      'satisfaction_with_feedback',
      'satisfaction_with_feedback_statement',
      'manager_attention',
      'feedback_to_leaders',
      'appreciation',
      'appreciation_statement',
      'role_expectations',
      'recognition_frequency',
      'goal_setting',
    ],
    theme_personal_growth: [
      'career_opportunities',
      'career_opportunities_statement',
      'interest_in_work',
      'pace_of_learning',
      'personal_growth',
      'accomplishment',
      'redirection',
    ],
    theme_feedback: [
      'satisfaction_with_feedback',
      'satisfaction_with_feedback_statement',
      'appreciation',
      'appreciation_statement',
      'role_expectations',
      'redirection',
      'feedback_to_leaders',
      'giving_feedback',
      'recognition_frequency',
    ],
    theme_psychological_safety: [
      'mistake_tolerance',
      'voicing_concerns',
      'rejecting_differences',
      'risk_tolerance',
      'social_undermining',
      'strengths_utilization',
      'barrier_to_help',
      'psychological_safety',
      'psychological_safety_statement',
      'support',
      'accepting_differences',
    ],
    theme_company_culture_and_practices: [
      'company_ambition',
      'understanding_mission',
      'meaningful_mission',
      'confidence_in_future',
      'understanding_strategy',
      'values',
      'rewarding',
      'agility',
      'processes',
      'fairness',
      'fairness_statement',
      'support_for_wellbeing',
      'impact',
      'career_progression',
      'goal_setting',
    ],
    theme_enps: ['enps'],
  },
  kpi_wellbeing: {
    theme_mental_wellbeing: [
      'wellbeing',
      'wellbeing_statement',
      'stress',
      'sick_leave',
      'sense_of_control',
      'remote_policies',
      'acceptance',
      'recovery',
      'reasonable_workload',
      'competence',
      'competence_statement',
    ],
    theme_physical_health_safety: ['recovery', 'safety', 'equipment', 'sick_leave'],
  },
  kpi_deib: {
    theme_perceived_equality: ['inclusion', 'inclusion_statement', 'acceptance', 'belonging'],
    theme_equality_practices: [
      'diversity',
      'equal_opportunity',
      'non_discrimination',
      'accepting_differences',
      'rejecting_differences',
    ],
  },
  kpi_enps: { theme_enps: ['enps'] },
}

export const isEnps = (tag: Kpi | Theme | QuestionTag | null): boolean =>
  tag === 'kpi_enps' || tag === 'theme_enps' || tag === 'enps'

export const KpiThemes: { [key in Kpi]: Theme[] } = mapObject(
  KpiThemeQuestionStructure,
  (theme) => Object.keys(theme) as Theme[]
)

export const KpiQuestionTags: { [key in Kpi]: PulseQuestionTag[] } = mapObject(
  KpiThemeQuestionStructure,
  (theme) => unique(flatten(Object.values(theme)))
)

export const QuestionTagsInMultipleKpis = unique(
  flatten([...Object.values(KpiQuestionTags)]).filter(
    (tag, _, arr) => arr.filter((item) => item === tag).length > 1
  )
)

export const ThemeQuestionTags: { [key in Theme]: PulseQuestionTag[] } = {
  ...KpiThemeQuestionStructure.kpi_engagement,
  ...KpiThemeQuestionStructure.kpi_wellbeing,
  ...KpiThemeQuestionStructure.kpi_deib,
}

export const AnyKpiQuestionTags = { ...KpiQuestionTags, ...ThemeQuestionTags }

export const themes = Object.keys(ThemeQuestionTags) as Theme[]

export const allCurrentPulseQuestionTags = assertNonEmpty(
  unique(flatten(Object.values(AnyKpiQuestionTags)))
)

export const getTags = (kpi: Kpi | Theme): (Theme | PulseQuestionTag)[] =>
  isKpi(kpi) ? KpiThemes[kpi] : ThemeQuestionTags[kpi]

export const getQuestionTags = (measurement: Kpi | Theme | PulseQuestionTag): PulseQuestionTag[] =>
  isKpi(measurement)
    ? KpiThemes[measurement].flatMap((theme) => ThemeQuestionTags[theme])
    : isTheme(measurement)
      ? ThemeQuestionTags[measurement]
      : [measurement]

/**
 * Sorts question tags first default, then custom, last deprecated
 */
export const compareQuestionTags = (
  a: QuestionId | PulseQuestionTag,
  b: QuestionId | PulseQuestionTag
) => {
  const aIsDefault = defaultTags.includes(a as PulseQuestionTag)
  const bIsDefault = defaultTags.includes(b as PulseQuestionTag)

  if (aIsDefault && !bIsDefault) {
    return -1
  }
  if (!aIsDefault && bIsDefault) {
    return 1
  }

  const aIsDeprecated = deprecatedTags.includes(a as PulseQuestionTag)
  const bIsDeprecated = deprecatedTags.includes(b as PulseQuestionTag)

  if (aIsDeprecated && !bIsDeprecated) {
    return 1
  }
  if (!aIsDeprecated && bIsDeprecated) {
    return -1
  }

  return 0
}

export const allPulseQuestionTags: ReadonlyNonEmptyArray<PulseQuestionTag> =
  pulseQuestionsInSortOrder
