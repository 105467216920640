import type React from 'react'

const BaseContainer = ({
  id,
  outerStyle,
  innerStyle,
  children,
}: {
  id?: string
  outerStyle?: React.CSSProperties
  innerStyle?: React.CSSProperties
  children: React.ReactNode
}): JSX.Element => (
  <div
    id={id}
    className="base-outer-container"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      ...outerStyle,
    }}
  >
    <div
      className="base-inner-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '960px',
        margin: '0 auto',
        maxWidth: '100%',
        ...innerStyle,
      }}
    >
      {children}
    </div>
  </div>
)
export default BaseContainer
