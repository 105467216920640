import type { ThemeConfig } from 'antd'
import { blue, colorError, colorSuccess, colorWarning, lightlightGrey } from '@teamspective/common'

const theme: ThemeConfig = {
  token: {
    colorPrimary: blue,
    colorInfo: blue,
    colorSuccess,
    colorWarning,
    colorError,
    colorTextBase: '#1a1513',
    colorBorder: lightlightGrey,
    colorBorderSecondary: lightlightGrey,
    colorBgBase: '#ffffff',
    fontFamily:
      "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';",
    fontFamilyCode:
      "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';",
    fontSize: 14,
    lineWidth: 1,
    lineType: 'solid',
    borderRadius: 6,
    controlHeight: 32,
    colorTextPlaceholder: 'rgba(0,0,0,0.59)',
    colorTextDisabled: 'rgba(0,0,0,0.59)',
    colorWarningBg: '#ffe58f',
  },
  components: {
    Tag: { defaultBg: 'rgba(0,0,0,0.04)' },
    Slider: { dotSize: 4 },
    Timeline: { dotBg: 'inherit' },
    Avatar: {
      containerSize: 28,
      textFontSize: 12,
      containerSizeSM: 16,
      textFontSizeSM: 8,
      groupBorderColor: '#fffffff00',
    },
    Table: {
      cellPaddingInline: 12,
    },
    Tabs: {
      horizontalMargin: '0 0 24px 0',
    },
  },
}

export default theme
