import type { DateString } from '../date.ts'
import type { UserId, NetworkSurveyRoundId, TeamId } from '../ids.ts'
import type { User } from '../user.ts'

const collaborationLayerNames = ['collaborateWeekly', 'collaborateLessThanWeekly'] as const

export const recognitionLayerNames = [
  'advice',
  'agility',
  'information',
  'excellence',
  'clarity',
  'development',
  'energy',
  'trust',
] as const

export const recognitionLayerToArea: Record<RecognitionLayerName, NetworkRecognitionArea> = {
  information: 'leadership',
  clarity: 'leadership',
  agility: 'leadership',
  development: 'leadership',
  excellence: 'execution',
  advice: 'execution',
  energy: 'culture',
  trust: 'culture',
}

export type RecognitionLayerName = (typeof recognitionLayerNames)[number]

export const networkLayerNames = [...collaborationLayerNames, ...recognitionLayerNames] as const

export const isRecognitionLayerName = (n: string): n is RecognitionLayerName =>
  recognitionLayerNames.includes(n as RecognitionLayerName)

export type NetworkLayerName = (typeof networkLayerNames)[number]

export const feedbackActivationQuestionnaireLayers: NetworkLayerName[] = [
  'collaborateWeekly',
  'advice',
  'agility',
]

export const networkRecognitionAreaNames = ['leadership', 'culture', 'execution'] as const

export const requiredNetworkSurveyQuestionnaireLayers = [
  ...feedbackActivationQuestionnaireLayers,
] as const
export const isRequiredNetworkSurveyQuestion = (layer: NetworkLayerName) =>
  requiredNetworkSurveyQuestionnaireLayers.includes(layer)

export const surveyMetricNames = ['influence', 'demand'] as const
export type SurveyMetric = (typeof surveyMetricNames)[number]

export const networkRecognitionMetrics = [
  ...networkRecognitionAreaNames,
  ...surveyMetricNames,
] as const
export type NetworkRecognitionMetric = (typeof networkRecognitionMetrics)[number]
export type NetworkRecognitionArea = (typeof networkRecognitionAreaNames)[number]

export type NetworkSurveyTie = {
  layer: NetworkLayerName
  srcUserId: UserId
  destUserId: UserId
  source: string
  surveyRoundId: NetworkSurveyRoundId | null
  weight?: number
}

export type CollaborationTie = {
  source: number
  target: number
  weight: number
  significance: number
}

export type NetworkRole = {
  type: 'leadership' | 'culture' | 'execution' | 'connector'
  userId: UserId
  teamId: TeamId
}

export type NetworkRoleType = NetworkRole['type']

type NetworkScore = {
  score: number
  percentile: number
}

export type UserNetworkRecognitionScores = {
  userId: UserId
  influence: NetworkScore
  demand: NetworkScore
  recognitionAreas: Record<NetworkRecognitionArea, NetworkScore>
  recognitionLayers: Record<RecognitionLayerName, NetworkScore>
}

export type UserNetworkMetrics = {
  userId: UserId
  teamIds: TeamId[]
  roles: NetworkRole[]
} & UserNetworkRecognitionScores

export type RawUserNetworkMetrics = {
  userId: UserId
  teamIds: TeamId[]
  roles: NetworkRole[]
  influence: number
  demand: number
  recognitionAreas: Record<NetworkRecognitionArea, number>
  recognitionLayers: Record<RecognitionLayerName, number>
}

export type TeamNetworkConnection = { weight: number; significance: number; unique: number }
export type TeamNetworkScore = {
  [teamId: TeamId]: { [teamId: TeamId]: TeamNetworkConnection }
}

export const extractMetric = (
  metrics: UserNetworkMetrics,
  metric: NetworkRecognitionMetric
): NetworkScore =>
  metric === 'influence' || metric === 'demand' ? metrics[metric] : metrics.recognitionAreas[metric]

export type NetworkMetrics = {
  teams: TeamNetworkScore
  users: UserNetworkMetrics[]
  collaborationTies: CollaborationTie[]
  incomingTies: Partial<Record<number, NetworkSurveyTie[]>>
}

type ConnectionReason =
  | { type: 'feedback-providing'; when: DateString }
  | { type: 'feedback-receiving'; when: DateString }
  | { type: 'praise-providing'; when: DateString }
  | { type: 'praise-receiving'; when: DateString }
  | { type: 'network-in'; layer: NetworkLayerName; when: DateString }
  | { type: 'network-out'; layer: NetworkLayerName; when: DateString }
  | { type: 'workspace'; workspaceId: TeamId }
  | { type: 'team'; teamId: TeamId }

export type UserConnection = {
  user: User
  reasons: ConnectionReason[]
  lastContact: DateString
}

export type NetworkSurveyRound = {
  networkSurveyRoundId: NetworkSurveyRoundId
  teamId: TeamId
  begin: DateString
  end: DateString
}

export type NetworkQuestionnaire = {
  layers: { layer: NetworkLayerName; required: boolean }[]
  round: NetworkSurveyRound | null
}
