import type { Language } from '@teamspective/common'
import { fullLanguages, languages } from '@teamspective/common'
import { languageTranslations } from '@teamspective/common'
import { translations } from '@teamspective/common'
import type { SelectProps } from 'antd'
import { Select } from 'antd'

type LanguageSelectorProps = {
  lang: Language
  onChange: (lang: Language) => void
  style?: React.CSSProperties
  variant?: SelectProps['variant']
}

const LanguageSelector = ({ lang, onChange, style, variant }: LanguageSelectorProps) => {
  return (
    <Select
      variant={variant}
      style={style}
      value={lang}
      onChange={onChange}
      options={languages.map((langValue) => ({
        value: langValue,
        label: `${languageTranslations[lang][langValue]}${
          !(fullLanguages as readonly Language[]).includes(langValue)
            ? ` (${translations[lang].surveysOnly})`
            : ''
        }`,
      }))}
    />
  )
}

export default LanguageSelector
