import { TinyColor } from '@ctrl/tinycolor'
import { memoize } from '@teamspective/common'

const hashInputToDegrees = (input: string | number): number => {
  const plaintext = `=${String(input)}a`
  const seed = 131
  const seed2 = 137
  let hash = 0

  const MAX_SAFE_INTEGER = parseInt(String(9007199254740991 / seed2))
  for (let i = 0; i < plaintext.length; i++) {
    if (hash > MAX_SAFE_INTEGER) {
      hash = parseInt(String(hash / seed2))
    }
    hash = hash * seed + plaintext.charCodeAt(i)
  }
  return hash
}

export const getTagColors = memoize(
  (vibrantBaseColor: string): { textColor: string; opaqueColor: string } => {
    const opaqueColor = new TinyColor(vibrantBaseColor).setAlpha(0.5).toRgbString()
    const textColor = new TinyColor(vibrantBaseColor).shade(60).toHex8String()
    return { textColor, opaqueColor }
  }
)

export const tinted = memoize((baseColor: string): string =>
  new TinyColor(baseColor).tint(20).toHex8String()
)

export const getDarkBackgroundColor = memoize((baseColor: string): string =>
  new TinyColor({ ...new TinyColor(baseColor).toHsl(), l: 0.3 }).toRgbString()
)

export const userColor = memoize((value: number | string): string => {
  const hash = hashInputToDegrees(value)
  return new TinyColor({ h: (hash % 199) + 50, s: 0.51, l: 0.65 }).toHexString()
})

export const teamColor = memoize((value: number | string): string => {
  const hash = hashInputToDegrees(value)
  return new TinyColor({ h: (hash % 199) + 50, s: 0.28, l: 0.6 }).toHexString()
})

export const clusterColor = memoize((value: number | string): string => {
  const hash = hashInputToDegrees(value)
  return new TinyColor({ h: (hash % 150) + 150, s: (hash % 20) / 100 + 0.5, l: 0.62 }).toHexString()
})

export const topicColor = memoize((value: number | string): string => {
  const hash = hashInputToDegrees(value)
  return new TinyColor({ h: 140 + (hash % 160), s: 0.4, l: 0.8 }).toHexString()
})
