import { translations } from '../translations.ts'
import type {
  CompleteUser,
  IncompleteUser,
  ToBeInvitedUser,
  User,
  LimitedUser,
  UnlimitedUser,
} from '../types/user.ts'

export const languages = [
  'fi',
  'en',
  'sv',
  'no',
  'dk',
  'es',
  'it',
  'de',
  'fr',
  'pl',
  'cn',
  'uk',
] as const
export type Language = (typeof languages)[number]

export const isLanguage = (lang: string): lang is Language => languages.includes(lang as Language)

export const fullLanguages = ['en', 'fi'] as const satisfies readonly Language[]
export type FullLanguage = (typeof fullLanguages)[number]

export const fullLanguage = (lang: Language): FullLanguage => (lang === 'fi' ? lang : 'en')

export const languageNames = {
  en: 'English',
  fi: 'suomi',
  sv: 'svenska',
  es: 'español',
  it: 'italiano',
  de: 'Deutsch',
  fr: 'français',
  pl: 'polski',
  cn: '中文',
  uk: 'українська',
} as const

export const userLang = (
  user:
    | CompleteUser
    | IncompleteUser
    | LimitedUser
    | { status: 'loading' }
    | { status: 'logged-out' },
  fallback: Language = 'en'
): Language => {
  if (user.status === 'incomplete' || user.status === 'loading' || user.status === 'logged-out') {
    return fallback
  }
  return user.language
}

export const userFirstName = (user: CompleteUser | IncompleteUser | LimitedUser): string => {
  if (user.status === 'incomplete') {
    return user.email
  }
  return user.firstName
}

export const userFullName = (user: CompleteUser | IncompleteUser | LimitedUser): string => {
  if (user.status === 'incomplete') {
    return user.email
  }
  return `${user.firstName} ${user.lastName}`
}

export const isSameUser = (
  a: ToBeInvitedUser | User | null,
  b: ToBeInvitedUser | User | null
): boolean => {
  if (a === null || b === null) {
    return false
  }
  if (Boolean(a.email) && Boolean(b.email) && a.email === b.email) {
    return true
  }
  if (a.status !== 'to-be-invited' && b.status !== 'to-be-invited') {
    return a.userId === b.userId
  }
  return false
}

export const getDomainForEmail = (email: string): string => {
  const [, domain] = email.toLowerCase().split('@')
  if (!domain) {
    throw new Error('Invalid email address')
  }
  return domain
}

export const maybeAnonymousUserName = ({
  user,
  lang,
}: {
  user: ToBeInvitedUser | User | 'anonymous' | null
  lang: Language
}): string => {
  if (user === 'anonymous') {
    return translations[lang].anonymous
  }
  return userName(user)
}

export const userName = (user: ToBeInvitedUser | User | null): string => {
  if (!user) {
    return ''
  }
  switch (user.status) {
    case 'to-be-invited':
      return user.email
    case 'incomplete':
      return user.email
    case 'complete':
    case 'limited':
      return `${user.firstName} ${user.lastName}`
  }
}

export const userNameWithEmail = (user: ToBeInvitedUser | User): string => {
  switch (user.status) {
    case 'to-be-invited':
    case 'incomplete':
    case 'limited':
      return userName(user)
    case 'complete':
      return `${userName(user)} (${user.email})`
  }
}

export const fullNameToFirstNameLastName = (
  name: string
): { firstName: string; lastName: string } => {
  const splitName = name.split(' ')
  if (splitName.length === 1) {
    return { firstName: splitName[0], lastName: '' }
  }
  if (splitName.length > 1) {
    return { firstName: splitName[0], lastName: splitName.slice(1).join(' ') }
  }
  return { firstName: name, lastName: '' }
}

export const userIsUnlimited = (u: User): u is UnlimitedUser => u.status !== 'limited'

const getLastName = (user: User): string | null =>
  user.status !== 'incomplete' ? user.lastName : null

export const sortUsersByLastNameWithLocales =
  (locales?: string | string[]) => (a: User, b: User) => {
    const aLastName = getLastName(a)
    const bLastName = getLastName(b)
    if (!aLastName && !bLastName) {
      return userName(a).localeCompare(userName(b), locales)
    }
    // Users without a last name (i.e. without a profile) go last in the list
    if (!aLastName) {
      return 1
    }
    if (!bLastName) {
      return -1
    }
    // Break ties by using full name
    return (
      aLastName.localeCompare(bLastName, locales) || userName(a).localeCompare(userName(b), locales)
    )
  }

export const sortUsersByLastName = sortUsersByLastNameWithLocales()

export const sortMaybeAnonymousUsersByLastName = (a: User | 'anonymous', b: User | 'anonymous') => {
  if (a === 'anonymous' && b === 'anonymous') {
    return 0
  }
  if (a === 'anonymous') {
    return 1
  }
  if (b === 'anonymous') {
    return -1
  }
  return sortUsersByLastName(a, b)
}
