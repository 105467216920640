import {
  getYear,
  getMonth,
  getDate,
  getHours,
  getMinutes,
  isDate as libIsDate,
  parseISO,
} from 'date-fns'
import {
  fromZonedTime,
  toZonedTime,
  getTimezoneOffset as dateFnsGetTimezoneOffset,
} from 'date-fns-tz'

import type { TzDatabaseName } from '../timezones.ts'
import type { DateString, Day, DayTime, Time } from '../types/date.ts'
import { leftPadNumber } from '../util.ts'

export const dateToDay = (date: Date, tz: TzDatabaseName | null): Day => {
  const zonedTime = tz === null ? date : toZonedTime(date, tz)
  return {
    year: getYear(zonedTime),
    month: getMonth(zonedTime),
    date: getDate(zonedTime),
  }
}

export const dateToTime = (date: Date, tz: TzDatabaseName): Time => {
  const zonedTime = toZonedTime(date, tz)
  return {
    hours: getHours(zonedTime),
    minutes: getMinutes(zonedTime),
    tz,
  }
}

export const dateToDayTime = (date: Date, tz: TzDatabaseName): DayTime => ({
  ...dateToDay(date, tz),
  ...dateToTime(date, tz),
})

const MS_IN_MINUTE = 1000 * 60

export const getTzOffsetMinutes = (tz: TzDatabaseName, d: Date = new Date()): number =>
  Math.round(dateFnsGetTimezoneOffset(tz, d) / MS_IN_MINUTE)

export const timeToString = ({ hours, minutes }: { hours: number; minutes: number }): string => {
  if (hours > 24 || minutes > 60) {
    throw new Error(`Invalid time: hours: ${hours} minutes: ${minutes}`)
  }
  return `${leftPadNumber(hours)}:${leftPadNumber(minutes)}`
}

export const stringToTime = (timeString: string, tz: TzDatabaseName): Time => {
  const [hourStr, minuteStr] = timeString.split(':')
  return { hours: Number(hourStr), minutes: Number(minuteStr), tz }
}

export const dayTimeToDate = ({ year, month, date, hours, minutes, tz }: DayTime): Date => {
  return fromZonedTime(
    `${year}-${leftPadNumber(month + 1)}-${leftPadNumber(date)} ${timeToString({
      hours,
      minutes,
    })}`,
    tz
  )
}

export const dayToDate = (day: Day): Date => {
  // We only care about the day, setting the time to noon to avoid timezone mishaps
  return dayTimeToDate({ ...day, hours: 12, minutes: 0, tz: 'Etc/UTC' })
}

export const dateToString = (date: Date): DateString => date.toISOString() as DateString

const isDate = (d: unknown): d is Date => libIsDate(d)

export const toDate = (d: Date | DateString): Date => {
  if (isDate(d)) {
    return d
  }
  return parseISO(d)
}
