import { average, isNotNull, mapRange, round, unique } from '../util.ts'
import type { ClientPayloadUser, User } from './user.ts'
import type { DateString } from './date.ts'
import type {
  EvaluationQuestionScale,
  EvaluationQuestionTag,
  MetaEvaluationAnswerReason,
  Question,
  QuestionScale,
  TranslationString,
} from './question.ts'
import type { Score } from './stats.ts'
import type { EvaluationAnswerAnalysisWithId } from './evaluationAnswerAnalysis.ts'
import type { Branded } from './common.ts'
import type { SendNotificationResult } from './notifications.ts'
import type {
  EvaluationId,
  EvaluationTemplateId,
  TeamId,
  QuestionId,
  EvaluationThemeId,
  UserId,
} from './ids.ts'

export type EvaluationRole = 'evaluator' | 'evaluee'

export const evaluationTypes = ['employee', 'manager', 'peer', 'other', 'self', 'external'] as const
export type EvaluationType = (typeof evaluationTypes)[number]
export type PendingEvaluationDescriptor = {
  evaluationId: EvaluationId
  evaluationType: EvaluationType
  evaluationTemplateId: EvaluationTemplateId
  requested: DateString
}
export const parseEvaluationType = (value: string): EvaluationType => {
  if (!(evaluationTypes as ReadonlyArray<string>).includes(value)) {
    throw Error(`Invalid EvaluationType ${value}`)
  }
  return value as EvaluationType
}

export type BaseEvaluation = {
  evaluationId: EvaluationId
  teamId: TeamId
  evaluator: User
  evaluee: User
  requested: DateString
  evaluationTemplateId: EvaluationTemplateId
  evaluationType: EvaluationType
  extended: boolean
  expires: DateString
}

type BaseEvaluationAnswer = {
  value: number | string
  reason: 'answered' | MetaEvaluationAnswerReason
  questionId: QuestionId
  evaluationId: EvaluationId
  redacted: boolean
  scale: EvaluationQuestionScale
}

export type EvaluationAnswer = {
  answerType: 'answered' | 'skipped'
} & BaseEvaluationAnswer

export type DraftedEvaluationAnswer = {
  answerType: 'drafted'
} & BaseEvaluationAnswer

export type RequestedEvaluation = {
  type: 'requested'
  requestSkipped: DateString | null
  expires: DateString
} & BaseEvaluation

export type DraftEvaluation = {
  type: 'draft'
  updated: DateString
  answers: (EvaluationAnswer | DraftedEvaluationAnswer)[]
  expires: DateString
} & BaseEvaluation

export type SingleRequestedEvaluationPayload = {
  evalueeUserId: UserId
  evaluator: ClientPayloadUser
  evaluationTemplateId: EvaluationTemplateId
}

export type InsertedEvaluationDescriptor = {
  evaluationId: EvaluationId
  evaluationTemplateId: EvaluationTemplateId
  evaluatorUserId: UserId
  evalueeUserId: UserId
}

export type PendingEvaluation = RequestedEvaluation | DraftEvaluation
export const isPendingEvaluation = (evaluation: Evaluation): evaluation is PendingEvaluation =>
  evaluation.type === 'requested' || evaluation.type === 'draft'

export type ProvidedEvaluationVisibility = 'published' | 'not-published'

export type ProvidedEvaluation = {
  type: 'provided'
  provided: DateString
  analysed: boolean
  answers: EvaluationAnswer[]
  visibility: ProvidedEvaluationVisibility
} & BaseEvaluation

// View of a provided evaluation in frontend, evaluator possibly anonymized in
// case user is viewing their own evaluations. Type is nominal to ensure that
// plain ProvidedEvaluation is not passed to frontend.
export type FilteredEvaluationView<T extends BaseEvaluation> = Branded<
  T & { evaluator: User | 'anonymous' },
  'EvaluationView'
>
export type ProvidedEvaluationView = FilteredEvaluationView<ProvidedEvaluation>
export type RequestedEvaluationView = FilteredEvaluationView<RequestedEvaluation>
export type DraftEvaluationView = FilteredEvaluationView<DraftEvaluation>

export type Evaluation = RequestedEvaluation | DraftEvaluation | ProvidedEvaluation
export type EvaluationView = RequestedEvaluationView | DraftEvaluationView | ProvidedEvaluationView

// This is an attempt for a new better evaluation type that would only be used
// to display information related to the *request*, never results. The intent is
// to use it for admin visibility to the evaluation progress. The idea is that the
// EvaluationRequest never needs to be redacted.
export type EvaluationRequest = {
  type: 'requested' | 'provided'
  evaluationId: EvaluationId
  teamId: TeamId
  evaluee: User
  evaluator: User
  evaluationTemplateId: EvaluationTemplateId
  evaluationType: EvaluationType
  expires: DateString
  requested: DateString
  provided: DateString | null
  extended: boolean
  evaluationLastNotified: null | {
    at: DateString
    medium: SendNotificationResult
  }
}

export type EvaluationTemplate = {
  evaluationTemplateId: EvaluationTemplateId
  name: TranslationString
  message: TranslationString | undefined
  type: EvaluationType
  teamId: TeamId
  created: DateString
  updated: DateString
  archived: DateString | null
  questions: {
    questionId: QuestionId
    required: boolean
  }[]
}

export type UserAssignedEvaluation = {
  evalueeUserId: UserId
  evaluatorUserId: UserId
  type: EvaluationType
}

export type AvailableEvaluees = {
  users: User[]
  suggestions: UserAssignedEvaluation[]
}

export type EvaluationSummary = {
  summaryText: string | null
  since: DateString
  themes: EvaluationThemeSummary[]
  questions: EvaluationNumericQuestionSummary[]
  selfEvaluations: ProvidedEvaluation[]
  analysisComplete: boolean
  analysis: EvaluationAnswerAnalysisWithId[]
  comments: {
    questionId: QuestionId
    evaluationType: EvaluationType
    comment: string
  }[]
}

export type FullEvaluationThemeSummary = {
  average: Score
  theme: EvaluationTheme
  evaluationType: 'all'
  workspaceAverage: Score
}

export type TypeEvaluationThemeSummary = {
  average: Score
  theme: EvaluationTheme
  evaluationType: EvaluationType
}

export type EvaluationThemeSummary = FullEvaluationThemeSummary | TypeEvaluationThemeSummary

export type EvaluationNumericQuestionSummary = {
  questionId: QuestionId
  evaluationTemplateId: EvaluationTemplateId
  average: Score
}

export const evaluationDefaultQuestionTags: Record<EvaluationType, EvaluationQuestionTag[]> = {
  employee: [
    'evaluation_employee_collaboration_a',
    'evaluation_employee_performance_a',
    'evaluation_employee_promotion_a',
    'evaluation_employee_risk_a',
    'evaluation_open',
  ],
  manager: [
    'evaluation_manager_collaboration_a',
    'evaluation_manager_performance_a',
    'evaluation_manager_performance_b',
    'evaluation_manager_performance_c',
    'evaluation_open',
  ],
  peer: ['evaluation_peer_collaboration_a', 'evaluation_peer_performance_a', 'evaluation_open'],
  self: ['evaluation_self_collaboration_a', 'evaluation_self_performance_a', 'evaluation_open'],
  other: [],
  external: [],
}

export type EvaluationTheme = {
  evaluationThemeId: EvaluationThemeId
  teamId: TeamId | null
  content: {
    name: TranslationString
  }
  evaluationThemeTag: EvaluationThemeTag | null
  questionIds: QuestionId[]
}

const mapEvaluationAnswerToScore = (
  answer: EvaluationAnswer,
  scale: QuestionScale
): number | null => {
  switch (scale) {
    case 'linear7':
      return mapRange([1, 7], [1, 10], Number(answer.value))
    case 'linear5':
      return mapRange([1, 5], [1, 10], Number(answer.value))
    case 'linear4':
      return mapRange([1, 4], [1, 10], Number(answer.value))
    case 'balance5':
    case 'binary':
    case 'custom':
    case 'nps':
    case 'written':
    default:
      return null
  }
}

export const calculateEvaluationThemeResult = (
  evaluations: ProvidedEvaluationView[],
  theme: EvaluationTheme,
  questions: Question[]
): number | null => {
  const themeAnswers = evaluations.flatMap((e) =>
    e.answers.filter((a) => theme.questionIds.includes(a.questionId) && a.reason === 'answered')
  )
  const scores = themeAnswers
    .map((answer) => {
      const question = questions.find((q) => q.questionId === answer.questionId)
      if (!question) {
        throw Error(
          `Could not calculate evaluation theme score due to missing question ${answer.questionId}`
        )
      }
      return mapEvaluationAnswerToScore(answer, question.scale)
    })
    .filter(isNotNull)
  return scores.length ? round(average(scores), 1) : null
}

export const calculateEvaluationQuestionResult = (
  evaluations: ProvidedEvaluationView[],
  question: Question
) => {
  const answers = evaluations.flatMap((e) =>
    e.answers.filter((a) => a.questionId === question.questionId && a.reason === 'answered')
  )
  const scores = answers.flatMap((answer) => {
    return question.scale === 'linear7'
      ? mapRange([1, 7], [1, 10], Number(answer.value))
      : question.scale === 'linear5'
        ? mapRange([1, 5], [1, 10], Number(answer.value))
        : question.scale === 'linear4'
          ? mapRange([1, 4], [1, 10], Number(answer.value))
          : []
  })
  return scores.length ? round(average(scores), 1) : null
}

export const calculateEvaluationThemeResultFromAnswers = (
  answers: EvaluationAnswer[],
  questions: Question[]
): number | null => {
  const scores = answers
    .map((answer): number | null => {
      if (answer.reason !== 'answered') {
        return null
      }
      const question = questions.find((q) => q.questionId === answer.questionId)
      if (!question) {
        throw Error(
          `Could not calculate evaluation theme score due to missing question ${answer.questionId}`
        )
      }
      return mapEvaluationAnswerToScore(answer, question.scale)
    })
    .filter(isNotNull)

  return scores.length ? round(average(scores), 1) : null
}

export const getActiveThemes = (
  evaluationThemes: EvaluationTheme[],
  evaluationTemplates: EvaluationTemplate[]
) => {
  const questionnaireQuestions = unique(
    evaluationTemplates.flatMap((template) => template.questions.map((q) => q.questionId))
  )
  return evaluationThemes.filter((theme) =>
    theme.questionIds.some((themeQuestionId) => questionnaireQuestions.includes(themeQuestionId))
  )
}

export const evaluationRemindersDaysBefore = [7, 2]

export const draftEvaluationToRequested = (evaluation: DraftEvaluation): RequestedEvaluation => {
  return {
    ...evaluation,
    type: 'requested',
    requestSkipped: null,
  }
}

export const evaluationThemeTags = ['performance', 'collaboration'] as const
export type EvaluationThemeTag = (typeof evaluationThemeTags)[number]
