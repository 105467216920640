import { Button as AntButton } from 'antd'
import React from 'react'

type Props = Omit<React.ComponentProps<typeof AntButton>, 'onClick'> & {
  onClick: (e: React.MouseEvent<HTMLElement>) => Promise<unknown>
}

const SubmitButton = (props: Props) => {
  const [loading, setLoading] = React.useState(false)
  return (
    <AntButton
      {...props}
      loading={loading || props.loading}
      onClick={async (e) => {
        setLoading(true)
        try {
          await props.onClick(e)
        } finally {
          setLoading(false)
        }
      }}
    />
  )
}

export default SubmitButton
