import type React from 'react'
import { useParams } from 'react-router-dom'

import { api } from '../../api'
import CodeLoginForm from '../../components/user/CodeLoginForm'
import { translations } from '@teamspective/common'
import LoginPageContainer from './LoginPageContainer'

const CodeLoginPage: React.FunctionComponent = () => {
  const { workspaceLoginCode } = useParams<{ workspaceLoginCode: string }>()

  return (
    <LoginPageContainer type="code" id="page-login">
      <h3>{translations.en.loginWithCode}</h3>
      <CodeLoginForm
        onSubmit={async ({ code }: { code: string }) => {
          const response = await api.codeLogin({
            loginCode: code,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            workspaceLoginCode: workspaceLoginCode!,
          })
          window.location.replace(`?state=${response.data.status}`)
        }}
      />
    </LoginPageContainer>
  )
}

export default CodeLoginPage
