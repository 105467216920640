import { unique } from '../../util.ts'
import type { UserId, TeamId } from '../ids.ts'

import type { AbstractGraph, SerializedGraph } from 'graphology-types'
import { surveyMetricNames } from './survey.ts'
import type { ActivityType, PassiveNetworkMetric, PassiveIdentity } from './passive.ts'
import { passiveNetworkMetrics } from './passive.ts'

export const networkSnapshotTypes = ['passive', 'survey'] as const
export type NetworkSnapshotType = (typeof networkSnapshotTypes)[number]

export const networkMetrics = unique([...passiveNetworkMetrics, ...surveyMetricNames] as const)
export type NetworkMetric = (typeof networkMetrics)[number]

/**
 * SigmaJS node attributes.
 *
 * https://www.sigmajs.org/docs/advanced/data/
 */
type SigmaNode = {
  x?: number
  y?: number
  /**
   * How the node should be rendered.
   * The value must match one of `nodeProgramClasses`,
   * otherwise a error will be thrown at render time.
   */
  type?: 'circle' | 'square'
  size?: number
  color?: string
  label?: string
  hidden?: boolean
  forceLabel?: boolean
  zIndex?: number
}

/**
 * SigmaJS edge attributes.
 *
 * https://www.sigmajs.org/docs/advanced/data/
 */
type SigmaEdge = {
  size?: number
  color?: string
  label?: string
  /**
   * How the edge should be rendered.
   * The value must match one of `edgeProgramClasses`,
   * otherwise a error will be thrown at render time.
   */
  type?: 'line' | 'arrow' | 'curve'
  hidden?: boolean
  forceLabel?: boolean
  zIndex?: number
}

type BaseNodeAttributes = {
  /** Amount of people in the node. Not set for individual person nodes (size 1) */
  size?: number
  /** Helper to narrow down from which kind of graph the node comes from */
  graphGrouping: GraphGrouping
} & SigmaNode

type BaseEdgeAttributes = {
  // TODO: should this be weight? Or there be specialized attributes for survey/passive?
  activity: number
  /**
   * Significance of the edge. Calculated using disparity filter with 0-100 value.
   * Nodes always have their most active edge as 100 significance.
   *
   * See `calculateSignificance` for details.
   */
  significance: number
  preference: number
  /** Helper to narrow down from which kind of graph the edge comes from */
  graphGrouping: GraphGrouping
} & SigmaEdge

/**
 * Source from which specific network graph types are derived per source and per grouping.
 *
 * Remember to bump `SerializedNetworkSnapshotLastFourMonths` for passive schema changes
 * and `NetworkSurveySnapshotCache` for survey schema changes.
 */
type NetworkAttributeTypeMap = {
  passive: {
    grouping: {
      person: {
        node: {
          graphGrouping: 'person'
          totalAffordance: number
          totalActivity: number
          activityByType: Record<ActivityType, number>
          clusterId: number
          demand: number
        } & { [metric in PassiveNetworkMetric]: number } & PassiveIdentity &
          BaseNodeAttributes
        edge: BaseEdgeAttributes & {
          graphGrouping: 'person'
          affordance: number
        }
      }
      team: {
        node: {
          graphGrouping: 'team'
          size: number
          teamId: TeamId
          /** Parent team ID. Can point to another team or the workspace. Not set for workspace itself. */
          parentTeamId?: TeamId
          collaborationCentrality: number
        } & BaseNodeAttributes
        edge: BaseEdgeAttributes & {
          graphGrouping: 'team'
        }
      }
      cluster: {
        node: BaseNodeAttributes & {
          graphGrouping: 'cluster'
          size: number
          clusterId: number
          collaborationCentrality: number
        }
        edge: BaseEdgeAttributes
      }
    }
    graph: {
      graphGrouping: 'person' | 'team' | 'cluster'
      graphSource: 'passive'
    }
  }
  survey: {
    grouping: {
      person: {
        node: {
          graphGrouping: 'person'
          teamIds: TeamId[]
          clusterId?: number
          userId: UserId
          influence: number
          engagement: number
        } & BaseNodeAttributes
        edge: BaseEdgeAttributes & {
          graphGrouping: 'person'
        }
      }
      team: {
        node: {
          graphGrouping: 'team'
          teamId: TeamId
          /** Parent team ID. Can point to another team or the workspace. Not set for workspace itself. */
          parentTeamId?: TeamId
        } & BaseNodeAttributes
        edge: BaseEdgeAttributes & {
          graphGrouping: 'team'
        }
      }
      cluster: {
        node: {
          graphGrouping: 'cluster'
          clusterId: number
        } & BaseNodeAttributes
        edge: BaseEdgeAttributes & {
          graphGrouping: 'cluster'
        }
      }
    }
    graph: {
      graphGrouping: GraphGrouping
      graphSource: 'survey'
    }
  }
}

export type GraphSource = keyof NetworkAttributeTypeMap
export type GraphGrouping = keyof NetworkAttributeTypeMap[GraphSource]['grouping']
export type NodeAttributes<
  Grouping extends GraphGrouping = GraphGrouping,
  Source extends GraphSource = GraphSource,
> = NetworkAttributeTypeMap[Source]['grouping'][Grouping]['node']
export type EdgeAttributes<
  Grouping extends GraphGrouping = GraphGrouping,
  Source extends GraphSource = GraphSource,
> = NetworkAttributeTypeMap[Source]['grouping'][Grouping]['edge']
export type GraphAttributes<
  Source extends GraphSource = GraphSource,
  Grouping extends GraphGrouping = GraphGrouping,
> = NetworkAttributeTypeMap[Source]['graph'] & {
  graphGrouping: Grouping
}

export type NetworkGraph<
  Grouping extends GraphGrouping = GraphGrouping,
  Source extends GraphSource = GraphSource,
> = AbstractGraph<
  NodeAttributes<Grouping, Source>,
  EdgeAttributes<Grouping, Source>,
  GraphAttributes<Source, Grouping>
>

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type AsSerialized<G extends AbstractGraph<{}, {}, {}>> =
  G extends AbstractGraph<infer NodeAttrs, infer EdgeAttrs, infer GraphAttrs>
    ? SerializedGraph<NodeAttrs, EdgeAttrs, GraphAttrs>
    : never

export type SerializedNetworkSnapshot<Source extends GraphSource = GraphSource> = {
  graphSource: Source
} & {
  [Grouping in GraphGrouping]: AsSerialized<NetworkGraph<Grouping, Source>>
}

/**
 * Check if a graph is a specific network graph.
 */
export const isGraphOfType = <
  Grouping extends GraphGrouping = GraphGrouping,
  Source extends GraphSource = GraphSource,
>(
  graph: AbstractGraph<
    Record<string, unknown>,
    Record<string, unknown>,
    { graphGrouping: string; graphSource: string }
  >,
  {
    source,
    grouping,
  }: {
    source?: Source
    grouping?: Grouping
  }
): graph is NetworkGraph<Grouping, Source> => {
  const graphAttributes = graph.getAttributes()
  return (
    (!source || graphAttributes.graphSource === source) &&
    (!grouping || graphAttributes.graphGrouping === grouping)
  )
}
