import type { DetailedWorkspace, Stage, TeamId, WorkspaceClientId } from './types/index.ts'

export const FREE_WORKSPACE_SIZE = 30
export const contactEmail = 'hello@teamspective.com'

export const SLACK_CLIENT_ID = {
  dev: '919111044883.932443403799',
  staging: '919111044883.1133608330085',
  production: '919111044883.1141197560740',
} as const

export const SLACK_ONA_CLIENT_ID = {
  dev: '919111044883.6183527043223',
  staging: '919111044883.6200520395428',
  production: '919111044883.6200416274692',
} as const

export const HEAP_APP_ID = {
  dev: '',
  staging: '830716044',
  production: '93293874',
} as const satisfies Record<Stage, string>

export const PYLON_APP_ID = 'e6ed6f14-484c-428c-9629-75465bdba8f2'

export const BASE_URL = {
  dev: 'http://localhost:8080',
  staging: 'https://demo.teamspective.com',
  production: 'https://app.teamspective.com',
} as const satisfies Record<Stage, string>

export const SLACK_BASE_URL = {
  ...BASE_URL,
  dev: 'https://slack-dev.teamspective.com',
} as const satisfies Record<Stage, string>

export const GOOGLE_CLIENT_ID =
  '121998926571-1lotq405bp8otglcb3r0kd5ng4u6uebh.apps.googleusercontent.com'

export const MS_TEAMS_APP_ID = {
  dev: 'd39c5508-d411-461e-99ce-890a6637287d',
  staging: '9f588dbd-ef54-41de-bcf5-40f7af9b8cc9',
  production: '446996aa-d227-4749-bd35-0fdf51c88270',
} as const satisfies Record<Stage, string>

export const MS_TEAMS_ONA_APP_ID = {
  dev: 'd53130e7-646c-40e1-bc5c-b4960d24680b',
  staging: '5f1dd8cb-19cb-4e44-a7a0-0da5cf7b2caf',
  production: '58d8caa7-11eb-4d86-bada-34380037e8e2',
} as const satisfies Record<Stage, string>

export const GOOGLE_ONA_SERVICE_USER_USER_ID = '107410831817981079594'
export const GOOGLE_ONA_OAUTH2_SCOPES = [
  'https://www.googleapis.com/auth/admin.directory.user.readonly',
  'https://www.googleapis.com/auth/calendar.readonly',
  'https://www.googleapis.com/auth/calendar.events.readonly',
] as const

export type SlackOauthIntent = 'login' | 'addToSlack' | 'addOnaToSlack'

const SlackLoginScopes = ['openid', 'email', 'profile'] as const

export const allSlackScopes = [
  'channels:read',
  'chat:write',
  'chat:write.public',
  'chat:write.customize',
  'commands',
  'im:history',
  'im:read',
  'im:write',
  'team:read',
  'users:read',
  'users:read.email',
  'users:write',
  'groups:read',
  'groups:write',
  'groups:history',
  'channels:history',
  'channels:read',
] as const

export type SlackScope = (typeof allSlackScopes)[number]

export const discussionSlackSyncScopes: SlackScope[] = [
  'chat:write.customize',
  'groups:read',
  'groups:write',
  'groups:history',
]

export const addToSlackScopes: SlackScope[] = [
  'channels:read',
  'chat:write',
  'chat:write.public',
  'commands',
  'im:history',
  'im:read',
  'im:write',
  'team:read',
  'users:read',
  'users:read.email',
  'users:write',
  ...discussionSlackSyncScopes,
]

export const workspaceHasDiscussionSlackSync = (workspace: DetailedWorkspace): boolean => {
  if (workspace.slackWorkspace.type !== 'installed' || workspace.slackWorkspace.tokenInvalid) {
    return false
  }

  const workspaceScopes = workspace.slackWorkspace.tokenScopes

  return discussionSlackSyncScopes.every((s) => workspaceScopes.includes(s))
}

export const SlackPassiveOnaBotScopes: SlackScope[] = ['users:read', 'users:read.email']
export const SlackPassiveOnaUserScopes: SlackScope[] = ['channels:history', 'channels:read']

export const SlackOauthEndpoint = {
  login: '/slack-api/sign-in',
  addToSlack: '/slack-api/add-to-slack',
  addOnaToSlack: '/slack-ona-api/add-ona-to-slack',
} as const satisfies Record<SlackOauthIntent, string>

type SlackAuthContext = {
  stage: Stage
  redirectPath?: string
  slackTeamId?: string
}

export const slackOAuthLoginUrl = (authContext: SlackAuthContext): string => {
  const redirectUri = encodeURIComponent(
    SLACK_BASE_URL[authContext.stage] + SlackOauthEndpoint.login
  )
  const teamParam = authContext.slackTeamId ? `&team=${authContext.slackTeamId}` : ''
  const stateParam = authContext.redirectPath
    ? `&state=${encodeURIComponent(authContext.redirectPath)}`
    : ''

  const scopes = SlackLoginScopes.join(',')

  return `https://slack.com/openid/connect/authorize?response_type=code&scope=${scopes}&client_id=${
    SLACK_CLIENT_ID[authContext.stage]
  }&redirect_uri=${redirectUri}${teamParam}${stateParam}`
}

export const slackOAuthAddToSlackUrl = (stage: Stage, workspaceId: TeamId | null): string => {
  const redirectUri = encodeURIComponent(SLACK_BASE_URL[stage] + SlackOauthEndpoint.addToSlack)
  const scopes = addToSlackScopes.join(',')

  return `https://slack.com/oauth/v2/authorize?scope=${scopes}&client_id=${
    SLACK_CLIENT_ID[stage]
  }&redirect_uri=${redirectUri}&state=${workspaceId ?? ''}`
}

export const slackOAuthAddOnaToSlackUrl = ({ teamId }: { teamId: TeamId }): string => {
  return `/slack-ona-api/install?teamId=${teamId}`
}

export const getWorkspaceSamlMetadataUrl = (workspacePublicId: WorkspaceClientId, stage: Stage) =>
  `${BASE_URL[stage]}/saml/consume/${workspacePublicId}`

export const getWorkspaceSamlSignInUrl = (workspacePublicId: WorkspaceClientId, stage: Stage) =>
  `${BASE_URL[stage]}/auth/sso/saml/${workspacePublicId}/login`

export const MAX_NEW_TEAM_INVITED_MEMBERS = 15
export const MAX_FREE_USER_REQUESTS_PER_WEEK = 50

export const SENTRY_TUNNEL_PATHNAME = '/bugs'
export const SENTRY_HOST = 'o267299.ingest.sentry.io'
export const SENTRY_FRONTEND_PROJECT = '1519323'
export const SENTRY_FRONTEND_DSN = `https://7688cdd36cdd4246b6d9ee49ee25b1b7@${SENTRY_HOST}/${SENTRY_FRONTEND_PROJECT}`

export const PUBLIC_STRIPE_KEY = {
  dev: 'pk_test_hCtedJFoWpbzL1TjrHxvuGiC00hGko3f3D',
  staging: 'pk_test_hCtedJFoWpbzL1TjrHxvuGiC00hGko3f3D',
  production: 'pk_live_YEhffS0FhpGDkUew6f5mCdy6001Ftg9Or7',
} as const satisfies Record<Stage, string>
