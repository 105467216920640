import type { ApiDefinition, ApiEndpoint } from '@teamspective/common'
import { apiEndpoints, extractRequestId, mapObject } from '@teamspective/common'
import axiosLib, { AxiosError } from 'axios'
import { captureException } from '@sentry/browser'
import { notification, Typography } from 'antd'

const axios = axiosLib.create({
  baseURL: '/api',
  withCredentials: true,
  validateStatus: (status: number) => status !== 400 && status < 500,
})

const clientSideErrors = [
  AxiosError.ERR_NETWORK,
  AxiosError.ERR_CANCELED,
  AxiosError.ETIMEDOUT,
  AxiosError.ECONNABORTED,
]

const captureAndThrow = (e: Error): never => {
  const axiosError = e instanceof AxiosError ? e : undefined
  const reportToSentry = !axiosError || !clientSideErrors.includes(axiosError.code as string)

  const response = axiosError?.response
  const requestId = response ? extractRequestId(response) : undefined

  notification.error({
    message: 'Unfortunately there was an error',
    description: (
      <span>
        {`${reportToSentry ? 'Our team has been notified. ' : ''}Please try again soon.`}
        {requestId && (
          <>
            <br />
            <Typography.Text copyable style={{ fontSize: '10px' }}>
              {`RequestId: ${requestId}`}
            </Typography.Text>
          </>
        )}
      </span>
    ),
  })

  if (reportToSentry) {
    captureException(e, {
      extra: axiosError
        ? (axiosError.toJSON() as Record<string, unknown>)
        : { note: 'not-instanceof-axios-error' },
      contexts: { logging: { reqId: requestId } },
    })
  }

  throw e
}

const httpGet = async <R, P>(url: string, payload?: P): Promise<R> => {
  const reply = await axios
    .get<R>(url, payload ? { params: payload } : undefined)
    .catch(captureAndThrow)
  return reply.data
}

const httpDelete = async <R, P>(url: string, payload?: P): Promise<R> => {
  const reply = await axios
    .delete<R>(url, payload ? { params: payload } : undefined)
    .catch(captureAndThrow)
  return reply.data
}

const httpPost = async <R, P>(url: string, payload?: P): Promise<R> => {
  return await axios
    .post<R>(url, payload)
    .then((r) => r.data)
    .catch(captureAndThrow)
}

const httpPut = async <R, P>(url: string, payload?: P): Promise<R> => {
  return await axios
    .put<R>(url, payload)
    .then((r) => r.data)
    .catch(captureAndThrow)
}

const getEndpoint = <R, P>(e: ApiEndpoint) => {
  switch (e.method) {
    case 'get':
      return (p?: P) => httpGet<R, P>(e.url, p)
    case 'delete':
      return (p?: P) => httpDelete<R, P>(e.url, p)
    case 'post':
      return (p?: P) => httpPost<R, P>(e.url, p)
    case 'put':
      return (p?: P) => httpPut<R, P>(e.url, p)
  }
}

export const api = mapObject(apiEndpoints, getEndpoint) as ApiDefinition
