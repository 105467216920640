import type { Branded } from '../common.ts'
import type {
  UserId,
  TeamId,
  PassiveSlackSourceId,
  PassiveGoogleSourceId,
  PassiveMsTeamsSourceId,
} from '../ids.ts'

export const PassiveIdNamespace = {
  /**
   * Global namespaced ids. These are non-conflicting and possibly shared between sources.
   * These must not be low-numbers due to likelihood of conflicts with other sources.
   *
   * Examples: emails, personal phone numbers
   */
  Global: '' as Branded<'', 'PassiveId_GLOBAL'>,
  /** Google long-ids */
  Google: 'go' as Branded<'go', 'PassiveId_GOOGLE'>,
  /** Slack user ids. (Can conflict between workspaces) */
  Slack: 'sl' as Branded<'sl', 'PassiveId_SLACK'>,
  /** Teamspective user ids */
  Teamspective: 'teamspective' as Branded<'teamspective', 'PassiveId_TEAMSPERSPECTIVE'>,
} as const
export type PassiveIdNamespace = typeof PassiveIdNamespace
export type IdPrefix = PassiveIdNamespace[keyof PassiveIdNamespace]
export type PassiveIdentityId = `${IdPrefix}${string}`
export type ActivityType = 'invitation' | 'meeting' | 'message' | 'reaction' | 'mention'

export type PassiveIdentity =
  | {
      identityType: 'user'
      /** Name of the user */
      label: string
      userId: UserId
      teamIds: TeamId[]
      ids: PassiveIdentityId[]
    }
  | {
      identityType: 'external'
      ids: PassiveIdentityId[]
      /** Name of the user or one of the IDs if no name is specified */
      label: string
      primaryEmail?: string
    }

export const passiveNetworkMetrics = ['collaborationCentrality', 'engagement', 'demand'] as const
export type PassiveNetworkMetric = (typeof passiveNetworkMetrics)[number]

export const isPassiveNetworkMetric = (value: string | null): value is PassiveNetworkMetric =>
  Boolean(value) && passiveNetworkMetrics.includes(value as PassiveNetworkMetric)

/**
 * Payload for `addPassiveSource`.
 *
 * Used for integrations that do not come from redirect-based authorization flows
 * but instead come from UI settings setup.
 */
export type NewPassiveSource = {
  type: 'google-calendar'
  adminEmail: string
}

export type PassiveSourceConfiguration = {
  slack?: {
    sourceId: PassiveSlackSourceId
    channels: number
    channelsSynced: number
    active: boolean
    scopesUpToDate: boolean
  }
  googleCalendar?: {
    sourceId: PassiveGoogleSourceId
    calendars: number
    calendarsSynced: number
    active: boolean
  }
  msTeams?: {
    sourceId: PassiveMsTeamsSourceId
    channels: number
    channelsSynced: number
    active: boolean
  }
}

/**
 * Available passive data sources.
 *
 * Note that ms-teams is not implemented in fetching.
 */
export const passiveDataSources = ['slack', 'googleCalendar', 'msTeams'] as const
export type PassiveDataSource = (typeof passiveDataSources)[number]
