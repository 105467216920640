import { captureEvent } from '@sentry/react'
import type { Stage, UserId } from '@teamspective/common'
import { HEAP_APP_ID } from '@teamspective/common'
import { isRunningTests } from '../isRunningTests'
import { logger } from '../util'

let heapAppId: string | null = null

export const identifyHeapUser = (userId: UserId, retryCount = 0): void => {
  if (isRunningTests) {
    return
  }
  if (retryCount > 5) {
    logger.warn('Heap not initialized - user not identified.', { heapAppId, userId, retryCount })
    return
  }
  if (!heapAppId || !('heap' in window)) {
    setTimeout(() => identifyHeapUser(userId, retryCount + 1), 500)
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  ;(window.heap as any).identify(userId.toString())
}

export const initHeap = (stage: Stage): void => {
  if (isRunningTests) {
    return
  }
  if (heapAppId) {
    captureEvent({ message: 'Heap double initialization' })
    return
  }
  heapAppId = HEAP_APP_ID[stage]
  if (!heapAppId) {
    logger.info('No heap app id found.', { heapAppId, stage })
    return
  }

  if (!('heap' in window)) {
    logger.warn('Heap not initialized.', { heapAppId })
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  ;(window.heap as any).load(heapAppId)
}
