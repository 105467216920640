import { useState } from 'react'
import { Button, Card, Tooltip, Dropdown } from 'antd'
import { CheckOutlined, PlusOutlined as PlusIcon, BulbOutlined } from '@ant-design/icons'

import type {
  AnyRequest,
  FeedbackCategory,
  Feedback,
  Language,
  RequestTopic,
  TopicId,
} from '@teamspective/common'
import { links, userFirstName, withEllipsis, userName } from '@teamspective/common'
import styled from 'styled-components'

import Loader from '../../components/Loader'
import TopicTitle from '../../components/feedback/TopicTitle'
import { lightBrown, lightBlack } from '@teamspective/common'
import { translations } from '@teamspective/common'
import useTypewriter from '../../components/hooks/useTypewriter'
import topicStore from '../../stores/topicStore'
import { CloseIcon, FavouriteIcon } from '../../components/icons'
import authStore from '../../stores/authStore'
import Redirect from '../../components/Redirect'
import EditFeedback from './EditFeedback'
import type { FeedbackApi } from './DemoFeedbackApi'
import type { ItemType } from 'antd/es/menu/interface'

const numberToCategory = (n: number): 'None' | 'One' | 'Two' | 'Three' | 'TooMany' => {
  switch (n) {
    case 0:
      return 'None'
    case 1:
      return 'One'
    case 2:
      return 'Two'
    case 3:
      return 'Three'
    default:
      return 'TooMany'
  }
}

const FeedbackTopicCard = styled(Card)`
  width: calc(50% - 16px);
  &:nth-child(2n) {
    margin-left: 8px;
  }
  &:nth-child(2n + 1) {
    margin-right: 8px;
  }
  margin-bottom: 16px;
  border-color: ${lightBrown};

  @media screen and (max-width: 940px) {
    && {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
`

const FeedbackTopicButton = styled(FeedbackTopicCard).attrs({ as: Button })`
  height: 153px !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: dashed;
  color: ${lightBlack};
  font-weight: initial;
  cursor: pointer;
`

const RemoveTopicButton = styled(Button)`
  padding: 0 4px;
`

const TooltipParagraph = styled.p`
  &:last-of-type {
    margin-bottom: 0;
  }
`

const AddTopicSection = ({
  request,
  requestTopics,
  favouriteTopicIds,
  lang,
  refresh,
  api,
}: {
  request: AnyRequest
  requestTopics: RequestTopic[]
  favouriteTopicIds: TopicId[]
  lang: Language
  refresh: () => void
  api: FeedbackApi
}) => {
  const { topics: allTopics } = topicStore.useContainer()

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items:
          allTopics !== 'loading'
            ? allTopics
                .filter((t) => !requestTopics.some((rt) => rt.topicId === t.topicId))
                .map((t): ItemType => {
                  const isFavouriteTopic = favouriteTopicIds.includes(t.topicId)
                  return {
                    key: t.topicId,
                    label: (
                      <Tooltip
                        placement={requestTopics.length % 2 === 0 ? 'right' : 'left'}
                        title={
                          <>
                            <TooltipParagraph>{t.description}</TooltipParagraph>
                            {isFavouriteTopic && (
                              <TooltipParagraph>
                                {translations[lang].wantsFeedbackOnTopic(
                                  userName(request.requester)
                                )}
                              </TooltipParagraph>
                            )}
                          </>
                        }
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>{t.label}</span>
                          <span>{isFavouriteTopic ? <FavouriteIcon /> : null}</span>
                        </div>
                      </Tooltip>
                    ),
                    onClick: () =>
                      api
                        .addRequestTopic({ requestId: request.requestId, topicId: t.topicId })
                        .then(refresh),
                  }
                })
            : undefined,
      }}
    >
      <FeedbackTopicButton size="small" key="add-topic">
        {requestTopics.length ? translations[lang].addAnotherTopic : translations[lang].chooseTopic}
      </FeedbackTopicButton>
    </Dropdown>
  )
}

const CreateFeedback = ({
  request,
  requestTopics,
  refresh,
  lang,
  favouriteTopicIds,
  api,
}: {
  request: 'none' | 'loading' | AnyRequest
  requestTopics: RequestTopic[]
  refresh: () => unknown
  lang: Language
  favouriteTopicIds: TopicId[]
  api: FeedbackApi
}): JSX.Element => {
  const { currentUserId } = authStore.useContainer()

  const [editingTopic, setEditingTopic] = useState<{
    topic: RequestTopic
    type: FeedbackCategory
  } | null>(null)

  const existingFeedbackDrafts =
    request === 'none' || request === 'loading' ? [] : request.feedback.filter((f) => f.content)

  const amount = numberToCategory(existingFeedbackDrafts.length)
  const cheer = useTypewriter(translations[lang][`feedbackInstructions${amount}`])

  if (request === 'none') {
    return <Redirect to={links.frontPage} />
  }

  if (request === 'loading') {
    return <Loader />
  }
  return (
    <div style={{ marginBottom: 60 }}>
      <h3 style={{ marginBottom: 24 }}>
        <BulbOutlined style={{ marginRight: 12 }} />
        {cheer}
      </h3>
      {editingTopic ? (
        <EditFeedback
          lang={lang}
          request={request}
          topic={editingTopic.topic}
          category={editingTopic.type}
          onClose={() => {
            refresh()
            setEditingTopic(null)
          }}
          api={api}
        />
      ) : null}
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        {requestTopics.map((topic) => {
          const redirecting = existingFeedbackDrafts.filter(
            (f) => f.topicId === topic.topicId && f.category === 'redirecting'
          )
          const reinforcing = existingFeedbackDrafts.filter(
            (f) => f.topicId === topic.topicId && f.category === 'reinforcing'
          )

          const feedbackWritten = Boolean(redirecting.length || reinforcing.length)
          return (
            <FeedbackTopicCard
              size="small"
              styles={{ body: { position: 'relative' } }}
              key={topic.topicId}
              title={
                <TopicTitle
                  titleStyle={{ lineHeight: '36px' }}
                  topic={topic}
                  name={userFirstName(request.requester)}
                  lang={lang}
                />
              }
              className={feedbackWritten ? 'always-on-parent' : 'hover-parent'}
              extra={
                <>
                  {favouriteTopicIds.includes(topic.topicId) && (
                    <FavouriteIcon style={{ marginRight: 8 }} />
                  )}
                  {topic.createdByUserId === currentUserId && (
                    <RemoveTopicButton
                      onClick={() =>
                        api
                          .removeRequestTopic({
                            requestId: request.requestId,
                            topicId: topic.topicId,
                          })
                          .then(refresh)
                      }
                      type="text"
                      disabled={feedbackWritten}
                    >
                      <CloseIcon />
                    </RemoveTopicButton>
                  )}
                </>
              }
            >
              <div
                className="not-visible-on-parent-hover"
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {translations[lang].writeFeedback}
              </div>

              <div
                className="narrow-screen-flex-wrap"
                style={{ display: 'flex', flex: 1, flexDirection: 'row', gap: 8 }}
              >
                {(
                  [
                    ['reinforcing', reinforcing],
                    ['redirecting', redirecting],
                  ] as [FeedbackCategory, Feedback[]][]
                ).map(([category, feedback]) => (
                  <Tooltip
                    key={category}
                    title={
                      feedback.length
                        ? withEllipsis(feedback.map((r) => r.content).join(', '), 400)
                        : null
                    }
                  >
                    <Button
                      className="visible-on-parent-hover"
                      onClick={() => setEditingTopic({ topic, type: category })}
                      type={feedback.length ? 'primary' : 'dashed'}
                      block
                      style={{
                        height: 'unset',
                        paddingTop: 10,
                        paddingBottom: 8,
                        display: 'block',
                      }}
                      data-cy={`${category}-${topic.topicId}`}
                    >
                      <div>
                        {feedback.length ? <CheckOutlined /> : <PlusIcon />}
                        <span style={{ marginLeft: 8 }}>
                          {translations[lang][`${category}Feedback`]}
                        </span>
                      </div>
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontSize: 12,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {translations[lang][`${category}Synonyms`]}
                      </span>
                    </Button>
                  </Tooltip>
                ))}
              </div>
            </FeedbackTopicCard>
          )
        })}
        <AddTopicSection
          request={request}
          requestTopics={requestTopics}
          favouriteTopicIds={favouriteTopicIds}
          lang={lang}
          refresh={refresh}
          api={api}
        />
      </div>
    </div>
  )
}

export default CreateFeedback
