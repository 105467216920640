import type { FeedbackId, FeedbackVisibility, ToBeInvitedUser } from '@teamspective/common'
import {
  listItemsInNaturalLanguage,
  type Language,
  type User,
  userName,
  isNotNull,
} from '@teamspective/common'
import { translations } from '@teamspective/common'

export const getFeedbackVisibilityDescription = ({
  visibility,
  lang,
  feedbackShares,
  recipient,
  provider,
}: {
  visibility: FeedbackVisibility
  recipient: User | null
  provider: User | ToBeInvitedUser | 'anonymous' | null
  feedbackShares: { user: User; feedbackId: FeedbackId }[]
  lang: Language
}): string[] => {
  const recipientProviderVisibility =
    !recipient && !provider
      ? translations[lang].visibleToProviderAndRecipient
      : `${translations[lang].visibleTo} ${listItemsInNaturalLanguage[lang](
          [recipient, provider === 'anonymous' ? null : provider].filter(isNotNull).map(userName)
        )}`

  const sharedVisibility = feedbackShares.length
    ? `${translations[lang].sharedWith} ${listItemsInNaturalLanguage[lang](
        feedbackShares.map((u) => userName(u.user))
      )}.`
    : null

  const privacyVisibility = translations[lang][`feedbackVisibility_${visibility}`]

  return [recipientProviderVisibility, sharedVisibility, privacyVisibility].filter(isNotNull)
}
