import { useCallback, useState } from 'react'
import { Alert, Button, Checkbox, Input, Space } from 'antd'
import type { RequestLoginTokenPayload } from '@teamspective/common'
import { emailRegex } from '@teamspective/common'

import { useLocation } from 'react-router'
import { ArrowRightIcon, EmailIcon } from '../icons'
import { colorError } from '@teamspective/common'
import { Trans, useLingui } from '@lingui/react/macro'

const EmailForm = ({
  onSubmit,
  showRememberMe,
  cta,
  freeDomainEmail,
}: {
  onSubmit: (params: RequestLoginTokenPayload) => void
  showRememberMe: boolean
  cta: 'login' | 'signUp'
  freeDomainEmail?: string
}): JSX.Element => {
  const { t } = useLingui()

  const { pathname } = useLocation()

  const [email, setEmail] = useState<string>('')
  const [rememberMe, setRememberMe] = useState<boolean>(true)
  const [errorState, setErrorState] = useState<boolean>(false)

  const isFreeDomainEmail = freeDomainEmail && email === freeDomainEmail
  const isValidEmail = emailRegex.test(email) && !isFreeDomainEmail

  const submit = useCallback(() => {
    setErrorState(false)
    if (!isValidEmail) {
      setErrorState(true)
      return
    }
    try {
      onSubmit({ email, rememberMe, redirect: pathname })
    } catch {
      setErrorState(true)
    }
  }, [email, rememberMe, onSubmit, pathname, isValidEmail])

  return (
    <div style={{ width: '100%', textAlign: 'left' }}>
      <Space.Compact style={{ width: '100%' }}>
        <Input
          id="input-email"
          onChange={(e) => setEmail(e.target.value)}
          onPressEnter={submit}
          prefix={<EmailIcon style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
          placeholder={t`Company email`}
          type="email"
          style={{ width: 'calc(100% - 100px)', height: 40, boxSizing: 'border-box' }}
          status={isFreeDomainEmail ? 'error' : undefined}
        />
        <Button
          id={`action-${cta}`}
          onClick={submit}
          type="primary"
          disabled={!isValidEmail}
          htmlType="submit"
          style={{ width: 100, height: 40 }}
        >
          {cta === 'login' ? <Trans>Log in</Trans> : <Trans>Sign up</Trans>}
          <ArrowRightIcon />
        </Button>
      </Space.Compact>

      {isFreeDomainEmail && (
        <div style={{ textAlign: 'center', color: colorError, marginTop: 8 }}>
          <Trans>Please use your company email to log in</Trans>
        </div>
      )}

      {isValidEmail && showRememberMe ? (
        <Checkbox
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
          style={{ marginTop: 8 }}
        >
          <Trans>Keep me logged in in this browser</Trans>
        </Checkbox>
      ) : null}

      {errorState ? (
        <Alert
          message={t`Unfortunately there was an error`}
          type="error"
          closable
          afterClose={() => setErrorState(false)}
          style={{ marginBottom: 24 }}
        />
      ) : (
        <div />
      )}
    </div>
  )
}

export default EmailForm
